import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import SaversMainContainer from './parts/SaversMainContainer';

import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

import InputText from '../../elements/InputText';
// import InputNumber from '../../elements/InputNumber';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import InputCheckbox from '../../elements/InputCheckbox';

import ContactListModal from '../../modals/ContactListModal';
import OrderItemFinderModal from '../../modals/OrderItemFinderModal';
import OrderListModal from '../../modals/OrderListModal';
import CourierListModal from '../../modals/CourierListModal';
import initTreeView from '../../../helpers/initTreeView';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Fieldset } from 'primereact/fieldset';

export default function GroupForm (){
    const { group_id } = useParams();
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Savers Group');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.shipment_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]    );
    const [customer_data, setCustomer] = useState([]);
    const [customer_modal, setContactModal] = useState(0);
    const [so_list_modal, setSOModal] = useState(0);
    const [is_delivered, setIsDelivered] = useState(0);

    /** Modals */
    const [product_modal, setProductModal] = useState(0);
    const [couriers_list, setCouriersList] = useState([]);
    const [couriers_modal, setCouriersModal] = useState(0);
   
    useEffect(() => {
        initTreeView()

        if(group_id){
            setPageTitle('Savers Group Info')
            getData(group_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items]);

    useEffect(() => {
    }, [customer_data]);

    

    useEffect(() => {

    }, [record_data.shipping_status_id]);

    function getData(group_id){
        if(!group_id){
            return
        }
        const url = helpers.getUrl(`v1/savers/groups/${group_id}`)

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.shipment_date = new Date(res.shipment_date)
            if(res.delivered_date){
                items.delivered_date = new Date(res.delivered_date)
            }

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            setRecordLoaded(1)

            if(res.delivered_date){
                setIsDelivered(1)
            }
        })
        .catch((err) => {
            setLoading(0)
            //getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    //window.location.replace("/shipments/list");
                }
            })
        }); 
    }

    // function getCourierList(page = '', search = '', get_status = '',date_start='',date_end=''){
    //     let filter = `?store_id=${helpers.getStoreID()}&paginate=0&with_default=1&limit=0&`

    //     if(page){
    //         filter += 'page='+page+'&'
    //     }
    //     if(search){
    //         filter += 'search='+search+'&'
    //     }
    
    //     const queryString = window.location.search ?? '';
    //     const urlParams = new URLSearchParams(queryString);
    //     let param = urlParams.get('status') ?? '';

    //     if(param){
    //         filter += 'filter_by=status&filter='+param+'&'
    //     }

    //     if(get_status){
    //         filter += 'filter_by=status&filter='+get_status+'&'
    //     }

    //     if(date_start && date_end){
    //         filter += 'date_from='+date_start+'&'+'date_to='+date_end+'&'
    //     }

    //     filter = filter.slice(0, -1)

    //     setLoading(1)

    //     const url = helpers.getUrl('V1/manage/couriers/list'+filter)

    //     httpRequest(url, "GET", {}, true)
    //         .then((res) => {
    //             setCouriersList(res)
    //             setLoading(0)
    //         })
    //         .catch((err) => {
    //             //console.log(err)
    //             setLoading(0)
    //         }); 
    // }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {
                return(
                <tr>
                    <td>{data.product_name} ({data.product_variant_name})</td>
                    <td>{data.quantity}</td>
                    {
                    record_loaded ?
                    <td>{data.fulfilled_shipment}</td> : ''
                    }
                    <td>
                        <InputNumber
                            id="quantity_to_ship" 
                            name="quantity_to_ship" 
                            value={data.quantity_to_ship}
                            // tagClass="form-control mb-0"
                            inputClassName="form-control rounded-0"
                            // onChange={(e) => inputItemChange(e, key)}
                            min={0}
                            max={data.fulfilled_shipment}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="Quantity"
                            decrementButtonClassName="p-input-button-left" 
                            incrementButtonClassName="p-input-button-right" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        />
                    </td>
                    <td><button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button></td>
                </tr>)

            })
        }
        else{
            // items = <tr>
            //             <td colSpan={5} className='text-center'>&nbsp;</td>
            //         </tr>
        }


        setTableItems(items)
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...record_items]
        let target = {...items[key]}

        target[name] = value
        items[key] = target

        setRecordItems(items)
    }

    function removeItem(key){
        let items = [...record_items]
        items.splice(key, 1); 

        setRecordItems(items)
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        if(name == 'is_delivered'){
            setIsDelivered(value)
            return;
        }

        if(name === 'courier_id'){
            
            let couriers = couriers_list.find((el) => {
                if(el.id === parseInt(value)){
                    return el
                }

            })
            new_state.courier = couriers.courier_name
        }

        setRecordData({ ...record_data, ...new_state });
    }

    function handleProduct(e){
        let items = [...record_items]

        const fulfilled_quantity = parseInt(e.fulfilled_quantity) ?? 0;
        const remaining_quantity = parseInt(e.quantity) - fulfilled_quantity

        let new_item = {
            "product_id": e.id,
            "product_code": e.product_code,
            "product_name": e.product_name,
            "product_variant_id": e.product_variant_id,
            "product_variant_name": e.product_variant_name,
            "quantity": e.quantity,
            "quantity_to_ship": "",
            "fulfilled_shipment": remaining_quantity,
            "shipping_status_id": record_data.shipping_status_id,
            "so_item_id": e.so_item_id
        }

        items.push(new_item)

        setRecordItems(items)
    }
    
    function handleOrder(e){
        let data = {...record_data}

        data.so_id = e.id
        data.sales_order = e.order_no
        
        setRecordItems([])
        setRecordData(data)
    }

    function submit(){
        setLoading(1)

        let url = helpers.getUrl(`V1/manage/order/shipment/create`)
        if(group_id){
            url = helpers.getUrl(`V1/manage/order/shipment/${group_id}`)
        }

        let form_data = {...record_data}
        form_data['items'] = record_items;
        form_data['sales_order'] = record_data.so_id

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = group_id;
                if(!id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/shipments/${id}/form`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    

    function handleCustomer(e){
        let data = {...record_data}
        let customer = {...customer_data}

        data.customer_id = e.id
        data.customer_name = e.contact_name
        data.customer_data = e

        data.so_id = ''
        data.sales_order = ''

        customer.id = e.id
        customer.contact_name = e.contact_name

        setRecordData(data)
        setCustomer(e)
    }

    function handleCourier(e){
        let data = {...record_data}

        data.courier_id = e.id
        data.courier = e.courier_name
        data.courier_name = e.courier_name

        setRecordData(data)
        //getCourierList()
    }


    return (
        <>
        <ContactListModal is_show={customer_modal} setShow={setContactModal} handleSelect={handleCustomer} />

        <OrderListModal id={record_data.customer_id} is_show={so_list_modal} setShow={setSOModal} handleSelect={handleOrder} type="sales" />

        {/* <CourierFormModal 
            show_modal={couriers_modal} 
            close_modal={setCouriersModal} 
            handleSave={handleCourier}
        /> */}

        <CourierListModal
            show_modal={couriers_modal} 
            close_modal={setCouriersModal}  
            handleSelect={handleCourier}
        />

        <OrderItemFinderModal 
            reference_id={record_data.customer_id} 
            is_show={product_modal} 
            setShow={setProductModal}  
            handleSelect={handleProduct} 
            exclude={record_items} 
            restrict_stock={false}
            show_price = {false}
            show_cost = {true}
            source='sales'
            order_id={record_data.so_id}
            title="Sold Items"
        />

        <SaversMainContainer page_title={page_title} menu="manage-group" breadcrumbs={true} >
                <div className="content-fluid">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        {group_id ?
                        <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                            <h5 className='mb-3 mb-md-0 d-flex'>Code: {group_id}</h5>
                            <div className='d-flex flex-column flex-sm-row'>
                            </div>

                            <div className='text-right'>     
                                <button type='button' className='btn btn-success mr-0 ' onClick={(e) => submit(1)} style={{padding: '0.5rem 0.75rem'}}>Save</button>
                            </div>
                        </div> : ''}

                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-5 pb-2">
                                            <div className="row">
                                                <div className="col-sm-2 col-lg-2 text-left text-md-right">
                                                    <label>Group Name <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-sm-7 col-md-5 col-lg-4 form-group">
                                                    <InputText
                                                        id="group_name" 
                                                        name="group_name" 
                                                        value={record_data.group_name}
                                                        tagClass="form-control mb-0"
                                                        placeholder=""
                                                        onChange={inputChange}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="tracking_no" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <TabView className='border-top'>
                                            <TabPanel header="Settings">
                                                <div className="row">
                                                    <div className="border rounded col-lg-6 p-3">
                                                        a
                                                    </div>
                                                    <div className="col-lg-6">
                                                        
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel header="Members">
                                                <p className="m-0">
                                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, 
                                                    eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                                                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui 
                                                    ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                                                </p>
                                            </TabPanel>
                                        </TabView>
                                    </div>

                                </div>
                            </div>

                            <div className='text-right mt-3'>  
                                <button type='button' className='btn btn-success mr-0 ' onClick={(e) => submit(1)} disabled={!record_data.sales_order} style={{padding: '0.5rem 0.75rem'}}>Save</button>
                                {/* <Link className='btn btn-outline-dark' to="/shipments/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>                   */}
                                
                            </div>
                        </form>
                    </div>
                </div>
        </SaversMainContainer>
        </>
    )
}