import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../helpers/images';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import helpers from '../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../parts/Loader';
import initTreeView from '../../helpers/initTreeView';
 
export default function UnderConstructionPage ({module="Under Construction"}){
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        initTreeView()
        //Mounted
        //getDataList()
    }, []);


    return (
        <>
        <MainContainer page_title={module} breadcrumbs={true}>
            <div className="container text-center" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <h1 className='text-primary'><strong>COMING SOON.</strong></h1>
                {
                    module ? <h4>The {module} is under Construction.</h4> : <h4>This feature is under Construction.</h4>
                }
            </div>
        </MainContainer>
        </>
    )
}