import React, { useEffect, useState } from 'react';
import { useParams  } from "react-router-dom";
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
import images from '../../../helpers/images';
import initTreeView from '../../../helpers/initTreeView';
import StoreInfo from '../../parts/StoreInfo';

export default function SalesOrderPrintView (){
    const { order_id } = useParams();
    const [loading, setLoading] = useState(1);
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.sales_order_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);
   
    useEffect(() => {
        initTreeView()
        if(order_id){
            getData(order_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items]);

    function getData(order_id){
        if(!order_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/order/sales/${order_id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.order_date = new Date(res.order_date)

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            setRecordLoaded(1)
            
            setTimeout(function(){
                window.print()
            }, 2000);
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/sales/list");
                }
            })
        }); 

    }
    function computeSubTotals(){
        let items = 0;
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
            items = items + Number(data.quantity)
            total_amount = total_amount + parseInt(data.quantity) * parseFloat(data.amount)
        });

        return helpers.formatCurrencyAmount(total_amount);
    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {
                return(
                <tr>
                    <td>
                        <div className='mb-0'>{data.product_name}{data.product_variant_name != 'Generic' ? ` (${data.product_variant_name})` : ''}</div>
                        <div><small>SKU: {data.product_code}</small></div>
                        {data.product_description ? <div className='mt-1'><small style={{whiteSpace: 'pre-line'}}>{data.product_description}</small></div> : ''}
                    </td>
                    {/* <td>{data.product_code}</td> */}
                    <td>{data.quantity}{data.unit_name ? ` ${data.unit_name}` : ''}</td>
                    <td>{helpers.formatCurrencyAmount(data.amount)}</td>
                    {/* <td>{data.tax_amount ? helpers.formatCurrencyAmount(data.tax_amount) : '---'}</td> */}
                    <td>{helpers.formatCurrencyAmount(data.total_amount)}</td>
                </tr>)

            })
        }

        setTableItems(<tbody>{items}</tbody>)
    }

    return (
        <div className="invoice p-4 mb-3">
        <Loader isLoading={loading}/>
            <div className="d-flex justify-content-between mb-3">
                <StoreInfo store={record_data.store_info} />

                <div className='text-right'>
                    <h2><strong>SALES ORDER</strong></h2>
                    {/* <h5>{helpers.displayOrderStatusBadge(record_data.order_status_id, record_data.order_status)}</h5> */}
                    <h5 className='mb-0'>{record_data.order_no ? `Order #: ${record_data.order_no}` : '---'}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-4 mb-3">
                    <h5 className='mb-0'><b>Bill To:</b></h5>
                    <div>
                        <p className='mb-0'><b>{record_data.customer_name}</b></p>
                        <p className='mb-0'>{record_data.billing_address}</p>
                        <p className='mb-0'>{record_data.billing_phone}</p>
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <h5 className='mb-0'><b>Ship To:</b></h5>
                    <div>
                        <p className='mb-0'><b>{record_data.shipping_contact_name}</b></p>
                        <p className='mb-0'>{record_data.shipping_address}</p>
                        <p className='mb-0'>{record_data.shipping_phone}</p>
                    </div>
                </div>
                <div className="col-4 text-lg-right mb-2">
                    <table className='table table-borderless table-sm'>
                        <tbody>
                            {/* <tr>
                                <td className='py-0'><b>Order #:</b></td>
                                <td className='py-0'>{record_data.order_no ? record_data.order_no : '---'}</td>
                            </tr> */}
                            <tr>
                                <td className='py-0'><b>Date:</b></td>
                                <td className='py-0'>{record_loaded ? helpers.formatDate(record_data.order_date) : '---'}</td>
                            </tr>
                            <tr>
                                <td className='py-0'><b>Terms:</b></td>
                                <td className='py-0'>{record_data.payment_term ? record_data.payment_term : '---'}</td>
                            </tr>
                            {/* <tr>
                                <td className='py-0'><b>Shipment Status:</b></td>
                                <td className='py-0'>{record_data.shipping_status ? record_data.shipping_status : '---'}</td>
                            </tr> */}
                            {
                                record_data.order_status_id == 4
                                ?
                                <tr>
                                    <td className='py-0'><b>Reason:</b></td>
                                    <td className='py-0'>{record_data.reason}</td>
                                </tr> : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='table-responsive'>
                <table className='table table-striped table-sm border-bottom'>
                    <thead>
                        <tr>
                            <th>Item</th>
                            {/* <th>SKU</th> */}
                            <th>Quantity</th>
                            <th>Price</th>
                            {/* <th>Tax</th> */}
                            <th>Amount</th>
                        </tr>
                    </thead>
                    {
                        table_items
                    }
                </table>
            </div>

            <div className='row'>
                <div className="col-7 d-flex align-items-center">{record_data.customer_notes ? <p className='pt-1 pt-lg-4'>{record_data.customer_notes}</p>:''}</div>
                <div className="col-5 mb-2 border p-2">
                    <table className='table table-sm table-borderless mb-0'>
                        <tbody>
                            <tr>
                                <td className='border-top-0'><b>Sub Total</b></td>
                                <td className='border-top-0'>{computeSubTotals()}</td>
                            </tr>
                            <tr>
                                <td><b>Shipping Fee</b></td>
                                <td>{helpers.formatCurrencyAmount(record_data.shipping_fee)}</td>
                            </tr>
                            <tr>
                                <td><b>Tax</b></td>
                                <td>{parseFloat(record_data.total_tax) > 0 ? helpers.formatCurrencyAmount(record_data.total_tax) : '---'}</td>
                            </tr>
                            <tr className='bg-dark'>
                                <td><b>Total</b></td>
                                <td>{helpers.formatCurrencyAmount(record_data.total_amount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row border-top mt-5">
                <div className="col-12 text-center">
                    <small>Powered By Topventory Solutions</small>
                </div>
            </div>
        </div>
    )
}