import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
import DataTable from 'react-data-table-component';
import store from '../../helpers/store';
 
export default function ProductFinderModal ({
        is_show = false, 
        setShow, 
        handleSelect, 
        exclude = [], 
        restrict_stock = true, 
        show_price = true, 
        show_cost = false,
        module="",
        module_ref=false,
        warehouse_id = ''
    }){
    const [loading, setLoading] = useState(0);
    const [notice, setNotice] = useState(<div className="alert alert-primary px-3 py-2 mb-0 text-center mt-3" role="alert">Search Item to Begin.</div>);
    const [result_list, setProductResult] = useState([]);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    
   
    useEffect(() => {

    }, []);
   
    useEffect(() => {
        reset()
    }, [is_show]);
   
    useEffect(() => {
        displayTable()
    }, [result_list]);

    function displayTable(){
        let tables = []

        let data = []

        //if(result_list.hasOwnProperty('data')) return;
        if(result_list.hasOwnProperty('data')){
            data = result_list.data
        }
        
        if (Object.keys(data).length > 0) {
            tables = Object.entries(data).map(([key, data]) => {

        
                const result = exclude.find((item) => {
                    if(item.product_variant_id == data.product_variant_id) {
                        return item.product_variant_id;
                    }
                })

                if(result) return;

                let cell = 'bg-maroon'
                if(parseInt(data.available_stock) > 0){
                    cell = 'bg-teal'
                }

                return (<tr key={data.product_variant_id} className='cursor-pointer'>
                            <td>{data.product_variant_id}</td>
                            <td>
                                <b>{data.product_name} ({data.product_variant_name})</b><br/>
                                <span>Product Code: {data.product_code}</span>
                            </td>
                            <td>{data.product_type_id ? (data.product_type_id == 1 ? 'Goods' : 'Services') : '---'}</td>
                            <td>{data.category_name ? data.category_name : '[Deleted]'}</td>
                            <td width={100} className="text-center">
                                <span className={`badge ${cell}`} style={{fontSize: '18px'}}>{data.available_stock}</span>
                            </td>
                            {/* <td>{data.warehouse_name}</td> */}
                            {show_price ? <td>&#8369;{helpers.formatAmount(data.product_price)}</td> : ''}
                            {show_cost ? <td>&#8369;{helpers.formatAmount(data.product_cost)}</td> : ''}
                            <td width={100} className='text-center'>{displayAction(data)}</td>
                        </tr>)
            })
        }

        if(tables.length){
            setDataTable(
                <>
                    <table className='table table-sm mb-0 item-table-header table-hover'>
                        <thead>
                            <tr>
                                <th className='bg-light'>ID</th>
                                <th className='bg-light'>ITEMS</th>
                                <th className='bg-light'>TYPE</th>
                                <th className='bg-light'>CATEGORY</th>
                                <th className='bg-light text-center'>STOCKS</th>
                                {/* <th className='bg-light text-center'>WAREHOUSE</th> */}
                                {show_price ? <th className='bg-light'>PRICE</th> : ''}
                                {show_cost ? <th className='bg-light'>COST</th> : ''}
                                <th className='bg-light'></th>
                            </tr>
                        </thead>
                        <tbody>{tables}</tbody>
                    </table>
                </>
            )
        }
        else if(!tables.length && search_key){
            setDataTable('')
            setNotice(<div className="alert alert-info mt-3 px-3 py-2 mb-0" role="alert">No available item</div>)
        }
        else {
            setNotice(<div className="alert alert-info mt-3 px-3 py-2 mb-0" role="alert">Search Item to Begin.</div>)
        }
    }

    function displayAction(data){
        let buttons = []

        if(restrict_stock){
            if(parseInt(data.available_stock) <= 0 && data.track_inventory == 1){
                //buttons.push(<button className='btn btn-sm btn-danger' type="button" disabled>Out of stock</button>)
                buttons.push(<span className='text-danger'>Out of stock</span>)
            }
            else{
                buttons.push(<button className='btn btn-sm btn-primary' type="button" onClick={(e) => onSelect(data)}>Select</button>)
            }
        

            return buttons
        }

        buttons.push(<button className='btn btn-sm btn-primary' type="button" onClick={(e) => onSelect(data)}>Select</button>)

        return buttons
    }

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function onSelect(e){
        reset();
        setShow(0);
        handleSelect(e)
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if(!value) {
            reset()
            return;
        }

        setLoading(0)
        setSearchKey(value)

        let filter = `?store_id=${helpers.getStoreID()}&`

        if(value){
            filter += 'search='+value+'&'
        }

        if(module){
            filter += 'module='+module+'&'
        }
        if(module_ref){
            filter += 'module_ref='+module_ref+'&'
        }
        if(warehouse_id){
            filter += 'warehouse_id='+warehouse_id+'&'
        }

        
        
        filter = filter.slice(0, -1)

        const url = helpers.getUrl(`V1/manage/item/search`+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setProductResult(res)
                setLoading(0)
            })
            .catch(() => {
                reset()
                setProductResult([])
                setLoading(0)
                setNotice(<div className="alert alert-warning mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 
    }

    function reset(){
        setProductResult([])
        setDataTable([])
        setNotice('')
        setSearchKey('')
    }

    return (
        <>
            <Modal show={is_show} size="xl">
                <Modal.Header>
                    <h5>Item Finder</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="row"  style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <div className="col-12">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search a item" aria-label="Search a item" aria-describedby="basic-addon2" onChange={inputChange} autoFocus />
                                {/* <div className="input-group-append">
                                    <button className="btn btn-outline-primary" type="button" onClick={getData}>Search</button>
                                </div> */}
                            </div>
                            <span className='text-danger'>{notice}</span>
                            {/* {search_key ? <span>Search result for: {search_key}</span> : ''} */}
                        </div>
                    </div>
                    
                    <div className='mt-3' style={{maxHeight: '500px', overflow: 'auto', scrollBehavior: 'smooth'}}>
                        {data_table}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}