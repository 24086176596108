/**
 * Select Input Reusable Components
 */
import React from "react";

const DropDownSelection = ({
        id, 
        name, 
        value, 
        placeholder = '', 
        label='',
        labelClass='',
        optionList, 
        optionProps={},
        tagClass='',
        onChange, 
        onFocus,
        readOnly = false,
        disabled = false,
        ucWords=false, // Upper Case Words
        exclude = []
    }) => {

    /** Dynamically access object property **/
    const opt_value = optionProps.value; 
    const opt_label = optionProps.label; 

    function ucwords(str){
        str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
        });
    
        return str;
    }

    return (
        <>
            {label && <label htmlFor={id} className={labelClass}>{label}</label>}
            <select
                id={id}
                // onFocus={(e) => onFocus(e)}
                onChange={(e) => onChange(e)}
                name={name}
                className={tagClass}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
            >
                {/* Placeholder */}
                <option value="" disabled>{placeholder}</option>

                {optionList &&
                    optionList.length > 0 &&
                    optionList.map((option, key) => {
                        //console.log(exclude)
                        //console.log(option[opt_value].toString())
                        //console.log(exclude.includes(option[opt_value]))

                        return (
                            <option key={key} value={option[opt_value]}>
                                {ucWords ? ucwords(option[opt_label]) : option[opt_label]}
                            </option>
                        );
                    })}
            </select>
        </>
    );
};

export default DropDownSelection;
