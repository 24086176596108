import React, { useEffect } from 'react';
import { Routes, Route, Navigate, redirect, useLocation  } from "react-router-dom";

/** Component Pages Here */
import HomePage from '../components/pages/HomePage';
import StoreSelector from '../components/pages/StoreSelector';

/**
 * Store Profile
 */
import StoreProfilePage from '../components/pages/store/StoreProfilePage';
import StoreDeliveryAddressPage from '../components/pages/store/StoreDeliveryAddressPage';
import StoreWarehousesPage from '../components/pages/store/StoreWarehousesPage';

import LoginPage from '../components/pages/LoginPage';
import Page404 from '../components/pages/Page404';
import DeniedPage from '../components/pages/DeniedPage';

import StarterTemplate from '../components/pages/StarterTemplate';

import ProfilePage from '../components/pages/account/ProfilePage';

import ProductList from '../components/pages/products/ProductList';
import ProductForm from '../components/pages/products/ProductForm';

import InventoryAdjustmentList from '../components/pages/products/InventoryAdjustmentList';
import InventoryAdjustmentForm from '../components/pages/products/InventoryAdjustmentForm';

import SalesOrderList from '../components/pages/sales-order/SalesOrderList';
//import SalesOrderForm from '../components/pages/sales-order/SalesOrderForm';
import SalesOrderView from '../components/pages/sales-order/SalesOrderView';
import SalesOrderForm from '../components/pages/sales-order/SalesOrderForm';
import SalesOrderPrintView from '../components/pages/sales-order/SalesOrderPrintView';

import SalesReturnList from '../components/pages/sales-order/SalesReturnList';
import SalesReturnForm from '../components/pages/sales-order/SalesReturnForm';

import InvoicesList from '../components/pages/invoices/InvoicesList';
import InvoiceForm from '../components/pages/invoices/InvoiceForm';
import InvoiceView from '../components/pages/invoices/InvoiceView';
import InvoicePrintView from '../components/pages/invoices/InvoicePrintView';

import PaymentReceiveList from '../components/pages/payment-received/PaymentReceiveList';
import PaymentReceiveForm from '../components/pages/payment-received/PaymentReceiveForm';

import PurchaseOrderList from '../components/pages/purchase-order/PurchaseOrderList';
import PurchaseOrderFormV1 from '../components/pages/purchase-order/PurchaseOrderFormBAK';
import PurchaseOrderForm from '../components/pages/purchase-order/PurchaseOrderForm';
import PurchaseOrderPrintView from '../components/pages/purchase-order/PurchaseOrderPrintView';
import PurchaseOrderView from '../components/pages/purchase-order/PurchaseOrderView';

import PurchaseReceivesList from '../components/pages/purchase-order/PurchaseReceivesList';
import PurchaseReceiveForm from '../components/pages/purchase-order/PurchaseReceiveForm';

import BillsList from '../components/pages/bills/BillsList';
import BillForm from '../components/pages/bills/BillForm';
import BillView from '../components/pages/bills/BillView';
import BillPrintView from '../components/pages/bills/BillPrintView';

import PaymentMadeList from '../components/pages/payment-made/PaymentMadeList';
import PaymentMadeForm from '../components/pages/payment-made/PaymentMadeForm';

import BrandsList from '../components/pages/management/BrandsList';
import VariantTypesList from '../components/pages/products/VariantTypesList';
import CategoryList from '../components/pages/management/CategoryList';

import ShipmentsList from '../components/pages/sales-order/ShipmentList';
import ShipmentForm from '../components/pages/sales-order/ShipmentForm';

import ContactList from '../components/pages/management/ContactList';
import ContactForm from '../components/pages/management/ContactForm';

import VendorsList from '../components/pages/management/VendorsList';
import VendorsForm from '../components/pages/management/VendorsForm';

import CouriersList from '../components/pages/management/CouriersList';

import helpers from '../helpers/helpers';
import Swal from 'sweetalert2';

/**
 * Reports
 */
import MainReportPage from '../components/pages/reports/MainReportPage';
import ReportStock from '../components/pages/reports/ReportStock';
import ReportSalesOrder from '../components/pages/reports/ReportSalesOrder';
import ReportSalesItem from '../components/pages/reports/ReportSalesItem';
import ReportInvoices from '../components/pages/reports/ReportInvoices';
import ReportPurchasesOrder from '../components/pages/reports/ReportPurchasesOrder';
import ReportPurchasesItem from '../components/pages/reports/ReportPurchasesItem';
import ReportBills from '../components/pages/reports/ReportBills';
import ReportContacts from '../components/pages/reports/ReportContacts';
import ReportVendors from '../components/pages/reports/ReportVendors';
import ReportPaymentReceived from '../components/pages/reports/ReportPaymentReceived';
import ReportPaymentMade from '../components/pages/reports/ReportPaymentMade';
import ReportSalesReturn from '../components/pages/reports/ReportSalesReturn';
/************************ */

/**
 * Settings
 */
import PageSettings from '../components/pages/management/PageSettings';
import TaxRatesList from '../components/pages/management/TaxRatesList';

import UnderConstructionPage from '../components/pages/UnderConstructionPage';

/**
 * Accounting Module
 */
import ChartOfAccountsList from '../components/pages/accounting/ChartOfAccountsList';
import JournalsList from '../components/pages/accounting/JournalsList';
import SharesList from '../components/app/savers/SharesList';
import HomePageSavers from '../components/app/savers/HomePageSavers';
import SharesForm from '../components/app/savers/SharesForm';
import GroupForm from '../components/app/savers/GroupForm';


const Protected = ({ isLoggedIn, allowedPermissions=[],  children, requestor='' }) => {
    const is_authenticated = helpers.isAuthenticated();
    const token = helpers.getToken();
    const authorized = helpers.checkPermissions(allowedPermissions);
    const default_store = Object.keys(helpers.getDefaultStore()).length

    if(token === 'undefined' || !is_authenticated){
        return <Navigate replace to="/login" />
    }
    else if(!default_store){
        return <Navigate replace to="/my-store" />
    }
    else if(allowedPermissions.length && !authorized){
        //return <Navigate replace to="/" />
        return <DeniedPage />
    }
    
    return children;
};
const Protected2 = ({ isLoggedIn, allowedPermissions=[],  children, requestor='' }) => {
    const is_authenticated = helpers.isAuthenticated();
    const token = helpers.getToken();
    const authorized = helpers.checkPermissions(allowedPermissions);

    if(token === 'undefined' || !is_authenticated){
        return <Navigate replace to="/login" />
    }
    else if(allowedPermissions.length && !authorized){
        return <DeniedPage />
    }
    
    return children;
};

function MainRoutes(){
    const location = useLocation();
    var interval = setInterval(() => {
        if(location.pathname != '/login' && location.pathname != '/'){
            if(helpers.getToken() === 'undefined' || !helpers.isAuthenticated()){
            clearInterval(interval);

            Swal.fire({
                //title: 'Your session has change or expired',
                text: 'Your session has changed or expired. Please log in again to continue using the app.',
                showCancelButton: false,
                showConfirmButton: true,
                closeOnCancel: true,
                confirmButtonText: 'Log In',
                allowOutsideClick: false,
                icon: "error",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/login";
                    }
                })
            }
            return;
        }
    }, 3000);

    return (
        <>
        <Routes>
            <Route 
                exact path="/"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <Navigate replace to="/dashboard" />
                    </Protected>
                }
            />

            <Route 
                exact path="/login" 
                element={<LoginPage />} 
            />

            <Route path="/account">
                <Route 
                    exact path="profile" 
                    element={
                        <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                            <ProfilePage />
                        </Protected2>
                    }
                />
            </Route>

            

            {/* <Route path="/inventory"> */}
                    <Route 
                        exact path="dashboard"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()}>
                                <HomePage />
                            </Protected>
                        }
                    />

                    {/* STORE MANAGEMENT */}
                    <Route path="store">
                        <Route 
                            exact path="profile"
                            element={
                                <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                                    <StoreProfilePage />
                                </Protected2>
                            }
                        />
                        <Route 
                            exact path="delivery-address"
                            element={
                                <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                                    <StoreDeliveryAddressPage />
                                </Protected2>
                            }
                        />
                        <Route 
                            exact path="warehouses"
                            element={
                                <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                                    <StoreWarehousesPage />
                                </Protected2>
                            }
                        />
                        {/* <Route 
                            exact path="store"
                            element={
                                <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                                    <UnderConstructionPage module="Store Management" />
                                </Protected2>
                            }
                        /> */}
                    </Route>
                    
                    {/* PRODUCTS */}
                    <Route path="item">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                    <ProductList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":product_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <ProductForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ProductForm />
                                </Protected>
                            }
                        />
                    </Route>
                        
                    <Route 
                        exact path="inventory-adjustment"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <InventoryAdjustmentList />
                            </Protected>
                        }
                    />

                        
                    <Route 
                        exact path="inventory-adjustment/:adjustment_id/edit"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <InventoryAdjustmentForm />
                            </Protected>
                        }
                    />
                    
                    <Route 
                        exact path="inventory-adjustment/form"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <InventoryAdjustmentForm />
                            </Protected>
                        }
                    />
                    
                    <Route 
                        exact path="settings/brands"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                <BrandsList />
                            </Protected>
                        }
                    />
                
                    <Route 
                        exact path="settings/variant-types"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                <VariantTypesList />
                            </Protected>
                        }
                    />
                
                    <Route 
                        exact path="settings/categories"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                <CategoryList />
                            </Protected>
                        }
                    />

                    {/* SALES ORDER */}
                    <Route path="sales">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                    <SalesOrderList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <SalesOrderForm />
                                </Protected>
                            }
                        />
                        
                        <Route  //View
                            exact path=":order_id"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <SalesOrderView />
                                </Protected>
                            }
                        />

                        <Route // Print View
                            exact path=":order_id/print"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <SalesOrderPrintView />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":order_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <SalesOrderForm />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path=":order_id/sales-return"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <SalesReturnForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="invoices">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoicesList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoiceForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":invoice_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoiceForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":invoice_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoiceForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":invoice_id/payment"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentReceiveForm />
                                </Protected>
                            }
                        />
                        
                        <Route  //View
                            exact path=":invoice_id"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoiceView />
                                </Protected>
                            }
                        />

                        <Route // Print View
                            exact path=":invoice_id/print"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <InvoicePrintView />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="payment-receives">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentReceiveList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentReceiveForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":payment_receive_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentReceiveForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="shipments">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                    <ShipmentsList />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path=":shipment_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <ShipmentForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ShipmentForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="sales-returns">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                    <SalesReturnList />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path=":sales_return_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <SalesReturnForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="contacts">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ContactList />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path=":contact_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <ContactForm />
                                </Protected>
                            }
                        />
                    
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ContactForm />
                                </Protected>
                            }
                        />
                    </Route>
                    
                    <Route 
                        exact path="settings/couriers"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                <CouriersList />
                            </Protected>
                        }
                    />

                    {/* PURCHASE ORDER */}

                    <Route path="purchases">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-dashboard"]}>
                                    <PurchaseOrderList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseOrderForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":order_id"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseOrderView />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":order_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseOrderForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":order_id/print"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseOrderPrintView />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form-v1"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseOrderFormV1 />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="bills">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillsList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":bill_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":bill_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":bill_id/payment"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentMadeForm />
                                </Protected>
                            }
                        />
                        
                        <Route  //View
                            exact path=":bill_id"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillView />
                                </Protected>
                            }
                        />

                        <Route // Print View
                            exact path=":bill_id/print"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <BillPrintView />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="payment-made">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentMadeList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentMadeForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":payment_made_id/form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                    <PaymentMadeForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="purchase-receives">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseReceivesList />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":receive_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseReceiveForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <PurchaseReceiveForm />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="vendors">
                        <Route 
                            exact path="list"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <VendorsList />
                                </Protected>
                            }
                        />
                    
                        <Route 
                            exact path="form"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <VendorsForm />
                                </Protected>
                            }
                        />
                        
                        <Route 
                            exact path=":vendor_id/edit"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <VendorsForm />
                                </Protected>
                            }
                        />
                    </Route>

                    {/* Reports */}

                    <Route 
                        exact path="reports"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <MainReportPage />
                            </Protected>
                        }
                    />

                    <Route 
                        exact path="reports/item"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <ReportStock />
                            </Protected>
                        }
                    />

                    <Route path="reports">

                        <Route 
                            exact path="sales"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportSalesOrder />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="sales/top-items"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportSalesItem filtered_by='top-items' />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="sales/pending-deliveries"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportSalesItem filtered_by='pending-delivery' />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="sales-return"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportSalesReturn />
                                </Protected>
                            }
                        />

                    </Route>

                    <Route path="reports">
                        <Route 
                            exact path="invoices"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportInvoices />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="bills"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportBills />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="reports/payments">
                        <Route 
                            exact path="received"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportPaymentReceived />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="made"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportPaymentMade />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route path="reports">
                        <Route 
                            exact path="purchases"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportPurchasesOrder />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="purchases/top-items"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportPurchasesItem filtered_by='top-items' />
                                </Protected>
                            }
                        />

                        <Route 
                            exact path="purchases/receivables"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <ReportPurchasesItem filtered_by='receivables' />
                                </Protected>
                            }
                        />
                    </Route>

                    <Route 
                        exact path="reports/contacts"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <ReportContacts />
                            </Protected>
                        }
                    />

                    <Route 
                        exact path="reports/vendors"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <ReportVendors />
                            </Protected>
                        }
                    />
                    {/* End of Reports */}

                    <Route 
                        exact path="settings" 
                        element={
                            <Protected2 isLoggedIn={helpers.isAuthenticated()}>
                                <PageSettings />
                            </Protected2>
                        }
                    />

                    <Route path="settings">
                        <Route 
                            exact path="tax-rates"
                            element={
                                <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                    <TaxRatesList />
                                </Protected>
                            }
                        />
                    </Route>

                    {/* Accounting Module */}
                    <Route 
                        exact path="chart-of-accounts"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <ChartOfAccountsList />
                            </Protected>
                        }
                    />
                    <Route 
                        exact path="journals"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access", "service-request-create"]}>
                                <JournalsList />
                            </Protected>
                        }
                    />
            {/* </Route> */}

            <Route path="app/savers">
                <Route path=":group_id">
                    <Route 
                        exact path="dashboard"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <HomePageSavers />
                            </Protected>
                        }
                    />
                    <Route 
                        exact path="info"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <GroupForm />
                            </Protected>
                        }
                    />
                    <Route 
                        exact path="shares"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <SharesList />
                            </Protected>
                        }
                    />
                    <Route 
                        exact path="shares/new"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <SharesForm />
                            </Protected>
                        }
                    />
                    <Route 
                        exact path="shares/:share_id"
                        element={
                            <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                                <SharesForm />
                            </Protected>
                        }
                    />
                </Route>

                <Route 
                    exact path="group/new"
                    element={
                        <Protected isLoggedIn={helpers.isAuthenticated()} allowedPermissions={["all-access"]}>
                            <SharesList />
                        </Protected>
                    }
                />
            </Route>

            {/* Savers App */}

            <Route 
                exact path="/template"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <StarterTemplate />
                    </Protected>
                }
            />

            <Route 
                exact path="/app"
                element={
                    <Protected isLoggedIn={helpers.isAuthenticated()}>
                        <Navigate replace to="/dashboard" />
                    </Protected>
                }
            />

            <Route 
                path="*" 
                element={<Page404 />} 
            />

            
            {/* Apps Module */}
            

            <Route 
                exact path="/production" 
                element={<UnderConstructionPage module="Production Management System" />} 
            />
            <Route 
                exact path="/finance" 
                element={<UnderConstructionPage module="Accounting Management System" />} 
            />
            <Route 
                exact path="/customerx" 
                element={<UnderConstructionPage module="Customer Experience Management" />} 
            />
            <Route 
                exact path="/talents" 
                element={<UnderConstructionPage module="HR Management System" />} 
            />
            <Route 
                exact path="/logistics" 
                element={<UnderConstructionPage module="Logistic Management System" />} 
            />
        </Routes>
        </>
    )
}

export default MainRoutes;
