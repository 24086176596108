import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import { httpRequest } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';

import CourierFormModal from '../../modals/CourierFormModal';
import Dropdown from 'react-bootstrap/Dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import initTreeView from '../../../helpers/initTreeView';
 
export default function CouriersList ({viewer = 'admin', menu = 'settings', page_title = "Manage Custom Couriers"}){
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [search_key, setSearchKey] = useState('');
    const [courier_id, setCourierID] = useState('');
    const [show_modal, setShowModal] = useState(0);

    const [data_table, setDataTable] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [selected_all, setSelectAll] = useState(false);
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });

    useEffect(() => {
        initTreeView()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        selected_all
    ]);

    useEffect(() => {
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            "search": search_key
        })
    }, [datatable_state]);

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        const search = condition.search ?? search_key
        let filter = `?store_id=${helpers.getStoreID()}&paginate=1&`

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(search){
            filter += 'search='+search+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/manage/couriers/list'+filter)

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            displayDataTable(res)
        })
        .catch((err) => {
            //console.log(err)
            setLoading(0)

            Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }); 
    }
    
    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": datatable_state.page + 1,
            "search": value
        })
    }

    function triggerAction(data = '', action = '', status = ''){
        let ids = [];
        if(action === 'single-delete'){
            ids.push(data.id)
        }
        else{
            ids = data.map(o => o['id']);
        }

        let title = 'Delete Courier'
        if(ids.length > 1){
            title = 'Delete Couriers'
        }

        Swal.fire({
            title: title,
            text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
            showCancelButton: true,
            showConfirmButton: false,
            closeOnCancel: true,
            showDenyButton: true,
            denyButtonText: 'Yes'
        })
        .then((result) => {
            if (result.isDenied) {
                deleteItem(ids)
            }
        })
    }

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/couriers/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "courier_ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    "search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                className='border-top'
                dataKey={data.id}
                value={data.data} 
                // tableStyle={{ minWidth: '50rem' }}
                size="normal"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollHeight="500px"
                scrollable
                responsive={true}
                emptyMessage="No data to diplay"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="courier_name" header="NAME" ></Column>
                <Column className="text-right" body={displayActionMenu} style={{ maxWidth: '50px' }}></Column> 
            </DataTable>
        )

    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function displayActionMenu(data){
        return (<Dropdown>
                        <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item key="edit" onClick={(e) => editForm(data.id)}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>)
    }

    function addForm(e){
        setCourierID('')
        setShowModal(1)
    }
    function editForm(id){
        setShowModal(1)
        setCourierID(id)
    }

    function handleSave(){
        getDataList()
    }

    return (
        <>
        <CourierFormModal 
            show_modal={show_modal} 
            close_modal={setShowModal} 
            handleSave={handleSave}
            id={courier_id}
        />
        <MainContainer page_title={page_title} menu={menu} parent_link='/settings' parent_text="Settings">
            <div className="container-fluid">
                <div className='mb-4'>
                    <Link to="/settings" className="btn btn-outline-dark border"><i className="fas fa-arrow-left mr-2" /> Settings</Link>
                </div>
            </div>

            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header border-bottom-0">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <input type="text" className="form-control" placeholder="Search Courier" onChange={search} />
                                    </div>
                                    <div className="col-lg-8 text-right mt-3 mt-md-0">
                                        <button type='button' className='btn btn-primary float-right' onClick={addForm}><i className="fas fa-plus-circle mr-2" />New</button>
                                
                                        {selected_items.length ?
                                        <Dropdown className='float-right mr-3'>
                                            <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items)}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                { data_table }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
        </>
    )
}