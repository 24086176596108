import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

// import InputText from '../../elements/InputText';
// import InputNumber from '../../elements/InputNumber';
// import InputTextArea from '../../elements/InputTextArea';
import InputCheckbox from '../../elements/InputCheckbox';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';
import RenderErrors from '../../elements/RenderErrors';
import DropDownSelection from '../../elements/DropDownSelection';

import ContactListModal from '../../modals/ContactListModal';
import AddressBookModal from '../../modals/AddressBookModal';
import ProductFinderModal from '../../modals/ProductFinderModal';
import initTreeView from '../../../helpers/initTreeView';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';

import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
         

export default function PaymentReceiveForm (){
    const { payment_receive_id } = useParams();
    const { invoice_id } = useParams();

    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Payment Record');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [invoice_data, setInvoiceData] = useState([]);
    const [record_data, setRecordData] = useState(store.payment_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);
    const [customer_data, setCustomer] = useState(store.so_customer_data);
    const [enums, setEnums] = useState(store.order_enums);
    const [customer_modal, setContactModal] = useState(0);
    const [selected_customer_record, setCustomerRecord] = useState('new');
    const [received_amount, setAmountReceived] = useState(0);
    const [extra_amount, setExtraAmount] = useState(0);
    const [is_full_payment, setFullPayment] = useState(0);
    const [el_received_amount, setElAmountReceived] = useState([]);
    
   
    useEffect(() => {
        initTreeView()
        //const payment_receive_id = helpers.getURLParams('InvoiceID') ?? ''

        if(invoice_id){
            setPageTitle('Invoice Payment Record')
            //setInvoiceID(payment_receive_id)
            //getData(invoice_id)
            getInvoice(invoice_id)
        }
        else if(payment_receive_id){
            setPageTitle('Payment Received Details')
            getData(payment_receive_id)
        }
        else{
            getEnums()
        }
    }, []);

    useEffect(() => {
        if(el_received_amount){
            confirmApplyToAll(el_received_amount)
        }
    }, [el_received_amount]);

    useEffect(() => {
        displayTableItems()
        computeAppliedAmount();
        computeExtraAmount();
    }, [record_items]);

    useEffect(() => {
        if(record_data.received_amount){
            computeAppliedAmount()
            computeExtraAmount()
        }
    }, [record_data.received_amount]);

    useEffect(() => {
        let total_balance = 0

        if(is_full_payment){
            Object.entries(record_items).map(([key, data]) => {
                total_balance = parseFloat(total_balance) + parseFloat(data.due_balance)

                data['payment'] = parseFloat(data.due_balance)
                let items = [...record_items]
                items[key] = data
                setRecordItems(items)
            });
        }
        else{
            Object.entries(record_items).map(([key, data]) => {
                data['payment'] = 0
                let items = [...record_items]
                items[key] = data
                setRecordItems(items)
            });
        }

        if(!total_balance) total_balance = null;

        let new_state = { "received_amount" : total_balance }
        setRecordData({ ...record_data, ...new_state });

        displayTableItems()
    }, [is_full_payment]);

    function getData(payment_receive_id){
        if(!payment_receive_id){
            return
        }
        const url = helpers.getUrl('V1/manage/payment-receives/'+payment_receive_id+'?store_id='+helpers.getStoreID())
        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            
            let data = res;
            data.payment_date = new Date(res.payment_date)

            setLoading(0)
            setRecordLoaded(1)
            setRecordData(data)
            setRecordItems(data.items)
            getEnums()
        })
        .catch((err) => {
            setLoading(0)
            getEnums()
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    window.location.replace("/payment-receives/list");
                }
            })
        }); 

    }

    function getInvoice(invoice_id){
        if(!invoice_id){
            return
        }
        const url = helpers.getUrl('V1/manage/invoices/'+invoice_id+'?store_id='+helpers.getStoreID())
        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            const balance_amount = parseFloat(res.balance_amount)
            if(balance_amount <= 0){
                window.location.replace(`/invoices/${invoice_id}`);
            }

            setLoading(0)
            setRecordLoaded(1)
            getEnums()
            setInvoiceData(res)

            let data = {...record_data}
            data.customer_id = res.customer_id ?? ''
            data.customer_name = res.customer_name ?? ''
            setRecordData(data)
    
            let new_item = []
            new_item.push({
                "id": "",
                "invoice_id": res.id,
                "invoice_date": res.invoice_date,
                "invoice_no": res.invoice_no,
                "total_amount": res.total_amount,
                "balance_amount": res.balance_amount,
                "payment": 0,
                "due_balance": res.balance_amount
            })
            setRecordItems(new_item)
        })
        .catch((err) => {
            setLoading(0)
            getEnums()
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace(`/invoices/${invoice_id}/form`);
                }
            })
        }); 

    }

    function computeAppliedAmount(){
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
            if(data.payment){
                total_amount = total_amount + parseFloat(data.payment)
            }
        });

        setAmountReceived(<>&#8369;{helpers.formatAmount(total_amount)}</>)
        return total_amount;
    }

    function computeExtraAmount(){
        let received_amount = record_data.received_amount ?? 0
        let extra_amount = 0

        extra_amount = parseFloat(received_amount) - computeAppliedAmount()

        setExtraAmount(<>&#8369;{helpers.formatAmount(extra_amount)}</>)
    }

    function displayTableItems(){
        let table_items = []
        
        if (record_items.length) {
            table_items = Object.entries(record_items).map(([key, data]) => {
                return(
                <tr>
                    <td>{data.invoice_date ? helpers.formatDate(data.invoice_date) : '---'}</td>
                    <td>{data.invoice_no ? data.invoice_no : '---'}</td>
                    <td>&#8369;{helpers.formatAmount(data.total_amount)}</td>
                    <td>&#8369;{helpers.formatAmount(data.due_balance)}</td>
                    <td>
                        <InputNumber 
                            name="payment" 
                            value={parseFloat(data.payment)} 
                            id="payment" 
                            onValueChange={(e) => inputItemChange(e, key)} 
                            //mode="currency" 
                            //currency="PHP"
                            minFractionDigits={2}
                            min={0}
                            max={parseFloat(data.due_amount)}
                        />
                    </td>
                </tr>)

            })
        }

        setTableItems(table_items)
    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl('V1/enums/orders'+'?only=payment_methods&store_id='+helpers.getStoreID())
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if(name==='is_full_payment'){
            if (is_full_payment) {
                value = false;
            } else {
                value = true;
            }

            setFullPayment(!is_full_payment)
            return;
        }

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        } 

        if(name === 'received_amount'){
            if(value && !invoice_id){
                setElAmountReceived(e)
            }
            else{
                let items = [...record_items]
                let target = {...record_items[0]}
                target['payment'] = value
                items[0] = target
                setRecordItems(items)
            }
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        
        if(isNaN(parseFloat(value))){
            value = 0
        }

        let items = [...record_items]
        let target = {...record_items[key]}

        target[name] = value

        items[key] = target

        setRecordItems(items)
    }

    function handleCustomer(e){
        let data = {...record_data}

        data.customer_id = e.id ?? ''
        data.customer_name = e.contact_name ?? ''

        setCustomer(e)
        setRecordData(data)

        if(e.invoices.length){
            const invoices = e.invoices;
            let new_item = []
            new_item = Object.entries(invoices).map(([key, data]) => {
                    return ({
                            "id": "",
                            "invoice_id": data.id,
                            "invoice_date": data.invoice_date,
                            "invoice_no": data.invoice_no,
                            "total_amount": data.total_amount,
                            "balance_amount": data.balance_amount,
                            "payment": 0,
                            "due_balance": data.balance_amount
                    })
            })

            setRecordItems(new_item)
        }
    }

    function submit(e, status = ''){
        e.preventDefault()
        //setLoading(1)

        let url = helpers.getUrl('V1/manage/payment-receives/create')
        if(payment_receive_id){
            url = helpers.getUrl('V1/manage/payment-receives/' + payment_receive_id)
        }

        let form_data = {...record_data}
        form_data['items'] = record_items;

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = payment_receive_id;
                if(!id){
                    id = res.data.id
                }

                let redirect_url = `/payment-receives/${id}/form`
                if(invoice_id){
                    redirect_url = `/invoices/${invoice_id}`
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(redirect_url);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    const confirmApplyToAll = (event) => {
        if(event.originalEvent){
            confirmPopup({
                target: event.originalEvent.target,
                message: 'Would you like this amount to be applied to each payment field?',
                icon: 'pi pi-exclamation-triangle',
                defaultFocus: 'accept',
                accept
            });
        }
    };

    
    const accept  = () => {
        let received_amount = parseFloat(record_data.received_amount)

        Object.entries(record_items).map(([key, data]) => {
            if(received_amount >= parseFloat(data.due_balance)){
                data['payment'] = parseFloat(data.due_balance)
                received_amount = parseFloat(received_amount) - parseFloat(data.due_balance)
            }
            else if(received_amount != 0){
                data['payment'] = parseFloat(received_amount)
                received_amount = parseFloat(received_amount) - parseFloat(data.due_balance)
            }
            else{
                data['payment'] = 0
            }
            let items = [...record_items]
            items[key] = data
            setRecordItems(items)
        });
    };

    return (
        <>
        <ContactListModal is_show={customer_modal} setShow={setContactModal} handleSelect={handleCustomer} />

        <MainContainer page_title={page_title} menu="manage-payment-receives" breadcrumbs={true} parent_link='/payment-receives/list' parent_text="Manage Payment Receives">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <form>
                            <div className="row mb-2">
                                <div payment_receive_id="so-customer-section" className="col-lg-12">

                                    {record_data.payment_no ?
                                    <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                        <h5 className='mb-3 mb-md-0 d-flex'>{record_data.payment_no}</h5>
                                        <div className='d-flex flex-column flex-sm-row'>
                                        </div>
                                    </div> : ''}

                                    <div className="card">
                                        <div className="card-body pt-md-5">

                                            {record_data.invoice_no ?
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Payment #</label>
                                                </div>
                                                <div className="col-md-3 col-lg-2 form-group">
                                                <input type="text" className="form-control" placeholder="Payment No" value={record_data.invoice_no} readOnly />
                                                </div>
                                            </div> : ''}

                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Customer <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3">
                                                    <div className="input-group">
                                                        {
                                                            record_loaded ? 
                                                            <input type="text" className="form-control" placeholder="Select Customer" disabled={payment_receive_id}  value={record_data.customer_name}  /> 
                                                            : 
                                                            <input type="text" className="form-control" placeholder="Select Customer" onClick={(e) => setContactModal(1)} value={record_data.customer_name} />
                                                        }
                                                        

                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={(e) => setContactModal(1)} disabled={record_loaded}><i className="fas fa-search" /></button>
                                                        </div>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="customer_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"><hr/></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Payment Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Calendar 
                                                        name="payment_date"  
                                                        value={record_data.payment_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={!record_data.customer_name}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="payment_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            {
                                            invoice_id && record_loaded ?
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Due Balance</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 d-flex form-group">
                                                    <span className='border px-3 py-2 bg-light rounded'>{helpers.formatCurrencyAmount(invoice_data.balance_amount)}</span>
                                                </div>
                                            </div> : ''
                                            }

                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Received Amount <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <div className="p-inputgroup flex-1">
                                                        <span className="p-inputgroup-addon">&#8369;</span>
                                                        <InputNumber 
                                                            name="received_amount" 
                                                            value={record_data.received_amount} 
                                                            id="received_amount" 
                                                            onValueChange={inputChange} 
                                                            minFractionDigits={2}
                                                            disabled={!record_data.customer_name || is_full_payment}
                                                        />
                                                    </div>
                                                    <ConfirmPopup dismissable={false} />

                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="received_amount" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                    {
                                                        invoice_id && record_loaded ?
                                                        <RenderErrors 
                                                        errors={errors} 
                                                        name="items" 
                                                        className="text-danger mb-2"
                                                        style={{fontSize: '.9rem'}}
                                                        /> : ''
                                                    }

                                                    {  // For Single Invoice Payment
                                                        !record_loaded ?
                                                        <div className="d-flex align-items-center mt-2">
                                                            <Checkbox name="is_full_payment" id="is_full_payment" value={is_full_payment} onChange={inputChange} checked={is_full_payment} 
                                                                disabled={!record_data.customer_name} />
                                                            <label htmlFor="is_full_payment" className="m-0 ml-2 font-weight-normal">Full amount payment</label>
                                                        </div> : ''
                                                    }

                                                    {
                                                        invoice_id ?
                                                        <div className="d-flex align-items-center mt-2">
                                                            <Checkbox name="is_full_payment" id="is_full_payment" value={is_full_payment} onChange={inputChange} checked={is_full_payment} 
                                                                disabled={!record_data.customer_name} />
                                                            <label htmlFor="is_full_payment" className="m-0 ml-2 font-weight-normal">Full amount payment</label>
                                                        </div> : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Payment Method <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Dropdown  
                                                        name='payment_method_id'
                                                        value={parseInt(record_data.payment_method_id)} 
                                                        onChange={inputChange} 
                                                        options={enums.payment_methods} 
                                                        optionLabel="method_name" 
                                                        optionValue="id" 
                                                        placeholder="Select Payment Method" 
                                                        className="w-100" 
                                                        disabled={!record_data.customer_name}
                                                        filter
                                                        showClear
                                                     />

                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="payment_method_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Reference No</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText 
                                                        name="reference_no"
                                                        value={record_data.reference_no} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        disabled={!record_data.customer_name}  
                                                    />

                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="order_no" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className={!record_data.customer_name ? 'text-muted' : ''}>Notes</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                   <InputTextarea 
                                                        name="notes" 
                                                        value={record_data.notes}  
                                                        onChange={inputChange}
                                                        rows={2} 
                                                        cols={30} 
                                                        className='w-100'
                                                        disabled={!record_data.customer_name}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                            !invoice_id ?
                            <div payment_receive_id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th>DATE</th>
                                                        <th>INVOICE #</th>
                                                        <th>INVOICE AMOUNT</th>
                                                        <th width={200}>DUE AMOUNT</th>
                                                        <th width={200}>PAYMENT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    { errors.hasOwnProperty('items') ?
                                                    <tr>
                                                        <td colSpan={6} className='text-center'>
                                                            <RenderErrors 
                                                                errors={errors} 
                                                                name="items" 
                                                                className="text-danger mb-2"
                                                                style={{fontSize: '.9rem'}}
                                                            />
                                                        </td>
                                                    </tr> : ''}
                                                </tbody>
                                                <tbody className='border-top-0'>
                                                    <tr>
                                                        <td colSpan={4} className='text-right border-right'>Applied Payment Amount</td>
                                                        <td colSpan={1}><b>{received_amount}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4} className='text-right border-right'>Received Amount</td>
                                                        <td colSpan={1}><b>{helpers.formatCurrencyAmount(record_data.received_amount)}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4} className='text-right border-right'>Extra Amount</td>
                                                        <td colSpan={1}><b>{extra_amount}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""
                            }

                            <div className='text-right mt-3'>     
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(e, 5)}>Save</button>

                                {
                                    invoice_id ? 
                                    <Link className='btn btn-outline-dark' to={`/invoices/${invoice_id}`}>Cancel</Link> : 
                                    <Link className='btn btn-outline-dark' to="/payment-receives/list">Cancel</Link>
                                }
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}