import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
 
export default function OrderListModal ({id, is_show = false, setShow, handleSelect, type=''}){
    const [loading, setLoading] = useState(0);
    const [name, setName] = useState('');
    const [notice, setNotice] = useState('');
    const [order_list, setOrderList] = useState([]);
    const [table_data, setTableData] = useState([]);
    const [label, setLabel] = useState('');
    useEffect(() => {
        if(type == 'purchase'){
            setLabel('Purchase')
        }
        else if(type == 'sales'){
            setLabel('Sales')
        }

    }, []);

   
    useEffect(() => {
    }, [type]);
   
    useEffect(() => {
        if(is_show) {
            getData()
        }
    }, [is_show]);
   
    useEffect(() => {
        let table_items_lg = []

        
        if (Object.keys(order_list).length > 0) {
            table_items_lg = Object.entries(order_list).map(([key, data]) => {
                return (<tr key={data.id}>
                            <td>{data.order_no}</td>
                            <td width={50}>
                                <button className='btn btn-sm btn-primary' type="button" onClick={(e) => onSelect(data)}>Select</button>
                            </td>
                        </tr>)
            })
        }

        setTableData(
            <>
            <div>
                <table className='table table-hover table-sm mb-0 mt-3'><tbody>{table_items_lg}</tbody></table>
            </div>
            </>
        )
    }, [order_list]);

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function onSelect(e){
        reset();
        setShow(0);
        handleSelect(e)
    }

    function inputChange(e) {
        let { value, name } = e.target;
        
        if(!value) {
            setNotice('Vendor field is required') 
        }

        if(value) setNotice('');

        setName(value);
        getData(value)
    }

    function getData(po_no) {
        setLoading(1)

        let filter = '?store_id='+helpers.getStoreID()

        if(po_no){
            filter += '&search='+po_no+'&'
            filter = filter.slice(0, -1)
        }

        let url = ''
        if(type == 'purchase'){
            url = helpers.getUrl(`V1/manage/vendor/${id}/orders${filter}`)
        }
        else if(type == 'sales'){
            url = helpers.getUrl(`V1/manage/contact/${id}/orders${filter}`)
        }
        //let url = helpers.getUrl(`V1/manage/contact/${id}/orders`)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setOrderList(res)
                setLoading(0)
            })
            .catch((err) => {
                reset()
                setLoading(0)
                setNotice(<div className="alert alert-danger mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 
    }

    function reset(){
        setName('')
        setOrderList([])
        setTableData([])
        setNotice('')
    }

    return (
        <>
            <Modal show={is_show}>
                <Modal.Header>
                    <h5>Select {label} Order</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"  style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <div className="col-12">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder={"Search " + label +" Order #"} aria-label="Vendor Name" aria-describedby="basic-addon2" onChange={inputChange} autoFocus />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-primary" type="button" onClick={getData}>Search</button>
                                </div>
                            </div>
                            <span className='text-danger'>{notice}</span>

                        </div>
                    </div>

                    {table_data}
                </Modal.Body>
            </Modal>
        </>
    )
}