import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

import InputText from '../../elements/InputText';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';
import DropDownSelection from '../../elements/DropDownSelection';
import ProductFinderModal from '../../modals/ProductFinderModal';
import initTreeView from '../../../helpers/initTreeView';

import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
 
export default function InventoryAdjustmentForm (){
    const { adjustment_id } = useParams();
    //const [adjustment_id, setAdjustmentID] = useState(0);
    
    const [enums, setEnums] = useState(store.product_enums);

    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    
    const [page_title, setPageTitle] = useState('New Adjustment');
    const [table_items, setTableItems] = useState([]);

    /** Modals */
    const [product_modal, setProductModal] = useState(0);

    const [record_data, setAdjustmentData] = useState(store.adjustment_data);
    
    const [adjustment_items, setAdjustmentItems] = useState([]);
    //1 = Quantity 2 = Selling Price 3 = Buy Price
    
   
    useEffect(() => {
        if(adjustment_id){
            setPageTitle('Inventory Adjustment Details')
            getData(adjustment_id)
        }else{
            getEnums()
        }
    }, []);

    useEffect(() => {
        initTreeView()
        displayTableItems()
        //computeTotals();
    }, [adjustment_items, record_data.type_id]);

    useEffect(() => {

    }, [record_data.shipping_status_id]);

    function getData(adjustment_id){
        if(!adjustment_id){
            return
        }
        const url = helpers.getUrl('V1/manage/item/adjustment/'+adjustment_id+'?store_id='+helpers.getStoreID())

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.adjustment_date = new Date(res.adjustment_date)

            setLoading(0)
            setAdjustmentData(res)
            setAdjustmentItems(res.items)
            getEnums()
        })
        .catch((err) => {
            setLoading(0)
            //getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/inventory-adjustment");
                }
            })
        }); 
    }

    function getEnums(){
        setLoading(1)
        const url = helpers.getUrl(`V1/enums/item?store_id=${helpers.getStoreID()}&only=warehouses`)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            // let warehouses = res.warehouses
            // warehouses.unshift({
            //     "id": 0, "warehouse_name": "All Warehouse"
            // })
            // res.warehouses = warehouses

            setLoading(0)
            setEnums(res)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function displayTableItems(){
        let items = []
        
        if (adjustment_items.length) {
            items = Object.entries(adjustment_items).map(([key, data]) => {

                return(
                <tr>
                    <td>{data.product_variant_id}</td>
                    <td>{data.product_name} ({data.product_variant_name})</td>

                    { record_data.type_id == 1 ? <td>{data.available_stock}</td> : ''}
                    { record_data.type_id == 2 ? <td>&#8369;&nbsp;{helpers.formatAmount(data.product_price)}</td> : ''}
                    { record_data.type_id == 3 ? <td>&#8369;&nbsp;{helpers.formatAmount(data.product_cost)}</td> : ''}
                    <td>
                        { record_data.type_id == 1 ?
                        <InputNumber
                            id="new_value" 
                            name="new_value" 
                            value={data.new_value}
                            inputClassName="form-control rounded-0"
                            min={0}
                            max={999999999}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="Quantity"
                            decrementButtonClassName="p-input-button-left" 
                            incrementButtonClassName="p-input-button-right" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        /> :
                        <InputNumber
                            id="new_value" 
                            name="new_value" 
                            value={data.new_value}
                            inputClassName="form-control"
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="New Price"
                            minFractionDigits={2}
                            max={999999999}
                        />
                        }

                    </td>
                    <td><button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button></td>
                </tr>)

            })
        }
        else{
            // items = <tr>
            //             <td colSpan={5} className='text-center'>&nbsp;</td>
            //         </tr>
        }


        setTableItems(items)
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...adjustment_items]
        let target = {...items[key]}

        target[name] = value
        items[key] = target

        setAdjustmentItems(items)
    }

    function removeItem(key){
        let items = [...adjustment_items]
        items.splice(key, 1); 

        setAdjustmentItems(items)
    }
    
    function inputChange(e,) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        if(name === 'type_id'){
            new_state.warehouse_id = '';
        }
        if(name === 'warehouse_id'){
            setAdjustmentItems([])
        }

        setAdjustmentData({ ...record_data, ...new_state });
    }

    function handleProduct(e){
        let items = [...adjustment_items]

        let new_item = {
            "id": "",
            "product_variant_id": e.product_variant_id,
            "new_value": "",
            "old_value": "",
            "adjustment": "",
            "available_stock": e.available_stock,
            "product_price": e.product_price,
            "product_cost": e.product_cost,
            "product_name": e.product_name,
            "product_variant_name": e.product_variant_name
        }

        items.push(new_item)

        setAdjustmentItems(items)
    }

    

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;
        
        //form_data['sales_order'] = record_data.so_id

        if(!record_data.type_id){
            new_errors['type_id'] = ["The adjustment type field is required."]
        }
        else{
            delete new_errors['type_id'];
        }

        if(!record_data.adjustment_date){
            new_errors['adjustment_date'] = ["The date field is required."]
        }
        else{
            delete new_errors['adjustment_date'];
        }

        if(!record_data.reason_id){
            new_errors['reason_id'] = ["The reason field is required."]
        }
        else{
            delete new_errors['reason_id'];
        }

        if(record_data.type_id == 1){
            if(!record_data.warehouse_id){
                new_errors['warehouse_id'] = ["The warehouse field is required."]
            }
            else{
                delete new_errors['warehouse_id'];
            }
        }
        else{
            delete new_errors['warehouse_id'];
        }

        if(!adjustment_items.length){
            new_errors['items'] = ["The items field is required."]
        }
        else{
            delete new_errors['items'];
        }

        
        if(adjustment_items.length){
            const has_empty = adjustment_items.find((item) => {
                if(item.new_value==''){
                    return item;
                }
            })
            
            if(has_empty){
                new_errors['items'] = ["The items field format is invalid."]
            }
            else{
                delete new_errors['items'];
            }
        }

        setErrors(new_errors);

        return new_errors;
    }

    function submit(){
        const validate = validateForm();

        if(Object.keys(validate).length) return;
        setLoading(1)

        let url = helpers.getUrl('V1/manage/item/adjustment/create')
        if(adjustment_id){
            url = helpers.getUrl('V1/manage/item/adjustment/' + adjustment_id)
        }

        let form_data = {...record_data}
        form_data['items'] = adjustment_items;
        form_data['store_id'] = helpers.getStoreID();

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = adjustment_id;
                if(!adjustment_id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/inventory-adjustment/${id}/edit`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <>
        <ProductFinderModal 
            is_show={product_modal} 
            setShow={setProductModal}  
            handleSelect={handleProduct} 
            exclude={adjustment_items} 
            restrict_stock={false}
            show_price = {false}
            show_cost = {false}
            module="adjustment"
            module_ref={record_data.type_id}
            warehouse_id={record_data.warehouse_id}
        />

        <MainContainer page_title={page_title} menu="manage-inventory-adjustment" breadcrumbs={true} parent_link='/inventory-adjustment' parent_text="Manage Inventory Adjustment">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-5">
                                            <div className="row">
                                                <div className="col-12"></div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Adjustment Type <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <ButtonGroupSelection
                                                        tagClass="form-control"
                                                        id="type_id"
                                                        name='type_id' 
                                                        value={record_data.type_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={store.adjustment_type}
                                                        optionProps ={{value:'id', label:'label'}}
                                                        disabled={adjustment_id}
                                                        //size='sm'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="type_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            { record_data.type_id == 1 ?
                                            <div className="row">
                                                <div className="col-12"></div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Warehouse <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-4 form-group">
                                                    {
                                                        !adjustment_id ?
                                                        <DropDownSelection
                                                            tagClass="form-control"
                                                            id="warehouse_id"
                                                            name='warehouse_id' 
                                                            placeholder='Select Warehouse'
                                                            value={record_data.warehouse_id}  
                                                            onChange={inputChange}
                                                            onFocus=""
                                                            optionList={enums.warehouses}
                                                            optionProps ={{value:'id', label:'warehouse_name'}}
                                                            disabled={adjustment_id}
                                                        /> :

                                                        <input type='text' className='form-control' value={record_data.warehouse_name} disabled />
                                                    }
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="warehouse_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div> : ''
                                            }
                                            <div className="row">
                                                <div className="col-12"></div>

                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    {/* <InputText
                                                        id="adjustment_date" 
                                                        name="adjustment_date" 
                                                        value={record_data.adjustment_date}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        placeholder="Shipment Date"
                                                        type="date"
                                                    /> */}
                                                    <Calendar 
                                                        id="adjustment_date" 
                                                        name="adjustment_date"  
                                                        value={record_data.adjustment_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={record_data.type_id == 1 && !record_data.warehouse_id}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="adjustment_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"></div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Reason <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-4 form-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="reason_id"
                                                        name='reason_id' 
                                                        placeholder='Select Adjustment Reason'
                                                        value={record_data.reason_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={store.adjustment_reasons}
                                                        optionProps ={{value:'id', label:'label'}}
                                                        disabled={record_data.type_id == 1 && !record_data.warehouse_id}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="reason_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"></div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Reference No</label>
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <InputText
                                                        id="reference_no" 
                                                        name="reference_no" 
                                                        value={record_data.reference_no}
                                                        tagClass="form-control mb-0"
                                                        placeholder="Refrence No"
                                                        onChange={inputChange}
                                                        disabled={record_data.type_id == 1 && !record_data.warehouse_id}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="reference_no" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"></div>
                                                <div className="col-md-1"></div>
                                                <div className="col-md-2">
                                                    <label>Description</label>
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <InputTextArea
                                                        id="description" 
                                                        name="description" 
                                                        value={record_data.description}  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        rows={2}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="description" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th width={70}>ITEM&nbsp;#</th>
                                                        <th>ITEMS</th>

                                                        { record_data.type_id == 1 ? <th width={200}>CURRENT AVAILABLE</th> : ''}
                                                        { record_data.type_id == 2 ? <th width={200}>CURRENT PRICE</th> : ''}
                                                        { record_data.type_id == 3 ? <th width={150}>COST</th> : ''}

                                                        {/* <th width={150}>DELIVERED</th> */}
                                                        <th width={200}>ADJUSTMENT</th>
                                                        <th width={20}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    <tr className='bg-light'>
                                                        <td colSpan={5} className='text-center'>
                                                            <RenderErrors 
                                                                errors={errors} 
                                                                name="items" 
                                                                className="text-danger mb-2"
                                                                style={{fontSize: '.9rem'}}
                                                            />
                                                            <button type="button" className="btn btn-outline-primary" onClick={(e) => setProductModal(1)} disabled={record_data.type_id == 1 && !record_data.warehouse_id}>Add Items</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <center>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(1)}>Save</button>
                                <Link className='btn btn-outline-dark' to="/inventory-adjustment/">Cancel</Link>                   
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}