import React, { useEffect, useState } from 'react';
import { Link, useParams  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
import images from '../../../helpers/images';
import initTreeView from '../../../helpers/initTreeView';
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PaymentsListModal from '../../modals/PaymentsListModal';
import { Accordion, AccordionTab } from 'primereact/accordion';
import StoreInfo from '../../parts/StoreInfo';

export default function PurchaseOrderView (){
    const { order_id } = useParams();
    const [loading, setLoading] = useState(1);
    const [page_title, setPageTitle] = useState('New Bill');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.purchases_order_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);
    const [payments_modal, setPaymentsModal] = useState(false);
    const [bill_table, setBillTable] = useState([]);
    
   
    useEffect(() => {
        initTreeView()
        if(order_id){
            setPageTitle('Purchase Order Details')
            getData(order_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items]);

    useEffect(() => {
        displayBillItems()
    }, [record_data]);

    function getData(order_id){
        if(!order_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/order/purchase/${order_id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.order_date = new Date(res.order_date)

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/purchases/list");
                }
            })
        }); 

    }
    function computeSubTotals(){
        let items = 0;
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
            items = items + Number(data.quantity)
            total_amount = total_amount + parseInt(data.quantity) * parseFloat(data.amount)
        });

        return helpers.formatCurrencyAmount(total_amount);
    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {
                return(
                <tr>
                    <td>
                        <div className='mb-0'>{data.product_name}{data.product_variant_name != 'Generic' ? ` (${data.product_variant_name})` : ''}</div>
                        {data.product_description ? <div className='mt-2' style={{whiteSpace: 'pre-line', maxHeight: '150px', overflow: 'auto'}}>{data.product_description}</div> : ''}
                    </td>
                    <td>{data.product_code}</td>
                    <td>{data.quantity}{data.unit_name ? <small> {data.unit_name}</small> : ''}</td>
                    <td>{data.received_quantity}/{data.quantity}</td>
                    <td>{helpers.formatCurrencyAmount(data.amount)}</td>
                    {/* <td>{data.tax_amount ? helpers.formatCurrencyAmount(data.tax_amount) : '---'}</td> */}
                    <td>{helpers.formatCurrencyAmount(data.total_amount)}</td>
                </tr>)

            })
        }

        setTableItems(<tbody>{items}</tbody>)
    }

    function displayBillItems(){
        if (Object.entries(record_data.bill).length) {
            let items = []
            const bill = record_data.bill
            items = <tr>
                        <td>{bill.bill_date}</td>
                        <td><Link to={`/bills/${bill.id}`} target='_blank'>{bill.bill_no}</Link></td>
                        <td>{bill.due_date}</td>
                        <td>{helpers.formatCurrencyAmount(bill.total_amount)}</td>
                        <td>{helpers.formatCurrencyAmount(bill.balance_amount)}</td>
                        <td>{bill.bill_status}</td>
                        <td width={20}>
                            <div  className='text-center'>
                            <Dropdown as={ButtonGroup} drop="down" className='mr-sm-2' size="sm" >
                                <Dropdown.Toggle variant="outline-dark" id="dropdown-split-basic" />
                                <Dropdown.Menu>
                                    <Dropdown.Item href={`/bills/${bill.id}/payment`} target='_blank'><i className="fas fa-credit-card mr-2" style={{width: '20px'}} />Add Payment</Dropdown.Item>
                                    <Dropdown.Item href={`/bills/${bill.id}/print`} target='_blank'><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => triggerAction(bill.id, 'single-delete')} className='text-danger'><i className="fas fa-trash mr-2" style={{width: '20px'}} />Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </td>
                    </tr>

            setBillTable(<div className="container mb-3">
                <Accordion>
                    <AccordionTab header="Bill" className="text-dark">
                        <div className='table-responsive-md'>
                        <table className="table table-sm mb-0">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Bill #</th>
                                <th>Due Date</th>
                                <th>Amount</th>
                                <th>Balance</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>{items}</tbody>
                        </table>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>)
        }
    }

    function triggerAction(data, action = '', status = ''){
        if(!action) return;

        if(action === 'open'){
            updateStatusItem(1)
        }
        else if(action === 'confirm'){
            updateStatusItem(2)
        }
        else if(action === 'force-receives'){
            forReceiving(data, 'received')
            return;
        }
        else if(action === 'redo-receives'){
            forReceiving(data, 'redo')
            return;
        }
        else if(action === 'instant-bill'){
            createInvoice(data)
            return;
        }
        else if(action === 'single-delete' || action === 'bulk-delete'){
            let ids = [];
            ids.push(data)

            let title = 'Delete Bill'

            Swal.fire({
                title: title,
                text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
                showCancelButton: true,
                showConfirmButton: false,
                closeOnCancel: true,
                showDenyButton: true,
                denyButtonText: 'Yes'
            })
            .then((result) => {
                if (result.isDenied) {
                    deleteItem(ids)
                }
            })
        }

        //updateStatusItem(order_id, status)
    }

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/bills/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "bill_ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function forReceiving(id, action = ''){
        const url = helpers.getUrl(`V1/manage/order/purchase/${id}/receives`)
        httpRequest(url, 'POST', {
            postdata: {
                "action": action,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 

    }

    function updateStatusItem(status_id){
        const url = helpers.getUrl('V1/manage/order/purchase/status')
        httpRequest(url, 'POST', {
            postdata: {
                "order_id": order_id,
                "store_id": helpers.getStoreID(),
                "status_id": status_id
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK"
                })
            }); 
    }

    function createInvoice(id){
        const url = helpers.getUrl('V1/manage/order/purchase/bill')
        httpRequest(url, 'POST', {
            postdata: {
                "order_id": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 

    }

    return (
        <>
        <PaymentsListModal show_modal={payments_modal} id={order_id} close_modal={setPaymentsModal} />
        <MainContainer page_title={page_title} menu="manage-purchases" breadcrumbs={true} parent_link='/purchases/list' parent_text="Manage Purchase Order">
                <div className="content" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="container-fluid">
                            <div className="row mb-2">
                                <div order_id="so-customer-section" className="col-lg-12">
                                    {record_loaded ?
                                    <div className='border rounded p-3 mb-3 d-flex justify-content-between align-items-center'>

                                        <h5 className='mb-0 mb-md-0 d-flex'>{record_data.order_no}</h5>

                                        <div className='d-sm-flex flex-column flex-sm-row d-none'>
                                            <Dropdown as={ButtonGroup} drop="down" className='mr-sm-2' >
                                                <Dropdown.Toggle variant="outline-dark" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    {
                                                    parseInt(record_data.order_status_id) !== 1 && 
                                                    (parseInt(record_data.shipping_status_id) === 1 || parseInt(record_data.shipping_status_id) === 2 || parseInt(record_data.shipping_status_id) === 3) ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'force-receives')}><i className="fas fa-dolly mr-2" style={{width: '20px'}} />Force Receive</Dropdown.Item> : ''
                                                    }

                                                    {
                                                    record_data.force_receiving ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'redo-receives')}><i className="fas fa-redo mr-2" style={{width: '20px'}} />Redo Force Receive</Dropdown.Item> : ''
                                                    }

                                                    {
                                                    parseInt(record_data.order_status_id) === 1 ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'confirm', 2)}><i className="fas fa-check-circle mr-2" style={{width: '20px'}} />Set as Confirm</Dropdown.Item> : ''
                                                    }
        
                                                    {
                                                    parseInt(record_data.order_status_id) === 2 && parseInt(record_data.shipping_status_id) === 1 ?
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'open', 1)}><i className="fas fa-file-alt mr-2" style={{width: '20px'}} />Set as Open</Dropdown.Item> : ''
                                                    }

                                                    {
                                                        parseInt(record_data.order_status_id) !== 3 && parseInt(record_data.order_status_id) !== 4 ?
                                                        <Dropdown.Item as={Link} to={`/purchases/${order_id}/edit`}><i className="fas fa-pencil-alt mr-2" style={{width: '20px'}} />Edit</Dropdown.Item>:''
                                                    }

                                                    <Dropdown.Item href={`/purchases/${order_id}/print`} target='_blank'><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            {
                                            parseInt(record_data.order_status_id) === 2 ?
                                            <Dropdown as={ButtonGroup} drop="down" className='mr-sm-2' >
                                                <Dropdown.Toggle variant="outline-primary" id="dropdown-split-basic">
                                                    Create
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>

                                                    {   
                                                        parseInt(record_data.order_status_id) === 2 ? 
                                                        <Dropdown.Item onClick={(e) => triggerAction(order_id, 'instant-bill')}><i className="fas fa-file-invoice mr-2" style={{width: '20px'}} />Bill Purchase</Dropdown.Item>:''
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> : ''
                                            }

                                            <Link className='btn btn-dark' to="/purchases/list">Cancel</Link>  
                                        </div>

                                        <div className='d-block d-sm-none'>
                                            <Dropdown as={ButtonGroup} drop="down-right" className='mr-sm-2'>
                                                <Dropdown.Toggle variant="dark" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    {   
                                                        parseInt(record_data.order_status_id) === 2 ? 
                                                        <Dropdown.Item onClick={(e) => triggerAction(order_id, 'instant-bill')}><i className="fas fa-file-invoice mr-2" style={{width: '20px'}} />Create Bill</Dropdown.Item>:''
                                                    }

                                                    {
                                                    parseInt(record_data.order_status_id) !== 1 && 
                                                    (parseInt(record_data.shipping_status_id) === 1 || parseInt(record_data.shipping_status_id) === 2 || parseInt(record_data.shipping_status_id) === 3) ?
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'force-receives')}><i className="fas fa-dolly mr-2" style={{width: '20px'}} />Force Receive</Dropdown.Item> : ''
                                                    }

                                                    {
                                                    record_data.force_receiving ?
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'redo-receives')}><i className="fas fa-redo mr-2" style={{width: '20px'}} />Redo Force Receive</Dropdown.Item> : ''
                                                    }

                                                    {
                                                    parseInt(record_data.order_status_id) === 1 ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'confirm', 2)}><i className="fas fa-check-circle mr-2" style={{width: '20px'}} />Set as Confirm</Dropdown.Item> : ''
                                                    }
        
                                                    {parseInt(record_data.order_status_id) === 2 && parseInt(record_data.shipping_status_id) === 1 ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction(order_id, 'open', 1)}><i className="fas fa-file-alt mr-2" style={{width: '20px'}} />Set as Open</Dropdown.Item> : ''}

                                                    {
                                                        parseInt(record_data.order_status_id) !== 3 && parseInt(record_data.order_status_id) !== 4 ?
                                                        <Dropdown.Item as={Link} to={`/purchases/${order_id}/edit`}><i className="fas fa-pencil-alt mr-2" style={{width: '20px'}} />Edit</Dropdown.Item> : ''
                                                    }

                                                    <Dropdown.Item href={`/purchases/${order_id}/print`} target='_blank'><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                    </div>

                    {
                        bill_table
                    }

                            
                    <div className="container">
                        {
                            record_loaded ?
                            <div className="invoice p-4 mb-3">
                                <div className="d-flex justify-content-between mb-3">
                                    <StoreInfo store={record_data.store_info} />
                                    
                                    <div className='text-right'>
                                        <h2><strong>PURCHASE ORDER</strong></h2>
                                        <h5>{helpers.displayOrderStatusBadge(record_data.order_status_id, record_data.order_status)}</h5>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <h5 className='mb-0'><b>Vendor:</b></h5>
                                        <div>
                                            <p className='mb-0'><b>{record_data.vendor_name}</b></p>
                                            <p className='mb-0'>{record_data.vendor_address}</p>
                                            <p className='mb-0'>{record_data.vendor_phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <h5 className='mb-0'>
                                            <b>Ship To:</b>
                                            {parseInt(record_data.receiver_type_id) === 2 ? <small className='ml-2'>(Customer)</small> : ''}
                                        </h5>
                                        <div>
                                            <p className='mb-0'><b>{record_data.shipping_contact_name}</b></p>
                                            <p className='mb-0'>{record_data.shipping_address}</p>
                                            <p className='mb-0'>{record_data.shipping_phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 text-lg-right mb-2">
                                        <table className='table table-borderless table-sm'>
                                            <tbody>
                                                <tr>
                                                    <td className='py-0'><b>PO #:</b></td>
                                                    <td className='py-0'>{record_data.order_no ? record_data.order_no : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Date:</b></td>
                                                    <td className='py-0'>{helpers.formatDate(record_data.order_date)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Terms:</b></td>
                                                    <td className='py-0'>{record_data.payment_term ? record_data.payment_term : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Expected Date:</b></td>
                                                    <td className='py-0'>{helpers.formatDate(record_data.expected_date)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Ship via:</b></td>
                                                    <td className='py-0'>{record_data.courier ? record_data.courier : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Shipment:</b></td>
                                                    <td className='py-0'>{record_data.shipping_status ? record_data.shipping_status : '---'}</td>
                                                </tr>
                                                {
                                                    record_data.order_status_id == 4
                                                    ?
                                                    <tr>
                                                        <td className='py-0'><b>Reason:</b></td>
                                                        <td className='py-0'>{record_data.reason}</td>
                                                    </tr> : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm border-bottom'>
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>SKU</th>
                                                <th>Quantity</th>
                                                <th>Receivable</th>
                                                <th>Price</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        {
                                            table_items
                                        }
                                    </table>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-7 d-flex align-items-center">{record_data.vendor_notes ? <p className='pt-1 pt-lg-4'>{record_data.vendor_notes}</p>:''}</div>
                                    <div className="col-lg-5 mb-0 border p-2">
                                        <table className='table table-sm table-borderless mb-0'>
                                            <tbody>
                                                <tr>
                                                    <td className='border-top-0'><b>Sub Total</b></td>
                                                    <td className='border-top-0'>{computeSubTotals()}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Shipping Fee</b></td>
                                                    <td>{helpers.formatCurrencyAmount(record_data.shipping_fee)}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tax</b></td>
                                                    <td>{parseFloat(record_data.total_tax) > 0 ? helpers.formatCurrencyAmount(record_data.total_tax) : '---'}</td>
                                                </tr>
                                                <tr className='bg-dark'>
                                                    <td><b>Total</b></td>
                                                    <td>{helpers.formatCurrencyAmount(record_data.total_amount)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div className="invoice p-5 mb-3">
                                <h4>&nbsp;</h4>
                            </div>
                        }
                    </div>
                </div>
        </MainContainer>
        </>
    )
}