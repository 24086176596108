import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';

import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import appConfig from '../../config/env'
import { httpRequest, httpFormRequest } from '../../helpers/httpService'
import appSession from '../../helpers/session'
import images from '../../helpers/images';
import Loader from '../parts/Loader';
import ReCAPTCHA from "react-google-recaptcha";
 
function LoginPage (){
    const [errors, setErrors] = useState([]);
    const [login_data, setLogin] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(0);
    const recaptchaRef = useRef(null);
    
    // useEffect(() => {
    //     //Mounted
    // }, []);

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
        }

        let new_state = { [name]: value }

        setLogin({ ...login_data, ...new_state });
    }

    function attemptLogin(type = ''){
        const captcha = recaptchaRef.current.getValue();

        if(!captcha){
            setErrors( {
                recaptcha: ['The recaptcha is required.']
            } );
            return;
        }

        let form_data = {...login_data};
        const url = appConfig.apiUrl + '/login';
        setLoading(1)

        httpRequest(url, "POST", {
            postdata: { 
                ...form_data, 
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const {token, permissions, user, default_store, default_store_id, store_selection} = res.data
                appSession.setSession(res.data)
                setLoading(0)

                window.location.href = "/";

            })
            .catch((err) => {
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }
      
                Swal.fire({
                    //title: 'Authentication Failed',
                    text: 'Invalid username or password',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
    }

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }
    
    return (
        <div className='login-page' style={{position: 'relative'}}>
        <Loader isLoading={loading}/>
            <div className="login-box">
            {/* /.login-logo */}
                {/* <div className='mb-4 text-center'>
                    <img src={images.asinsoLogo} alt="Logo" width={200} />
                    <sup className='ml-1'>BETA</sup>
                </div> */}
                <div className="card">
                    <div className="card-header text-center pt-4 pb-2 border-bottom-0">
                        <img src={images.topventory_logo} alt="Logo" width={200} />
                        <sup className='ml-1'>BETA</sup>
                        {/* <hr className='mb-0 mt-4'/> */}
                    </div>
                    <div className="card-body">
                        {/* <p className="login-box-msg">Login to start your session.</p> */}
                        <h4 className='mb-4 text-center'>Login</h4>
                        <form action="../../index3.html" method="post">
                            <div className="input-group mb-3">
                                <InputText
                                    id={'email'} 
                                    name={'email'} 
                                    value={login_data.email}
                                    tagClass="form-control"
                                    onChange={inputChange}
                                    //onChange={(e) => setLoginEmail(e.target.value)}
                                    placeholder='Enter your email'
                                />
                                {/* <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div> */}
                                
                                <RenderErrors 
                                    errors={errors} 
                                    name="email" 
                                    className="text-danger"
                                    style={{fontSize: '.9rem'}}
                                />
                            </div>
                            <RenderErrors 
                                errors={errors} 
                                name="email" 
                                className="text-danger fs-7"
                            />
                            <div className="input-group mb-3">
                                <InputText
                                    id={'password'} 
                                    name={'password'}
                                    value={login_data.password} 
                                    tagClass="form-control"
                                    type='password'
                                    onChange={inputChange}
                                    placeholder='Eter your password'
                                />
                                {/* <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        // sitekey="6LdmdwwpAAAAALXqUgq-F9Upm120UUepV-ohMlY9"
                                        sitekey="6LcDXdkpAAAAAGq2dOVk44gO_W9LBMm5l1uQa5Bq"
                                        onChange={()=>removeErrors({target: {name:'recaptcha'}})}
                                        className='text-center'
                                    />
                                    <RenderErrors 
                                        errors={errors} 
                                        name="recaptcha" 
                                        className="text-danger"
                                        style={{fontSize: '.9rem'}}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    {/* <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember" className='ml-2'>
                                            Remember Me
                                        </label>
                                    </div> */}
                                </div>
                                {/* /.col */}
                                <div className="col-4">
                                    {/* <button type="submit" className="btn btn-primary btn-block">Sign In</button> */}
                                </div>
                            {/* /.col */}
                            </div>
                        </form>
                    </div>
                    <div className="card-footer text-center">
                            <div className="row d-flex justify-content-center">
                                <div className="col-12">
                                <button type="button" className="btn btn-primary btn-block"  onClick={(e) => attemptLogin('user')}>Login</button>
                                </div>
                            </div>
                        {/* <img src={images.asinsoLogo} alt="Logo" width={200} /> */}
                        
                        
                    </div>
                    {/* /.card-body */}
                </div>
                {/* <div className='text-center mt-0'>
                    <small><br/>Powered by <a href="https://daroy.net">Philtrix Technology</a></small>
                </div> */}
            {/* /.card */}
            </div>
        </div>
    )
}
 
export default LoginPage;