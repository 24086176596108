import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import SaversMainContainer from './parts/SaversMainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store_saver';

import InputText from '../../elements/InputText';
// import InputNumber from '../../elements/InputNumber';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import InputCheckbox from '../../elements/InputCheckbox';
import ContactListModal from '../../modals/ContactListModal';
import OrderItemFinderModal from '../../modals/OrderItemFinderModal';
import OrderListModal from '../../modals/OrderListModal';
import CourierListModal from '../../modals/CourierListModal';
import initTreeView from '../../../helpers/initTreeView';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
 
export default function SharesForm (){
    const { group_id } = useParams();
    const { share_id } = useParams();
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Group Shares');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.group_share);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]    );
    const [customer_data, setCustomer] = useState([]);
    const [customer_modal, setContactModal] = useState(0);
    const [so_list_modal, setSOModal] = useState(0);

    const [all_fullshare, setAllFullshare] = useState(0);
    const [all_present, setAllPresent] = useState(0);
    const [members_list, setMembersList] = useState([]);
    const [share_items, setShareItems] = useState([]);
    const [group_options, setGroupOptions] = useState({
        "max_share": 5
    });
   
    useEffect(() => {
        initTreeView()

        if(share_id){
            setPageTitle('Group Shares Details')
            getData(share_id)
        }
        else{
            getEnums()
            getGroupSettings();
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items, all_fullshare, all_present]);

    function getEnums(){

        const members = [
            {id: 1, user_id: 1, member_name: 'Carolina Marin'},
            {id: 2, user_id: 2, member_name: 'Pedro Morales'},
            {id: 3, user_id: 3, member_name: 'Daniel Serrano'},
            {id: 4, user_id: 4, member_name: 'Sofia Pastor'},
            {id: 5, user_id: 5, member_name: 'Raul Garrido'},
            {id: 6, user_id: 6, member_name: 'Vicente Campos'},
            {id: 7, user_id: 7, member_name: 'Juan Dela Cruz'},
            {id: 8, user_id: 8, member_name: 'Maria Soles'},
        ];
        setMembersList(members)

        let items = [];
        items = Object.entries(members).map(([key, data]) => {
            return({
                "id": 0,
                "share_id": 0,
                "member_id": data.id,
                "member_name": data.member_name,
                "attendance_id": 0,
                "shares": 0
            })
        });
        
        setRecordItems(items)
    }

    function getGroupSettings(){
        setLoading(1)

        const url = helpers.getUrl(`v1/savers/groups/${group_id}/options`)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setGroupOptions(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }); 
    }

    function getData(share_id){
        if(!share_id){
            return
        }
        const url = helpers.getUrl(`v1/savers/group-share/${share_id}`)

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.shipment_date = new Date(res.shipment_date)
            if(res.delivered_date){
                items.delivered_date = new Date(res.delivered_date)
            }

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            setRecordLoaded(1)
            getEnums()

            // if(res.delivered_date){
            //     setAllFullshare(1)
            // }
        })
        .catch((err) => {
            setLoading(0)
            getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    window.location.replace(`/app/savers/${group_id}/shares`);
                }
            })
        }); 
    }

    function displayTableItems(){
        let md_items = []
        let mobile_items = []
        let shares = [...share_items];
        if (record_items.length) {
            md_items = Object.entries(record_items).map(([key, data]) => {
                const counter = parseInt(key) + 1

                let attendance = false
                if(data.attendance_id == 1){
                    attendance = true
                }

                return(
                <tr>
                    <td className='text-center'>{counter}</td>
                    <td>{data.member_name}</td>
                    <td className='text-center'>
                        <InputNumber
                            id="shares" 
                            name="shares" 
                            value={data.shares}
                            inputClassName="form-control rounded-0"
                            min={0}
                            max={group_options.max_share}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="No. of Shares"
                            decrementButtonClassName="p-input-button-left p-button-danger" 
                            incrementButtonClassName="p-input-button-right p-button-success" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        />
                    </td>
                    <td className='text-center'>
                        <div className="flex align-items-center">
                            <Checkbox 
                                inputId={`attendance-${data.member_id}`} name="attendance_id" value={1} onChange={(e) => inputItemChange(e, key)} checked={attendance} 
                            />
                        </div>
                    </td>
                </tr>)

            })

            mobile_items = Object.entries(record_items).map(([key, data]) => {
                const counter = parseInt(key) + 1

                let attendance = false
                if(data.attendance_id == 1){
                    attendance = true
                }

                return(
                <tr>
                    <td className='text-center'>{counter}</td>
                    <td>
                        <div>{data.member_name}</div>
                        <hr/>
                        <InputNumber
                            id="shares" 
                            name="shares" 
                            value={data.shares}
                            inputClassName="form-control rounded-0"
                            min={0}
                            max={group_options.max_share}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="No. of Shares"
                            decrementButtonClassName="p-input-button-left p-button-danger" 
                            incrementButtonClassName="p-input-button-right p-button-success" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        />
                        <div className='mb-2 mt-3'>
                            <Checkbox 
                                inputId={`attendance-${data.member_id}`} name="attendance_id" value={1} onChange={(e) => inputItemChange(e, key)} checked={attendance} 
                            />
                            <label htmlFor={`attendance-${data.member_id}`} className='mb-0 ml-2'>Present</label>
                        </div>
                    </td>
                </tr>)

            })
        }
        else{
            md_items = <tr>
                        <td colSpan={4} className='text-center'>No data to diplay</td>
                    </tr>
            mobile_items = <tr>
                        <td colSpan={2} className='text-center'>No data to diplay</td>
                    </tr>
        }


        setTableItems(
            <>
                <div className='table-responsive d-none d-md-block'>
                    <table className='table table-sm table-striped table-borderlesss mb-0'>
                        <thead>
                            <tr>
                                <th className='text-center' style={{verticalAlign: 'middle'}}>No.</th>
                                <th width="35%" style={{verticalAlign: 'middle', minWidth: '200px'}}>Name</th>
                                <th style={{minWidth: '150px'}} className='text-center'>
                                    <InputCheckbox
                                        id="all_fullshare"
                                        name="all_fullshare"
                                        value="1"
                                        onChange={inputChange}
                                        checked = {all_fullshare}
                                        tagClass='me-2'
                                        label="All Full Share"
                                        labelClass="ms-2 font-weight-normal mb-0"
                                    /><br/>
                                    Share
                                </th>
                                <th className='text-center'>
                                    <InputCheckbox
                                        id="all_present"
                                        name="all_present"
                                        value="1"
                                        onChange={inputChange}
                                        checked = {all_present}
                                        tagClass='me-2'
                                        label="All Present"
                                        labelClass="ms-2 font-weight-normal mb-0"
                                    /><br/>
                                    Attendance
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {md_items}
                        </tbody>
                    </table>
                </div>
                <div className='table-responsive mb-0 d-md-none'>
                    <table className='table table-sm table-striped table-borderlesss'>
                        <thead>
                            <tr>
                                <th className='text-center' style={{verticalAlign: 'middle', maxWidth: '80px'}}>No.</th>
                                <th style={{verticalAlign: 'middle', minWidth: '140px'}}>Name</th>
                                {/* <th style={{minWidth: '130px'}} className='text-center'>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {mobile_items}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...record_items]
        let target = {...items[key]}

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        target[name] = value
        items[key] = target

        if(name === 'shares'){
            const total_members = Object.keys(members_list).length
            const total_shares = parseInt(total_members) * group_options.max_share

            let total_current_shares = items.reduce((accumulator, current) => accumulator + parseInt(current.shares), 0);
        
            let is_all_fullshare = 1
            if(total_current_shares < total_shares){
                is_all_fullshare = 0
            }
            setAllFullshare(is_all_fullshare)
        }

        if(name === 'attendance_id'){
            const total_members = Object.keys(members_list).length


            let total_current_attendance = items.reduce((accumulator, current) => accumulator + parseInt(current.attendance_id), 0);
        
            let is_all_present = 1
            if(total_current_attendance < total_members){
                is_all_present = 0
            }
            setAllPresent(is_all_present)
        }

        setRecordItems(items)
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        if(name == 'all_fullshare'){
            const modified_record_items = record_items.map(obj => {
                let update = {}
                if(value){
                    update.shares = group_options.max_share
                }
                else{
                    update.shares = 0
                }
    
                return { ...obj, ...update};
            });
    
            if(Object.keys(modified_record_items).length){
                setRecordItems(modified_record_items)
            }

            setAllFullshare(value)
            return;
        }

        if(name == 'all_present'){
            const modified_record_items = record_items.map(obj => {
                let update = {}
                if(value){
                    update.attendance_id = 1
                }
                else{
                    update.attendance_id = 0
                }
    
                return { ...obj, ...update};
            });
    
            if(Object.keys(modified_record_items).length){
                setRecordItems(modified_record_items)
            }

            setAllPresent(value)
            return;
        }

        setRecordData({ ...record_data, ...new_state });
    }

    function submit(){
        setLoading(1)

        let url = helpers.getUrl(`v1/savers/group-share/new`)
        if(share_id){
            url = helpers.getUrl(`v1/savers/group-share/${share_id}`)
        }

        let form_data = {...record_data}
        form_data['member_shares'] = record_items;
        form_data['group_id'] = group_id

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = share_id;
                if(!id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/shipments/${id}/form`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    

    function handleCustomer(e){
        let data = {...record_data}
        let customer = {...customer_data}

        data.customer_id = e.id
        data.customer_name = e.contact_name
        data.customer_data = e

        data.so_id = ''
        data.sales_order = ''

        customer.id = e.id
        customer.contact_name = e.contact_name

        setRecordData(data)
        setCustomer(e)
    }

    function handleCourier(e){
        let data = {...record_data}

        data.courier_id = e.id
        data.courier = e.courier_name
        data.courier_name = e.courier_name

        setRecordData(data)
        //getCourierList()
    }


    return (
        <SaversMainContainer page_title={page_title} menu="manage-saver-shares" breadcrumbs={true} parent_link={`/app/savers/${group_id}/shares`} parent_text="Group Member Shares">
                <div className="content-fluid">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        {record_data.shipment_no ?
                        <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                            <h5 className='mb-3 mb-md-0 d-flex'>{record_data.shipment_no}&nbsp;&nbsp;&nbsp;{helpers.displayInvoiceStatusBadge(record_data.order_status_id, record_data.invoice_status)}</h5>
                            <div className='d-flex flex-column flex-sm-row'>
                            </div>

                            <div className='text-right'>     
                                <Link className='btn btn-outline-dark ml-3' to="/shipments/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                            </div>
                        </div> : ''}

                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-4 pb-2">
                                            <div className="row">
                                                <div className="col-sm-2 col-lg-1 text-left text-md-right">
                                                    <label>Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-sm-7 col-md-5 col-lg-4 form-group">
                                                    <Calendar 
                                                        id="share_date" 
                                                        name="share_date"  
                                                        value={record_data.share_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd"
                                                        maxDate={new Date()} 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        // disabled={!record_data.sales_order}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="share_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row d-md-none">
                                                <div className="col-sm-2 col-lg-1 text-left text-md-right">
                                                </div>
                                                <div className="col-sm-7 col-md-5 col-lg-4 form-group">
                                                        <InputCheckbox
                                                            id="all_fullshare"
                                                            name="all_fullshare"
                                                            value="1"
                                                            onChange={inputChange}
                                                            checked = {all_fullshare}
                                                            tagClass='me-2'
                                                            label="All Full Share"
                                                            labelClass="ms-2 font-weight-normal mb-2"
                                                        />
                                                        <br/>
                                                        <InputCheckbox
                                                            id="all_present"
                                                            name="all_present"
                                                            value="1"
                                                            onChange={inputChange}
                                                            checked = {all_present}
                                                            tagClass='me-2'
                                                            label="All Present"
                                                            labelClass="ms-2 font-weight-normal mb-0"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body px-2 px-md-0 pt-0">
                                            {
                                                table_items
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>  
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(1)} style={{padding: '0.5rem 0.75rem'}}>Save</button>
                                <Link className='btn btn-outline-dark' to={`/app/savers/${group_id}/shares`} style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>                  
                                
                            </div>
                        </form>
                    </div>
                </div>
        </SaversMainContainer>
    )
}