import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
//import InputText from '../../elements/InputText'
import { InputText } from 'primereact/inputtext';
import InputTextArea from '../../elements/InputTextArea'
import DropDownSelection from '../../elements/DropDownSelection';
import Loader from '../../parts/Loader';
import RenderErrors from '../../elements/RenderErrors';
import store from '../../../helpers/store';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';

import BrandFormModal from '../../modals/BrandFormModal';
import CategoryFormModal from '../../modals/CategoryFormModal';
import TaxRateFormModal from '../../modals/TaxRateFormModal';

import VariantTypeListModal from '../../modals/VariantTypeListModal';
import initTreeView from '../../../helpers/initTreeView';
import InputCheckbox from '../../elements/InputCheckbox';

import { InputNumber } from 'primereact/inputnumber';
 
function ProductForm ({readonly = false}){
    const { product_id } = useParams();
    const [loading, setLoading] = useState(0);
    
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setProduct] = useState(store.product_data);
    const [variants_data, setVariants] = useState(store.variants_data);

    const [variant_type_selector, setVariantTypeSelector] = useState([]);
    const [stock_info_form, setStockInfoForm] = useState([]);
    const [new_variants_data, setNewVariants] = useState([]);
    //const [product_id, SetProductID] = useState('');
    const [errors, setErrors] = useState([]);

    const [variant_type_list, setVariantTypeList] = useState([]);
    const [category_list, setCategoryList] = useState([]);
    const [brand_list, setBrandList] = useState([]);
    const [uom_list, setUnitOfMeasureList] = useState([]);
    
    const [enums, setEnums] = useState(store.product_enums);
    
    const [selected_variant_types, setSelectedVariantType] = useState([]);
    const [selected_variant_type_values_main, setSelectedVariantValueMain] = useState([]);
    const [selected_variant_type_values_sub, setSelectedVariantValueSub] = useState([]);

    const [total_variation, setTotalVariation] = useState(0);
    const [variant_type_value_main, setVariantValueMain] = useState([]);
    const [variant_type_value_sub, setVariantValueSub] = useState([]);

    const [show_brand_modal, setShowBrandModal] = useState(0);
    const [show_category_modal, setShowCategoryModal] = useState(0);
    const [show_tax_modal, setShowTaxModal] = useState(0);

    const [variant_types_modal, setVariantTypesModal] = useState(0);
    const [variant_type_no, setVariantNo] = useState('');
    const [exclude_variant_type, setExcludeVariantType] = useState([]);
    
    const [warehouse_id, setWarehouseID] = useState('');

    useEffect(() => {
        initTreeView()
        setLoading(1)

        if(product_id){
            getData(product_id)
        }
        else{
            getEnums()
        }
    }, []);

    useEffect(() => {
        displayVariantTypeSelector()
    }, [
        total_variation,
        variant_type_list, 
        selected_variant_types, 
        selected_variant_type_values_main, 
        selected_variant_type_values_sub
    ]);

    useEffect(() => {
        displayStockInputForm()
    }, [
        total_variation,
        variant_type_list,
        selected_variant_types, 
        selected_variant_type_values_main, 
        selected_variant_type_values_sub,
        variants_data,
        record_data.product_type_id,
        record_data.track_inventory,
        warehouse_id
    ]);


    function isGenericVariant(target_data = ''){
        let is_generic = 0
        if(!target_data){
            //console.log('target_data not defined')
            //console.log('target_data', target_data)
            target_data = selected_variant_types;
        }
        else{
            //console.log('target_data', target_data)
        }

        if (selected_variant_types[0]){
            if(selected_variant_types[0].variant_type_id === 1) {
                is_generic = 1
            }
        }

        return is_generic;
    }

    function getInputValue(index, main_variant_value_id, sub_variant_value_id, field_name, main_field_name = '', default_return = ''){
        let specific_variant = variants_data.find((item) => {
            const total_variant_value = item.variant_value_ids ? item.variant_value_ids.length : 0;
            if(index == 0 && total_variant_value == 1 && item.variant_value_ids[index] == main_variant_value_id){
                return item
            }
            else if(item.variant_value_ids[0] == main_variant_value_id && item.variant_value_ids[1] == sub_variant_value_id){
                return item
            }

            //     return item.product_variant_details.some((item) => {
            //         console.log(item)
            //          return item.name === 'milk';
            //     });
        });

        if(!specific_variant) return default_return;

        if(main_field_name){
            let inventory = getInventoryIndex(specific_variant[main_field_name], warehouse_id)
            //console.log(inventory)
            //return specific_variant[main_field_name][inventory][field_name]

            if(inventory !== 'none'){
                return specific_variant[main_field_name][inventory][field_name]
            }
            //return result[main_field_name][0][field_name]
        }

        //console.log(field_name + ': specific_variant', specific_variant[field_name] ?? default_return)
        return specific_variant[field_name] ?? default_return
    }

    function getInventoryIndex(obj, target){
        let index = obj.findIndex((item) => {
            if(parseInt(item.warehouse_id) === parseInt(target)){
                return item;
            }
        })

        if(index < 0) return 'none';

        return index
    }

    function displayVariantTypeSelector(){
        let display = []

        const is_generic = isGenericVariant();

        for (let i = 0; i < total_variation; i++) {
            const variant_no = i + 1;
            let is_disabled = false;

            if(variant_no == 2 && !selected_variant_type_values_main.length){
                is_disabled = true;
            }

            let variant_type_id = '';
            let variant_type = '';

            if(!is_generic && selected_variant_types[i]){
                variant_type_id = selected_variant_types[i]['variant_type_id']
                variant_type = selected_variant_types[i]['variant_type']
            }
            
            display.push(<div className="col-md-6">
                            <div className="card">
                                <div className="card-header pt-2 pb-2 pr-2 border-bottom-0">

                                    <button type="button" className='btn btn-xs btn-danger float-right' onClick={(e)=>removeVariation(i)}>&nbsp;X&nbsp;</button>

                                    Variant {variant_no}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Select Variant Type" value={variant_type} readOnly={1}  onClick={(e)=>openVariantTypeModal(i)} style={{backgroundColor: '#fff'}} disabled={variant_no == 2 && is_disabled}/>

                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={(e)=>openVariantTypeModal(i)} disabled={variant_no == 2 && is_disabled}><i className="fas fa-search" /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 d-flex justify-content-start align-items-center">
                                            {displayVariantTypeValue(variant_type_id, variant_no)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
        }

        if(display.length){
            setVariantTypeSelector(
                <div className="col-sm-12 mb-3">
                    <div className="row">
                        {display}
                    </div>
                </div>
                )
        }
        else{
            setVariantTypeSelector([])
        }
    }

    function displayVariantTypeValue(variant_type_id = '', variant_no = ''){

        if(!variant_type_id) return;

        var result = variant_type_list.find(item => item.id == variant_type_id);

        let buttons = ''
        //if(result){
            let variant_value_list = variant_type_value_main;
            if(variant_no == 2){
                variant_value_list = variant_type_value_sub;
            }

            buttons = Object.entries(variant_value_list).map(([index, data]) => {
                let exist = ''
                if(variant_no == 1){
                    exist = selected_variant_type_values_main.find(item => item.variant_type_id == variant_type_id && item.variant_value_id == data.variant_value_id);
                }
                else{
                    exist = selected_variant_type_values_sub.find(item => item.variant_type_id == variant_type_id && item.variant_value_id == data.variant_value_id);
                }
                
                let is_selected = 'btn btn-outline-primary btn-sm mr-2'
                if(exist){
                    is_selected = 'btn btn-primary btn-sm mr-2'
                }


                if(variant_no == 2 && !selected_variant_type_values_main.length){
                    return
                }

                return <button key={index}  className={is_selected} type='button' onClick={(e)=>inputVariantTypeValue(variant_type_id, data, variant_no)}>{data.variant_value}</button>
            });

        return buttons;
    }

    function displayStockInputForm(){
        let form_table = []

        const is_generic = isGenericVariant()

        /** 
         * Table Header 
         * */
        let table_header = []

        if(!is_generic){
            table_header = Object.entries(selected_variant_types).map(([index, data]) => {
                if(index > 0 && !selected_variant_type_values_sub.length){
                    return
                }
                return (<th key={index}>{data.variant_type}</th>)
            })
        }
        table_header.push(<>
            <th width="200">Selling&nbsp;Price&nbsp;<span className='text-danger'>*</span></th>
            {/* <th width="200">Special&nbsp;Price</th> */}
            <th width="200">Cost&nbsp;<span className='text-danger'>*</span></th>
            {record_data.track_inventory ? 
            <>
            <th width="100">Stock&nbsp;<span className='text-danger'>*</span></th>
            <th width="150">Reorder&nbsp;Point&nbsp;<small data-toggle="tooltip" data-placement="top" title="Stock reorder point"><i className="fas fa-question-circle mr-2" /></small></th>
            </> : ''}
            <th width="150">SKU&nbsp;<small data-toggle="tooltip" data-placement="top" title="Leave blank to auto generate SKU"><i className="fas fa-question-circle mr-2" /></small></th>
            <th width="150">Barcode&nbsp;#&nbsp;<small data-toggle="tooltip" data-placement="top" title="12 Digits Barcode"><i className="fas fa-question-circle mr-2" /></small></th>
        </>)
        form_table.push(<thead  key="stock-form-thead"><tr key="stock-form-thead-tr">{table_header}</tr></thead>)

        /**
         * Table Body
         */

        //console.log('displayStockInputForm() total_variation', total_variation)
        let variants = []
        let table_body = []
        let index_counter = 0;
        
        if(total_variation == 0){ //Generic
            const result = variants_data.find((item) => {
                if(item.variant_value_ids.length == 1){
                    if(item.variant_value_ids[0] == 1){
                        return item;
                    }
                }
            })

            // let {id, product_id,product_code, product_price, product_special_price, barcode, manufacturer_part_number, serial_number, qty, product_cost}  = '';
            // let reorder_point = 0;
            // let product_inventories = []; 
            let id = '';
            let product_id = '';
            let product_code = '';
            let product_cost = 0;
            let product_price = 0;
            let product_special_price = '';
            let barcode = '';
            let manufacturer_part_number = '';
            let serial_number = '';
            let reorder_point = 0;
            let product_inventories = []; 

            if(result !== undefined){
                id = result.id ?? '';
                product_id = result.product_id ?? '';
                product_code = result.product_code ?? '';
                product_cost = result.product_cost ?? 0;
                product_price = result.product_price ?? 0;
                product_special_price = result.product_special_price ?? '';
                barcode = result.barcode ?? '';
                manufacturer_part_number = result.manufacturer_part_number ?? '';
                serial_number = result.serial_number ?? '';
                //qty = result.product_inventory[0].available_quantity ?? 0
                //reorder_point = result.product_inventory[0].reorder_point ?? 0
                reorder_point = result.reorder_point ?? 0;

                product_inventories = Object.entries(result.product_inventory).map(([index, data]) => {
                    return {
                            "id": "",
                            "product_id": "",
                            "product_variant_id": 0,
                            "warehouse_id": data.warehouse_id,
                            "location_id": 0,
                            "available_quantity": data.available_quantity,
                            "minimum_stock_level": 0,
                            "maximum_stock_level": 0,
                            "reorder_point": reorder_point
                        }
                })
            }
            else{
                product_inventories = Object.entries(enums.warehouses).map(([index, data]) => {
                    return {
                            "id": "",
                            "product_id": "",
                            "product_variant_id": 0,
                            "warehouse_id": data.id,
                            "location_id": 0,
                            "available_quantity": 0,
                            "minimum_stock_level": 0,
                            "maximum_stock_level": 0,
                            "reorder_point": 0
                        }
                })
            }

            variants = [{
                "id": id,
                "product_id": product_id,
                "variant_value_ids": [1],
                "variant_details": [{
                                        "variant_type_id": 1,
                                        "variant_type": "Generic",
                                        "variant_value_id": 1,
                                        "variant_value": "Generic"
                                    }],
                "product_code": product_code,
                "product_cost": product_cost,
                "product_price": product_price,
                "product_special_price": product_special_price,
                "barcode": barcode,
                "manufacturer_part_number": manufacturer_part_number,
                "serial_number": serial_number,
                "product_inventory": product_inventories,
                "reorder_point": reorder_point
                // "product_inventory": [
                //     {
                //         "id": "",
                //         "product_id": "",
                //         "product_variant_id": 0,
                //         "warehouse_id": 1,
                //         "location_id": 0,
                //         "available_quantity": qty,
                //         "minimum_stock_level": 0,
                //         "maximum_stock_level": 0,
                //         "reorder_point": reorder_point
                //     }
                // ]
            }]

            const variant_value_id = 1;//Generic
            table_body = <tr key={1}>
                            <td>
                                {/* <InputText
                                    id="product_price" 
                                    name="product_price" 
                                    value={getInputValue(0, variant_value_id, '', 'product_price', '')}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Selling Price (PHP)"
                                    type="number"
                                /> */}
                                <InputNumber
                                    id="product_price" 
                                    name="product_price" 
                                    value={getInputValue(0, variant_value_id, '', 'product_price', '')} 
                                    inputClassName="form-control"
                                    onValueChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Selling Price (PHP)"
                                    minFractionDigits={2}
                                    className='w-100'
                                />
                            </td>
                            {/* <td>
                                <InputText
                                    id="product_special_price" 
                                    name="product_special_price" 
                                    value={getInputValue(0, variant_value_id, '', 'product_special_price', '')}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Special Price (PHP)"
                                    type="number"
                                />
                            </td> */}
                            <td>
                                {/* <InputText
                                    id="product_cost" 
                                    name="product_cost" 
                                    value={getInputValue(0, variant_value_id, '', 'product_cost', '', '')}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Item Cost (PHP)"
                                    type="number"
                                /> */}
                                <InputNumber
                                    id="product_cost" 
                                    name="product_cost" 
                                    value={getInputValue(0, variant_value_id, '', 'product_cost', '', 0)} 
                                    inputClassName="form-control"
                                    onValueChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Item Cost (PHP)"
                                    minFractionDigits={2}
                                    className='w-100'
                                />
                            </td>
                            {record_data.track_inventory ?
                            <>
                            <td>
                                {/* <InputText
                                    id="available_quantity" 
                                    name="available_quantity" 
                                    value={getInputValue(0, variant_value_id, '', 'available_quantity', 'product_inventory', 0)}   
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory', 'sanitize_number')}
                                    placeholder="Quantity"
                                /> */}
                                    <InputNumber
                                        id="available_quantity" 
                                        name="available_quantity" 
                                        value={getInputValue(0, variant_value_id, '', 'available_quantity', 'product_inventory', 0)}  
                                        inputClassName="form-control"
                                        onValueChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory')}
                                        placeholder="Quantity"
                                        className='w-100'
                                        //min={0}
                                    />
                            </td>
                            <td>
                                {/* <InputText
                                    id="reorder_point" 
                                    name="reorder_point" 
                                    value={getInputValue(0, variant_value_id, '', 'reorder_point', 'product_inventory')}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory', 'sanitize_number')}
                                    placeholder="Reorder Point"
                                /> */}
                                <InputNumber
                                    id="reorder_point" 
                                    name="reorder_point" 
                                    value={getInputValue(0, variant_value_id, '', 'reorder_point', '', 0)}  
                                    inputClassName="form-control"
                                    onValueChange={(e) => inputVariants(e, variant_value_id)}
                                    placeholder="Reorder Point"
                                    className='w-100'
                                    min={0}
                                />
                            </td>
                            </> : ''}
                            <td>
                                <InputText
                                    id="product_code" 
                                    name="product_code" 
                                    value={getInputValue(0, variant_value_id, '', 'product_code', '')}  
                                    inputClassName="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '')}
                                    placeholder="Product Code"
                                    className='w-100'
                                />
                            </td>

                            <td>
                                {/* <InputText
                                    id="barcode" 
                                    name="barcode" 
                                    value={getInputValue(0, variant_value_id, '', 'barcode', '')}  
                                    tagClass="form-control mb-0"
                                    onChange={(e) => inputVariants(e, variant_value_id, '', '', 'product_inventory', 'sanitize_number')}
                                    placeholder="Barcode"
                                    type="number"
                                /> */}
                                    <InputText
                                        id="barcode" 
                                        name="barcode" 
                                        value={getInputValue(0, variant_value_id, '', 'barcode', '', '')}
                                        inputClassName="form-control"
                                        onInput={(e) => inputVariants(e, variant_value_id, '', '', 'product_inventory')}
                                        placeholder="Barcode"
                                        className='w-100'
                                        max={999999999999}
                                        keyfilter="num"
                                    />
                            </td>
                    </tr>
        }
        else{
            if(is_generic == 0){
                table_body = Object.entries(selected_variant_type_values_main).map(([index, data]) => {
                    const variant_type_id = data.variant_type_id
                    const variant_value_id = data.variant_value_id
                    //const variant_type_data = variant_type_list.find(item => item.id == variant_type_id);
                    const main_variant_value_data = data;

                    // let variant_value_data = [];
                    // if(variant_type_data){
                    //     variant_value_data = variant_type_data.variant_values.find(item => item.id == variant_value_id)
                    // }
                    if(variant_type_id == 1) return;
                    
                    const variant_value_data = selected_variant_type_values_main.find(item => item.variant_value_id == variant_value_id)
                    let variant_value_main = ''
                    if(variant_value_data.hasOwnProperty('variant_value')){
                        variant_value_main = variant_value_data.variant_value
                    }

                    let tr = '';

                    
                    if(!selected_variant_type_values_sub.length)
                    { //1 variant level only
                        index_counter++;
                        const result = variants_data.find((item) => {
                            if(item.variant_value_ids.length == 1){
                                if(item.variant_value_ids[0] == variant_value_id){
                                    return item;
                                }
                            }
                        })

                        // let {id, product_id,product_code, product_price, product_special_price, barcode, manufacturer_part_number, serial_number, qty, product_cost}  = '';
                        // let reorder_point = 0;
                        // let product_inventories = []; 

                        let id = '';
                        let product_id = '';
                        let product_code = '';
                        let product_cost = 0;
                        let product_price = 0;
                        let product_special_price = '';
                        let barcode = '';
                        let manufacturer_part_number = '';
                        let serial_number = '';
                        let reorder_point = 0;
                        let product_inventories = []; 

                        if(result !== undefined){
                            id = result.id ?? '';
                            product_id = result.product_id ?? '';
                            product_code = result.product_code ?? '';
                            product_cost = result.product_cost ?? 0;
                            product_price = result.product_price ?? 0;
                            product_special_price = result.product_special_price ?? '';
                            barcode = result.barcode ?? '';
                            manufacturer_part_number = result.manufacturer_part_number ?? '';
                            serial_number = result.serial_number ?? '';
                            //qty = result.product_inventory[0].available_quantity ?? 0
                            //reorder_point = result.product_inventory[0].reorder_point ?? 0
                            reorder_point = result.reorder_point ?? 0;

                            product_inventories = Object.entries(result.product_inventory).map(([index, data]) => {
                                return {
                                        "id": "",
                                        "product_id": "",
                                        "product_variant_id": 0,
                                        "warehouse_id": data.warehouse_id,
                                        "location_id": 0,
                                        "available_quantity": data.available_quantity,
                                        "minimum_stock_level": 0,
                                        "maximum_stock_level": 0,
                                        "reorder_point": reorder_point
                                    }
                            })
                        }
                        else{
                            product_inventories = Object.entries(enums.warehouses).map(([index, data]) => {
                                return {
                                        "id": "",
                                        "product_id": "",
                                        "product_variant_id": 0,
                                        "warehouse_id": data.id,
                                        "location_id": 0,
                                        "available_quantity": 0,
                                        "minimum_stock_level": 0,
                                        "maximum_stock_level": 0,
                                        "reorder_point": 0
                                    }
                            })
                        }

                        variants.push({
                            "id": id,
                            "product_id": product_id,
                            "variant_value_ids": [variant_value_id],
                            "variant_details": [main_variant_value_data],
                            "product_code": product_code,
                            "product_cost": product_cost,
                            "product_price": product_price,
                            "product_special_price": product_special_price,
                            "barcode": barcode,
                            "manufacturer_part_number": manufacturer_part_number,
                            "serial_number": serial_number,
                            "product_inventory": product_inventories,
                            "reorder_point": reorder_point
                            // "product_inventory": [
                            //     {
                            //         "id": "",
                            //         "product_id": "",
                            //         "product_variant_id": "",
                            //         "warehouse_id": 1,
                            //         "location_id": 0,
                            //         "available_quantity": qty,
                            //         "minimum_stock_level": 0,
                            //         "maximum_stock_level": 0,
                            //         "reorder_point": reorder_point
                            //     }
                            // ]
                        })


                        tr = <tr key={index_counter}>
                                    <td className='align-middle'>{variant_value_main}</td>
                                    <td>
                                        {/* <InputText
                                            id="product_price" 
                                            name="product_price" 
                                            value={getInputValue(0, variant_value_id, '', 'product_price', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Selling Price (PHP)"
                                            type="number"
                                        /> */}
                                        <InputNumber
                                            id="product_price" 
                                            name="product_price" 
                                            value={getInputValue(0, variant_value_id, '', 'product_price', '')}
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Selling Price (PHP)"
                                            minFractionDigits={2}
                                            className='w-100'
                                        />
                                    </td>
                                    {/* <td>
                                        <InputText
                                            id="product_special_price" 
                                            name="product_special_price" 
                                            value={getInputValue(0, variant_value_id, '', 'product_special_price', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Special Price (PHP)"
                                            type="number"
                                        />
                                    </td> */}
                                    <td>
                                        {/* <InputText
                                            id="product_cost" 
                                            name="product_cost" 
                                            value={getInputValue(0, variant_value_id, '', 'product_cost', '', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Item Cost (PHP)"
                                            type="number"
                                        /> */}
                                        <InputNumber
                                            id="product_cost" 
                                            name="product_cost" 
                                            value={getInputValue(0, variant_value_id, '', 'product_cost', '', 0)}
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Item Cost (PHP)"
                                            minFractionDigits={2}
                                            className='w-100'
                                        />
                                    </td>

                                    {record_data.track_inventory ?
                                    <>
                                    <td>
                                        {/* <InputText
                                            id="available_quantity" 
                                            name="available_quantity" 
                                            value={getInputValue(0, variant_value_id, '', 'available_quantity', 'product_inventory', 0)}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory', 'sanitize_number')}
                                            placeholder="Quantity"
                                        /> */}
                                        <InputNumber
                                            id="available_quantity" 
                                            name="available_quantity" 
                                            value={getInputValue(0, variant_value_id, '', 'available_quantity', 'product_inventory', 0)}
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory')}
                                            placeholder="Quantity"
                                            className='w-100'
                                            //min={0}
                                        />
                                    </td>
                                    <td>
                                        {/* <InputText
                                            id="reorder_point" 
                                            name="reorder_point" 
                                            value={getInputValue(0, variant_value_id, '', 'reorder_point', 'product_inventory', 0)}   
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '', 'product_inventory', 'sanitize_number')}
                                            placeholder="Reorder Point"
                                        /> */}
                                        <InputNumber
                                            id="reorder_point" 
                                            name="reorder_point" 
                                            //value={getInputValue(0, variant_value_id, '', 'reorder_point', 'product_inventory', 0)}  
                                            value={getInputValue(0, variant_value_id, '', 'reorder_point', '', 0)}
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id)}
                                            placeholder="Reorder Point"
                                            className='w-100'
                                            min={0}A
                                        />
                                    </td>
                                    </> : ''}
                                    <td>
                                        <InputText
                                            id="product_code" 
                                            name="product_code" 
                                            value={getInputValue(0, variant_value_id, '', 'product_code', '')}  
                                            inputClassName="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '')}
                                            placeholder="Product Code"
                                            className='w-100'
                                        />
                                    </td>

                                    <td>
                                        {/* <InputText
                                            id="barcode" 
                                            name="barcode" 
                                            value={getInputValue(0, variant_value_id, '', 'barcode', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, '', '', 'product_inventory', 'sanitize_number')}
                                            placeholder="Barcode"
                                            type="number"
                                        /> */}
                                        <InputText
                                            id="barcode" 
                                            name="barcode" 
                                            value={getInputValue(0, variant_value_id, '', 'barcode', '', '')} 
                                            inputClassName="form-control"
                                            onInput={(e) => inputVariants(e, variant_value_id, '', '', 'product_inventory')}
                                            placeholder="Barcode"
                                            className='w-100'
                                            max={999999999999}
                                            keyfilter="num"
                                        />
                                    </td>
                            </tr>
                    }
                    else 
                    { //2 level variants
                        tr = Object.entries(selected_variant_type_values_sub).map(([index, data]) => {
                            index_counter++;
                            const sub_variant_value_data = data;
                            //const sub_variant_type_id = data.variant_type_id
                            const sub_variant_value_id = data.variant_value_id
                            // const sub_variant_type_data = variant_type_list.find(item => item.id == sub_variant_type_id);

                            // let sub_variant_value_data = []
                            // if(sub_variant_type_data){
                            //     sub_variant_value_data = sub_variant_type_data.variant_values.find(item => item.id == sub_variant_value_id)
                            // }
                            const variant_value_data = selected_variant_type_values_sub.find(item => item.variant_value_id == sub_variant_value_id)
                            let variant_value_sub = ''
                            if(variant_value_data.hasOwnProperty('variant_value')){
                                variant_value_sub = variant_value_data.variant_value
                            }


                            const result = variants_data.find((item) => {
                                if(item.variant_value_ids.length > 1){
                                    if(item.variant_value_ids[0] == variant_value_id && item.variant_value_ids[1] == sub_variant_value_id){
                                        return item;
                                    }
                                }
                            })

                            let id = '';
                            let product_id = '';
                            let product_code = '';
                            let product_cost = 0;
                            let product_price = 0;
                            let product_special_price = '';
                            let barcode = '';
                            let manufacturer_part_number = '';
                            let serial_number = '';
                            let reorder_point = 0;
                            let product_inventories = []; 

                            if(result !== undefined){
                                id = result.id;
                                product_id = result.product_id;
                                product_code = result.product_code;
                                product_cost = result.product_cost ?? 0;
                                product_price = result.product_price ?? 0;
                                product_special_price = result.product_special_price;
                                barcode = result.barcode;
                                manufacturer_part_number = result.manufacturer_part_number;
                                serial_number = result.serial_number;
                                reorder_point = result.reorder_point ?? 0;

                                product_inventories = Object.entries(result.product_inventory).map(([index, data]) => {
                                    return {
                                            "id": "",
                                            "product_id": "",
                                            "product_variant_id": 0,
                                            "warehouse_id": data.warehouse_id,
                                            "location_id": 0,
                                            "available_quantity": data.available_quantity,
                                            "minimum_stock_level": 0,
                                            "maximum_stock_level": 0,
                                            "reorder_point": reorder_point
                                        }
                                })
                            }
                            else{
                                product_inventories = Object.entries(enums.warehouses).map(([index, data]) => {
                                    return {
                                            "id": "",
                                            "product_id": "",
                                            "product_variant_id": 0,
                                            "warehouse_id": data.id,
                                            "location_id": 0,
                                            "available_quantity": 0,
                                            "minimum_stock_level": 0,
                                            "maximum_stock_level": 0,
                                            "reorder_point": 0
                                        }
                                })
                            }

                            variants.push({
                                "id": id,
                                "product_id": product_id,
                                "variant_value_ids": [variant_value_id, sub_variant_value_id],
                                "variant_details" : [main_variant_value_data, sub_variant_value_data],
                                "product_code": product_code,
                                "product_cost": product_cost,
                                "product_price": product_price,
                                "product_special_price": product_special_price,
                                "barcode": barcode,
                                "manufacturer_part_number": manufacturer_part_number,
                                "serial_number": serial_number,
                                "product_inventory": product_inventories,
                                "reorder_point": reorder_point
                                // "product_inventory": [
                                //     {
                                //         "id": "",
                                //         "product_id": "",
                                //         "product_variant_id": 0,
                                //         "warehouse_id": 1,
                                //         "location_id": 0,
                                //         "available_quantity": qty,
                                //         "minimum_stock_level": 0,
                                //         "maximum_stock_level": 0,
                                //         "reorder_point": reorder_point
                                //     }
                                // ]
                            })

                            return(
                                <tr className={index_counter}>
                                    {index == 0 ? <td rowSpan={selected_variant_type_values_sub.length} className='align-middle'>{variant_value_main}</td> : ''}
                                    <td className='align-middle'>{variant_value_sub}</td>
                                    <td>
                                        {/* <InputText
                                            id="product_price" 
                                            name="product_price" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_price', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, '', 'sanitize_amount')}
                                            placeholder="Selling Price (PHP)"
                                        /> */}
                                        <InputNumber
                                            id="product_price" 
                                            name="product_price" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_price', '')}  
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id)}
                                            placeholder="Selling Price (PHP)"
                                            minFractionDigits={2}
                                            className='w-100'
                                        />
                                    </td>
                                    {/* <td>
                                        <InputText
                                            id="product_special_price" 
                                            name="product_special_price" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_special_price', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id)}
                                            placeholder="Special Price (PHP)"
                                            type="number"
                                        />
                                    </td> */}
                                    <td>
                                        {/* <InputText
                                            id="product_cost" 
                                            name="product_cost" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_cost', '')}  
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, '', 'sanitize_amount')}
                                            placeholder="Item Cost (PHP)"
                                        /> */}
                                        <InputNumber
                                            id="product_cost" 
                                            name="product_cost" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_cost', '', 0)}  
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id)}
                                            placeholder="Item Cost (PHP)"
                                            minFractionDigits={2}
                                            className='w-100'
                                        />
                                    </td>
                                    {record_data.track_inventory ?
                                    <>
                                    <td>
                                        {/* <InputText
                                            id="available_quantity" 
                                            name="available_quantity" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'available_quantity', 'product_inventory', 0)}   
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, 'product_inventory', 'sanitize_number')}
                                            placeholder="Quantity"
                                        /> */}
                                        <InputNumber
                                            id="available_quantity" 
                                            name="available_quantity" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'available_quantity', 'product_inventory', 0)}
                                            inputClassName="form-control"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, 'product_inventory')}
                                            placeholder="Quantity"
                                            className='w-100'
                                            //min={0}
                                        />
                                    </td>
                                    <td>
                                        {/* <InputText
                                            id="reorder_point" 
                                            name="reorder_point" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'reorder_point', 'product_inventory', 0)}   
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, 'product_inventory', 'sanitize_number')}
                                            placeholder="Reorder Point"
                                        /> */}
                                        <InputNumber
                                            id="reorder_point" 
                                            name="reorder_point" 
                                            //value={getInputValue(1, variant_value_id, sub_variant_value_id, 'reorder_point', 'product_inventory', 0)}
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'reorder_point', '', 0)}
                                            inputClassName="form-control mb-0"
                                            onValueChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id)}
                                            placeholder="Reorder Point"
                                            className='w-100'
                                            min={0}
                                        />
                                    </td>
                                    </> : ''}
                                    <td>
                                        <InputText
                                            id="product_code" 
                                            name="product_code" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'product_code', '')} 
                                            inputClassName="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id)}
                                            placeholder="Product Code"
                                            className='w-100'
                                        />
                                    </td>

                                    <td>
                                        {/* <InputText
                                            id="barcode" 
                                            name="barcode" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'barcode', '')} 
                                            tagClass="form-control mb-0"
                                            onChange={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, '', 'sanitize_number')}
                                            placeholder="Barcode"
                                        /> */}
                                        <InputText
                                            id="barcode" 
                                            name="barcode" 
                                            value={getInputValue(1, variant_value_id, sub_variant_value_id, 'barcode', '', '')}
                                            inputClassName="form-control"
                                            onInput={(e) => inputVariants(e, variant_value_id, sub_variant_value_id, '')}
                                            placeholder="Barcode"
                                            className='w-100'
                                            max={999999999999}
                                            keyfilter="num"
                                        />
                                    </td>
                            </tr>
                            )
                        });
                    }
                    

                    return (tr)
                })
            }

            // if(selected_variant_type_values_main.length)
            // { //With multiple variants

            // }
            // else{ // Generic or Single Variant
            //     variants = []
                
            //     //if(total_variation == 0 ){
                    
            //     //}
            // }
        }


        form_table.push(<tbody key="stock-form-tbody">{table_body}</tbody>)

        setNewVariants(variants)

        setStockInfoForm (<div className="table-responsive mb-1"><table key="stock-form-table" className='table table-bordered table-sm table-striped mb-0'>{form_table}</table></div>)
    }

    function openVariantTypeModal(variant_no){
        setVariantNo(variant_no)
        setVariantTypesModal(1)
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }
        else if (name === "warehouse_id") {
            setWarehouseID(value)
            return;
        }

        let new_state = { [name]: value }

        setProduct({ ...record_data, ...new_state });
    }

    function inputVariants(e, variant_value_id, sub_variant_value_id = '', property = '', sanitize = '') {
        let { value, name } = e.target;

        if(sanitize == 'sanitize_number'){
            let number = value;

            number = number.toString();

            number = number.replace(/[^0-9]+/g, "");

            value = number
        }
        else if(sanitize == 'sanitize_amount'){

        }

        if(name === 'available_quantity' && !value){
            value = 0;
        }

        let variants = [...variants_data]
        const result = variants.findIndex((item) => {
            if(!sub_variant_value_id && item.variant_value_ids.length == 1){
                if(item.variant_value_ids[0] == variant_value_id){
                    return item;
                }
            }
            else{
                if(item.variant_value_ids[0] == variant_value_id && item.variant_value_ids[1] == sub_variant_value_id){
                    return item;
                }
            }
        })

        if(result < 0){
            let product_inventories = []; 
            product_inventories = Object.entries(enums.warehouses).map(([index, data]) => {
                return {
                        "id": "",
                        "product_id": "",
                        "product_variant_id": 0,
                        "warehouse_id": data.id,
                        "location_id": 0,
                        "available_quantity": 0,
                        "minimum_stock_level": 0,
                        "maximum_stock_level": 0,
                        "reorder_point": 0
                    }
            })
            
            let new_variant = {
                "id": "",
                "product_id": "",
                "variant_value_ids": [],
                "product_code": "",
                "product_cost": "",
                "product_price": "",
                "product_special_price": "",
                "barcode": "",
                "manufacturer_part_number": "",
                "serial_number": "",
                "product_inventory": product_inventories,
                "reorder_point": 0
                // "product_inventory": [
                //     {
                //         "id": 0,
                //         "product_id": "",
                //         "product_variant_id": "",
                //         "warehouse_id": 1,
                //         "location_id": 0,
                //         "available_quantity": 0,
                //         "minimum_stock_level": 0,
                //         "maximum_stock_level": 0,
                //         "reorder_point": 0
                //     }
                // ]
            }

            
            let variant_value_ids = [variant_value_id]

            if(sub_variant_value_id){
                variant_value_ids.push(sub_variant_value_id)
            }

            new_variant['variant_value_ids'] = variant_value_ids
            
            if(property){
                new_variant[property][0][name] = value
            }
            else{
                new_variant[name] = value
            }
            
            variants.push(new_variant)

            setVariants(variants)
        }
        else{
            let specific_variant = variants[result]
            if(property){
                let inventory = getInventoryIndex(specific_variant[property], warehouse_id)

                if(inventory !== 'none'){
                    specific_variant[property][inventory][name] = value
                }    
                //specific_variant[property][0][name] = value
            }
            else{
                specific_variant[name] = value
            }

            variants[result] = specific_variant
            setVariants(variants)
        }
    }

    function inputVariantType(e, index) {
        let { value, name } = e.target;

        let variant_types = [...selected_variant_types]

        if(index == 0 && variant_types.includes(value)){
            variant_types = [value]
        }
        else{  
            variant_types[index] = value;
        }

        if(index == 0){
            setSelectedVariantValueMain([])
        }
        else{
            setSelectedVariantValueSub([])
        }

        setSelectedVariantType(variant_types)
    }

    function inputVariantTypeValue(variant_type_id, variant_value_data, variant_no){
        let variant_values = []
        if(variant_no == 1){
            variant_values = [...selected_variant_type_values_main]
        }
        else{
            variant_values = [...selected_variant_type_values_sub]
        }

        let variant_type = selected_variant_types[variant_no-1];
        const variant_value_id = variant_value_data.variant_value_id

        const exist = variant_values.findIndex(item => item.variant_type_id == variant_type_id && item.variant_value_id == variant_value_id);


        if(exist > -1){
            variant_values.splice(exist, 1); 
        }
        else{
            variant_values.push({
                "variant_type_id": variant_type_id,
                "variant_type": variant_type.variant_type,
                "variant_value_id": variant_value_id,
                "variant_value": variant_value_data.variant_value
            })
        }

        if(variant_no == 1){
            setSelectedVariantValueMain(variant_values)
        }
        else{
            setSelectedVariantValueSub(variant_values)
        }
    }

    function getEnums(){
        setLoading(1)
        const url = helpers.getUrl('V1/enums/item?store_id='+helpers.getStoreID())
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setEnums(res)
            setVariantTypeList(res.variant_type_list)
            setCategoryList(res.category_list)
            setBrandList(res.brand_list)
            setUnitOfMeasureList(res.uom_list)

            const warehouse = res.warehouses
            if(Object.keys(res.warehouses).length){
                setWarehouseID(warehouse[0].id)
            }

            if(!record_loaded){ //New record
                let variants = [...variants_data]
                let product_inventories = []; 
                product_inventories = Object.entries(warehouse).map(([index, data]) => {
                    return {
                            "id": "",
                            "product_id": "",
                            "product_variant_id": 0,
                            "warehouse_id": data.id,
                            "location_id": 0,
                            "available_quantity": 0,
                            "minimum_stock_level": 0,
                            "maximum_stock_level": 0,
                            "reorder_point": 0
                        }
                })
                variants[0].product_inventory = product_inventories;
                variants[0].reorder_point = 0;
            }
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function addVariation(){
        let total_variant_type = total_variation;

        if(total_variant_type < 2){
            setTotalVariation(total_variant_type + 1)
        }
    }

    function removeVariation(index){
        let total_variant_type = total_variation;

        let variant_types = [...selected_variant_types]
        variant_types.splice(index, 1); 

        if(index == 0){
            setSelectedVariantValueMain(selected_variant_type_values_sub)// Transfer to sub to main
            setSelectedVariantValueSub([])
        }
        else{
            setSelectedVariantValueSub([])
        }

        setSelectedVariantType(variant_types)
        setTotalVariation(total_variant_type - 1)
    }

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in record_data) {
            form.append(key, record_data[key]);
        }

        return form
    }

    function getData(product_id){
        if(!product_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/item/${product_id}?store_id=${helpers.getStoreID()}`)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setProduct(res)
                setVariants(res.variants)
                setRecordLoaded(1)

                let total_variations = res.selected_variant_types.length;
                let variant_types = res.selected_variant_types[0].variant_type_id ?? ''

                if(variant_types == 1){ //generic
                    total_variations = 0
                }
                
                setSelectedVariantType(res.selected_variant_types)
                setSelectedVariantValueMain(res.selected_variant_type_values_main)
                setSelectedVariantValueSub(res.selected_variant_type_values_sub)

                setVariantValueMain(res.selected_variant_type_values_main)
                setVariantValueSub(res.selected_variant_type_values_sub)

                setTotalVariation(total_variations)
                setLoading(0)
                getEnums()
            })
            .catch((err) => {
                setLoading(0)
                getEnums()

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/item/list");
                    }
                })
            }); 
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.product_type_id){
            new_errors['product_type_id'] = ['The item type field is required.']
        }
        else{
            delete new_errors['product_type_id'];
        }

        if(!record_data.product_name){
            new_errors['product_name'] = ['The item name field is required.']
        }
        else{
            delete new_errors['product_name'];
        }

        if(record_data.product_type_id == 1 && !record_data.unit_id){
            new_errors['unit_id'] = ['The unit field is required.']
        }
        else{
            delete new_errors['unit_id'];
        }

        if(!record_data.category_id){
            new_errors['category_id'] = ['The category field is required.']
        }
        else{
            delete new_errors['category_id'];
        }

        if(!record_data.brand_id){
            new_errors['brand_id'] = ['The brand field is required.']
        }
        else{
            delete new_errors['brand_id'];
        }

        if(record_data.package_weight && !record_data.weight_unit){
            new_errors['weight_unit'] = ['The weight unit field is required.']
        }
        else{
            delete new_errors['weight_unit'];
        }
        
        if((record_data.package_length || record_data.package_width || record_data.package_height) && !record_data.volume_unit){
            new_errors['volume_unit'] = ['The volume unit field is required.']
        }
        else {//if(record_data.volume_unit){
            delete new_errors['volume_unit'];
        }

        if(!new_variants_data.length){
            variant_errors++;
        }
        else{
            //console.log('new_variants_data', new_variants_data)
            // Variants
            Object.entries(new_variants_data).map(([key, data]) => {
                if(data.product_price===''){
                    variant_errors++;
                    //Must be not be empty
                    //console.log('product_price must not empty')
                }
                if(data.product_cost===''){
                    variant_errors++;
                    //Must be not be empty
                    //console.log('product_cost must not empty')
                }
                if(data.barcode && data.barcode.length != 12){
                    variant_errors++;
                    //12 Digit only
                    //console.log('barcode 12 Digit only')
                }

                if(isNaN(Number(data.reorder_point))){
                    variant_errors++;
                    //console.log('reorder_point must be number')
                }

                Object.entries(data.product_inventory).map(([key, data]) => {
                    //console.log('product_inventory',data)
                    if(data.available_quantity===''){
                        variant_errors++;
                        //Must be not be empty
                        //console.log('available_quantity must not empty')
                    }
                    else if(isNaN(Number(data.available_quantity))){//data.available_quantity
                        variant_errors++;
                        //Must be numeric
                        //console.log('available_quantity must be number')
                    }

                    // if(isNaN(Number(data.reorder_point))){
                    //     variant_errors++;
                    //     //console.log('reorder_point must be number')
                    // }
                })

                if(!Object.keys(data.variant_details).length){
                    variant_errors++;
                }

            })
        }

        
        if(variant_errors){
            new_errors['variants'] = ['The variants field data format is invalid.']
        }
        else{
            delete new_errors['variants'];
        }

        setErrors(new_errors);

        return new_errors;
    }


    function submit(product_status = 1){
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        let url = helpers.getUrl('V1/manage/item/create')
        let method = 'POST'
        if(product_id){
            url = helpers.getUrl('V1/manage/item/' + product_id)
            method = 'PUT'
        }

        let form_data = {...record_data}
        form_data['variants'] = new_variants_data;
        form_data['product_status'] = product_status;

        //return
        setLoading(1)
        httpRequest(url, 'POST', {
            postdata: {...form_data},
        }, true)
            .then((res) => {

                let id = product_id;
                if(!id){
                    //id = res.id
                    id = res.data.id
                }

                setLoading(0)

                let msg = res.message

                Swal.fire({
                    title: "Success",
                    text: msg,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/item/${id}/edit`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                // Swal.fire({
                //     title: "Error!",
                //     text: err.message,
                //     icon: "error",
                //     confirmButtonText: "OK",
                // });
            }); 
    }

    function handleBrandSave(e){
        let data = {...record_data}

        data.brand_id = e.id

        setProduct(data)
        getEnums()
    }

    function handleCategorySave(e){
        let data = {...record_data}

        data.category_id = e.id

        setProduct(data)
        getEnums()
    }

    function handleTaxRateSave(e){
        let data = {...record_data}

        data.tax_rate_id = e.id

        setProduct(data)
        getEnums()
    }

    function handleVariantType(e){
        let variant_types = [...selected_variant_types]

        let new_variant_type = {
            "variant_type_id": e.id,
            "variant_type": e.variant_name
        }
        
        if(!variant_types[variant_type_no]){
            variant_types.push(new_variant_type)
        }
        else{
            variant_types[variant_type_no] = new_variant_type
        }

        setSelectedVariantType(variant_types)

        if(variant_type_no == 1){
            setVariantValueSub(e.variant_values)
        }
        else{
            setVariantValueMain(e.variant_values)
        }
    }
    
    return (
        <>
        <CategoryFormModal 
            show_modal={show_category_modal} 
            close_modal={setShowCategoryModal} 
            handleSave={handleCategorySave}
        />

        <TaxRateFormModal 
            show_modal={show_tax_modal} 
            close_modal={setShowTaxModal} 
            handleSave={handleTaxRateSave}
        />

        <BrandFormModal 
            show_modal={show_brand_modal} 
            close_modal={setShowBrandModal} 
            handleSave={handleBrandSave}
        />

        <VariantTypeListModal
            show_modal={variant_types_modal}
            close_modal={setVariantTypesModal}
            handleSelect={handleVariantType}
            exclude={selected_variant_types}
        />

        <MainContainer page_title="Item Form" menu="manage-item" parent_link='/item/list' parent_text="Manage Items">
            <div className="container-fluid pb-5">
                <div className="row d-flex justify-content-center" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="col-sm-12 col-md-10 col-lg-12">
                        <form>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className='float-right mb-0'>{helpers.displayStatusBadge(record_data.product_status)}</h5>
                                    <h5 className='mb-0'>Info</h5>
                                </div>
                                <div className="card-body">
                                        <div className='row mb-1'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                            </div>
                                            <div className="col-md-5 col-lg-4 form-group">
                                                <ButtonGroupSelection
                                                    tagClass="form-control"
                                                    id="product_type_id"
                                                    name='product_type_id' 
                                                    value={record_data.product_type_id}  
                                                    onChange={inputChange}
                                                    onFocus=""
                                                    optionList={store.product_type}
                                                    optionProps ={{value:'id', label:'label'}}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="product_type_id" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-6 col-lg-4 form-group">
                                                <InputText
                                                    id="product_name" 
                                                    name="product_name" 
                                                    value={record_data.product_name}
                                                    tagClass="form-control mb-0"
                                                    onChange={inputChange}
                                                    placeholder="Ex Samsung Galaxy S23 Smart Phone"
                                                    className='w-100'
                                                    // label={<>Item Description <span className="text-danger">*</span></>}
                                                    readOnly={readonly}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="product_name" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />

                                            </div>
                                        </div>
                                        <div className='row mb-1'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Description</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <InputTextArea
                                                    id="product_description" 
                                                    name="product_description" 
                                                    value={record_data.product_description}  
                                                    tagClass="form-control"
                                                    onChange={inputChange}
                                                    placeholder="Enter Description"
                                                    rows={4}
                                                    readOnly={readonly}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="address_1" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-1'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Category <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-6 col-lg-4 form-group">
                                                <div className="input-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="category_id"
                                                        name='category_id' 
                                                        placeholder='Select Category'
                                                        value={record_data.category_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={category_list}
                                                        optionProps ={{value:'id', label:'category_name'}}
                                                        // label={<>Category <span className="text-danger">*</span></>}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button" onClick={(e) => setShowCategoryModal(1)}><i className="fas fa-plus-circle" /></button>
                                                    </div>
                                                </div>
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="category_id" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-1'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Brand <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-6 col-lg-4 form-group">
                                                <div className="input-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="brand_id"
                                                        name='brand_id' 
                                                        placeholder='Select Brand'
                                                        value={record_data.brand_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={brand_list}
                                                        optionProps ={{value:'id', label:'brand_name'}}
                                                        // label={<> Brand</>}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button" onClick={(e) => setShowBrandModal(1)}><i className="fas fa-plus-circle" /></button>
                                                    </div>
                                                </div>
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="brand_id" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                            <div className="col-12" />
                                        </div>
                                        <div className='row mb-1'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Default Tax Rate</label>
                                            </div>
                                            <div className="col-md-6 col-lg-4 form-group">
                                                <div className="input-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="tax_rate_id"
                                                        name='tax_rate_id' 
                                                        placeholder='Select Tax Rate'
                                                        value={record_data.tax_rate_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={enums.tax_rates}
                                                        optionProps ={{value:'id', label:'label'}}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button" onClick={(e) => setShowTaxModal(1)}><i className="fas fa-plus-circle" /></button>
                                                    </div>
                                                </div>
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="tax_rate_id" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                <label>Unit {record_data.product_type_id == 1 ? <span className="text-danger">*</span> : ''}</label>
                                            </div>
                                            <div className="col-md-6 col-lg-4 form-group">
                                                <DropDownSelection
                                                    tagClass="form-control"
                                                    id="unit_id"
                                                    name='unit_id' 
                                                    placeholder='Select Unit of Measure'
                                                    value={record_data.unit_id}  
                                                    onChange={inputChange}
                                                    onFocus=""
                                                    optionList={uom_list}
                                                    optionProps ={{value:'id', label:'unit_name'}}
                                                />
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="unit_id" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-3 col-lg-2"></div>
                                            <div className="col-md-5 col-lg-4">
                                                <InputCheckbox
                                                    id="track_inventory"
                                                    name="track_inventory"
                                                    value="1"
                                                    onChange={inputChange}
                                                    checked = {record_data.track_inventory}
                                                    tagClass='me-2'
                                                    label="Track Inventory"
                                                    labelClass="ms-2 font-weight-normal"
                                                />
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <div className='float-right'>
                                        {total_variation < 2 ? <button type='button' className='btn btn-primary btn-sm' onClick={addVariation}>{total_variation == 0 ? 'Add Variation' : 'More Variation'}</button>: ''}
                                    </div>
                                    <h5 className='mb-0'>Stock & Pricing</h5>
                                </div>
                                <div className="card-body">
                                    {record_data.track_inventory ? 
                                    <div className='row'>
                                        <div className="col-md-6 col-lg-3 form-group">
                                            <DropDownSelection
                                                tagClass="form-control"
                                                id="warehouse_id"
                                                name='warehouse_id' 
                                                placeholder='Select Warehouse'
                                                value={warehouse_id}  
                                                onChange={inputChange}
                                                onFocus=""
                                                optionList={enums.warehouses}
                                                optionProps ={{value:'id', label:'warehouse_name'}}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="warehouse_id" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div> : ''}

                                    <div className="row">
                                        {/* <div className="col-md-3 form-group">
                                            {total_variation < 2 ? <button type='button' className='btn btn-primary btn-sm' onClick={addVariation}>{total_variation == 0 ? 'Add Variation' : 'More Variation'}</button>: ''}
                                        </div> */}
                                        {
                                            variant_type_selector
                                        }
                                            
                                        <div className="col-sm-12 mb-0" />
                                        <div className="col-sm-12">
                                                {stock_info_form}
                                                <RenderErrors 
                                                    errors={errors} 
                                                    name="variants" 
                                                    className="text-danger"
                                                    style={{fontSize: '.9rem'}}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { record_data.product_type_id == 1 ?
                            <div className="card">
                                <div className="card-header">
                                    <h5 className='mb-0'>Shipping</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 col-lg-2 form-group">
                                            <label>
                                                <span className='mr-2'>Weight</span>
                                                <ButtonGroupSelection
                                                    tagClass="form-control"
                                                    id="weight_unit"
                                                    name='weight_unit'
                                                    value={record_data.weight_unit}  
                                                    onChange={inputChange}
                                                    onFocus=""
                                                    optionList={store.weight_units}
                                                    optionProps ={{value:'id', label:'label'}}
                                                    size="sm"
                                                />
                                            </label>
                                            {/* <InputText
                                                id="package_weight" 
                                                name="package_weight" 
                                                value={record_data.package_weight}  
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder={record_data.weight_unit} 
                                                className='w-100'
                                            /> */}
                                            <InputNumber 
                                                name="package_weight" 
                                                id="package_weight" 
                                                value={record_data.package_weight} 
                                                onValueChange={inputChange} 
                                                minFractionDigits={2}
                                                className='w-100'
                                                inputClassName="form-control"
                                                placeholder='Package Weight'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="weight_unit" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-2 form-group">
                                            <label>
                                                <span className='mr-2'>Length</span>
                                                <ButtonGroupSelection
                                                    tagClass="form-control"
                                                    id="volume_unit"
                                                    name='volume_unit'
                                                    value={record_data.volume_unit}  
                                                    onChange={inputChange}
                                                    onFocus=""
                                                    optionList={store.volume_units}
                                                    optionProps ={{value:'id', label:'label'}}
                                                    size="sm"
                                                />
                                            </label>
                                            <InputNumber 
                                                name="package_length" 
                                                id="package_length" 
                                                value={record_data.package_length} 
                                                onValueChange={inputChange} 
                                                minFractionDigits={2}
                                                className='w-100'
                                                inputClassName="form-control"
                                                placeholder='Package Length'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="volume_unit" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-2 form-group">
                                            <label className='mb-3'>Width{record_data.volume_unit ? ' ('+record_data.volume_unit+')' : ''}</label>
                                            <InputNumber 
                                                name="package_width" 
                                                id="package_width" 
                                                value={record_data.package_width} 
                                                onValueChange={inputChange} 
                                                minFractionDigits={2}
                                                className='w-100'
                                                inputClassName="form-control"
                                                placeholder='Package Width'
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-2 form-group">
                                            <label className='mb-3'>Height{record_data.volume_unit ? ' ('+record_data.volume_unit+')' : ''}</label>
                                            <InputNumber 
                                                name="package_height" 
                                                id="package_height" 
                                                value={record_data.package_height} 
                                                onValueChange={inputChange} 
                                                minFractionDigits={2}
                                                className='w-100'
                                                inputClassName="form-control"
                                                placeholder='Package Height'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> : '' }

                            <div className='text-right mt-4'>                    
                                <button type='button' className='btn btn-secondary' onClick={(e) => submit(2)}>Save Draft</button>
                                <button type='button' className='btn btn-success ml-3' onClick={(e) => submit(1)}>Publish</button>
                                <Link className='btn btn-outline-dark ml-3' to={'/item/list'}>Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainContainer>
        </>
    )
}
 
export default ProductForm;