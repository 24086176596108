import React, { useEffect } from 'react';
import SaversContentHeader from './SaversContentHeader';
import SaversHeader from './SaversHeader';
import SaversFooter from './SaversFooter';
import helpers from '../../../../helpers/helpers';
import "primereact/resources/themes/lara-light-blue/theme.css";
 
export default function SaversMainContainer ({
        page_title='', 
        menu="", 
        breadcrumbs=true, 
        children, 
        parent_link='', 
        parent_text='',
        containerClass="container-fluid"
    }){
    
    useEffect(() => {
        //Mounted
    }, []);

    
    return (
        <>
        {
            helpers.isAuthenticated() ? <SaversHeader menu={menu} /> : ''
        }

        <div className="content-wrapper pb-3">
            <SaversContentHeader title={page_title} breadcrumbs={breadcrumbs}  parent_link={parent_link} parent_text={parent_text} containerClass={containerClass} />
            
            <section className="content">
                {children}
            </section>

        </div>

        <SaversFooter />
        </>
    )
}