// import moment from 'moment';
import images from './images';
import appConfig from '../config/env';
import { Link } from "react-router-dom";


// /**
//  * 
//  * @returns Autentication
//  */

const getToken = () => {
    if (localStorage.getItem('asinso-token')) {
        return localStorage.getItem('asinso-token')
    }

    return false
}

const isAuthenticated = () => {
    if (localStorage.getItem('asinso-token')) {
        return true
    }

    return false
}

const getUserName = () => {
    if (localStorage.getItem('asinso-name')) {
        return localStorage.getItem('asinso-name')
    }

    return false
}


const getStoreID = () => {
    if (localStorage.getItem('asinso-default-store')) {
        const store_data = JSON.parse(localStorage.getItem('asinso-default-store'))

        if(store_data.hasOwnProperty('id')){
            return store_data.id
        }
    }

    return false
}

const getDefaultStore = () => {
    if (localStorage.getItem('asinso-default-store')) {
        return JSON.parse(localStorage.getItem('asinso-default-store'))
    }

    return false
}

const getStoreSelection = () => {
    if (localStorage.getItem('asinso-store-selection')) {
        return JSON.parse(localStorage.getItem('asinso-store-selection'))
    }

    return false
}

const getUserID = () => {
    if (localStorage.getItem('asinso-uid')) {
        return localStorage.getItem('asinso-uid')
    }

    return false
}

const getEmployeeID = () => {
    if (localStorage.getItem('asinso-eid')) {
        return localStorage.getItem('asinso-eid')
    }

    return;
}

const getPermissions = () => {
    if (localStorage.getItem('asinso-permissions')) {
        return localStorage.getItem('asinso-permissions')
    }

    return []
}

const getUrl = (slug = '') => {
    const url = appConfig.apiUrl + `/${slug}`;

    return url
}

function displayStatusBadge(status = ''){
    let label = '';
    let class_name = ''

    switch (status) {
        case 1:
            class_name = "badge bg-success"
            break;
        case 2:
            class_name = "badge bg-secondary"
            break;
        default:
            return;
    }

    label = <span className={class_name}>{displayStatus(status)}</span>

    return label;
}

function displayStatus(status = ''){
    let label = '';

    switch (status) {
        case 1:
            label = 'Active'
            break;
        case 2:
            label = 'Draft'
            break;
        default:
            return;
    }

    return label;
}

function displayOrderStatusBadge(status_id = '', status_name = ''){
    let label = '';
    let class_name = ''

    switch (Number(status_id)) {
        case 1:
            class_name = "badge bg-info"
            break;
        case 2:
            class_name = "badge bg-primary"
            break;
        case 3:
            class_name = "badge bg-olive"
            break;
        case 4:
            class_name = "badge bg-danger"
            break;
        default:
            return;
    }

    if(class_name){
        label = <span className={class_name}>{status_name}</span>
    }

    return label;
}

function displayShippingStatus(status_id = '', status_name = ''){
    let label = '';
    let class_name = ''

    switch (Number(status_id)) {
        case 1:
            class_name = "text-primary"
            break;
        case 2:
            class_name = "text-info"
            break;
        case 3:
            class_name = "text-teal"
            break;
        case 4:
            class_name = "text-success"
            break;
        default:
            return;
    }

    if(class_name){
        label = <span className={class_name}>{status_name}</span>
    }

    return label;

}

function displayInvoiceStatusBadge(status_id = '', status_name = ''){
    let label = '';
    let class_name = ''

    switch (parseInt(status_id)) {
        case 1:
            class_name = "badge bg-gray"
            break;
        case 2:
            class_name = "badge bg-orange"
            break;
        case 3:
            class_name = "badge bg-primary"
            break;
        case 4:
            class_name = "badge bg-purple"
            break;
        case 5:
            class_name = "badge bg-info"
            break;
        case 6:
            class_name = "badge bg-teal"
            break;
        case 7:
            class_name = "badge bg-olive"
            break;
        case 8:
            class_name = "badge bg-maroon"
            break;
        default:
            return;
    }

    if(class_name){
        label = <span className={class_name}>{status_name}</span>
    }

    return label;
}

function displayBillStatusBadge(status_id = '', status_name = ''){
    let label = '';
    let class_name = ''

    switch (parseInt(status_id)) {
        case 1:
            class_name = "badge bg-gray"
            break;
        case 2:
            class_name = "badge bg-info"
            break;
        case 3:
            class_name = "badge bg-teal"
            break;
        case 4:
            class_name = "badge bg-olive"
            break;
        default:
            return;
    }

    if(class_name){
        label = <span className={class_name}>{status_name}</span>
    }

    return label;
}

function ucwords(str){
    if(!str) return;
    str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });

    return str;
}

function formatAmount(amount){
    if(!amount) return '0.00'

    let total = amount;
    var num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    total = num_parts.join(".");
    if(!isNaN(total)){
        total = Number(total).toFixed(2)
    }
    total = total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}

function formatCurrencyAmount(amount){
    if(!amount) return <>&#8369;0.00</>

    let total = amount;
    var num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    total = num_parts.join(".");
    if(!isNaN(total)){
        total = Number(total).toFixed(2)
    }
    total = total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return <>&#8369;{total}</>
}

function getFilteredObject(data, filtered_keys = []){
    let result = []
    for (var key in data) {
        const target_data = data[key]
        const filtered = Object.keys(target_data)
                        .filter(key => filtered_keys.includes(key))
                        .reduce((obj, key) => {
                        obj[key] = target_data[key];
                        return obj;
                        }, {});

        result.push(filtered)
    }

    return result
}

function checkPermissions(allowedPermissions) {
    // return allowedPermissions.every(value => {
    //   return user_permissions.includes(value);
    // });
    
    let user_permissions = getPermissions()
    if(!Array.isArray(user_permissions)){
        user_permissions = getPermissions()
        user_permissions = user_permissions.split(',')
    }

    var found = false;
    for (var i = 0; i < allowedPermissions.length; i++) {
        if(user_permissions.includes(allowedPermissions[i])){
            found = true;
            break;
        }
    }

    return found;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}



function getURLParams(param = ''){
    const queryString = window.location.search ?? '';
    let url = window.location.origin;
    let amount = ''
    let method = ''

    if(queryString){
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get(param)
    }

    return ''
}

function getCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    
    const formattedToday = yyyy + '-' + mm + '-' + dd;
 
    return formattedToday
 }

 function displayStockLevel(available_stock = '', reorder_point = ''){
     const stock = parseInt(available_stock ?? 0);
     const reorder_level = parseInt(reorder_point ?? 0);
     const default_stock_level = 5;

     let level = <div className="badge bg-olive">In stock</div>
     if(stock === 0){
         level = <div className="badge bg-red">Out Of Stock</div>
     }
     else if(stock <= reorder_level || stock <= default_stock_level){
         level = <div className="badge bg-orange">Low</div>
     }

     return level;
}


function filterObject(data = [], filter, filter_by = 'id') {
    if(data.length){
        return data.find(item => item[filter_by] == filter)
    }
    else{
        return
    }
 }

function removeString(str, rev = /-/g, replace = "") {
    var newStr = str.replace(/-/g, replace);

    return newStr
}

const helpers = {
    removeString,
    getToken,
    isAuthenticated,
    getUserName,
    getUserID,
    getPermissions,
    getUrl,
    getEmployeeID,
    displayStatusBadge,
    displayInvoiceStatusBadge,
    displayBillStatusBadge,
    ucwords,
    formatAmount,
    formatCurrencyAmount,
    getFilteredObject,
    checkPermissions,
    formatDate,
    getURLParams,
    getCurrentDate,
    displayOrderStatusBadge,
    displayShippingStatus,
    getDefaultStore,
    getStoreSelection,
    getStoreID,

    displayStockLevel,
    filterObject
}

export default helpers;