/**
 * Input Input Number Reusable Components
 */
import React from "react";

const InputNumber = ({
        id,
        name, 
        value, 
        label='', 
        tagClass='',
        labelClass='',
        placeholder = '', 
        onChange,
        onFocus,
        onBlur,
        min = "",//Call back value
        max = "",//Call back value
        readOnly = false,
        style={}
    }) => {

    function sanitizeInput(e){
        let { value, name } = e.target;

        let number = value;

        number = number.toString();

        number = number.replace(/[^0-9]+/g, "");

        onChange({
            target: {
                name:name,
                value: number
            }
        });
    }

    function handleBlur(e){
        let newState = {
            target: {
                name: name
            }
        }

        if(min && parseInt(value) < parseInt(min)){

            newState.target.value = min;

        }else if(max && parseInt(value) > parseInt(max)){

            newState.target.value = max;
            
        }else{
            return;
        }

        onChange(newState);
    }

    return (
        <>
            {label && <label htmlFor={id} className={labelClass}>{label}</label>}
            <input
                id={id}
                value={value|| ''}
                onKeyUp={onFocus}
                onChange={sanitizeInput}
                onBlur={handleBlur}
                className={tagClass}
                type="number"
                name={name}
                placeholder={placeholder}
                readOnly={readOnly}
                style={style}
            />
        </>
    );
};

export default InputNumber;
