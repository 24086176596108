import helpers from "./helpers"

const group_share = {
      'share_date': new Date(), 
      'member_shares': [],
      'total_share': 0,
      'group_id': ''
}

const store = {
   group_share,
}

export default store