import React from 'react';
import { Outlet, Link } from "react-router-dom";
import helpers from '../../helpers/helpers';
import images from '../../helpers/images';

export default function SideMenu({menu}){
    function setMenuOpen(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-open'
        }

        return classes;
    }


    function setCurrentMainMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'pointer nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'

            // if(parent_menu){
            //     classes += 'bg-navy text-light'
            // }
            // else{
            //     classes += 'bg-secondary'
            // }
        }

        return classes;
    }

    function setCurrentSubMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'
        }

        return classes;
    }

    function AccessPermission({ allowedPermissions=[], children }){
        const authorized = helpers.checkPermissions(allowedPermissions);

        if(allowedPermissions.length && !authorized){
            //return <Navigate replace to="/" />
            return ''
        }
    
        return children;
    }

    function setMenuOpen(menu_id = [], current_page = ''){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-is-opening menu-open'
        }
        return classes;
    }

    function getStoreName(){
        let store_data = helpers.getDefaultStore()

        if(store_data.hasOwnProperty('name')){
            return store_data.name;
        }
    }

    return (
        <>
        {/* Main Sidebar Container */}
        <aside className="main-sidebar elevation-4 sidebar-light-dark">
        {/* <aside className="main-sidebar sidebar-dark-light elevation-4"> */}
            {/* Brand Logo */}
            <Link className="brand-link d-flex align-items-center" to="/">
                <img src={images.topventory_icon} alt="Icon"  className="brand-image" />
                <img className='brand-text' src={images.topventory_text_only} alt="Logo" />
                <small className='ml-1'><sup>BETA</sup></small>
            </Link>

            {/* Sidebar */}
            
            <div className="sidebar">
                {/* Sidebar Menu */}
                <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column nav-collapse-hide-child nav-child-indent nav-compact" data-widget="treeviews" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link className={setCurrentMainMenu(['home'], menu, true)} to="/dashboard">
                            <i className="nav-icon fas fa-tachometer-alt mr-2" />
                            <p>Dashboard</p>
                        </Link>
                    </li>



                    {/* <AccessPermission 
                        isLoggedIn={helpers.isAuthenticated()} 
                        allowedPermissions={["all-access", "admin", "itemView", "itemCreate", "itemUpdate", "itemDelete", "itemAdjustView", "itemAdjustCreate", "itemAdjustUpdate", "itemAdjustDelete", "variantTypeView", "variantTypeCreate", "variantTypeUpdate", "variantTypeDelete", "brandView", "brandCreate", "brandUpdate", "brandDelete"]}
                    > */}
                        <li className={setMenuOpen(['manage-item', 'manage-item-form', 'item-brand', 'variant-types', 'manage-inventory-adjustment', 'item-category'], menu, true)}>
                            <a className={setCurrentMainMenu(['manage-item', 'manage-item-form', 'item-brand', 'variant-types', 'manage-inventory-adjustment', 'item-category'], menu, true)}>
                                <i className="nav-icon fas fa-tags mr-2" />
                                <p>Inventory <i className="right fas fa-angle-left" /></p>
                            </a>
                            <ul className="nav nav-treeview">
                                {/* <AccessPermission 
                                    isLoggedIn={helpers.isAuthenticated()} 
                                    allowedPermissions={["all-access", "admin", "itemView", "itemCreate", "itemUpdate", "itemDelete"]}
                                > */}
                                <li className="nav-item">
                                    <Link className={setCurrentSubMenu(['manage-item'], menu, true)} to="/item/list">
                                        <p className='ml-3'>Items</p>
                                    </Link>
                                </li>
                                {/* </AccessPermission> */}
                                
                                {/* <AccessPermission 
                                    isLoggedIn={helpers.isAuthenticated()} 
                                    allowedPermissions={["all-access", "admin", "itemAdjustView", "itemAdjustCreate", "itemAdjustUpdate", "itemAdjustDelete"]}
                                > */}
                                <li className="nav-item">
                                    <Link className={setCurrentSubMenu(['manage-inventory-adjustment'], menu, true)} to="/inventory-adjustment">
                                        <p className='ml-3'>Inventory Adjustment</p>
                                    </Link>
                                </li>
                                {/* </AccessPermission> */}
                                
                                {/* <AccessPermission 
                                    isLoggedIn={helpers.isAuthenticated()} 
                                    allowedPermissions={["all-access", "admin", "variantTypeView", "variantTypeCreate", "variantTypeUpdate", "variantTypeDelete"]}
                                > */}
                                {/* <li className="nav-item">
                                    <Link className={setCurrentSubMenu(['variant-types'], menu, true)} to="/settings/variant-types">
                                        <p className='ml-3'>Variant Type</p>
                                    </Link>
                                </li> */}
                                {/* </AccessPermission> */}
                                
                                {/* <AccessPermission 
                                    isLoggedIn={helpers.isAuthenticated()} 
                                    allowedPermissions={["all-access", "admin", "brandView", "brandCreate", "brandUpdate", "brandDelete"]}
                                > */}
                                {/* <li className="nav-item">
                                    <Link className={setCurrentMainMenu(['item-category'], menu, true)} to="/settings/categories">
                                        <p className='ml-3'>Categories</p>
                                    </Link>
                                </li> */}
                                {/* </AccessPermission> */}
                                
                                {/* <AccessPermission 
                                    isLoggedIn={helpers.isAuthenticated()} 
                                    allowedPermissions={["all-access", "admin", "brandView", "brandCreate", "brandUpdate", "brandDelete"]}
                                > */}
                                {/* <li className="nav-item">
                                    <Link className={setCurrentMainMenu(['item-brand'], menu, true)} to="/settings/brands">
                                        <p className='ml-3'>Brands</p>
                                    </Link>
                                </li> */}
                                {/* </AccessPermission> */}
                            </ul>
                        </li>
                    {/* </AccessPermission> */}


                    <li className={setMenuOpen(['manage-sales', 'manage-sales-form', 'manage-shipments', 'manage-contacts', 'couriers', 'manage-invoices', 'manage-payment-receives', 'manage-sales-return'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-sales', 'manage-sales-form', 'manage-shipments', 'manage-contacts', 'couriers', 'manage-invoices', 'manage-payment-receives', 'manage-sales-return'], menu, true)}>
                            <i className="nav-icon fas fa-shopping-cart mr-2"></i>
                            <p>Sales <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-sales'], menu, true)} to="/sales/list">
                                    <p className='ml-3'>Sales Order</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-invoices'], menu, true)} to="/invoices/list">
                                    <p className='ml-3'>Invoices</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-payment-receives'], menu, true)} to="/payment-receives/list">
                                    <p className='ml-3'>Payment Receives</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentMainMenu(['manage-contacts'], menu, true)} to="/contacts/list">
                                    <p className='ml-3'>Customers</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-shipments'], menu, true)} to="/shipments/list">
                                    {/* <i className="nav-icon fas fa-shipping-fast mr-2 ml-2" /> */}
                                    <p className='ml-3'>Shipments</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-sales-return'], menu, true)} to="/sales-returns/list">
                                    <p className='ml-3'>Sales Return</p>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className={setCurrentMainMenu(['couriers'], menu, true)} to="/settings/couriers">
                                    <p className='ml-3'>Couriers</p>
                                </Link>
                            </li> */}
                        </ul>
                    </li>

                    <li className={setMenuOpen(['manage-purchases', 'manage-purchases-form', 'manage-purchase-receives', 'manage-vendors', 'manage-bills', 'manage-payment-made'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-purchases', 'manage-purchases-form', 'manage-purchase-receives', 'manage-vendors', 'manage-bills', 'manage-payment-made'], menu, true)}>
                            <i className="nav-icon fas fa-shopping-bag mr-2" />
                            <p>Purchases <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-purchases'], menu, true)} to="/purchases/list">
                                    {/* <i className="nav-icon fas fa-list mr-2 ml-2" /> */}
                                    <p className='ml-3'>Purchase Orders</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-bills'], menu, true)} to="/bills/list">
                                    <p className='ml-3'>Bills</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-payment-made'], menu, true)} to="/payment-made/list">
                                    <p className='ml-3'>Payment Makes</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentMainMenu(['manage-vendors'], menu, true)} to="/vendors/list">
                                    <p className='ml-3'>Vendors</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-purchase-receives'], menu, true)} to="/purchase-receives/list">
                                    {/* <i className="nav-icon far fa-file mr-2 ml-2" /> */}
                                    <p className='ml-3'>Purchase Receives</p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={setMenuOpen(['reports'], menu, true)}>
                        <Link className={setCurrentMainMenu(['reports'], menu, true)} to="/reports">
                            <i className="nav-icon fas fa-chart-pie mr-2" />
                            <p>Reports</p>
                        </Link>
                    </li>

                    <li className={setMenuOpen(['settings'], menu, true)}>
                        <Link className={setCurrentMainMenu(['settings'], menu, true)} to="/settings">
                            <i className="nav-icon fas fa-cog mr-2" />
                            <p>Settings</p>
                        </Link>
                    </li>

                    {/* <li className={setMenuOpen(['manage-journals', 'manage-coa'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-journals', 'manage-coa'], menu, true)}>
                            <i className="nav-icon fas fa-calculator mr-2" />
                            <p>Accounting <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-journals'], menu, true)} to="/journals">
                                    <p className='ml-3'>Journals</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-coa'], menu, true)} to="/chart-of-accounts">
                                    <p className='ml-3'>Chart of Accounts</p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={setMenuOpen(['manage-saver-shares', 'manage-coa'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-saver-shares', 'manage-coa'], menu, true)}>
                            <i className="nav-icon fas fa-piggy-bank mr-2" />
                            <p>Savers <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-saver-shares'], menu, true)} to="/app/savers/1/shares">
                                    <p className='ml-3'>Shares</p>
                                </Link>
                            </li>
                        </ul>
                    </li> */}
                </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
        </>
    )
}
