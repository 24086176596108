/**
 * Select Input Reusable Components
 */
import React from "react";

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const ButtonGroupSelection = ({
        variant = "outline-secondary",
        size="",
        id, 
        name, 
        value, 
        label='',
        labelClass='',
        optionList, 
        optionProps={},
        tagClass='',
        onChange, 
        onFocus,
        readOnly = false,
        disabled = false,
        ucWords=false, // Upper Case Words
        exclude = [],
        containerClass=''
    }) => {

    /** Dynamically access object property **/
    const opt_value = optionProps.value; 
    const opt_label = optionProps.label; 

    function ucwords(str){
        str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
        });
    
        return str;
    }

    function displayButtons(){
        let buttons 

        buttons = optionList.map((option, key) => {

                if(value != option[opt_value] && exclude.includes(option[opt_value].toString()) ){
                    return
                }

                let is_active = 0;
                if(option[opt_value]==value){
                    is_active = 1
                    variant = 'primary'
                }
                else{
                    variant = 'outline-secondary'
                }

                const return_params = {
                    "target": {
                        "name": name,
                        "value": option[opt_value]
                    }
                }

                return (<Button variant={variant} active={is_active} onClick={(e)=>onChange(return_params)} disabled={disabled}>{option[opt_label]}</Button>)
        })
            

        return <ButtonGroup size={size} className={containerClass}>{buttons}</ButtonGroup>
    }

    return (
        <>
            {label && <div><label htmlFor={id} className={labelClass}>{label}</label></div>}
            {displayButtons()}
        </>
    );
};

export default ButtonGroupSelection;
