import helpers from "./helpers"

const store_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "name": "",
   "business_name": "",
   "slug": "",
   "address": "",
   "email": "",
   "phone_no": ""
}

const product_data = {
      'product_name': '', 
      'product_description': '', 
      'category_id': '', 
      'brand_id': 1, 
      'unit_id': '',
      'product_status': '', 
      'variants': [], 
      'package_weight': '',
      'package_length': '',
      'package_width': '',
      'package_height': '',
      "product_status": '',
      "weight_unit": '',
      "volume_unit": '',
		"store_id": helpers.getStoreID(),
		"product_type_id": 1,
		"tax_rate_id": "",
		"track_inventory": 1,
}

const variants_data = 
[
	{
		"id": 0,
		"product_id": 0,
		"category_id": 0,
		"brand_id": 1,
		"variant_value_ids": [1],
		"product_code": "",
		"product_cost": "",
		"product_price": "",
		"product_special_price": "",
		"barcode": "",
		"manufacturer_part_number": "",
		"serial_number": "",
      "product_variant_details": [],
		"product_inventory": [
			{
				"id": 0,
				"product_id": 0,
				"product_variant_id": 0,
				"warehouse_id": 0,
				"location_id": 0,
				"available_quantity": 0,
				"minimum_stock_level": 0,
				"maximum_stock_level": 0,
				"reorder_point": 0
         }
		]
	}
]


const product_type = [ 
   {"label": "Goods", "id": 1}, 
   {"label": "Services", "id": 2}, 
]

const product_status = [ 
   {"label": "Active", "id": 1}, 
   {"label": "Draft", "id": 2}, 
]


const weight_units = [ 
   {"label": "kg", "id": 'kg'}, 
   {"label": "lb", "id": 'lb'}, 
]
const volume_units = [ 
   {"label": "in", "id": 'in'}, 
   {"label": "cm", "id": 'cm'}, 
]

const customer_type = [ 
   {"label": "New Customer", "id": 'new'}, 
   {"label": "Existing Customer", "id": 'existing'}, 
]
const vendor_type = [ 
   {"label": "New Vendor", "id": 'new'}, 
   {"label": "Existing Vendor", "id": 'existing'}, 
]
const contact_type = [ 
   {"label": "Company", "id": 1}, 
   {"label": "Individual", "id": 2}, 
]
const contact_address_type = [ 
   {"label": "Shipping", "id": 1}, 
   {"label": "Billing", "id": 2}, 
]


const stock_level = [ 
   {"label": "In Stock", "id": 'available'}, 
   {"label": "Out of Stock", "id": 'none'}, 
   {"label": "Low Stock", "id": 'low'},  
   {"label": "Any", "id": ''}, 
]


const sales_order_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "sales_channel_id": 1,
   "order_no": "",
   "order_date": new Date(),
   "customer_id": "",
   "customer_name": "",
   "billing_address_id": "",
   "billing_address": "",
   "billing_phone": "",
   "billing_email": "",
   "billing_contact_name": "",
   "shipping_address_id": "",
   "shipping_address": "",
   "shipping_contact_name": "",
   "shipping_phone": "",
   "shipping_type_id": 2,
   "shipping_fee": 0,
   "shipping_status_id": 1,
   "payment_term_id": "",
   "payment_status_id": 1,
   "remarks": "",
   "order_status_id": 1,
   "shipping_status": "",
   "shipping_type": "",
   "payment_term": "",
   "payment_status": "",
   "order_status": "",
   "invoice": {},
   "customer_notes": "",
   "force_fulfillment": "",
   "store_info": {}
}

const purchases_order_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "order_no": "",
   "order_date": new Date(),
   "expected_date": '',
   "vendor_id": "",
   "vendor_name": "",
   "vendor_address_id": "",
   "vendor_address": "",
   "vendor_contact_name": "",
   "vendor_email": "",
   "vendor_phone": "",
   "courier_id": "",
   "courier": "",
   "shipping_address_id": "",
   "shipping_address": "",
   "shipping_contact_name": "",
   "shipping_phone": "",
   "shipping_type_id": 2,
   "shipping_fee": 0,
   "shipping_status_id": 1,
   "shipping_status": "",
   "shipping_type": "",
   "payment_term_id": 1,
   "payment_status_id": 1,
   "notes": "",
   "order_status_id": 1,
   "payment_term": "",
   "payment_status": "",
   "order_status": "",
   "bill": {},
   "vendor_notes": "",
   "force_receiving": "",
   "receiver_type_id": 1,
   "customer_id": "",
   "customer_name": "",
   "store_info": {}
}


const invoice_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "invoice_date": new Date(),
   "due_date": "",
   "order_id": "",
   "order_no": "",
   "customer_id": "",
   "customer_name": "",
   "customer_notes": "",
   "billing_address_id": "",
   "billing_address": "",
   "billing_phone": "",
   "shipping_contact_name": "",
   "shipping_address_id": "",
   "shipping_address": "",
   "shipping_phone": "",
   "shipping_fee": 0,
   "payment_term_id": "",
   "payment_term": "",
   "payment_status_id": 1,
   "sales_person_id": "",
   "sales_person": "",
   "sales_channel_id": "",
   "sales_channel": "",
   "notes": "",
   "customer_notes": "Thank you for your purchase!",
   "status_id": 1,
   "is_instant": 0,
   "order": {},
   "store_info": {}
}

const bill_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "bill_date": new Date(),
   "due_date": "",
   "order_id": "",
   "order_no": "",
   "vendor_id": "",
   "vendor_name": "",
   "billing_address_id": "",
   "billing_address": "",
   "billing_phone": "",
   "shipping_contact_name": "",
   "shipping_address_id": "",
   "shipping_address": "",
   "shipping_phone": "",
   "shipping_fee": 0,
   "payment_term_id": "",
   "payment_term": "",
   "payment_status_id": 1,
   "sales_person_id": "",
   "sales_person": "",
   "sales_channel_id": "",
   "sales_channel": "",
   "notes": "",
   "vendor_notes": "",
   "status_id": 1,
   "is_instant": 0,
   "order": {},
   "store_info": {}
}

const payment_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "payment_date": new Date(),
   "received_amount": null,
   "payment_method_id": "",
   "reference_no": "",
   "customer_id": "",
   "customer_name": "",
   "notes": "",
}

const payment_maid_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "payment_date": new Date(),
   "paid_amount": null,
   "payment_method_id": "",
   "reference_no": "",
   "vendor_id": "",
   "vendor_name": "",
   "notes": "",
}

const purchase_receive_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "po_id": "",
   "purchase_order": "",
   "receive_no": "",
   "received_date": new Date(),
   "vendor_id": "",
   "vendor_name": "",
   "warehouse_id": "",
}
const shipment_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "so_id": "",
   "sales_order": "",
   "shipment_no": "",
   "shipment_date": new Date(),
   "customer_id": "",
   "customer_name": "",
   "tracking_no": "",
   "courier_id": "",
   "courier": "",
   "customer_data": ""
}

const sales_return_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "so_id": "",
   "sales_order": "",
   "sales_return_no": "",
   "sales_return_date": new Date(),
   "customer_id": "",
   "customer_name": ""
}

const product_enums = {
   "brand_list": [],
   "category_list": [],
   "tax_rates": [],
   "variant_type_list": [],
   "uom_list": [],
   "warehouses": []
}

const order_enums = {
   "order_status": [],
   "payment_status": [],
   "payment_terms": [],
   "product_status": [],
   "shipping_status": [],
   "shipping_types": [],
}


const so_customer_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "contact_type_id": "",
   "customer_name": "",
   "gender": "",
   "email": "",
   "phone_no": "",
   "contact_person": "",
   "contact_phone_no": "",
   "address": []
}


const so_vendor_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "vendor_type_id": "",
   "vendor_name": "",
   "gender": "",
   "email": "",
   "phone_no": "",
   "contact_person": "",
   "contact_phone_no": "",
   "address": []
}

const contact_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "contact_type_id": "",
   "contact_name": "",
   "company_name": "",
   "email": "",
   "phone_no": "",
   "notes": "",
   "address": []
}

const vendor_data = {
   "id": "",
   "store_id": helpers.getStoreID(),
   "vendor_type_id": "",
   "vendor_name": "",
   "vendor_address": "",
   "contact_name": "",
   "email": "",
   "phone_no": "",
   "notes": "",
   "address": []
}


const adjustment_type = [ 
   {"label": "Stock", "id": 1}, 
   {"label": "Price", "id": 2}, 
   //{"label": "Cost", "id": 3}, 
]

const adjustment_reasons = [ 
   {"label": "Damage", "id": 1}, 
   {"label": "Expiry", "id": 2}, 
   {"label": "Obsolete", "id": 3}, 
   {"label": "Promotion", "id": 4}, 
   {"label": "Recording Error", "id": 5}, 
   {"label": "Returns", "id": 6}, 
   {"label": "Revaluation", "id": 7}, 
   {"label": "Stocktaking", "id": 8}, 
   {"label": "Theft", "id": 9}, 
   {"label": "Transfer", "id": 10}, 
]

const adjustment_data = {
   "type_id": 1, 
   "reason_id": "", 
   "description": "", 
   "reference_no": "", 
   "adjustment_date": new Date(), 
   "warehouse_id": '', 
   "warehouse_name": '', 
}

const profile_data = {
   first_name: "",
   last_name: "",
   birth_date: "",
   gender: "",
   mobile_no: "",
   address: "",
   address1: "",
   address2: "",
   address3: "",
   city: "",
   state: "",
   postal_code: "",
   country: ""
}

const user_data = {
   display_name: "",
   email: "",
   password: "",
   confirm_password: ""
}

const store_target = [
   "/store/profile",
   "/store/warehouses",
   "/store/delivery-address"
]

const per_page = 10;

const store = {
   store_data,
   store_target,
   variants_data,
   product_status,
   stock_level,
   product_data,
   product_type,
   weight_units,
   volume_units,
   sales_order_data,
   so_customer_data,
   product_enums,
   order_enums,
   customer_type,
   vendor_type,
   purchases_order_data,
   so_vendor_data,
   purchase_receive_data,
   shipment_data,
   sales_return_data,
   contact_data,
   contact_type,
   contact_address_type,
   vendor_data,
   adjustment_type,
   adjustment_data,
   adjustment_reasons,

   user_data,
   invoice_data,
   payment_data,
   bill_data,
   payment_maid_data
}

export default store