import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
 
export default function PaymentsListModal ({
        show_modal, 
        close_modal, 
        handleSelect, 
        id,
        src=""
    }){
    const [loading, setLoading] = useState(0);
    const [notice, setNotice] = useState('');
    const [data_list, setDataList] = useState([]);
    const [table_data, setTableData] = useState([]);
    const [label, setLabel] = useState('Payment Received');
   
    useEffect(() => {
        if(show_modal && id) {
            getData()
        }

        if(src === 'made'){
            setLabel('Payment Made')
        }
    }, [show_modal]);
   
    useEffect(() => {
        setTableData(
        <DataTable 
            className=''
            value={data_list} 
            size="normal"
            scrollHeight="500px"
            responsive={true}
            loading={loading}
            scrollable
            emptyMessage="No data to diplay"
        >
            <Column field="payment_date" header="DATE" ></Column>
            <Column field="payment_no" header="PAYMENT #" ></Column>
            <Column field="payment" header="PAYMENT" body={({payment})=> payment ? helpers.formatCurrencyAmount(payment) : '---' }></Column>
            <Column field="reference_no" header="REFERENCE #" ></Column>
        </DataTable>
        )
    }, [data_list]);

    function handleClose(e) {
        reset();
        close_modal(0);
    }

    function onSelect(e){
        reset();
        close_modal(0);
        handleSelect(e)
    }

    function getData(search = '') {
        setLoading(1)

        let filter = '?store_id='+helpers.getStoreID()+'&pagination=0&with_default=1&'

        if(search){
            filter += 'search='+search+'&'
        }

        filter = filter.slice(0, -1)

        let url = helpers.getUrl(`V1/manage/invoices/${id}/payments${filter}`)

        if(src === 'made'){
            url = helpers.getUrl(`V1/manage/bills/${id}/payments${filter}`)
        }

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setDataList(res)
                setLoading(0)
            })
            .catch((err) => {
                reset()
                setLoading(0)
                setNotice(<div className="alert alert-danger mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 
    }

    function reset(){
        setDataList([])
        setTableData([])
        setNotice('')
    }

    return (
        <>
            <Modal show={show_modal} size='lg'>
                <Modal.Header className='d-flex align-items-center'>
                    <h5 className='mb-0'>{label}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body className='px-0 pt-0'>
                    <div>
                    {table_data}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}