import React, { useEffect, useState } from 'react';
import { Link, useParams  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
import images from '../../../helpers/images';
import initTreeView from '../../../helpers/initTreeView';
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PaymentsListModal from '../../modals/PaymentsListModal';
import { Accordion, AccordionTab } from 'primereact/accordion';
import StoreInfo from '../../parts/StoreInfo';

export default function BillView (){
    const { bill_id } = useParams();
    const [loading, setLoading] = useState(1);
    const [page_title, setPageTitle] = useState('New Bill');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.bill_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);
    const [payments_modal, setPaymentsModal] = useState(false);
    const [order_table, setOrderTable] = useState([]);
    
   
    useEffect(() => {
        initTreeView()
        if(bill_id){
            setPageTitle('Bill Details')
            getData(bill_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items]);

    useEffect(() => {
        displayOrderItems()
    }, [record_data]);

    function getData(bill_id){
        if(!bill_id){
            return
        }
        const url = helpers.getUrl('V1/manage/bills/'+bill_id+'?store_id='+helpers.getStoreID())

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.bill_date = new Date(res.bill_date)
            items.due_date = new Date(res.due_date)

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/bills/list");
                }
            })
        }); 

    }
    function computeSubTotals(){
        let items = 0;
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
            items = items + Number(data.quantity)
            total_amount = total_amount + parseInt(data.quantity) * parseFloat(data.amount)
        });

        return helpers.formatCurrencyAmount(total_amount);
    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {
                return(
                <tr>
                    <td>
                        <div className='mb-0'>{data.product_name}{data.product_variant_name != 'Generic' ? ` (${data.product_variant_name})` : ''}</div>
                        {data.product_description ? <div className='mt-2' style={{whiteSpace: 'pre-line', maxHeight: '150px', overflow: 'auto'}}>{data.product_description}</div> : ''}
                    </td>
                    <td>{data.product_code}</td>
                    <td>{data.quantity}{data.unit_name ? ` ${data.unit_name}` : ''}</td>
                    <td>{helpers.formatCurrencyAmount(data.amount)}</td>
                    <td>{data.tax_amount ? helpers.formatCurrencyAmount(data.tax_amount) : '---'}</td>
                    <td>{helpers.formatCurrencyAmount(data.total_amount)}</td>
                </tr>)

            })
        }

        setTableItems(<tbody>{items}</tbody>)
    }

    function displayOrderItems(){
        if (Object.entries(record_data.order).length) {
            let items = []
            const order = record_data.order
            items = <tr>
                        <td>{order.order_date}</td>
                        <td><Link to={`/purchases/${order.id}`} target='_blank'>{order.order_no}</Link></td>
                        <td>{helpers.formatCurrencyAmount(order.total_amount)}</td>
                        <td>{helpers.displayShippingStatus(order.shipping_status_id, order.shipping_status)}</td>
                        <td>{order.order_status}</td>
                        <td width={20}>
                            <div  className='text-center'>
                            <Dropdown as={ButtonGroup} drop="down" className='mr-sm-2' size="sm" >
                                <Dropdown.Toggle variant="outline-dark" id="dropdown-split-basic" />
                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href={`/bills/${order.id}/payment`} target='_blank'>Add Payment</Dropdown.Item> */}
                                    <Dropdown.Item href={`/purchases/${order.id}/print`} target='_blank'><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={(e) => triggerAction(order.id, 'single-delete')} className='text-danger'>Delete</Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </td>
                    </tr>

            setOrderTable(<div className="container mb-3">
                <Accordion>
                    <AccordionTab header="Purchase Order" className="text-dark">
                        <div className='table-responsive-md'>
                        <table className="table table-sm mb-0">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order #</th>
                                <th>Amount</th>
                                <th>Receivable</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>{items}</tbody>
                        </table>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>)
        }
    }

    function triggerAction(action = '', bill_id = '', status = ''){
        if(!action) return;

        updateStatusItem(bill_id, status)
    }

    function updateStatusItem(bill_id, status_id){
        const url = helpers.getUrl('V1/manage/bills/status')
        httpRequest(url, 'POST', {
            postdata: {
                "bill_id": bill_id,
                "store_id": helpers.getStoreID(),
                "status_id": status_id
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK"
                })
            }); 
    }

    return (
        <>
        <PaymentsListModal show_modal={payments_modal} id={bill_id} close_modal={setPaymentsModal} src='made' />
        <MainContainer page_title={page_title} menu="manage-bills" breadcrumbs={true} parent_link='/bills/list' parent_text="Manage Bill">
                <div className="content" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="container-fluid">
                        <form>
                            <div className="row mb-2">
                                <div bill_id="so-vendor-section" className="col-lg-12">

                                    {record_loaded ?
                                    <div className='border rounded p-3 mb-3 d-flex justify-content-between align-items-center'>

                                        <h5 className='mb-0 mb-md-0 d-flex'>{record_data.bill_no}</h5>

                                        <div className='d-sm-flex flex-column flex-sm-row d-none'>
                                            <Dropdown as={ButtonGroup} drop="down" className='mr-sm-2' >
                                                <Dropdown.Toggle variant="outline-dark" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    {
                                                        parseInt(record_data.status_id) != 2 && (record_data.status_id) != 6 && parseInt(record_data.status_id) != 7 && parseInt(record_data.status_id) != 8 ?
                                                        <Dropdown.Item as={Link} to={`/bills/${bill_id}/edit`}><i className="fas fa-pencil-alt mr-2" style={{width: '20px'}} />Edit</Dropdown.Item>:''
                                                    }

                                                    <Dropdown.Item href={`/bills/${bill_id}/print`} target='_blank'><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            {parseInt(record_data.status_id) === 3 || parseInt(record_data.status_id) === 4 ? 
                                            <button type='button' className='btn bg-olive mb-2 mb-sm-0 mr-sm-2' onClick={(e) => setPaymentsModal(true)}><i className="fas fa-receipt mr-2" />Payment Maid</button> : ''}

                                            {parseInt(record_data.status_id) === 1 ? 
                                            <button type='button' className='btn bg-olive mb-2 mb-sm-0 mr-sm-2' onClick={(e) => triggerAction('sent', bill_id, 2)}><i className="fas fa-check-circle mr-2" />Set as Open</button> : ''}

                                            {parseInt(record_data.status_id) === 2 ? 
                                            <button type='button' className='btn btn-secondary mb-2 mb-sm-0 mr-sm-2' onClick={(e) => triggerAction('sent', bill_id, 1)}><i className="fas fa-file-alt mr-2" />Set as Draft</button> : ''}

                                            {parseInt(record_data.status_id) !== 7 ?
                                            <Link className='btn btn-primary mr-sm-2' to={`/bills/${bill_id}/payment`}><i className="fas fa-credit-card mr-2" />Add Payment</Link> : ''}

                                            <Link className='btn btn-dark' to="/bills/list">Cancel</Link>  
                                        </div>

                                        <div className='d-block d-sm-none'>
                                            <Dropdown as={ButtonGroup} drop="down-right" className='mr-sm-2'>
                                                <Dropdown.Toggle variant="dark" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    {parseInt(record_data.status_id) === 3 || parseInt(record_data.status_id) === 4 ? 
                                                    <Dropdown.Item onClick={(e) => setPaymentsModal(true)}><i className="fas fa-receipt mr-2" style={{width: '20px'}} />Payment Receive(s)</Dropdown.Item> : ''
                                                    }

                                                    {parseInt(record_data.status_id) === 1 ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction('sent', bill_id, 2)}><i className="fas fa-check-circle mr-2" style={{width: '20px'}} />Set as Open</Dropdown.Item> : ''}
        
                                                    {parseInt(record_data.status_id) === 2 ? 
                                                    <Dropdown.Item onClick={(e) => triggerAction('sent', bill_id, 1)}><i className="fas fa-file-alt mr-2" style={{width: '20px'}} />Set as Draft</Dropdown.Item> : ''}

                                                    {parseInt(record_data.status_id) !== 7 ?
                                                    <Dropdown.Item as={Link}  to={`/bills/${bill_id}/payment`}><i className="fas fa-credit-card mr-2" style={{width: '20px'}} />Add Payment</Dropdown.Item> : ''}
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item as={Link} to={`/bills/${bill_id}/edit`}><i className="fas fa-pencil-alt mr-2" style={{width: '20px'}} />Edit</Dropdown.Item>
                                                    <Dropdown.Item><i className="fas fa-print mr-2" style={{width: '20px'}} />Print View</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item as={Link} to="/bills/list"><i className="fas fa-times-circle mr-2" style={{width: '20px'}} />Cancel</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        order_table
                    }
                            
                    <div className="container">
                        {
                            record_loaded ?
                            <div className="invoice p-4 mb-3">
                                <div className="d-flex justify-content-between mb-3">
                                    <StoreInfo store={record_data.store_info} />
                                    
                                    <div className='text-right'>
                                        <h2><strong>BILL</strong></h2>
                                        <h5>{helpers.displayBillStatusBadge(record_data.status_id, record_data.bill_status)}</h5>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <h5 className='mb-0'><b>Bill To:</b></h5>
                                        <div>
                                            <p className='mb-0'><b>{record_data.vendor_name}</b></p>
                                            <p className='mb-0'>{record_data.billing_address}</p>
                                            <p className='mb-0'>{record_data.billing_contact_number}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <h5 className='mb-0'><b>Ship To:</b></h5>
                                        <div>
                                            <p className='mb-0'><b>{record_data.shipping_contact_name}</b></p>
                                            <p className='mb-0'>{record_data.shipping_address}</p>
                                            <p className='mb-0'>{record_data.shipping_phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 text-lg-right mb-2">
                                        <table className='table table-borderless table-sm'>
                                            <tbody>
                                                <tr>
                                                    <td className='pb-0'><b>Bill #:</b></td>
                                                    <td className='pb-0'>{record_data.bill_no ? record_data.bill_no : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Date:</b></td>
                                                    <td className='py-0'>{helpers.formatDate(record_data.bill_date)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Order #:</b></td>
                                                    <td className='py-0'>{record_data.order_no ? record_data.order_no : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Terms:</b></td>
                                                    <td className='py-0'>{record_data.payment_term ? record_data.payment_term : '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='py-0'><b>Due Date:</b></td>
                                                    <td className='py-0'>{record_data.due_date ? helpers.formatDate(record_data.due_date) : '---'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm border-bottom'>
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>SKU</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Tax</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        {
                                            table_items
                                        }
                                    </table>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-7 d-flex align-items-center">{record_data.vendor_notes ? <p className='pt-1 pt-lg-4'>{record_data.vendor_notes}</p>:''}</div>
                                    <div className="col-lg-5 mb-0 border p-2">
                                        <table className='table table-sm table-borderless mb-0'>
                                            <tbody>
                                                <tr>
                                                    <td className='border-top-0'><b>Sub Total</b></td>
                                                    <td className='border-top-0'>{computeSubTotals()}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Shipping Fee</b></td>
                                                    <td>{helpers.formatCurrencyAmount(record_data.shipping_fee)}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tax</b></td>
                                                    <td>{parseFloat(record_data.total_tax) > 0 ? helpers.formatCurrencyAmount(record_data.total_tax) : '---'}</td>
                                                </tr>
                                                <tr className='bg-dark'>
                                                    <td><b>Total</b></td>
                                                    <td>{helpers.formatCurrencyAmount(record_data.total_amount)}</td>
                                                </tr>
                                                <tr className='bg-light'>
                                                    <td><b>Balance Due:</b></td>
                                                    <td>{helpers.formatCurrencyAmount(record_data.balance_amount)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div className="invoice p-5 mb-3">
                                <h4>&nbsp;</h4>
                            </div>
                        }
                    </div>
                </div>
        </MainContainer>
        </>
    )
}