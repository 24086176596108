import React, { useEffect, useState } from 'react';
import { Outlet, Link, useParams } from "react-router-dom";
import helpers from '../../../../helpers/helpers';
import images from '../../../../helpers/images';

export default function SaversSideMenu({menu}){
    const { group_id } = useParams();
    function setMenuOpen(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-open'
        }

        return classes;
    }


    function setCurrentMainMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'pointer nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'

            // if(parent_menu){
            //     classes += 'bg-navy text-light'
            // }
            // else{
            //     classes += 'bg-secondary'
            // }
        }

        return classes;
    }

    function setCurrentSubMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'
        }

        return classes;
    }

    function AccessPermission({ allowedPermissions=[], children }){
        const authorized = helpers.checkPermissions(allowedPermissions);

        if(allowedPermissions.length && !authorized){
            //return <Navigate replace to="/" />
            return ''
        }
    
        return children;
    }

    function setMenuOpen(menu_id = [], current_page = ''){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-is-opening menu-open'
        }
        return classes;
    }

    function getStoreName(){
        let store_data = helpers.getDefaultStore()

        if(store_data.hasOwnProperty('name')){
            return store_data.name;
        }
    }

    return (
        <>
        {/* Main Sidebar Container */}
        <aside className="main-sidebar elevation-4 sidebar-light-dark">
        {/* <aside className="main-sidebar sidebar-dark-light elevation-4"> */}
            {/* Brand Logo */}
            <Link className="brand-link d-flex align-items-center" to="/">
                <img src={images.asinso_icon} alt="Icon"  className="brand-image" />
                <img className='brand-text' src={images.asinso_logoTextOnly} alt="Logo" />
                <small className='ml-1'><sup>BETA</sup></small>
            </Link>

            {/* Sidebar */}
            
            <div className="sidebar">
                {/* Sidebar Menu */}
                <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column nav-collapse-hide-child nav-child-indent nav-compact" data-widget="treeviews" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link className={setCurrentMainMenu(['home'], menu, true)} to="/dashboard">
                            <i className="nav-icon fas fa-tachometer-alt mr-2" />
                            <p>Dashboard</p>
                        </Link>
                    </li>

                    <li className={setMenuOpen(['manage-saver-shares', 'manage-coa'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-saver-shares', 'manage-coa'], menu, true)}>
                            <i className="nav-icon fas fa-piggy-bank mr-2" />
                            <p>Savings <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-saver-shares'], menu, true)} to={`/app/savers/${group_id}/shares`}>
                                    <p className='ml-3'>Shares</p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={setMenuOpen(['manage-group', 'manage-coa'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-group', 'manage-coa'], menu, true)}>
                            <i className="nav-icon fas fa-users mr-2" />
                            <p>Groups <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-group'], menu, true)} to={`/app/savers/${group_id}/info`}>
                                    {/* <i className="nav-icon fas fa-list mr-2 ml-2" /> */}
                                    <p className='ml-3'>Info</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-coa'], menu, true)} to="/app/savers/new">
                                    <p className='ml-3'>New Group</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-coa'], menu, true)} to="/app/savers/join-a-group">
                                    <p className='ml-3'>Join</p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={setMenuOpen(['manage-loans', 'manage-coa'], menu, true)}>
                        <a className={setCurrentMainMenu(['manage-loans', 'manage-coa'], menu, true)}>
                            <i className="nav-icon fas fa-hand-holding-usd mr-2" />
                            <p>Loans <i className="right fas fa-angle-left" /></p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-loans'], menu, true)} to="/app/savers/1/loans">
                                    {/* <i className="nav-icon fas fa-list mr-2 ml-2" /> */}
                                    <p className='ml-3'>List</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={setCurrentSubMenu(['manage-coa'], menu, true)} to="/chart-of-accounts">
                                    <p className='ml-3'>Chart of Accounts</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
        </>
    )
}
