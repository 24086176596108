import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Loader from '../../parts/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import initTreeView from '../../../helpers/initTreeView';
import ContactListModal from '../../modals/ContactListModal';
 
export default function ReportSalesItem ({filtered_by = ''}){
    const [data_loaded, setDataLoaded] = useState(0);
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [page_title, setPageTitle] = useState('Sales Order Report');
    const [customer_modal, setContactModal] = useState(0);
    const [enums, setEnums] = useState([]);
    const [filter_by, setFilterBy] = useState('');
    const [filters, setFilters] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        customer_name: '',
        customer: '',
        payment_status: '',
        payment_term: '',
        status: '',
        sales_channel: '',
        filter: '',
        dates: []
    });

    useEffect(() => {
        initTreeView()
        getEnums()
        getDataList({
            "filter_by": filtered_by
        })

        if(filtered_by) {
            if(filtered_by === 'top-items'){
                setPageTitle('Top Sold Items Report')
            }
            else if(filtered_by === 'pending-delivery'){
                setPageTitle('Pending Deliveries Report')
            }
        }
        setFilterBy(filtered_by)
    }, []);

    useEffect(() => {
        if(filters.customer || filters.payment_status || filters.payment_term || filters.status || filters.sales_channel || filters.filter || filters.dates){
            getDataList()
        }
    }, [
        filters.customer,
        filters.payment_status,
        filters.payment_term,
        filters.status,
        filters.sales_channel,
        filters.filter,
        filters.dates
    ]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [filters.sortOrder]);

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": '',
        "product_type": '',
        "filter_by": ''
    }){
        const page = condition.page ?? filters.page
        const per_page = condition.per_page ?? filters.rows
        const search = condition.search ?? search_key

        let params = `?store_id=${helpers.getStoreID()}&filter=${filtered_by}&`


        if(page){
            params += 'page='+page+'&'
        }
        if(per_page){
            params += 'per_page='+per_page+'&'
        }
        if(search){
            params += 'search='+search+'&'
        }

        if(filters.customer){
            params += 'customer='+filters.customer+'&'
        }
        if(filters.payment_status){
            params += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.payment_term){
            params += 'payment_term='+filters.payment_term+'&'
        }
        if(filters.status){
            params += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            params += 'sales_channel='+filters.sales_channel+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                params += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                params += 'order_to='+order_to+'&'
            }

        }

        params = params.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/reports/sales'+params)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setRecordData(res)
                setLoading(0)
                displayDataTable(res)
                setDataLoaded(1)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function getEnums(){
        setLoading(1)
        const url = helpers.getUrl('V1/enums/orders?store_id='+helpers.getStoreID())
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setEnums(res)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function exportReport(){
        const page = filters.page ?? ''
        const per_page = filters.rows ?? ''
        const search = search_key ?? ''
        const product_type = filters.product_type

        let filter = '?store_id='+helpers.getStoreID()+'&export=excel&'

        if(search){
            filter += 'search='+search+'&'
        }

        if(filters.customer){
            filter += 'customer='+filters.customer+'&'
        }
        if(filters.payment_status){
            filter += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.payment_term){
            filter += 'payment_term='+filters.payment_term+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            filter += 'sales_channel='+filters.sales_channel+'&'
        }
        if(filter_by){
            filter += 'filter='+filter_by+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                filter += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                filter += 'order_to='+order_to+'&'
            }

        }

        filter = filter.slice(0, -1)

        setLoading(1)
        const today = new Date();
        const month = today.getMonth() + 1;
        const date = today.getFullYear() + '-' + month + '-' + today.getDate() + '_' +  today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
        const file_name = 'sales-order-export-reports-' + date + '.xlsx';


        const url = helpers.getUrl('V1/reports/sales'+filter)
        
        fetch(url, {
            method: 'GET',
            headers: httpAuthHeaders(),
        })
        .then((res) => {
          if(res.ok) {
            return res.blob();
          }
          
          const json = res.json();
          const status_code = res.status;
  
          if (!res.ok) {
              let errorCode = json.error_code ? json.error_code : 'unknown';
              let message = json.message ? json.message : (res.statusText ? res.statusText : 'Request failed');
              let data = json.error ? json.error: '';
              let status = json.status ? json.status: '';
              let error = { data: data, message: message, error_code: errorCode, status_code: status_code, status: status };
  
              if (status_code === 422) error = { ...error, ...json };
  
              return Promise.reject(error);
          }
        })
        .then(blob => {
            
            Swal.fire("Export Completed", "Export successfully completed. Please check your downloads.", "success");

            var url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.setAttribute("href", url);
            a.download = file_name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.parentNode.removeChild(a);
            setLoading(0);
        })
        .catch(err => {
          Swal.fire("Error", "Export failed", "error");
          setLoading(0);
        });
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function inputDateChange(e){
        let { value, name } = e.target;
        let new_state = { 'dates': value }

        if(!value){
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            new_state = { 'dates': [firstDay, lastDay] }
        }
        setFilters({ ...filters, ...new_state });
    }

    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": filters.page + 1,
            "search": value
        })
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                dataKey={data.product_variant_id}
                value={data.data} 
                size="small"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={filters.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                scrollHeight="700px"
                scrollable
                stripedRows
                resizableColumns
                emptyMessage="No data to diplay"
            >
                {/* Per Item */}
                <Column 
                    header="Item ID" 
                    field="product_variant_id" 
                    style={{ width: '100px' }} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Name" 
                    field="product_name" 
                    body={({product_name, product_variant_name})=> `${product_name} (${product_variant_name})`}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Code"
                    field="product_code" 
                    body={({product_code})=> product_code ? product_code : '---'} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Order #" 
                    field="order_no" 
                    hidden={filtered_by !== 'pending-delivery'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Sold Quantity"
                    field="sold_quantity" 
                    hidden={filtered_by !== 'top-items'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="For Delivery"
                    field="remaining_quantity" 
                    hidden={filtered_by !== 'pending-delivery'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Fulfilled"
                    field="fulfilled_quantity" 
                    hidden={filtered_by !== 'pending-delivery'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Ordered Quantity"
                    field="quantity" 
                    hidden={filtered_by !== 'pending-delivery'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Unit"
                    field="unit_name" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Order&nbsp;Date" 
                    field="order_date" 
                    hidden={filtered_by !== 'pending-delivery'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="customer_name" 
                    header="Customer" 
                    hidden={filtered_by !== 'pending-delivery'} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Total Sales"
                    field="total_item_amount" 
                    body={({total_item_amount})=> total_item_amount ? getPrice(total_item_amount) : getPrice(0)} 
                    hidden={filtered_by !== 'top-items'}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Type" 
                    field="product_type_id" 
                    body={({product_type_id})=> product_type_id === 1 ? 'Goods' : 'Services'} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Category"
                    field="category_name" 
                    body={({category_name})=> category_name ? category_name : '[Deleted]'} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Brand"
                    field="brand_name" 
                    body={({brand_name})=> brand_name ? brand_name : '[Deleted]'} 
                    headerStyle={{ background: '#f9fafb' }}
                />
            </DataTable>
        )

    }

    const onPage = (e) => {
        setFilters(e)
        //setSelectedItems([])
    };

    function getPrice(data){
        return <>&#8369; {helpers.formatAmount(data)}</>
    }

    function handleCustomer(e){
        let new_state = { 
            'customer' : e.id,
            'customer_name': e.contact_name
        }
        setFilters({ ...filters, ...new_state });
    }

    return (
        <>
        <ContactListModal is_show={customer_modal} setShow={setContactModal} handleSelect={handleCustomer} />
        <MainContainer page_title={page_title} menu="reports" parent_link='/reports' parent_text="Reports">
            <div className="container-fluid">
                <div className='mb-4'>
                    <Link to="/reports" className="btn btn-outline-dark border"><i className="fas fa-arrow-left mr-2" /> Reports</Link>
                    <button type="button" className='btn bg-purple text-right float-right' onClick={exportReport} disabled={!data_loaded}><i className="fas fa-file-excel mr-2" />Export</button>
                </div>
            </div>

            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>

                <div className="card card-success card-outline">
                    <div className="card-header border-bottom-0">
                        <div className="row">
                            <div className="col-lg-10 mb-2">
                                <div className="row">
                                    
                                    {
                                        filtered_by!== 'dates' || filtered_by !== 'custom' ?
                                        <div className="col-md-4 col-lg-3 mb-2">
                                            <label>Order Dates</label><br/>
                                            <Calendar 
                                                value={filters.dates} 
                                                onChange={inputDateChange} 
                                                dateFormat="yy-mm-dd"
                                                selectionMode="range" 
                                                readOnlyInput 
                                                hideOnRangeSelection 
                                                className='w-100'
                                                showButtonBar
                                                placeholder='Order Date Range'
                                            />
                                        </div> 
                                        : ''
                                    }
                                    <div className="col-md-4 col-lg-2 mb-2">
                                        <label>Search</label>
                                        <input type="text" className="form-control" placeholder="Search" onChange={search} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 text-right mt-3 mt-md-0">
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
        </>
    )
}