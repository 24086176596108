import React from 'react';

function Footer(){
    return (
        <footer className="main-footer text-center text-md-right">
        &copy; {(new Date()).getFullYear()} <a href="https://topventory.com" className='text-muted'>Topventory Solutions.</a>
        </footer>
    )
}

export default Footer;
