import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
import InputText from '../../elements/InputText';
// import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import VendorModal from '../../modals/VendorModal';
import AddressBookModal from '../../modals/AddressBookModal';
import ProductFinderModal from '../../modals/ProductFinderModal';
import PaymentsListModal from '../../modals/PaymentsListModal';
import initTreeView from '../../../helpers/initTreeView';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { SplitButton } from 'primereact/splitbutton';
import { SelectButton } from 'primereact/selectbutton';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';
import CourierListModal from '../../modals/CourierListModal';
import ContactListModal from '../../modals/ContactListModal';
import { InputTextarea } from 'primereact/inputtextarea';

export default function PurchaseOrderForm (){
    const { order_id } = useParams();
    const [loading, setLoading] = useState(1);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Purchase Order');
    //const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.purchases_order_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);
    const [enums, setEnums] = useState(store.order_enums);

    const [vendor_modal, setVendorModal] = useState(0);
    const [product_modal, setProductModal] = useState(0);
    const [same_address, setSameAddress] = useState(0);
    const [payments_modal, setPaymentsModal] = useState(false);
    const [tax_breakdown, setTaxBreakdown] = useState([]);
    const [button_items, setButtonItems] = useState([]);
    const [couriers_modal, setCouriersModal] = useState(0);
    const [customer_modal, setContactModal] = useState(0);
    
    // Address Modal
    const [address_header_title, setAddressTitle] = useState('Search Address');
    const [address_src, setAddressSrc] = useState('');
    const [address_modal, setAddressModal] = useState(0);
    const [address_type, setAddressType] = useState('');
    const [address_reference_id, setAddressReferenceID] = useState(0); //For Modal

    useEffect(() => {
        initTreeView()

        if(order_id){
            setPageTitle('Edit Purchase Order')
            getData(order_id)
        }
        else{
            setButtonItems([{
                label: 'Save and Confirm',
                icon: 'pi pi-refresh',
                command: (e) => {
                    submit(e.originalEvent, 2)
                }
            }])
            getEnums()
        }
    }, []);

    useEffect(() => {
        displayTableItems()
        computeTotals();
        getTaxBeakdown()
    }, [record_items]);

    useEffect(() => {
        if(record_data.order_status_id == 1){
            setButtonItems([{
                label: 'Save and Confirm',
                icon: 'pi pi-refresh',
                command: (e) => {
                    submit(e.originalEvent, 2)
                }
            }])
        }
        else{
            setButtonItems([{
                label: 'Save as Open',
                icon: 'pi pi-refresh',
                command: (e) => {
                    submit(e.originalEvent, 1)
                }
            }])
        }
    }, [record_data, record_items]);

    useEffect(() => {
        displayTableItems()
    }, [enums]);

    function getData(order_id){
        if(!order_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/order/purchase/${order_id}?store_id=${helpers.getStoreID()}`)
        
        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.order_date = new Date(res.order_date)
            items.expected_date = new Date(res.expected_date)

            setLoading(0)
            setRecordData(items)
            setRecordItems(items.items)
            getEnums()
            //setRecordLoaded(1)

            let btn_item = []
            if(res.order_status_id === 1){
                btn_item.push({
                    label: 'Save and Confirm',
                    icon: 'pi pi-refresh',
                    command: (e) => {
                        submit(e.originalEvent, 2)
                    }
                })
            }
            else if(res.order_status_id === 2){
                btn_item.push({
                    label: 'Set as Open',
                    icon: 'pi pi-refresh',
                    command: (e) => {
                        submit(e.originalEvent, 1)
                    }
                })
            }

            setButtonItems(btn_item)
        })
        .catch((err) => {
            setLoading(0)
            getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    window.location.replace("/purchases/list");
                }
            })
        }); 

    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl(`V1/enums/orders?store_id=${helpers.getStoreID()}`)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function getTaxBeakdown(){
        let taxes = [];
        let total_tax = 0

        if (record_items.length) {
            Object.entries(record_items).map(([key, data]) => {
                if(!data.tax_rate && !data.tax_name) return;

                const tax = taxes.find(item => item.name == data.tax_name && parseFloat(data.tax_rate))

                if(!tax){
                    taxes.push({
                        "name": data.tax_name,
                        "display": `${data.tax_name} (${data.tax_rate}%)`,
                        "rate": parseFloat(data.tax_rate),
                        "total": parseFloat(data.tax_amount)
                    })

                    total_tax = total_tax + parseFloat(data.tax_amount)
                }
                else{
                    const tax_i = taxes.findIndex(item => item.name == data.tax_name && parseFloat(data.tax_rate))
                    let target = {...taxes[tax_i]}

                    let compute_tax = parseFloat(target.total) + parseFloat(data.tax_amount)
                    total_tax = total_tax + compute_tax

                    target['total'] = compute_tax
                    taxes[tax_i] = target
                }
            })
        }

        let breakdown = []
        if(taxes.length){
            let i = 1;
            breakdown = Object.entries(taxes).map(([key, data]) => {
                let td_class = 'border-0 pt-0'
                if(i == 1){
                    td_class = ''
                }
                i++;
                
                return (
                    <tr>
                        <td className={`text-right ${td_class}`}>{data.display}</td>
                        <td className={`${td_class}`}>{helpers.formatCurrencyAmount(data.total)}</td>
                    </tr>
                )
            })

            breakdown.push(
                        <tr>
                            <td className="border-0 pt-0 text-right"><b>Total Tax</b></td>
                            <td className='border-0 pt-0'><b className='border-top pt-2'>{helpers.formatCurrencyAmount(total_tax)}</b></td>
                        </tr>
            )
        }

        setTaxBreakdown(breakdown)
    }

    function computeSubTotals(){
        let items = 0;
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
            //const amount = data.amount ?? 0;
            const amount = data.amount ?? data.amount
            items = items + Number(data.quantity)
            total_amount = total_amount + parseInt(data.quantity) * parseFloat(amount)
        });

        return helpers.formatCurrencyAmount(total_amount);
    }

    function computeTotals(type){
                
        let items = 0;
        let total_amount = 0
        Object.entries(record_items).map(([key, data]) => {
           // const amount = data.amount ?? 0;
            const amount = data.amount ?? data.amount

            const tax_amount = data.tax_amount ?? 0;
            const quantity = data.quantity ?? 0
            items = items + Number(quantity)

            total_amount = total_amount + (parseInt(quantity) * parseFloat(amount) + parseFloat(tax_amount))
        });

        const shipping_fee = record_data.shipping_fee ?? 0;
        total_amount = total_amount + parseFloat(shipping_fee)

        if(type == 'quantity'){
            return items;
        }

        if(type == 'total'){
            return helpers.formatAmount(total_amount);
        }

    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {
                let total = parseInt(data.quantity) * parseFloat(data.amount)
                total = <>&#8369;&nbsp;{helpers.formatAmount(total)}</>
                return(
                <tr>
                    <td>
                        <span>{data.product_name}{data.product_variant_name != 'Generic' ? ` (${data.product_variant_name})` : '' }</span><br/>
                        {/* <small>{data.product_type_id == 1 ? 'Goods' : 'Service'}</small> */}
                        <small>SKU: {data.product_code}</small><br/>
                        <InputTextarea
                            name="product_description" 
                            value={data.product_description}  
                            className="w-100 mt-2 p-1"
                            maxLength={1500}
                            onChange={(e) => inputItemChange(e, key)}
                            placeholder="Description"
                            readOnly={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                        />
                    </td>
                    {/* <td>{data.product_code}</td> */}
                    <td width={250}>
                        {
                            record_data.order_status_id == 1 || record_data.order_status_id == 2
                            ?
                            <InputNumber
                                id="quantity" 
                                name="quantity" 
                                value={data.quantity}
                                tagClass="mb-0"
                                onValueChange={(e) => inputItemChange(e, key)}
                                placeholder="Qty"
                                min={data.received_quantity}
                                showButtons
                                buttonLayout="horizontal"
                                inputClassName="form-control rounded-0"
                                decrementButtonClassName="p-input-button-left" 
                                incrementButtonClassName="p-input-button-right" 
                                incrementButtonIcon="fas fa-plus" 
                                decrementButtonIcon="fas fa-minus"
                                readOnly={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                            />
                            :
                            <>{data.quantity}&nbsp;</>
                        }
                        {data.unit_name}
                    </td>
                    <td width={180}>
                        {
                            record_data.order_status_id == 1 || record_data.order_status_id == 2
                            ?
                            <InputNumber
                                id="amount" 
                                name="amount" 
                                value={data.amount}
                                inputClassName="w-100"
                                onValueChange={(e) => inputItemChange(e, key)}
                                placeholder="Price"
                                minFractionDigits={2}
                                readOnly={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                className='w-100'
                            />
                            :
                            helpers.formatCurrencyAmount(data.amount)
                        }
                    </td>
                    <td width={250}>
                        {
                            record_data.order_status_id == 1 || record_data.order_status_id == 2
                            ?
                            <Dropdown  
                                name='tax_rate'
                                value={parseInt(data.tax_rate_id)} 
                                onChange={(e) => inputItemChange(e, key)}
                                options={enums.tax_rates} 
                                optionLabel="label" 
                                optionValue="id" 
                                placeholder="Select Tax Rate" 
                                checkmark={true} 
                                highlightOnSelect={false}
                                showClear
                                filter 
                                disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                            />: 
                            <div>{data.tax_amount ? <>{helpers.formatCurrencyAmount(data.tax_amount)}<br/>[{data.tax_name} {data.tax_rate}%]</>  : ''}</div>
                        }

                        {
                            record_data.order_status_id == 1 || record_data.order_status_id == 2
                            ?
                            <div className='mt-2'>{data.tax_amount ? helpers.formatCurrencyAmount(data.tax_amount) : ''}</div> : ''
                        }
                    </td>
                    <td width={180} style={{paddingRight: '.75rem'}}>
                        <div className='d-flex align-items-center'>
                        {total}&nbsp;
                        {
                            parseInt(record_data.order_status_id) === 1 ? 
                            <button className='btn btn-sm text-muted float-right' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button>: ''
                        }
                        {
                            parseInt(record_data.order_status_id) === 2 && data.id == 0 ? 
                            <button className='btn btn-sm text-muted float-right' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button>: ''
                        }
                        </div>
                    </td>
                </tr>)

            })
        }

        setTableItems(items)
    }

    function removeItem(key){
        let items = [...record_items]
        items.splice(key, 1); 

        setRecordItems(items)
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if(name == 'same_address'){
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
            let data = {...record_data}
            data.shipping_address_id = ''
            data.shipping_address = ''
            data.shipping_contact_name = ''
            //data.shipping_phone = ''

            if(value){
                data.shipping_address_id = data.billing_address_id
                data.shipping_address = data.vendor_address
                data.shipping_contact_name = data.vendor_name
                //data.shipping_phone = ''
            }
                
            setRecordData(data)

            setSameAddress(value)
            return;
        }

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        if(same_address && name == 'vendor_name'){
            new_state.shipping_contact_name = value
        }

        if(name == 'receiver_type_id'){
            new_state.customer_id = ''
            new_state.customer_name = ''
            new_state.shipping_address_id = ''
            new_state.shipping_address = ''
            new_state.shipping_contact_name = ''
            new_state.shipping_phone = ''
        }

        setRecordData({ ...record_data, ...new_state });
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...record_items]
        let target = {...items[key]}

        if(name === 'quantity'){
            const available_stock = target['available_stock']

            if(target.tax_rate){
                
                const tax_amount = (parseFloat(target.tax_rate) / 100) * (parseInt(value) * parseFloat(target.amount))

                target.tax_amount = tax_amount
            }

            // if(parseInt(value) > parseInt(available_stock)){
            //     return;
            // }
        }
        else if(name === 'tax_rate'){
            name = 'tax_rate_id'
            const tax = helpers.filterObject(enums.tax_rates, value) ?? [];
            
            if ( tax.hasOwnProperty('id') ) {
                target.tax_name = tax.tax_name
                target.tax_rate = tax.tax_rate

                const tax_amount = (parseFloat(tax.tax_rate) / 100) * (parseFloat(target.amount) * parseFloat(target.quantity))

                if(!isNaN(tax_amount)){
                    target.tax_amount = tax_amount;
                }
            }
            else{
                target.tax_name = ''
                target.tax_rate = ''
                target.tax_amount = 0;
            }
        }
        else if(name === 'amount'){
            if(target.tax_rate){
                //const tax_amount = (parseFloat(target.tax_rate) / 100) * parseFloat(value)
                const tax_amount = (parseFloat(target.tax_rate) / 100) * (parseFloat(value) * parseFloat(target.quantity))

                target.tax_amount = tax_amount
            }
        }

        target[name] = value
        items[key] = target

        setRecordItems(items)
    }

    function handleVendor(e){
        let data = {...record_data}

        data.vendor_id = e.id ?? ''
        data.vendor_name = e.vendor_name ?? ''
        // data.vendor_contact_name = e.contact_name ?? ''
        // data.vendor_address = e.vendor_address ?? ''
        // data.vendor_phone = e.phone_no ?? ''

        data.vendor_contact_name = ''
        data.vendor_address = ''
        data.vendor_phone = ''

        //setCustomer(e)
        setRecordData(data)
    }

    function handleAddress(e){
        let data = {...record_data}


        if(address_src === 'vendor'){
            data.vendor_address_id = e.id ?? ''
            data.vendor_address = e.address
            data.vendor_contact_name = e.contact_name
            data.vendor_phone = e.phone_no
        }
        else{
            data.shipping_address_id = e.id
            data.shipping_address = e.address
            data.shipping_contact_name = e.contact_name
            data.shipping_phone = e.phone_no
        }
        
        setRecordData(data)
    }

    function viewAddressBook(type = ''){
        setAddressModal(1)

        if(type === 'vendor-billing'){
            setAddressTitle('Search Vendor Address')
            setAddressSrc('vendor')
            setAddressReferenceID(record_data.vendor_id)
            setAddressType(2) //billing
            return;
        }

        if(parseInt(record_data.receiver_type_id) === 2){ // Customer Delivery
            setAddressTitle('Search Customer Address')
            setAddressSrc('customer')
            setAddressReferenceID(record_data.customer_id)
            setAddressType(1) //shipping
            return
        }

        setAddressTitle('Search Delivery Address')
        setAddressSrc('delivery')
        setAddressReferenceID('')
        setAddressType(1) //shipping
    }

    function handleCustomer(e){
        let data = {...record_data}

        data.customer_id = e.id ?? ''
        data.customer_name = e.contact_name ?? ''
        data.shipping_address_id = ''
        data.shipping_address = ''
        data.shipping_contact_name = ''
        data.shipping_phone = ''

        setRecordData(data)
    }

    function handleProduct(e){
        let items = [...record_items]

        let new_item = {
            "id": 0,
            "product_id": e.id,
            "product_variant_id": e.product_variant_id,
            "product_type_id": e.product_type_id,
            "quantity": 1,
            "amount": e.product_cost,
            "product_code": e.product_code,
            "product_name": e.product_name,
            "product_description": "",
            "product_variant_name": e.product_variant_name,
            "unit_name": e.unit_name,
            "available_stock": e.available_stock,
            "shipping_status_id": 1,
            "tax_amount": 0,
            "tax_name": "",
            "tax_rate": "",
            "tax_rate_id": e.tax_rate_id,
            "received_quantity": 0,
        }

        /**
         * Compute Tax
         */
        const tax = helpers.filterObject(enums.tax_rates, e.tax_rate_id) ?? [];
        if ( tax.hasOwnProperty('id') ) {
            new_item.tax_name = tax.tax_name
            new_item.tax_rate = tax.tax_rate

            const tax_amount = (parseFloat(tax.tax_rate) / 100) * (parseFloat(new_item.amount) * parseFloat(new_item.quantity))

            if(!isNaN(tax_amount)){
                new_item.tax_amount = tax_amount;
            }
        }
        else{
            new_item.tax_name = ''
            new_item.tax_rate = ''
            new_item.tax_amount = 0
            new_item.tax_rate_id = 0
        }

        items.push(new_item)
        setRecordItems(items)
    }

    function submit(e, status = ''){
        e.preventDefault()
        //setLoading(1)

        let url = helpers.getUrl(`V1/manage/order/purchase/create`)
        if(order_id){
            url = helpers.getUrl(`V1/manage/order/purchase/${order_id}`)
        }

        let form_data = {...record_data}
        form_data['items'] = record_items;

        if(status){
            form_data['order_status_id'] = status
        }

        if(parseInt(record_data.receiver_type_id) === 1){
            form_data['customer_id'] = ''
            form_data['customer_name'] = ''
        }

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = order_id;
                if(!id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {;
                        window.location.replace(`/purchases/${id}`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function handleCourier(e){
        let data = {...record_data}

        data.courier_id = e.id
        data.courier = e.courier_name

        setRecordData(data)
    }

    return (
        <>
        <PaymentsListModal show_modal={payments_modal} id={order_id} close_modal={setPaymentsModal} handleSelect={handleVendor} />

        <VendorModal 
            is_show={vendor_modal} 
            setShow={setVendorModal} 
            handleSelect={handleVendor} 
        />

        <ContactListModal 
            is_show={customer_modal} 
            setShow={setContactModal}
            handleSelect={handleCustomer} 
        />

        <CourierListModal
            show_modal={couriers_modal} 
            close_modal={setCouriersModal}  
            handleSelect={handleCourier}
        />

        <AddressBookModal 
            reference_id={address_reference_id}
            is_show={address_modal} 
            setShow={setAddressModal}  
            handleSelect={handleAddress} 
            address_type={address_type} 
            header_title={address_header_title}
            store_id = {helpers.getStoreID()}
            src={address_src}
        /> 

        <ProductFinderModal 
            customer_id={record_data.customer_id} 
            is_show={product_modal} 
            setShow={setProductModal}  
            handleSelect={handleProduct} 
            exclude={record_items} 
            restrict_stock={false}
            show_price = {false}
            show_cost = {true}
        />

        <MainContainer page_title={page_title} menu="manage-purchases" breadcrumbs={true} parent_link='/purchases/list' parent_text="Manage Purchase Order">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <form>
                            <div className="row mb-2">
                                <div order_id="so-customer-section" className="col-lg-12">

                                    {record_data.order_no ?
                                    <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                        <h5 className='mb-3 mb-md-0 d-flex'>Purchase Order #: {record_data.order_no}&nbsp;&nbsp;&nbsp;{helpers.displayInvoiceStatusBadge(record_data.order_status_id, record_data.invoice_status)}</h5>
                                        <div className='d-flex flex-column flex-sm-row'>
                                        </div>

                                        <div className='text-right'>     
                                            {
                                                parseInt(record_data.order_status_id) === 1 || parseInt(record_data.order_status_id) == 2 ?
                                                <SplitButton 
                                                    label="Save" 
                                                    buttonClassName="rounded-left border-right" 
                                                    onClick={(e) => submit(e, 2)} 
                                                    model={button_items} 
                                                    severity={"success"} 
                                                /> : ''
                                            }

                                                <Link className='btn btn-outline-dark ml-3' to={`/purchases/list`} style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                                        </div>
                                    </div> : ''}

                                    <div className="card">

                                        <div className="card-body pt-md-5">

                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Vendor <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-8 col-lg-3 form-group">
                                                    <div className="input-group">
                                                        {
                                                            order_id ? <input type="text" className="form-control" placeholder="Select Vendor" disabled={order_id}  value={record_data.vendor_name}  /> : <input type="text" className="form-control" placeholder="Select Vendor" onClick={(e) => setVendorModal(1)} value={record_data.vendor_name} />
                                                        }
                                                        

                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={(e) => setVendorModal(1)} disabled={order_id}><i className="fas fa-search" /></button>
                                                        </div>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="vendor_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-3 col-lg-2"></div>
                                                <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
                                                    <div className='border p-2'>
                                                        <div>
                                                            {/* <p className='mb-0'><b>{record_data.vendor_name ? record_data.vendor_name : ''}</b></p> */}
                                                            <p className='mb-0'>{record_data.vendor_address ? record_data.vendor_address : '---'}</p>
                                                            <p className='mb-0'>{record_data.vendor_phone ? record_data.vendor_phone : ''}</p>
                                                        </div>
                                                        { parseInt(record_data.order_status_id) === 1 || parseInt(record_data.order_status_id) === 2 ? <button type="button" className="btn btn-primary btn-sm mt-2" onClick={(e) => viewAddressBook('vendor-billing')}>Vendor Address</button> : ''}
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="vendor_address" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            
                                                <div className="col-md-4 col-lg-3">
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12"><hr/></div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right" />
                                                <div className="col-md-5 col-lg-3 form-group">

                                                    <ButtonGroupSelection
                                                        tagClass="form-control"
                                                        id="receiver_type_id"
                                                        name='receiver_type_id' 
                                                        value={record_data.receiver_type_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={[
                                                            {"id":1,"type_name": "Store"},
                                                            {"id":2,"type_name": "Customer"}
                                                        ]}
                                                        optionProps ={{value:'id', label:'type_name'}}
                                                        disabled = {parseInt(record_data.order_status_id) === 2 || parseInt(record_data.order_status_id) === 3 || parseInt(record_data.order_status_id) === 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="receiver_type_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                    <div className='text-muted'><small>*The customer option pertains to drop shipping services.</small></div>
                                                </div>
                                            </div>
                                            
                                            {
                                            parseInt(record_data.receiver_type_id) === 2 ?
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right" />
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <div className="input-group">
                                                        {
                                                            parseInt(record_data.order_status_id) === 2 || parseInt(record_data.order_status_id) === 3 || parseInt(record_data.order_status_id) === 4 ? 
                                                            <input type="text" className="form-control" placeholder="Select Customer" disabled={order_id}  value={record_data.customer_name}  /> : 
                                                            <input type="text" className="form-control" placeholder="Select Customer" onClick={(e) => setContactModal(1)} value={record_data.customer_name} />
                                                        }
                                                        

                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={(e) => setContactModal(1)} disabled={order_id}><i className="fas fa-search" /></button>
                                                        </div>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="customer_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div> : ''
                                            }

                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Delivery Address <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <div className='border p-2'>
                                                        <div>
                                                            <p className='mb-0'>{record_data.shipping_contact_name ? <b>{record_data.shipping_contact_name}</b>:''}</p>
                                                            <p className='mb-0'>{record_data.shipping_address ? record_data.shipping_address : '---'}</p>
                                                            <p className='mb-0'>{record_data.shipping_phone ? record_data.shipping_phone : ''}</p>
                                                        </div>
                                                        { parseInt(record_data.order_status_id) === 1 || parseInt(record_data.order_status_id) === 2 ? <button type="button" className="btn btn-primary btn-sm mt-2" onClick={(e) => viewAddressBook('delivery')}>Delivery Address</button>: ''}
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="shipping_address" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Order Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Calendar 
                                                        name="order_date"  
                                                        value={record_data.order_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="order_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Expected Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Calendar 
                                                        name="expected_date"  
                                                        value={record_data.expected_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        minDate={new Date(record_data.order_date)}
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="expected_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Payment Terms <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Dropdown  
                                                        name='payment_term_id'
                                                        value={parseInt(record_data.payment_term_id)} 
                                                        onChange={inputChange} 
                                                        options={enums.payment_terms} 
                                                        optionLabel="payment_term" 
                                                        optionValue="id" 
                                                        placeholder="Select Payment Terms" 
                                                        className="w-100" 
                                                        checkmark={true} 
                                                        highlightOnSelect={false}
                                                        showClear
                                                        filter 
                                                        disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="payment_term_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Ship via</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">

                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Select Courier" value={record_data.courier} readOnly={1}  onClick={(e)=>setCouriersModal(1)} style={{backgroundColor: '#fff'}} disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}/>
                                                        
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={(e) => setCouriersModal(1)} title="Add Courier" disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}>
                                                                <i className="fas fa-search" title="Add Courier" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="courier" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right mb-2">
                                                    <label className='mb-0'>Notes</label><br/>
                                                    <small>(Internal only)</small>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextarea
                                                        order_id="notes" 
                                                        name="notes" 
                                                        value={record_data.notes}  
                                                        className='w-100'
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder=""
                                                        rows={3}
                                                        disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right mb-2">
                                                    <label className='mb-0'>Vendor Notes</label><br/>
                                                    <small>(Visible to vendor)</small>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextarea
                                                        order_id="vendor_notes" 
                                                        name="vendor_notes" 
                                                        value={record_data.vendor_notes}  
                                                        className='w-100'  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder=""
                                                        rows={3}
                                                        disabled={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="vendor_notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            { record_data.order_status_id == 4 ?
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Cancellation Reason</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextarea
                                                        value={record_data.reason}  
                                                        className='w-100'  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder="Optional"
                                                        rows={2}
                                                        disabled={true}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div order_id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th>ITEM</th>
                                                        <th>QUANTITY</th>
                                                        <th>ITEM&nbsp;PRICE</th>
                                                        <th>TAX</th>
                                                        <th>AMOUNT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    {parseInt(record_data.order_status_id) === 1 || parseInt(record_data.order_status_id) === 2 ?
                                                    <tr className='bg-light'>
                                                        <td colSpan={6} className='text-center'>
                                                            <RenderErrors 
                                                                errors={errors} 
                                                                name="items" 
                                                                className="text-danger mb-2"
                                                                style={{fontSize: '.9rem'}}
                                                            />
                                                            <button type="button" className="btn btn-outline-primary" onClick={(e) => setProductModal(1)}>Add Items</button>
                                                        </td>
                                                    </tr> : ''}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6"></div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <table className="table">
                                                
                                                <tbody className='border-top-0'>
                                                    <tr>
                                                        <td className='text-right border-right border-0'>Sub Total</td>
                                                        <td className='border-0'>{computeSubTotals()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right border-0 pt-0'>Quantity</td>
                                                        <td className='border-0 pt-0'>{computeTotals('quantity')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>Shipping Fee</td>
                                                        <td>
                                                            {
                                                                record_data.order_status_id == 3 && record_data.order_status_id == 4  ?
                                                                helpers.formatCurrencyAmount(record_data.shipping_fee) :
                                                                <InputNumber
                                                                    name="shipping_fee" 
                                                                    value={record_data.shipping_fee}
                                                                    inputClassName="form-control"
                                                                    onValueChange={inputChange}
                                                                    placeholder="PHP"
                                                                    minFractionDigits={2}
                                                                    readOnly={record_data.order_status_id == 3 || record_data.order_status_id == 4}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        tax_breakdown
                                                    }
                                                    <tr>
                                                        <td className='text-right border-right-0'><h5 className='font-weight-bold'>Total</h5></td>
                                                        <td><h5 className='font-weight-bold'>&#8369;{computeTotals('total')}</h5></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>     
                                {
                                    parseInt(record_data.order_status_id) === 1 || parseInt(record_data.order_status_id) == 2 ?
                                    <SplitButton 
                                        label="Save" 
                                        buttonClassName="rounded-left border-right" 
                                        onClick={(e) => submit(e, record_data.order_status_id)} 
                                        model={button_items} 
                                        severity={"success"} 
                                    /> : ''
                                }
                                
                                <Link className='btn btn-outline-dark ml-3' to={`/purchases/list`} style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}