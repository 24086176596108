import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
//import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import InputTextArea from '../elements/InputTextArea';
import ButtonGroupSelection from '../elements/ButtonGroupSelection';
import store from '../../helpers/store';
import helpers from '../../helpers/helpers';
import Swal from 'sweetalert2';

import { InputText } from 'primereact/inputtext';

import { InputNumber } from 'primereact/inputnumber';
 
export default function TaxRateFormModal ({
        show_modal = false, 
        id = '', 
        close_modal={}, 
        handleSave={}, 
    }){
        
    let default_data = {
        "id": "",
        "store_id": helpers.getStoreID(),
        "tax_name": "",
        "tax_rate": ""
    }
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(default_data);
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        setErrors([])

        if(show_modal && id){
            getData(id)
        }
        else if(show_modal && !id){
            setRecordData(default_data)
        }
    }, [show_modal, id]);


    function getData(id){
        if(!id){
            return
        }
        const url = helpers.getUrl(`V1/manage/tax-rates/${id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    
                }
            })
        }); 
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.tax_name){
            new_errors['tax_name'] = ['The tax name field is required.']
        }
        else{
            delete new_errors['tax_name'];
        }

        if(!record_data.tax_rate){
            new_errors['tax_rate'] = ['The tax rate field is required.']
        }
        else{
            delete new_errors['tax_rate'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function handleSubmit(e) {
        const validate = validateForm();
        if(Object.keys(validate).length) return;
        
        setLoading(1)

        let url = helpers.getUrl('V1/manage/tax-rates/create')
        if(id && record_loaded){
            url = helpers.getUrl(`V1/manage/tax-rates/${id}`)
        }

        let form_data = {...record_data}

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                form_data.id = res.id
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        close_modal(0);
                        handleSave(form_data);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
        
        //close_modal(0);
        // handleSave(form_data, index)
    }
    
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }
        else if(name === 'tax_rate'){
            if(value){
                value = Number(value).toFixed(2)
            }
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function inputChangeEnter(e) {
        if (e.key === "Enter"){
            setErrors([])
            if(!record_data.tax_name){
                setErrors(
                    {"tax_name":["The tax name name field is required."]}
                )
                return;
            }

            handleSubmit()
        }
    }

    function handleClose(e) {
        e.preventDefault();
        reset();
        close_modal(0);
    }

    function reset(){
        setRecordData(default_data)
    }

    return (
        <>
            <Modal show={show_modal}>
                <Modal.Header className='pt-3 pb-3'>
                    <h5 className='mb-0'>Tax Rate</h5>
                    <a href="#" className='btn btn-danger btn-sm'onClick={handleClose}><i className="fas fa-times"></i></a>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="row mb-4">
                        <div className="col-md-12">
                            <InputText
                                id="tax_name" 
                                name="tax_name" 
                                value={record_data.tax_name}
                                tagClass="form-control mb-0"
                                placeholder="Tax Name"
                                onChange={inputChange}
                                autoFocus={true}
                                onKeyDown={inputChangeEnter}
                                className='w-100'
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="tax_name" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">

                            <div className="p-inputgroup flex-1">
                                <InputNumber 
                                    name="tax_rate" 
                                    value={record_data.tax_rate} 
                                    id="tax_rate" 
                                    //min={1}
                                    max={1000}
                                    onValueChange={inputChange} 
                                    minFractionDigits={2}
                                />
                                <span className="p-inputgroup-addon">%</span>
                            </div>
                            <RenderErrors 
                                errors={errors} 
                                name="tax_rate" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => handleSubmit(1)} disabled={loading}><i className="fas fa-check mr-2" />Save</button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}