import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

import InputText from '../../elements/InputText';
// import InputNumber from '../../elements/InputNumber';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import InputCheckbox from '../../elements/InputCheckbox';

import ContactListModal from '../../modals/ContactListModal';
import OrderItemFinderModal from '../../modals/OrderItemFinderModal';
import OrderListModal from '../../modals/OrderListModal';
import CourierListModal from '../../modals/CourierListModal';
import initTreeView from '../../../helpers/initTreeView';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
 
export default function SalesReturnForm (){
    const { sales_return_id } = useParams();
    const { order_id } = useParams();
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Sales Return');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.sales_return_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]    );
    const [customer_data, setCustomer] = useState([]);
    const [customer_modal, setContactModal] = useState(0);
    const [so_list_modal, setSOModal] = useState(0);
    const [is_delivered, setIsDelivered] = useState(0);
    const [invoice_data, setOrderData] = useState([]);

    /** Modals */
    const [product_modal, setProductModal] = useState(0);
    const [couriers_list, setCouriersList] = useState([]);
    const [couriers_modal, setCouriersModal] = useState(0);
   
    useEffect(() => {
        initTreeView()


        if(order_id){
            setPageTitle('New Sales Return')
            //setInvoiceID(payment_receive_id)
            //getData(order_id)
            getSalesOrder(order_id)
        }
        else if(sales_return_id){
            setPageTitle('Sales Return Record')
            getData(sales_return_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [record_items]);

    useEffect(() => {
    }, [customer_data]);

    function getData(sales_return_id){
        if(!sales_return_id){
            return
        }
        const url = helpers.getUrl('V1/manage/sales-return/'+sales_return_id+'?store_id='+helpers.getStoreID())

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let new_res = res;
            new_res.sales_return_date = new Date(res.sales_return_date)
            if(new_res.delivered_date){
                new_res.delivered_date = new Date(res.delivered_date)
            }

            setLoading(0)
            setRecordData(new_res)
            setRecordItems(new_res.items)
            setRecordLoaded(1)

            if(res.delivered_date){
                setIsDelivered(1)
            }
        })
        .catch((err) => {
            setLoading(0)
            //getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    window.location.replace("/sales-returns/list");
                }
            })
        }); 
    }

    function getSalesOrder(order_id){
        if(!order_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/order/sales/${order_id}/fulfilled?store_id=${helpers.getStoreID()}`)
        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setRecordLoaded(1)
            
            let data = {...record_data}
            data.customer_id = res.customer_id ?? ''
            data.customer_name = res.customer_name ?? ''
            data.sales_order = res.order_no ?? ''
            data.so_id = order_id
            setRecordData(data)

            let items = []
            
            items = Object.entries(res.items).map(([key, data]) => {
                let remaining = parseFloat(data.fulfilled_quantity) - parseFloat(data.returned_quantity)

                if(remaining < 0) remaining = 0

                return({
                        "product_id": data.product_id,
                        //"product_code": data.product_code,
                        "product_name": data.product_name,
                        //"product_variant_id": data.product_variant_id,
                        "product_variant_name": data.product_variant_name,
                        "quantity": 0,
                        "fulfilled_quantity": data.fulfilled_quantity,
                        //"returned_quantity": data.returned_quantity,
                        "total_return":  data.returned_quantity,
                        "returned_quantity": remaining,
                        "so_item_id": data.id,
                        "unit_name": data.unit_name
                    
                })
            })

            setRecordItems(items)
        })
        .catch((err) => {
            setLoading(0)
            //getEnums()
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace(`/invoices/${order_id}/form`);
                }
            })
        }); 

    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {

                let remaining = parseFloat(data.fulfilled_quantity) - parseFloat(data.total_return)

                if(remaining < 0) remaining = 0

                return(
                <tr>
                    <td>{data.product_name} ({data.product_variant_name})</td>
                    <td>{data.fulfilled_quantity} {data.unit_name}</td>
                    <td>{remaining} {data.unit_name}</td>
                    <td>
                        <InputNumber
                            id="quantity" 
                            name="quantity" 
                            value={data.quantity}
                            inputClassName="form-control rounded-0"
                            min={0}
                            max={data.returned_quantity}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="Quantity"
                            decrementButtonClassName="p-input-button-left" 
                            incrementButtonClassName="p-input-button-right" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        />
                        {data.unit_name}
                    </td>
                    <td><button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button></td>
                </tr>)

            })
        }
        else{
            items = <tr>
                        <td colSpan={5} className='text-center'>
                            No data to diplay
                        </td>
                    </tr>
        }


        setTableItems(items)
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...record_items]
        let target = {...items[key]}

        target[name] = value
        items[key] = target

        setRecordItems(items)
    }

    function removeItem(key){
        let items = [...record_items]
        items.splice(key, 1); 

        setRecordItems(items)
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        if(name == 'is_delivered'){
            setIsDelivered(value)
            return;
        }

        if(name === 'courier_id'){
            
            let couriers = couriers_list.find((el) => {
                if(el.id === parseInt(value)){
                    return el
                }

            })
            new_state.courier = couriers.courier_name
        }

        setRecordData({ ...record_data, ...new_state });
    }

    function submit(){
        setLoading(1)

        let url = helpers.getUrl(`V1/manage/sales-return/create`)
        if(sales_return_id){
            url = helpers.getUrl(`V1/manage/sales-return/${sales_return_id}`)
        }

        let form_data = {...record_data}
        form_data['items'] = record_items;
        form_data['sales_order'] = record_data.so_id

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = sales_return_id;
                if(!id){
                    id = res.data.id
                }

                let redirect_url = `/sales-returns/${id}/form`
                if(order_id){
                    redirect_url = `/sales/${order_id}`
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(redirect_url);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <MainContainer page_title={page_title} menu="manage-sales-return" breadcrumbs={true} parent_link='/sales-returns/list' parent_text="Manage Order Sales Return">
                <div className="content-fluid">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        {record_data.sales_return_no ?
                        <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                            <h5 className='mb-3 mb-md-0 d-flex'>{record_data.sales_return_no}&nbsp;&nbsp;&nbsp;{helpers.displayInvoiceStatusBadge(record_data.order_status_id, record_data.invoice_status)}</h5>
                            <div className='d-flex flex-column flex-sm-row'>
                            </div>

                            <div className='text-right'>     
                                <Link className='btn btn-outline-dark ml-3' to="/sales-returns/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                            </div>
                        </div> : ''}

                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-5">
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Customer <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Select Customer" value={record_data.customer_name} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>SO # <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Select SO" value={record_data.sales_order} disabled={true} /> 
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="sales_order" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"><hr/></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Return Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <Calendar 
                                                        id="sales_return_date" 
                                                        name="sales_return_date"  
                                                        value={record_data.sales_return_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={!record_data.sales_order}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="sales_return_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className='mb-0'>Reason</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextArea
                                                        id="reason" 
                                                        name="reason" 
                                                        value={record_data.reason}  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        rows={2}
                                                        disabled={!record_data.sales_order}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="reason" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th>ITEM DESCRIPTION</th>
                                                        <th width={150}>FULFILLED</th>
                                                        <th width={150}>REMAINING</th>
                                                        <th width={200}>RETURN&nbsp;QUANTITY</th>
                                                        <th width={20}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    {
                                                        errors.hasOwnProperty('items')
                                                        ?
                                                            <tr>
                                                                <td colSpan={5} className='text-center'>
                                                                    <RenderErrors 
                                                                        errors={errors} 
                                                                        name="items" 
                                                                        className="text-danger mb-2"
                                                                        style={{fontSize: '.9rem'}}
                                                                    />
                                                                </td>
                                                            </tr> : ''
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>  
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(1)} disabled={!record_data.sales_order} style={{padding: '0.5rem 0.75rem'}}>Save</button>
                                {/* <Link className='btn btn-outline-dark' to="/sales-returns/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>       */}

                                {
                                    order_id ? 
                                    <Link className='btn btn-outline-dark' to={`/sales/${order_id}`} style={{padding: '0.5rem 0.75rem'}}>Cancel</Link> : 
                                    <Link className='btn btn-outline-dark' to="/sales-returns/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                                }            
                                
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
    )
}