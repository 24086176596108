import * as config from '../config/env';
import appConfig from '../config/env';
import {httpRequest} from '../helpers/httpService'
import helpers from './helpers';
import Swal from 'sweetalert2';

/**
 *
 * the session key
 */
function sessionKey() {
    return `${appConfig.prefix}_session`;
}

/**
 *
 * check the session and return its value if it exist
 */
function getSessionKey() {
    const key = sessionKey();
    return localStorage.getItem(key);
}

/**
 *
 * get session token object
 */
function getSessionTokenObj() {
    return { session_key: getSessionKey() };
}

/**
 *
 * set the session key val
 */
function setSessionToken(value) {
    const key = sessionKey();

    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }

    localStorage.setItem(key, value);
}

/**
 *
 * set the session key val
 */
 function setSessionItem(key, value) {

    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }

    localStorage.setItem(key, value);
}

/**
 *
 * set the session key val
 */
function resetFormSession() {
    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }
}


    
/**
 * 
 * Set session variables after successful login
 * 
 * @param {string} accessToken 
 * @param {number} expiresAt 
 * @param {string} name 
 */
function setSession(data, access_token, permissions, user) {
    setSessionItem("asinso-token", data.token)
    setSessionItem("asinso-permissions", data.permissions)
    setSessionItem("asinso-name", data.user.name)
    setSessionItem("asinso-uid", data.user.id)
    setSessionItem("asinso-default-store", JSON.stringify(data.default_store))

    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }
}

const logout = () => {
    const url = helpers.getUrl('logout')

    Swal.fire({
        text: 'Loging out. Please wait...',
        icon: "info",
        confirmButtonText: "OK",
        showConfirmButton: false,
        allowOutsideClick: false
    })

    httpRequest(url, 'POST', {}, true)
    .then((res) => {
        if(res.hasOwnProperty('status')){
            if(res.status === 'success'){
                deleteSession()
            }
        }
    })
    .catch((err) => {
        console.log(err)
        if(err.message === 'Unauthenticated.'){
            Swal.fire({
                title: "Invalid Access Token!",
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteSession()
                }
            })
        }
        else{
            Swal.fire({
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
        }

    }); 
}

const deleteSession = () => {
    if (localStorage.getItem('asinso-token')) {
        localStorage.removeItem('asinso-token');
    }

    if (localStorage.getItem('asinso-permissions')) {
        localStorage.removeItem('asinso-permissions');
    }

    if (localStorage.getItem('asinso-name')) {
        localStorage.removeItem('asinso-name');
    }

    if (localStorage.getItem('asinso-uid')) {
        localStorage.removeItem('asinso-uid');
    }

    if (localStorage.getItem('asinso-eid')) {
        localStorage.removeItem('asinso-eid');
    }

    if (localStorage.getItem('asinso-authenticated')) {
        localStorage.removeItem('asinso-authenticated');
    }

    if (localStorage.getItem('asinso-default-store')) {
        localStorage.removeItem('asinso-default-store');
    }

    if (localStorage.getItem('asinso-store-selection')) {
        localStorage.removeItem('asinso-store-selection');
    }

    if (localStorage.getItem(sessionKey())) {
        localStorage.removeItem(sessionKey());
    }

    window.location.href = '/login';
}


const appSession = {
    sessionKey,
    getSessionKey,
    getSessionTokenObj,
    setSessionToken,
    logout,
    setSession,
    resetFormSession,
    setSessionItem,
    deleteSession
}

export default appSession;