import React, { useEffect, useState } from 'react';
import { useNavigate  } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import InputText from '../../elements/InputText';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
import RenderErrors from '../../elements/RenderErrors';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import appSession from '../../../helpers/session';
import initTreeView from '../../../helpers/initTreeView';
 
export default function ProfilePage (){
    const [form_data, setFormData] = useState(store.user_data);
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();
    const id = helpers.getUserID();
    const name = helpers.getUserName();
    
    useEffect(() => {
        initTreeView()
        //Mounted
        getData()
    }, []);

    function getData(){
        const url = helpers.getUrl('V1/account/profile')
        setLoading(1)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                if(res.status === 'success'){
                    setFormData({ ...form_data, ...res.data });
                }
                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function validateForm(){
        let new_errors = {...errors}

        if(!form_data.display_name){
            new_errors['display_name'] = ['The display name field is required.']
        }
        else{
            delete new_errors['display_name'];
        }
        
        if(form_data.password && (form_data.password !== form_data.confirm_password)){
            new_errors['confirm_password'] = ['The confirm password field must match password.']
        }
        else {
            delete new_errors['confirm_password'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function submit(e){
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        setLoading(1)

        const url = helpers.getUrl('V1/account/profile')
        httpRequest(url, "POST", {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)
                if(res.status === 'success'){
                    appSession.setSessionItem("asinso-name", form_data.display_name)

                    let new_state = { 'password': '',  'confirm_password': ''}

                    setFormData({ ...form_data, ...new_state });

                    Swal.fire({
                        text: res.message,
                        icon: "success",
                        confirmButtonText: "OK",
                        allowOutsideClick: false
                    })
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFormData({ ...form_data, ...new_state });
    }
    
    return (
        <MainContainer menu="profile" page_title="User Profile">
            <div className="container-fluid">
                <form>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8" style={{position: 'relative'}}>
                            <Loader isLoading={loading}/>
                            <div className="card">
                                <div className="card-body">
                                    <div className='row mt-3 mb-2'>
                                        <div className="col-md-4 col-lg-4 text-md-right">
                                            <label>Registered Email</label>
                                        </div>
                                        <div className="col-md-5 col-lg-5 form-group text-secondary">
                                            {/* <InputText
                                                id="email" 
                                                name="email" 
                                                value={form_data.email}
                                                tagClass="form-control mb-0"
                                                placeholder="Email"
                                                type="text"
                                                readOnly
                                            /> */}
                                            {form_data.email}
                                        </div>
                                    </div>
                                    <div className='row mb-1'>
                                        <div className="col-md-4 col-lg-4 text-md-right">
                                        <label>Display Name <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-5 form-group">
                                            <InputText
                                                id="display_name" 
                                                name="display_name" 
                                                value={form_data.display_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Display Name"
                                                type="text"
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="display_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className="col-md-4 col-lg-4 text-md-right" />
                                        <div className="col-md-8 col-lg-8">
                                            <small>Note: Leave both password fields empty to keep your current password.</small>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-4 col-lg-4 text-md-right">
                                        <label>Password <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-5 form-group">
                                            <InputText
                                                id="password" 
                                                name="password" 
                                                value={form_data.password}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Password"
                                                type="password"
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="password" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className="col-md-4 col-lg-4 text-md-right">
                                        <label>Confirm Password <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-5">
                                            <InputText
                                                id="confirm_password" 
                                                name="confirm_password" 
                                                value={form_data.confirm_password}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder="Confirm Password"
                                                type="password"
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="confirm_password" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-4'>
                                <button type='button' className='btn btn-success' onClick={submit}>Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainContainer>
    )
}