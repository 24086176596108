import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Loader from '../../parts/Loader';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropDownSelection from '../../elements/DropDownSelection';
import Dropdown from 'react-bootstrap/Dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import initTreeView from '../../../helpers/initTreeView';
import store from '../../../helpers/store';
 
export default function SalesOrderList ({viewer = 'admin', menu = 'manage-sales', page_title = "Manage Sales Order"}){
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [enums, setEnums] = useState(store.order_enums);
    const [search_key, setSearchKey] = useState('');
    const [prompt_modal, setPromptModal] = useState(0);
    const [prompt_title, setPromptTitle] = useState('');
    const [prompt_caption, setPromptCaption] = useState('');
    const [prompt_error, setPromptError] = useState('');
    const [reasons_list, setResons] = useState();
    const [selected_reason, setSelectedReason] = useState('');
    const [order_id, setOrderID] = useState('');
    const [data_table, setDataTable] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [selected_all, setSelectAll] = useState(false);
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });
    const [filters, setFilters] = useState({
        status: 0,
        search: ""
    });

    useEffect(() => {
        initTreeView()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
        getEnums()
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        selected_all
    ]);

    useEffect(() => {
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            //"search": search_key
        })
    }, [datatable_state, filters]);
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl('V1/enums/orders?only=cancellation_reasons')
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setResons(res.cancellation_reasons)
            //setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function getDataList(condition = {
        "page": '',
        //"search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        //const search = condition.search ?? search_key
        let filter = `?store_id=${helpers.getStoreID()}&`

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(filters.search){
            filter += 'search='+filters.search+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/manage/order/sales/list'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setRecordData(res.list)
                setLoading(0)
                displayDataTable(res.list)
                setEnums(res.enums)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    // function search(e){
    //     let { value } = e.target;
    //     setSearchKey(value)
    //     // getDataList({
    //     //     "page": datatable_state.page + 1,
    //     //     "search": value
    //     // })
    // }

    //function triggerAction(action = 'action', id = '', order_no = ''){
    function triggerAction(data = '', action = '', status = '', order_no = ''){
        if(!action) return;

        if(action === 'cancel'){
            setOrderID(data)
            setPromptTitle('Order Cancellation')
            
            setPromptCaption(<>
                <h5 className='mb-3'>Order: #{order_no}</h5>
                <h5 className='mb-4'>This action cannot be reversed. All associated records will also be deleted. Do you want to continue?</h5>
                </>
            )
            setPromptModal(1)
        }
        else if(action === 'open'){
            updateStatusItem(data, status)
        }
        else if(action === 'confirm'){
            updateStatusItem(data, 2)
        }
        else if(action === 'single-delete' || action === 'bulk-delete'){
            let ids = [];
            if(action === 'single-delete'){
                ids.push(data.id)
            }
            else{
                ids = data.map(o => o['id']);
            }

            let title = 'Delete Sales Order'
            if(ids.length > 1){
                title = 'Delete Sales Orders'
            }

            Swal.fire({
                title: title,
                text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
                showCancelButton: true,
                showConfirmButton: false,
                closeOnCancel: true,
                showDenyButton: true,
                denyButtonText: 'Yes'
            })
            .then((result) => {
                if (result.isDenied) {
                    deleteItem(ids)
                }
            })
        }
    }

    function updateStatusItem(id, status_id){
        const url = helpers.getUrl('V1/manage/order/sales/status')
        httpRequest(url, 'POST', {
            postdata: {
                "order_id": id,
                "store_id": helpers.getStoreID(),
                "status_id": status_id
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    //"search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/order/sales/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "order_ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    //"search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                setLoading(0)
                setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                className='border-top'
                dataKey={data.id}
                value={data.data} 
                // tableStyle={{ minWidth: '50rem' }}
                size="normal"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollHeight="500px"
                scrollable
                responsive={true}
                emptyMessage="No data to diplay"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="order_date" header="DATE" ></Column>
                <Column field="order_no" header="ORDER&nbsp;#" body={({id, order_no})=> <Link to={`/sales/${id}`}>{order_no}</Link>}></Column>
                <Column field="customer_name" header="CUSTOMER" body={({customer_id, customer_name})=> <Link to={`/contacts/${customer_id}/edit`}>{customer_name}</Link>}></Column>
                <Column header="TOTAL" body={getTotal}></Column>
                <Column field="payment_term" header="TERM" ></Column>
                {/* <Column field="payment_status" header="PAYMENT" ></Column> */}
                <Column header="STATUS" body={({order_status_id, order_status})=> <h5>{helpers.displayOrderStatusBadge(order_status_id, order_status)}</h5>}></Column>
                <Column field="shipping_status" header="SHIPMENT" ></Column>
                <Column header="INVOICE" body={({invoice, order_no})=> invoice.invoice_no ? <Link to={`/invoices/${invoice.id}`} target='_blank'>{invoice.invoice_no}</Link> : '---'}/>
                <Column header="PAYMENT" body={({invoice})=> invoice.invoice_status ? invoice.invoice_status : '---'} />
                <Column className="text-right" body={displayActionMenu}></Column> 
            </DataTable>
        )

    }

    function getTotal(data){
        return helpers.formatCurrencyAmount(data.total_amount)
    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function displayActionMenu(data){
        return (<Dropdown>
                        <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item key="edit" as={Link}  to={`/sales/${data.id}/edit`}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                        
                            {data.order_status_id === 2 ? <Dropdown.Item key="open" onClick={(e) => triggerAction(data.id, 'open', 1, data.order_no)}><i className="fas fa-check-circle mr-2" />Set as Pending</Dropdown.Item> : ''}

                            {data.order_status_id === 1 ? <Dropdown.Item key="confirm" className='text-success' onClick={(e) => triggerAction(data.id, 'confirm', 2, data.order_no)}><i className="fas fa-check-circle mr-2" />Set as Confirm</Dropdown.Item> : ''}
                            
                            { parseInt(data.order_status_id) === 2 || parseInt(data.order_status_id) === 3  ?
                                <>
                                <Dropdown.Divider />
                                <Dropdown.Item key="delete" onClick={(e) => triggerAction(data.id, 'cancel', 4, data.order_no)}><i className="fas fa-times-circle mr-2" />Cancel</Dropdown.Item>
                                </> : ''
                             }

                            { 
                                parseInt(data.order_status_id) !== 3 && parseInt(data.order_status_id) !== 4  ?
                                <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" style={{width: '20px'}} />Delete</Dropdown.Item> : ''
                            }
                        </Dropdown.Menu>
                    </Dropdown>)
    }

    function closeModal(){
        setPromptModal(0)
        setOrderID('')
        setPromptTitle('')
        setPromptCaption('')
        setSelectedReason('')
        setPromptError('')
    }

    function cancelOrder(){
        setPromptError('')
        if(!selected_reason){
            setPromptError('Cancellation reason is required')
            return;
        }
        setPromptModal(0)
        setLoading(1)
        const url = helpers.getUrl('V1/manage/order/sales/status')

        httpRequest(url, 'POST', {
            postdata: {
                "order_id": order_id,
                "store_id": helpers.getStoreID(),
                "status_id": 4,
                "cancel_reason_id": selected_reason
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList()
                // let id = order_id;
                // if(!order_id){
                //     id = res.id
                // }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    //setErrors( err.data );
                }else{
                    //setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    return (
        <MainContainer page_title={page_title} menu={menu}>
            
            <Modal show={prompt_modal}>
                <Modal.Header>
                    <h5>{prompt_title}</h5>
                    <Button variant="danger" size="sm" onClick={closeModal}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 form-group">
                            {prompt_caption}

                            <DropDownSelection
                                tagClass="form-control"
                                id="selected_reason"
                                name='selected_reason' 
                                placeholder='Select Cancellation Reason'
                                value={selected_reason}  
                                onChange={(e) => setSelectedReason(e.target.value)}
                                onFocus=""
                                optionList={reasons_list}
                                optionProps ={{value:'id', label:'reason'}}
                                label={<>Reason <span className='text-danger'>*</span></>}
                            />
                            <div className='text-danger' style={{fontSize: '.9rem'}}>{prompt_error}</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={cancelOrder}>
                        Proceed
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container-fluid" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header border-bottom-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                        <DropDownSelection
                                            tagClass="form-control"
                                            id="status"
                                            name='status' 
                                            placeholder='Select Status'
                                            value={filters.status}  
                                            onChange={inputChange}
                                            onFocus=""
                                            optionList={enums.order_status}
                                            optionProps ={{value:'id', label:'order_status'}}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" name="search" className="form-control" placeholder="Search" onChange={inputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-right mt-3 mt-md-0">
                                <Link className='btn btn-primary float-right' to="/sales/form"><i className="fas fa-plus-circle mr-2" />New</Link>
                                
                                {selected_items.length ?
                                <Dropdown className='float-right mr-3'>
                                    <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items, 'bulk-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}