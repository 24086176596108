import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import Loader from '../../parts/Loader';

//import DataTable from 'react-data-table-component';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import initTreeView from '../../../helpers/initTreeView';
import store from '../../../helpers/store';
import DropDownSelection from '../../elements/DropDownSelection';
 
function ProductList ({viewer = 'admin', menu = 'manage-item', page_title = "Manage Items"}){
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [selected_items, setSelectedItems] = useState([]);
    const [selected_all, setSelectAll] = useState(false);
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });
    const [filters, setFilters] = useState({
        status: 0,
        search: ""
    });

    useEffect(() => {
        initTreeView()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        selected_all
    ]);

    useEffect(() => {
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            //"search": search_key
        })
    }, [datatable_state, filters]);
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function getDataList(condition = {
        "page": '',
        //"search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        //const search = condition.search ?? search_key

        let filter = '?store_id='+helpers.getStoreID()+'&'

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(filters.search){
            filter += 'search='+filters.search+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/manage/item/list'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setRecordData(res)
                setLoading(0)
                displayDataTable(res)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }
    

    // function search(e){
    //     let { value } = e.target;
    //     setSearchKey(value)
    //     getDataList({
    //         "page": datatable_state.page + 1,
    //         //"search": value
    //     })
    // }

    function triggerAction(data = '', action = '', status = ''){
        let ids = [];
        if(action === 'single-delete'){
            ids.push(data.id)
        }
        else {
            ids = data.map(o => o['id']);

            if(action === 'publish' || action === 'draft'){
                updateItemStatus(ids, status)
                return;
            }
        }

        let title = 'Delete Item'
        if(ids.length > 1){
            title = 'Delete Items'
        }

        Swal.fire({
            title: title,
            text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
            showCancelButton: true,
            showConfirmButton: false,
            closeOnCancel: true,
            showDenyButton: true,
            denyButtonText: 'Yes'
        })
        .then((result) => {
            if (result.isDenied) {
                deleteItem(ids)
            }
        })
    }

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/item/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    //"search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])
                
                if(err.data){
                    //setErrors( err.data );
                }else{
                    //setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function updateItemStatus(id, status){
        const url = helpers.getUrl('V1/manage/item/status')
        httpRequest(url, 'POST', {
            postdata: {
                "ids": id,
                "store_id": helpers.getStoreID(),
                "status": status
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    //"search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])
                
                if(err.data){
                    //setErrors( err.data );
                }else{
                    //setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                dataKey={data.id}
                value={data.data} 
                // tableStyle={{ minWidth: '50rem' }}
                size="normal"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollHeight="700px"
                scrollable
                stripedRows
                emptyMessage="No data to diplay"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="product_name" header="Name" style={{ minWidth: '300px' }}></Column>
                <Column field="category_name" header="Category" body={({category_name})=> category_name ? category_name : '[Delected]'}></Column>
                <Column header="Stocks" body={getAvailableStock}></Column>
                <Column field="unit_name" header="Unit" body={({unit_name})=> unit_name ? unit_name : '---'}></Column>
                <Column header="Price" body={getPrice}></Column>
                <Column field="brand_name" header="Brand" body={({brand_name})=> brand_name ? brand_name : '[Deleted]'}></Column>
                <Column field="product_type_id" body={({product_type_id})=> product_type_id === 1 ? 'Goods' : 'Services'} header="Type"></Column>
                <Column field="product_status" header="Status" body={({product_status})=> <h5>{helpers.displayStatusBadge(product_status)}</h5>}></Column>
                <Column body={displayActionMenu} ></Column>
            </DataTable>
        )

    }
    function displayActionMenu(data){
        return (<Dropdown>
                        <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item key="edit" as={Link} to={`/item/${data.id}/edit`}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>)
    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function getAvailableStock(data){
        let total = 0;
        Object.entries(data.variants).map(([key, data]) => {
            let sub_total = 0
            Object.entries(data.product_inventory).map(([key, data]) => {
                sub_total = sub_total + Number(data.available_quantity)
            })

            total = total + sub_total
        });

        return total
    }

    function getPrice(data){
        let price = Object.entries(data.variants).map(([key, data]) => {
            return parseFloat(data['product_price'])
        });
        price = price.sort(function(a, b) { return a - b; })
        const lowest_price = price[0]
        const highest_price = price[price.length - 1]

        if(lowest_price == highest_price){
            price = <>&#8369;{helpers.formatAmount(lowest_price)}</>
        }
        else{
            price = <>&#8369;{helpers.formatAmount(lowest_price)}&#8275;&#8369;{helpers.formatAmount(highest_price)}</>
        }

        return price
    }

    return (
        <MainContainer page_title={page_title} menu={menu}>
            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header border-bottom-0">
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className="row">
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                        <DropDownSelection
                                            tagClass="form-control"
                                            id="status"
                                            name='status' 
                                            placeholder='Select Status'
                                            value={filters.status}  
                                            onChange={inputChange}
                                            onFocus=""
                                            optionList={[ 
                                                {"label": "All Status", "id": 0}, 
                                                {"label": "Active", "id": 1}, 
                                                {"label": "Draft", "id": 2}, 
                                             ]}
                                            optionProps ={{value:'id', label:'label'}}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" name="search" className="form-control" placeholder="Search" onChange={inputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-right mt-3 mt-md-0">
                                <Link className='btn btn-primary text-right float-right' to="/item/form"><i className="fas fa-plus-circle mr-2" />New</Link>
                                
                                {selected_items.length ?
                                <Dropdown className='float-right mr-3'>
                                    <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>

                                    <Dropdown.Menu align="end">
                                        {/* <Dropdown.Item key="edit" as={Link} to={`/item/form?ItemID=${data.id}`}>Edit</Dropdown.Item>
                                         */}
                                        <Dropdown.Item key="publish" onClick={(e) => triggerAction(selected_items, 'publish', 1)}>Publish</Dropdown.Item>
                                        <Dropdown.Item key="draft" onClick={(e) => triggerAction(selected_items, 'draft', 2)}>Set as Draft</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items)}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}
 
export default ProductList;