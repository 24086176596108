import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
 
export default function AddressBookModal ({
        reference_id = '', 
        is_show = false, 
        setShow, 
        handleSelect, 
        header_title="Search Address",
        src="customer",
        store_id="",
        address_type=""
    }){
    const [loading, setLoading] = useState(0);
    const [name, setName] = useState('');
    const [notice, setNotice] = useState('');
    const [address_list, setAddress] = useState([]);
    const [table_data, setTableData] = useState([]);
   
    useEffect(() => {
        if(is_show) {
            getData()
        }
    }, [is_show, reference_id]);
   
    useEffect(() => {
        let table_items_lg = []
        let table_items_sm = []

        setNotice('')
        if(src == 'customer' && !reference_id) {
            setNotice(<div className="alert alert-primary px-3 py-2 mb-0 text-center" role="alert">Select Customer First.</div>)
            return;
        }
        else if(src == 'vendor' && !reference_id) {
            setNotice(<div className="alert alert-primary px-3 py-2 mb-0 text-center" role="alert">Select Vendor First.</div>)
            return;
        }

        if(!address_list.length){
            setNotice(<div className="alert alert-primary px-3 py-2 mb-0 text-center" role="alert">No available address.</div>)
        }

        
        if (Object.keys(address_list).length > 0) {
            table_items_lg = Object.entries(address_list).map(([key, data]) => {
                return (<tr key={data.id} style={{fontSize: '15px'}}>
                            <td>{data.address ?? data.address}</td>
                            {
                            src !== 'delivery' ?
                            <td>{data.address_type_id == 1 ? 'Shipping': 'Billing'}</td> : ''
                            }

                            <td>{data.is_default==1 ? 'Default' : ''}</td>
                            <td width={50}><button className='btn btn-sm btn-primary' type="button" onClick={(e) => onSelect(data)}>Select</button></td>
                        </tr>)
            })
            table_items_sm = Object.entries(address_list).map(([key, data]) => {
                return (<tr key={data.id} style={{fontSize: '15px'}}>
                            <td>
                                {data.address ?? data.address}
                                {
                                    src !== 'delivery' ?
                                    <><br/>Type: {data.address_type_id == 1 ? 'Shipping': 'Billing'}</>:''
                                }
                            </td>
                            <td width={50}><button className='btn btn-sm btn-primary' type="button" onClick={(e) => onSelect(data)}>Select</button></td>
                        </tr>)
            })
        }

        setTableData(
            <>
            <div className='d-none d-md-block'>
                <table className='table table-sm mb-0 table-hover'>{table_items_lg}</table>
            </div>
            <div className='d-block d-md-none'>
                <table className='table table-sm mb-0 table-hover'>{table_items_sm}</table>
            </div>
            </>
        )
    }, [address_list]);

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function onSelect(e){
        reset();
        setShow(0);
        handleSelect(e)
    }

    function getData(e) {
        setLoading(1)

        let filter = `?store_id=${helpers.getStoreID()}&`

        if(name){
            filter += 'search='+name+'&'
        }

        if(address_type){
            filter += 'address_type_id='+address_type+'&'
        }

        filter = filter.slice(0, -1)

        let url = ''
        if(src == 'customer'){
            url = helpers.getUrl(`V1/manage/contact/${reference_id}/address`+filter)
        }
        else if(src == 'vendor'){
            url = helpers.getUrl(`V1/manage/vendor/${reference_id}/address`+filter)
        }
        else if(src == 'delivery'){
            url = helpers.getUrl(`V1/manage/address/list?store_id=`+store_id)
        }

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setAddress(res)
                setLoading(0)
            })
            .catch(() => {
                reset()
                setNotice(<div className="alert alert-danger mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
                setLoading(0)
            }); 
    }

    function reset(){
        setName('')
        setAddress([])
        setTableData([])
        setNotice('')
    }

    return (
        <>
            <Modal show={is_show} size="lg">
                <Modal.Header>
                    <h5>{header_title}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                    <span className='text-danger'>{notice}</span>

                    {table_data}
                </Modal.Body>
            </Modal>
        </>
    )
}