import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Loader from '../../parts/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import initTreeView from '../../../helpers/initTreeView';
import DropDownSelection from '../../elements/DropDownSelection';
import VendorModal from '../../modals/VendorModal';
import { Accordion, AccordionTab } from 'primereact/accordion';
 
export default function ReportPurchasesOrder(){
    const [data_loaded, setDataLoaded] = useState(0);
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [page_title, setPageTitle] = useState('Purchases Order Report');
    const [vendor_modal, setVendorModal] = useState(0);
    const [enums, setEnums] = useState([]);
    const [filter_by, setFilterBy] = useState('');
    const [filters, setFilters] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        vendor_name: '',
        vendor: '',
        payment_status: '',
        payment_term: '',
        status: '',
        sales_channel: '',
        filter: '',
        dates: []
    });

    useEffect(() => {
        initTreeView()
        getEnums()
        getDataList()

        let p_title = page_title;
        let filter = helpers.getURLParams('filter') ?? ''
        if(filter) {
            if(filter === 'top-items'){
                p_title = 'Top Purchased Items Report'
            }
            else if(filter === 'receivables'){
                p_title = 'Receivable Items Report'
            }
            setPageTitle(p_title)

            let value = ''
            if(filter === 'top-items' || filter === 'receivables'){
                value = filter
                filter = 'filter'
            }
            else if(filter === 'dates'){
                var date = new Date();
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                value = [firstDay, lastDay]
            }
            

            setFilterBy(filter)
            let new_state = { [filter] : value }
            setFilters({ ...filters, ...new_state });
        }
    }, []);

    useEffect(() => {
        if(filters.vendor || filters.payment_status || filters.payment_term || filters.status || filters.sales_channel || filters.filter ||  filters.dates){
            getDataList()
        }

        //(typeof filters.dates[0] !== 'undefined' && typeof filters.dates[1] !== 'undefined')
    }, [
        filters.vendor,
        filters.payment_status,
        filters.payment_term,
        filters.status,
        filters.sales_channel,
        filters.filter,
        filters.dates
    ]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [filters.sortOrder]);

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": '',
        "product_type": ''
    }){
        const page = condition.page ?? filters.page
        const per_page = condition.per_page ?? filters.rows
        const search = condition.search ?? search_key

        let filter = '?store_id='+helpers.getStoreID()+'&'

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(search){
            filter += 'search='+search+'&'
        }

        if(filters.vendor){
            filter += 'vendor='+filters.vendor+'&'
        }
        if(filters.payment_status){
            filter += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.payment_term){
            filter += 'payment_term='+filters.payment_term+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            filter += 'sales_channel='+filters.sales_channel+'&'
        }
        if(filters.filter){
            filter += 'filter='+filters.filter+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                filter += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                filter += 'order_to='+order_to+'&'
            }

        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/reports/purchases'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setRecordData(res)
                setLoading(0)
                displayDataTable(res)
                setDataLoaded(1)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function getEnums(){
        setLoading(1)
        const url = helpers.getUrl('V1/enums/orders?store_id='+helpers.getStoreID())
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setEnums(res)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function exportReport(){
        const page = filters.page ?? ''
        const per_page = filters.rows ?? ''
        const search = search_key ?? ''
        const product_type = filters.product_type

        let filter = '?store_id='+helpers.getStoreID()+'&export=excel&'

        // if(page){
        //     filter += 'page='+page+'&'
        // }
        // if(per_page){
        //     filter += 'per_page='+per_page+'&'
        // }
        if(search){
            filter += 'search='+search+'&'
        }

        if(filters.vendor){
            filter += 'vendor='+filters.vendor+'&'
        }
        if(filters.payment_status){
            filter += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.payment_term){
            filter += 'payment_term='+filters.payment_term+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            filter += 'sales_channel='+filters.sales_channel+'&'
        }
        if(filters.filter){
            filter += 'filter='+filters.filter+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                filter += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                filter += 'order_to='+order_to+'&'
            }

        }

        filter = filter.slice(0, -1)

        setLoading(1)
        const today = new Date();
        const month = today.getMonth() + 1;
        const date = today.getFullYear() + '-' + month + '-' + today.getDate() + '_' +  today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
        const file_name = 'purchases-order-export-reports-' + date + '.xlsx';


        const url = helpers.getUrl('V1/reports/purchases'+filter)
        
        fetch(url, {
            method: 'GET',
            headers: httpAuthHeaders(),
        })
        .then((res) => {
          if(res.ok) {
            return res.blob();
          }
          
          const json = res.json();
          const status_code = res.status;
  
          if (!res.ok) {
              let errorCode = json.error_code ? json.error_code : 'unknown';
              let message = json.message ? json.message : (res.statusText ? res.statusText : 'Request failed');
              let data = json.error ? json.error: '';
              let status = json.status ? json.status: '';
              let error = { data: data, message: message, error_code: errorCode, status_code: status_code, status: status };
  
              if (status_code === 422) error = { ...error, ...json };
  
              return Promise.reject(error);
          }
        })
        .then(blob => {
            
            Swal.fire("Export Completed", "Export successfully completed. Please check your downloads.", "success");

            var url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.setAttribute("href", url);
            a.download = file_name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.parentNode.removeChild(a);
            setLoading(0);
        })
        .catch(err => {
          Swal.fire("Error", "Export failed", "error");
          setLoading(0);
        });
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function inputDateChange(e){
        let { value } = e.target;
        let new_state = { 'dates': value }

        if(!value){
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            new_state = { 'dates': [firstDay, lastDay] }
        }
        setFilters({ ...filters, ...new_state });
    }

    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": filters.page + 1,
            "search": value
        })
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                dataKey={data.product_variant_id}
                value={data.data} 
                size="small"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={filters.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                scrollHeight="700px"
                scrollable
                stripedRows
                resizableColumns
                emptyMessage="No data to diplay"
            >
                <Column 
                    header="ID" 
                    field="po_id" 
                    style={{ width: '100px' }}  
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Order&nbsp;Date" 
                    field="order_date" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Order #" 
                    field="order_no" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Order&nbsp;Status"
                    field="order_status" 
                    body={({order_status_id, order_status})=> <h5>{helpers.displayOrderStatusBadge(order_status_id, order_status)}</h5>}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="vendor_name" 
                    header="Vendor" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Total Purchased" 
                    field="total_amount" 
                    body={({total_amount})=> total_amount ? getPrice(total_amount) : ''}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Shipping Fee" 
                    field="shipping_fee" 
                    body={({shipping_fee})=> getPrice(shipping_fee)}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Tax" 
                    field="total_tax" 
                    body={({total_tax})=> getPrice(total_tax)}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="payment_term" 
                    header="Payment&nbsp;Term" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="total_items" 
                    header="Total&nbsp;Items" 
                    headerStyle={{ background: '#f9fafb'}}
                />
                <Column 
                    field="shipping_type" 
                    header="Shipping Type" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="shipping_status" 
                    header="Shipping&nbsp;Status"
                    headerStyle={{ background: '#f9fafb' }} 
                />
                <Column 
                    field="bill_no" 
                    header="Bill"
                    headerStyle={{ background: '#f9fafb' }} 
                    body={({bill_no})=> bill_no ? bill_no : ''}
                 />
                 <Column 
                     field="bill_status" 
                     header="Bill Status"
                     headerStyle={{ background: '#f9fafb' }} 
                     body={({bill_status})=> bill_status ? bill_status : ''}
                />
                <Column 
                    field="notes" 
                    header="Notes" 
                    body={({notes})=> notes ? notes : ''}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="reason" 
                    header="Cancellation&nbsp;Reason" 
                    body={({reason})=> reason ? reason : ''} 
                    headerStyle={{ background: '#f9fafb' }}
                />
            </DataTable>
        )

    }

    const onPage = (e) => {
        setFilters(e)
        //setSelectedItems([])
    };

    function getPrice(data){
        return <>&#8369; {helpers.formatAmount(data)}</>
    }

    function handleVendor(e){
        let new_state = { 
            'vendor' : e.id,
            'vendor_name': e.vendor_name
        }
        setFilters({ ...filters, ...new_state });
    }

    return (
        <>
        <VendorModal is_show={vendor_modal} setShow={setVendorModal} handleSelect={handleVendor} />

        <MainContainer page_title={page_title} menu="reports" parent_link='/reports' parent_text="Reports">
            <div className="container-fluid">
                <div className='mb-4'>
                    <Link to="/reports" className="btn btn-outline-dark border"><i className="fas fa-arrow-left mr-2" /> Reports</Link>
                    <button type="button" className='btn bg-purple text-right float-right' onClick={exportReport} disabled={!data_loaded}><i className="fas fa-file-excel mr-2" />Export</button>
                </div>
            </div>

            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>

                <Accordion>
                    <AccordionTab header="Filters" className="text-dark" unstyled={true}>
                        <div className="row">
                            {
                                filter_by === 'vendor' || filter_by === 'custom' ?
                                <div className="col-md-4 col-lg-3 mb-2">
                                    <label>Vendor</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Select vendor" onClick={(e) => setVendorModal(1)} value={filters.vendor_name} readOnly style={{backgroundColor: '#fff'}} />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={(e) => setVendorModal(1)}><i className="fas fa-search" /></button>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                            {
                                filter_by === 'payment_status' || filter_by === 'custom' ?
                                <div className="col-md-4 col-lg-3 mb-2">
                                <DropDownSelection
                                    tagClass="form-control"
                                    id="payment_status"
                                    name='payment_status' 
                                    placeholder='Select Payment Status'
                                    value={filters.payment_status}  
                                    onChange={inputChange}
                                    onFocus=""
                                    optionList={enums.payment_status}
                                    optionProps ={{value:'id', label:'payment_status'}}
                                    label={<>Payment Status</>}
                                /></div> : ''
                            }
                            {
                                filter_by === 'payment_term' || filter_by === 'custom' ?
                                <div className="col-md-4 col-lg-3 mb-2">
                                <DropDownSelection
                                    tagClass="form-control"
                                    id="payment_term"
                                    name='payment_term' 
                                    placeholder='Select Payment Term'
                                    value={filters.payment_term}  
                                    onChange={inputChange}
                                    onFocus=""
                                    optionList={enums.payment_terms}
                                    optionProps ={{value:'id', label:'payment_term'}}
                                    label={<>Payment Terms</>}
                                /></div> : ''
                            }
                            {
                                filter_by === 'status' || filter_by === 'custom' ?
                                <div className="col-md-4 col-lg-3 mb-2">
                                <DropDownSelection
                                    tagClass="form-control"
                                    id="status"
                                    name='status' 
                                    placeholder='Select Order Status'
                                    value={filters.status}  
                                    onChange={inputChange}
                                    onFocus=""
                                    optionList={enums.order_status}
                                    optionProps ={{value:'id', label:'order_status'}}
                                    label={<>Order Status</>}
                                /></div> : ''
                            }
                            {
                                filter_by === 'dates' || filter_by === 'custom' ?
                                <div className="col-md-4 col-lg-3 mb-2">
                                    <label>Order Dates</label><br/>
                                    <Calendar 
                                        value={filters.dates} 
                                        onChange={inputDateChange} 
                                        dateFormat="yy-mm-dd"
                                        selectionMode="range" 
                                        readOnlyInput 
                                        hideOnRangeSelection 
                                        className='w-100'
                                        showButtonBar
                                        placeholder='Order Date Range'
                                    />
                                </div> 
                                : ''
                            }
                            <div className="col-md-4 col-lg-3 mb-2">
                                <label>Search</label>
                                <input type="text" className="form-control" placeholder="Search" onChange={search} />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

                <div className="card card-primary card-outline mt-3">
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
        </>
    )
}