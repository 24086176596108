import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import SaversMainContainer from './parts/SaversMainContainer';

import { httpRequest } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
// import Dropdown from 'react-bootstrap/Dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import initTreeView from '../../../helpers/initTreeView';
import DropDownSelection from '../../elements/DropDownSelection';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
 
export default function SharesList ({viewer = 'admin', menu = 'manage-saver-shares', page_title = "Group Member Shares"}){
    const { group_id } = useParams();
    const [record_data, setRecordData] = useState([]);
    const [datatable_columns, setRecordColumns] = useState([]);
    const [datatable_footer, setRecordFooter] = useState([]);
    const [members_list, setMembers] = useState([]);
    const [loading, setLoading] = useState(1);
    const [search_key, setSearchKey] = useState('');

    const [data_table, setDataTable] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [selected_all, setSelectAll] = useState(false);
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });
    const [filters, setFilters] = useState({
        status: 0,
        search: "",
        member_id: ""
    });

    useEffect(() => {
        initTreeView()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        selected_all
    ]);

    function dummyData(){
        const shares = {
                "data": [
                    {
                        "no": 1, 
                        "member_name": "Carolina Marin", 
                        "total_shares": "35,000 (35)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 4000, 
                        "shares_02": "4,000 (4)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 3000, 
                        "shares_03": "3,000 (3)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 2000, 
                        "shares_04": "2,000 (2)", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 1000, 
                        "shares_06": "1,000 (1)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 2000, 
                        "shares_07": "2,000 (2)",
                        "date_08": "August 12, 2024", 
                        "amount_08": 3000, 
                        "shares_08": "3,000 (3)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 3000, 
                        "shares_09": "3,000 (3)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 2, 
                        "member_name": "Pedro Morales", 
                        "total_shares": "55,000 (55)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 5000, 
                        "shares_04": "5,000 (5)", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 5000, 
                        "shares_07": "5,000 (5)",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 3, 
                        "member_name": "Daniel Serrano", 
                        "total_shares": "55,000 (55)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 5000, 
                        "shares_04": "5,000 (5)", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 5000, 
                        "shares_07": "5,000 (5)",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 4, 
                        "member_name": "Sofia Pastor", 
                        "total_shares": "55,000 (55)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 5000, 
                        "shares_04": "5,000 (5)", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 5000, 
                        "shares_07": "5,000 (5)",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 5, 
                        "member_name": "Raul Garrido", 
                        "total_shares": "55,000 (55)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 5000, 
                        "shares_04": "5,000 (5)", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 5000, 
                        "shares_07": "5,000 (5)",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 6, 
                        "member_name": "Vicente Campos", 
                        "total_shares": "45,000 (45)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 0, 
                        "shares_04": "-none-", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 0, 
                        "shares_07": "-none-",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 7, 
                        "member_name": "Juan Dela Cruz", 
                        "total_shares": "45,000 (45)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 0, 
                        "shares_04": "-none-", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 0, 
                        "shares_07": "-none-",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    },
                    {
                        "no": 8, 
                        "member_name": "Maria Soles", 
                        "total_shares": "45,000 (45)", 
                        "date_01": "Jan 12, 2024", 
                        "amount_01": 5000, 
                        "shares_01": "5,000 (5)", 
                        "date_02": "Feb 15, 2024", 
                        "amount_02": 5000, 
                        "shares_02": "5,000 (5)", 
                        "date_03": "Mar 15, 2024", 
                        "amount_03": 5000, 
                        "shares_03": "5,000 (5)", 
                        "date_04": "Apr 14, 2024", 
                        "amount_04": 0, 
                        "shares_04": "-none-", 
                        "date_05": "May 16, 2024", 
                        "amount_05": 5000, 
                        "shares_05": "5,000 (5)", 
                        "date_06": "June 15, 2024", 
                        "amount_06": 5000, 
                        "shares_06": "5,000 (5)",
                        "date_07": "July 12, 2024", 
                        "amount_07": 0, 
                        "shares_07": "-none-",
                        "date_08": "August 12, 2024", 
                        "amount_08": 5000, 
                        "shares_08": "5,000 (5)",
                        "date_09": "Sep 15, 2024", 
                        "amount_09": 5000, 
                        "shares_09": "5,000 (5)",
                        "date_09": "Oct 14, 2024", 
                        "amount_10": 2000, 
                        "shares_10": "2,000 (2)", 
                        "amount_11": 5000, 
                        "shares_11": "5,000 (5)"
                    }
                ]
            }

        const columns = [
            {field: 'no', header: 'No', frozen: true},
            {field: 'member_name', header: 'Members', frozen: true},
            {field: 'total_shares', header: 'Total_Shares', frozen: true},
            {field: 'shares_01', header: 'Jan-12-2024', frozen: false},
            {field: 'shares_02', header: 'Feb-15-2024', frozen: false},
            {field: 'shares_03', header: 'Mar-15-2024', frozen: false},
            {field: 'shares_04', header: 'Apr-14-2024', frozen: false},
            {field: 'shares_05', header: 'May-16-2024', frozen: false},
            {field: 'shares_06', header: 'June-15-2024', frozen: false},
            {field: 'shares_07', header: 'July-12-2024', frozen: false},
            {field: 'shares_08', header: 'Aug-12-2024', frozen: false},
            {field: 'shares_09', header: 'Sep-15-2024', frozen: false},
            {field: 'shares_10', header: 'Oct-14-2024', frozen: false},
            {field: 'shares_11', header: 'Nov-12-2024', frozen: false},
        ];

        const footer_columns = [
            {value: 'Totals', colSpan: 2, frozen: true },
            {value: '550,000 (55)', colSpan: 1, frozen: true },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
            {value: '50,000 (5)', colSpan: 1, frozen: false },
        ];
        displayDataTable(shares, columns, footer_columns)

        const members = [
            {id: 1, user_id: 1, member_name: 'Carolina Marin'},
            {id: 2, user_id: 2, member_name: 'Pedro Morales'},
            {id: 3, user_id: 3, member_name: 'Daniel Serrano'},
            {id: 4, user_id: 4, member_name: 'Sofia Pastor'},
            {id: 5, user_id: 5, member_name: 'Raul Garrido'},
            {id: 6, user_id: 6, member_name: 'Vicente Campos'},
            {id: 7, user_id: 7, member_name: 'Juan Dela Cruz'},
            {id: 8, user_id: 8, member_name: 'Maria Soles'},
        ];
        setMembers(members)
    }

    useEffect(() => {
        dummyData()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            "search": search_key
        })
    }, [datatable_state, , filters]);
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        const search = condition.search ?? search_key
        let filter = `?store_id=${helpers.getStoreID()}&`

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(search){
            filter += 'search='+search+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl(`v1/savers/groups/${group_id}/list${filter}`)

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            displayDataTable(res)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }); 
    }
    
    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": datatable_state.page + 1,
            "search": value
        })
    }

    function triggerAction(data = '', action = '', status = ''){
        let ids = [];
        if(action === 'single-delete'){
            ids.push(data.id)
        }
        else{
            ids = data.map(o => o['id']);
        }

        let title = 'Delete Shipment'
        if(ids.length > 1){
            title = 'Delete Shipments'
        }

        Swal.fire({
            title: title,
            text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
            showCancelButton: true,
            showConfirmButton: false,
            closeOnCancel: true,
            showDenyButton: true,
            denyButtonText: 'Yes'
        })
        .then((result) => {
            if (result.isDenied) {
                deleteItem(ids)
            }
        })
    }

    function deleteItem(id){
        const url = helpers.getUrl('v1/savers/groups/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "shipment_ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    "search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                setLoading(0)
                setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function displayDataTable(data, columns = [], footer_columns = []){
        const footerGroup = (
            <ColumnGroup>
                <Row>
                {
                    Object.entries(footer_columns).map(([key, col]) => {
                        let text_align = 'center'
                        let frozen = false

                        if(col.colSpan > 1) {
                            text_align = 'right'
                            frozen = true
                        }

                        return <Column footer={col.value} frozen={col.frozen} colSpan={col.colSpan} footerStyle={{ textAlign: text_align }} footerClassName='bg-olive' />
                    })
                }
                </Row>
            </ColumnGroup>
        );

        setDataTable(
            <DataTable 
                className='border-top frozen-column'
                dataKey={data.id}
                value={data.data} 
                size="small"
                showGridlines
                stripedRows 
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollable
                scrollHeight="500px"
                responsive={true}
                emptyMessage="No data to diplay"
                footerColumnGroup={footerGroup}
                columnResizeMode="fit"
            >

                {
                    Object.entries(columns).map(([key, col]) => {
                        let frozen = false
                        let bodyClassName = 'text-center'
                        let headerClassName  = 'text-center'

                        if(col.field === 'no' || col.field === 'member_name'){
                            bodyClassName = 'text-left'
                            headerClassName = 'text-left'
                        }

                        let style = {}
                        if(col.field === 'member_name'){
                            style = { maxWidth: '500px' }
                        }

                        return (<Column key={col.field} field={col.field} header={col.header} frozen={col.frozen} headerClassName={headerClassName} bodyClassName={bodyClassName} />)
                    })
                }
            </DataTable>
        )

    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function displayActionMenu(data){
        return (<Dropdown>
                    <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item key="edit" as={Link} to={`/shipments/${data.id}/form`}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>)
    }

    return (
        <SaversMainContainer page_title={page_title} menu={menu}>

            <div className="container-fluid" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header border-bottom-0">
                        <div className="row">     
                            <div className="col-lg-6 mb-2">
                                <div className="row">
                                    <div className="col-lg-6 mb-3 mb-lg-0">
                                        <Dropdown  
                                            name='member_id'
                                            value={parseInt(filters.member_id)} 
                                            onChange={inputChange}
                                            options={members_list}
                                            optionLabel="member_name" 
                                            optionValue="id" 
                                            placeholder="Select Member" 
                                            checkmark={true} 
                                            highlightOnSelect={false}
                                            showClear
                                            filter 
                                            className='w-100'
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                value={search_key} 
                                                placeholder="Search" 
                                                onChange={search} 
                                            />
                                            <span className="p-inputgroup-addon"><i className='fa fa-search' /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-right mt-3 mt-md-0">
                                <Link className='btn btn-primary float-right' to={`/app/savers/${group_id}/shares/new`}><i className="fas fa-plus-circle mr-2" />New</Link>
                                
                                {selected_items.length ?
                                <Dropdown className='float-right mr-3'>
                                    <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items)}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> : ''}

                            </div>

                            {/* <div className="col-lg-4 mb-2">
                                <input type="text" className="form-control" placeholder="Search" onChange={search} />
                            </div>
                            <div className="col-lg-8 text-right mt-3 mt-md-0">
                            </div> */}
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </SaversMainContainer>
    )
}