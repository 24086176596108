import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import InputTextArea from '../elements/InputTextArea';
import ButtonGroupSelection from '../elements/ButtonGroupSelection';
import store from '../../helpers/store';
import helpers from '../../helpers/helpers';
import Swal from 'sweetalert2';
 
export default function BrandFormModal ({
        show_modal = false, 

        id = '', 
        close_modal={}, 
        handleSave={}, 
        header_title="Address",
        src="customer",
        index="",
        existing_data={}
    }){
        
    let default_data = {
        "id": "",
        "store_id": helpers.getStoreID(),
        "brand_name": "",
        "status": 1
    }
    const [record_data, setRecordData] = useState(default_data);
    const [record_loaded, setRecordLoaded] = useState(0);
    const [loading, setLoading] = useState(0);
    const [notice, setNotice] = useState('');
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        setErrors([])

        if(show_modal && id){
            getData(id)
        }
        else if(show_modal && !id){
            setRecordData(default_data)
        }
    }, [show_modal, id]);


    function getData(id){
        if(!id){
            return
        }
        const url = helpers.getUrl(`V1/manage/brands/${id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    
                }
            })
        }); 
    }

    function handleSubmit(e) {

        if(!record_data.brand_name){
            setErrors(
                {"brand_name":["The brand name field is required."]}
            )
            return;
        }
        
        setLoading(1)

        let url = helpers.getUrl('V1/manage/brands/create')
        if(id && record_loaded){
            url = helpers.getUrl(`V1/manage/brands/${id}`)
        }

        let form_data = {...record_data}

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                form_data.id = res.id
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        close_modal(0);
                        handleSave(form_data);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
        
        //close_modal(0);
        // handleSave(form_data, index)
    }
    
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function inputChangeEnter(e) {
        if (e.key === "Enter"){
            setErrors([])
            if(!record_data.brand_name){
                setErrors(
                    {"brand_name":["The brand name field is required."]}
                )
                return;
            }

            handleSubmit()
        }
    }

    function handleClose(e) {
        e.preventDefault();
        reset();
        close_modal(0);
    }

    function reset(){
        setRecordData(default_data)
    }

    return (
        <>
            <Modal show={show_modal}>
                <Modal.Header className='pt-3 pb-3'>
                    <h5 className='mb-0'>Brand Name</h5>
                    <a href="#" className='btn btn-danger btn-sm'onClick={handleClose}><i className="fas fa-times"></i></a>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="row">
                        {/* <div className="col-md-4">
                            <label>Name <span className="text-danger">*</span></label>
                        </div> */}
                        <div className="col-md-12">
                            <InputText
                                id="brand_name" 
                                name="brand_name" 
                                value={record_data.brand_name}
                                tagClass="form-control mb-0"
                                placeholder="e.g Samsung"
                                onChange={inputChange}
                                autoFocus={true}
                                onKeyDown={inputChangeEnter}
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="brand_name" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => handleSubmit(1)} disabled={loading}><i className="fas fa-check mr-2" />Save</button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}