import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import InputTextArea from '../elements/InputTextArea';
import ButtonGroupSelection from '../elements/ButtonGroupSelection';
import store from '../../helpers/store';
import helpers from '../../helpers/helpers';
import Swal from 'sweetalert2';
import { set } from 'date-fns';
 
export default function VariantTypesFormModal ({
        show_modal = false, 

        id = '', 
        close_modal={}, 
        handleSave={}, 
        header_title="Address",
        src="customer",
        store_id="",
        index="",
        existing_data={}
    }){
        
    let default_data = {
        "id": "",
        "store_id": helpers.getStoreID(),
        "variant_name": "",
    }
    const [record_data, setRecordData] = useState(default_data);
    const [record_loaded, setRecordLoaded] = useState(0);
    const [loading, setLoading] = useState(0);
    const [notice, setNotice] = useState('');
    const [errors, setErrors] = useState([]);

    const [variant_values, setVariantValues] = useState([]);

    const [new_value, setNewValue] = useState('');

    const [display_values, setDisplayValues] = useState('');
   
    useEffect(() => {
        setVariantValues([])
        setNewValue('')
        setDisplayValues('')
        setErrors([])
    }, []);
   
    useEffect(() => {
        //displayValues()
        if(show_modal && id){
            getData(id)
        }
        else if(show_modal && !id){
            reset()
        }
    }, [show_modal, id]);

    useEffect(() => {
        displayValues()
    }, [variant_values]);


    function getData(id){
        if(!id){
            return
        }
        const url = helpers.getUrl('V1/manage/variant-types/'+id+'?store_id='+helpers.getStoreID())

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setVariantValues(res.variant_values)
            setLoading(0)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    
                }
            })
        }); 
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.variant_name){
            new_errors['variant_name'] = ["The variant type field is required."]
        }
        else{
            delete new_errors['variant_name'];
        }

        if(!variant_values.length){
            new_errors['variant_value'] = ["The variant value field is required."]
        }
        else{
            delete new_errors['variant_value'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function handleSubmit(e) {
        const validate = validateForm();

        if(Object.keys(validate).length) return;
        
        setLoading(1)

        let url = helpers.getUrl(`V1/manage/variant-types/create`)
        if(id && record_loaded){
            url = helpers.getUrl(`V1/manage/variant-types/${id}`)
        }

        let form_data = {...record_data}
        form_data.variant_values = variant_values

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        close_modal(0);
                        handleSave();
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
        
        //close_modal(0);
        // handleSave(form_data, index)
    }
    
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function handleClose(e) {
        e.preventDefault();
        reset();
        close_modal(0);
    }

    function reset(){
        setRecordData(default_data)
        setVariantValues([])
        setNewValue('')
        setDisplayValues('')
        setErrors([])
    }
    
    
    function inputItemChange(e) {
        if (e.key === "Enter"){
            setErrors([])
            if(!new_value){
                setErrors(
                    {"variant_value":["The variant value field is required."]}
                )
                return;
            }

            let items = [...variant_values]

            items.push({
                "id": "",
                "variant_type_id": "",
                "variant_value": new_value,
            })
    
            setVariantValues(items)
            setNewValue('')
            return;
        }

        let { value, name } = e.target;
        setNewValue(value)
    }

    function addItem(e) {
        setErrors([])
        if(!new_value){
            setErrors(
                {"variant_value":["The variant value field is required."]}
            )
            return;
        }

        let items = [...variant_values]

        items.push({
            "id": "",
            "variant_type_id": "",
            "variant_value": new_value,
        })

        setVariantValues(items)
        setNewValue('')
    }

    function removeItem(e, key){
        e.preventDefault()
        let items = [...variant_values]
        items.splice(key, 1); 

        setVariantValues(items)
    }

    function displayValues(){
        let items = [];

        if (Object.keys(variant_values).length > 0) {
            items = Object.entries(variant_values).map(([key, record_data]) => {
                return (<span className='px-2 py-1 border float-left mr-2 mb-2'>{record_data.variant_value}<a className='ml-2 text-danger' href="#" onClick={(e)=> removeItem(e, key)}><i className="fas fa-times" style={{fontSize: '14px'}}></i></a></span>)
            })
        }

        setDisplayValues(items)
    }

    return (
        <>
            <Modal show={show_modal}>
                <Modal.Header className='pt-3 pb-3'>
                    <h5 className='mb-0'>Variant Types</h5>
                    <a href="#" className='btn btn-danger btn-sm'onClick={handleClose}><i className="fas fa-times"></i></a>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="row">
                        {/* <div className="col-md-4">
                            <label>Name <span className="text-danger">*</span></label>
                        </div> */}
                        <div className="col-md-12">
                            <InputText
                                id="variant_name" 
                                name="variant_name" 
                                value={record_data.variant_name}
                                tagClass="form-control mb-0"
                                placeholder="Type Name"
                                onChange={inputChange}
                                autoFocus={true}
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="variant_name" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    
                    {/* <label className='mt-3'>Variant Values</label> */}
                    
                    <div className="input-group mt-3">
                        <input type="text" className="form-control" placeholder="Hit ENTER or click ADD to insert new value" aria-label="Variant Value" value={new_value} onChange={inputItemChange} onKeyDown={inputItemChange} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-primary" type="button" onClick={addItem}>ADD</button>
                        </div>
                    </div>
                        <RenderErrors 
                            errors={errors} 
                            name="variant_value" 
                            className="text-danger"
                            style={{fontSize: '.9rem'}}
                        />
                    <div className='mt-3'>
                        {
                            display_values
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => handleSubmit(1)} disabled={loading}><i className="fas fa-check mr-2" />Save</button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}