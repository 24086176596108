import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../helpers/images';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import helpers from '../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../parts/Loader';
import initTreeView from '../../helpers/initTreeView';
 
function StoreSelector (){
    const [loading, setLoading] = useState(0);
    const [statistic, setStatistic] = useState([]);
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        initTreeView()
        //Mounted
        //getDataList()
    }, []);

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }

    function getDataList(page = '', search_key = ''){
        setLoading(1)

        let filter = '?'
        if(page){
            filter += 'page='+page+'&'
        }
        if(search_key){
            filter += 'search='+search_key+'&'
        }

        const technician_id = helpers.getEmployeeID();
        if(technician_id){
            filter += 'technician_id='+technician_id+'&'
        }

        filter = filter.slice(0, -1)

        const url = helpers.getUrl('admin/service-request/statistic'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setStatistic(res)
                // setRecordData(res)
                setLoading(0)
                // table_items(res.data)

                //const data = res.data.filter(item => item.status == 'created');
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function linkTo(target = ''){
        // let page = 'service-request'

        // if(!helpers.checkPermissions(["service-request-dashboard"])){
        //     page = 'technician-service-request'
        // }

        // navigate(page + params);
        navigate(target);
    }

    return (
        <>
        <MainContainer page_title={"Select Store"} breadcrumbs={false}>
                <div className="content">
                    <div className="container-fluid" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        <div className="row mt-3">
                            {/* <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("/sales/form")}>
                                    <span className="info-box-icon bg-light"><i className="fas fa-shopping-cart"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">New Sales Order</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("/shipments/form")}>
                                    <span className="info-box-icon bg-light"><i className="fas fa-shipping-fast"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Ship Order</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("/purchases/form")}>
                                    <span className="info-box-icon bg-light"><i className="fas fa-shopping-bag"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">New Purchase Order</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("/purchase-receives/form")}>
                                    <span className="info-box-icon bg-light"><i className="fas fa-dolly-flatbed"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Receive Purchase</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-box text-dark pointer' onClick={(e) => linkTo("/item/form")}>
                                    <span className="info-box-icon bg-light"><i className="fas fa-box-open"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Add New Item</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>



                    </div>{/* /.container-fluid */}
                </div>

        </MainContainer>
        </>
    )
}
 
export default StoreSelector;