import React from 'react';
import $ from 'jquery';

export default function initTreeView(){
    $(".nav-sidebar").on("click", ".nav-link-main", function(e) {
        //e.preventDefault();
        var parent = $(this).parent()
        $('.nav-sidebar').children('.nav-item').removeClass("menu-is-opening menu-open")
        parent.removeClass("menu-is-opening menu-open");
        parent.addClass("menu-is-opening menu-open");
    });
}
