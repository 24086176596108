import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
//import CourierFormModal from './CourierFormModal';
import CourierFormModal from './CourierFormModal'
 
export default function CourierListModal ({
        show_modal = false, 
        close_modal, 
        handleSelect, 
        exclude = [], 
    }){
    const [loading, setLoading] = useState(0);
    const [name, setName] = useState('');
    const [notice, setNotice] = useState('');
    const [courier_list, setCourierList] = useState([]);
    const [table_data, setTableData] = useState([]);
    const [label, setLabel] = useState('Select Courier');
    
    const [courier_form_modal, setCourierModal] = useState(0);

    // useEffect(() => {

    // }, []);
   
    useEffect(() => {
        if(show_modal) {
            getData()
        }
    }, [show_modal]);
   
    useEffect(() => {
        let table_items_lg = []

        
        if (Object.keys(courier_list).length > 0) {
            table_items_lg = Object.entries(courier_list).map(([key, data]) => {

                const result = exclude.find((variant_type) => {
                    if(variant_type.variant_type_id == data.id) {
                        return variant_type.variant_type_id;
                    }
                })

                if(result) return;

                return (<tr key={data.id}>
                            <td className='border-top-0 border-bottom'>
                                {data.courier_name}
                                <div className='text-muted'><small>{!data.store_id ? 'System Default': 'Custom'}</small></div>
                            </td>
                            <td className='border-top-0 border-bottom' width={50}><button className='btn btn-sm btn-outline-primary' type="button" onClick={(e) => onSelect(data)}>Select</button></td>
                        </tr>)
            })
        }

        setTableData(
            <>
            <div>
                <table className='table table-hover table-sm mb-0 p-0 mt-2 border-0'><tbody>{table_items_lg}</tbody></table>
            </div>
            </>
        )
    }, [courier_list]);

    function handleClose(e) {
        reset();
        close_modal(0);
    }

    function onSelect(e){
        reset();
        close_modal(0);
        handleSelect(e)
    }

    function inputChange(e) {
        let { value, name } = e.target;
        
        if(!value) {
            setNotice('Vendor field is required') 
        }

        if(value) setNotice('');

        setName(value);
        getData(value)
    }

    function getData(search = '') {
        setLoading(1)

        let filter = '?store_id='+helpers.getStoreID()+'&pagination=0&with_default=1&'

        if(search){
            filter += 'search='+search+'&'
        }
        

        filter = filter.slice(0, -1)

        const url = helpers.getUrl('V1/manage/couriers/list'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setCourierList(res)
                setLoading(0)
            })
            .catch((err) => {
                reset()
                setLoading(0)
                setNotice(<div className="alert alert-danger mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 
    }

    function reset(){
        setName('')
        setCourierList([])
        setTableData([])
        setNotice('')
    }

    function handleSave(){
        getData()
    }

    function handleCourier(e){
        reset();
        close_modal(0);
        handleSelect(e)
        // let data = {...shipment_data}

        // data.courier_id = e.id
        // data.courier = e.courier_name

        // setShip(data)
        // getCourierList()
    }

    return (
        <>
        
            <CourierFormModal 
                show_modal={courier_form_modal} 
                close_modal={setCourierModal} 
                handleSave={handleCourier}
                //id={variant_type_id}
            />

            <Modal show={show_modal}>
                <Modal.Header>
                    <h5>{label}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"  style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <div className="col-12">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder={"Search"} aria-label="Vendor Name" aria-describedby="basic-addon2" onChange={inputChange} autoFocus />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={(e)=>setCourierModal(1)}><i className="fas fa-plus-circle mr-2"></i>New</button>
                                </div>
                            </div>
                            <span className='text-danger'>{notice}</span>

                        </div>
                    </div>

                    <div style={{maxHeight: '350px', overflow: 'auto'}}>
                    {table_data}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}