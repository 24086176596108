import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';
//import InputText from '../../elements/InputText';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';
import AddressFormModal from '../../modals/AddressFormModal';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';
import initTreeView from '../../../helpers/initTreeView';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
 
export default function ContactForm (){
    const { contact_id } = useParams();
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Contact');
    const [record_data, setRecordData] = useState(store.contact_data);
    const [address_list, setAddress] = useState([]);
    const [show_modal, setShowModal] = useState(0);
    const [selected_address_data, setAddressData] = useState('');
    const [selected_address_id, setAddressID] = useState('');
    const [selected_address_index, setAddressIndex] = useState('');
    const [table_items, setTableItems] = useState([]);
    
   
    useEffect(() => {
        initTreeView()

        if(contact_id){
            setPageTitle('Contact Details')
            getData(contact_id)
        }
    }, []);

    useEffect(() => {
        displayTableItems()
    }, [address_list]);

    function getData(contact_id){
        if(!contact_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/contact/${contact_id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setRecordData(res)
            setAddress(res.address)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/contacts/list");
                }
            })
        }); 
    }

    function displayTableItems(){
        let items = []
        
        if (address_list.length) {
            items = Object.entries(address_list).map(([key, data]) => {

                return(
                <tr>
                    <td>{data.address_type_id == 1 ? 'Shippping': 'Billing'}</td>
                    <td>{data.address}</td>
                    <td>{data.contact_name}</td>
                    <td>{data.email}</td>
                    <td>{data.phone_no}</td>
                    <td>
                        <button className='btn btn-sm text-muted' type="button" onClick={(e) => editAddress(data, data.id, key)}><i className="fas fa-edit text-primary"></i></button>
                        <button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button>
                    </td>
                </tr>)

            })
        }
        else{
            // items = <tr>
            //             <td colSpan={5} className='text-center'>&nbsp;</td>
            //         </tr>
        }


        setTableItems(items)
    }

    function editAddress(address_data, address_id, data_index){
        setAddressID(address_id)
        setAddressIndex(data_index)
        setAddressData(address_data)
        setShowModal(1)
    }

    function newAddress(){
        setAddressID('')
        setAddressIndex('')
        setAddressData('')
        setShowModal(1)
    }

    function removeItem(key){
        let items = [...address_list]
        items.splice(key, 1); 

        setAddress(items)
    }
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function handleAddress(e, index){
        let items = [...address_list]

        let new_item = {
            "id": e.id,
            "contact_id": e.reference_id,
            "address_type_id": e.address_type_id,
            "contact_name": e.contact_name,
            "phone_no": e.phone_no,
            "email": e.email,
            "address": e.address,
            "flag": e.flag
        }


        if(index){
            items[index] = new_item
        }
        else{
            items.push(new_item)
        }

        setAddressData(new_item)
        setAddress(items)
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.contact_name){
            new_errors['contact_name'] = ['The contact name field is required.']
        }
        else{
            delete new_errors['contact_name'];
        }

        if(!record_data.contact_type_id){
            new_errors['contact_type_id'] = ['The type field is required.']
        }
        else{
            delete new_errors['contact_type_id'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function submit(){
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        let url = helpers.getUrl(`V1/manage/contact/create`)
        if(contact_id){
            url = helpers.getUrl(`V1/manage/contact/${contact_id}`)
        }

        let form_data = {...record_data}
        form_data.address = address_list;
        if(record_data.phone_no){
            form_data.phone_no = helpers.removeString(record_data.phone_no)
        }

        setLoading(1)
        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = contact_id;
                if(!id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/contacts/${id}/edit`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <>
        <AddressFormModal 
            is_show={show_modal} 
            setShow={setShowModal} 
            handleSave={handleAddress} 
            reference_id={selected_address_id}
            index={selected_address_index}
            address_data={selected_address_data}
        />

        <MainContainer page_title={page_title} menu="manage-contacts" breadcrumbs={true} parent_link='/contacts/list' parent_text="Manage Contacts">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-5">
                                            <div className="row">
                                                {/* Type */}
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Type <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <ButtonGroupSelection
                                                        tagClass="form-control"
                                                        id="contact_type_id"
                                                        name='contact_type_id' 
                                                        value={record_data.contact_type_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={store.contact_type}
                                                        optionProps ={{value:'id', label:'label'}}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="contact_type_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* Name */}
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Name <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="contact_name" 
                                                        name="contact_name" 
                                                        value={record_data.contact_name}
                                                        tagClass="form-control mb-0"
                                                        placeholder="Full Name"
                                                        onChange={inputChange}
                                                        className='w-100'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="contact_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* Company Name */}
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Contact Name</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="company_name" 
                                                        name="company_name" 
                                                        value={record_data.company_name}
                                                        tagClass="form-control mb-0"
                                                        placeholder="Company Name"
                                                        onChange={inputChange}
                                                        className='w-100'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="company_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* Email */}
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Email</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="email" 
                                                        name="email" 
                                                        value={record_data.email}
                                                        tagClass="form-control mb-0"
                                                        placeholder="Email"
                                                        onChange={inputChange}
                                                        type="email"
                                                        keyfilter="email"
                                                        className='w-100'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="email" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Phone No</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputMask 
                                                        id="phone_no" 
                                                        name="phone_no" 
                                                        mask="9999-999-9999" 
                                                        value={record_data.phone_no}
                                                        placeholder="0920-321-5468"
                                                        onChange={inputChange}
                                                        className='w-100'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="phone_no" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className='mb-0'>Note</label><br/>
                                                    <small>(Option only)</small>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextarea
                                                        id="notes" 
                                                        name="notes" 
                                                        value={record_data.notes}  
                                                        tagClass="form-control w-100"
                                                        onChange={inputChange}
                                                        rows={2}
                                                        className='w-100'
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header"><h5 className='mb-0'>Address Book</h5></div>
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th width={70}>Type</th>
                                                        <th>Address</th>
                                                        <th>Contact Name</th>
                                                        <th>Email</th>
                                                        <th>Phone No</th>
                                                        <th width={120}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    <tr className='bg-light'>
                                                        <td colSpan={6} className='text-center'>
                                                            <button type="button" className="btn btn-outline-primary" onClick={(e) => newAddress(1)}><i className="fas fa-plus-circle mr-2" />New</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <center>
                                            <RenderErrors 
                                                errors={errors} 
                                                name="items" 
                                                className="text-danger mb-2"
                                                style={{fontSize: '.9rem'}}
                                            />
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'> 
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(1)}>Save</button>  
                                <Link className='btn btn-outline-dark' to="/contacts/list">Cancel</Link>      
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}