import asinso_logo from '../assets/img/asinso-logo.webp';
import asinso_icon from '../assets/img/icon.jpg';
import asinso_logoTextOnly from '../assets/img/logo-text-only.webp';
import mainIcon from '../assets/img/icon.jpg';
import iconPhone from '../assets/img/icons/ico-phone.svg';
import iconMail from '../assets/img/icons/ico-mail.svg';
import iconCheck from '../assets/img/icons/ico-check.png';
import iconComment from '../assets/img/icons/ico-comment.svg';
//import donateBanner from '../assets/img/donate-banner.png';
import sslCheckout from '../assets/img/ssl-checkout.png';
import topventory_logo from '../assets/img/topventory-logo.webp'
import topventory_icon from '../assets/img/topventory-icon.webp'
import topventory_text_only from '../assets/img/topventory-text-only.webp'

import linkmo_logo from '../assets/img/apps-logo/Linkmo.co-main-logo.webp'

const images = {
    asinso_logo,
    asinso_logoTextOnly,
    asinso_icon,
    mainIcon,
    iconPhone,
    iconMail,
    iconCheck,
    iconComment,
    //donateBanner,
    sslCheckout,
    topventory_logo,
    topventory_icon,
    topventory_text_only,
    linkmo_logo
}

export default images