import React, { useEffect, useState } from 'react';
import './assets/css/adminlte.min.css';
import './assets/vendor/fontawesome-free/css/all.min.css' //
import './assets/css/custom.css'

import MainRoutes from './routes/Routes';
import initTreeView from './helpers/initTreeView';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

function App() {
  useEffect(() => {
    initTreeView()
      //Mounted
      //getDataList()
  }, []);

  return (
    <div className="App wrapper">
      <MainRoutes />
    </div>
  );
}

export default App;
