import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import store from '../../../helpers/store';
import Loader from '../../parts/Loader';
import initTreeView from '../../../helpers/initTreeView';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import RenderErrors from '../../elements/RenderErrors';
import appSession from '../../../helpers/session';
         
 
export default function StoreProfilePage (){
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [record_data, setRecordData] = useState(store.sales_order_data);
   
    useEffect(() => {
        initTreeView()

        getData()
    }, []);

    function getData(contact_id){
        const url = helpers.getUrl(`V1/manage/store/${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setRecordData(res)
            
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //window.location.replace("/contacts/list");
                }
            })
        }); 
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if(name == 'same_address'){
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
            let data = {...record_data}
            data.shipping_address_id = ''
            data.shipping_address = ''
            data.shipping_contact_name = ''
            //data.shipping_phone = ''

            if(value){
                data.shipping_address_id = data.billing_address_id
                data.shipping_address = data.billing_address
                data.shipping_contact_name = data.customer_name
                //data.shipping_phone = ''
            }
                
            setRecordData(data)

            //setSameAddress(value)
            return;
        }

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        // if(same_address && name == 'customer_name'){
        //     new_state.shipping_contact_name = value
        // }

        setRecordData({ ...record_data, ...new_state });
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.name){
            new_errors['name'] = ['The name field is required.']
        }
        else{
            delete new_errors['name'];
        }

        if(!record_data.business_name){
            new_errors['business_name'] = ['The business name field is required.']
        }
        else{
            delete new_errors['business_name'];
        }

        if(!record_data.slug){
            new_errors['slug'] = ['The slug field is required.']
        }
        else{
            delete new_errors['slug'];
        }

        if(!record_data.email){
            new_errors['email'] = ['The email field is required.']
        }
        else{
            delete new_errors['email'];
        }

        if(!record_data.email){
            new_errors['email'] = ['The email field is required.']
        }
        else{
            delete new_errors['email'];
        }

        if(!record_data.address){
            new_errors['address'] = ['The address field is required.']
        }
        else{
            delete new_errors['address'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function submit(e){
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        let url = helpers.getUrl(`V1/manage/store/${helpers.getStoreID()}`)

        let form_data = {...record_data}
        if(record_data.phone_no){
            form_data.phone_no = helpers.removeString(record_data.phone_no)
        }

        setLoading(1)
        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                appSession.setSessionItem('asinso-default-store', JSON.stringify(record_data))

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <>
        <MainContainer page_title="Store Profile" breadcrumbs={true}>
            <div className="container-fluid text-center" style={{position: 'relative'}}>
                <form>
                    <Loader isLoading={loading}/>


                    <div className="card">
                        <TabView onTabChange={(e) => navigate((store.store_target[e.index]))}  className='p-tabview-custom'>
                            <TabPanel header="Profile" className='text-left'>
                                <div className='p-3'>
                                    <div className='row mb-1 mt-md-2'>
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                        <label>Display Name <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputText
                                                id="name" 
                                                name="name" 
                                                value={record_data.name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder=""
                                                type="text"
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-1 mt-md-2'>
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                        <label>Business Name <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputText
                                                id="business_name" 
                                                name="business_name" 
                                                value={record_data.business_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder=""
                                                type="text"
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="business_name"
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-1 mt-md-2'>
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                        <label>URL Slug <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputText
                                                id="slug" 
                                                name="slug" 
                                                value={record_data.slug}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder=""
                                                type="text"
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="slug" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-1 mt-md-2'>
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                        <label>Email <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputText
                                                id="email" 
                                                name="email" 
                                                value={record_data.email}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                placeholder=""
                                                type="email"
                                                keyfilter="email"
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="email" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Email */}
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                            <label>Phone No</label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputMask 
                                                id="phone_no" 
                                                name="phone_no" 
                                                mask="9999-999-9999" 
                                                value={record_data.phone_no}
                                                placeholder="0920-321-5468"
                                                onChange={inputChange}
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="phone_no" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-lg-2 text-left text-md-right">
                                            <label>Address <span className="text-danger">*</span></label>
                                        </div>
                                        <div className="col-md-5 col-lg-3 form-group">
                                            <InputTextarea
                                                id="address" 
                                                name="address" 
                                                value={record_data.address}  
                                                tagClass="form-control w-100"
                                                onChange={inputChange}
                                                rows={2}
                                                className='w-100'
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="address" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Warehouse" />
                            <TabPanel header="Delivery Address" />
                        </TabView>
                    </div>

                    <div className='text-right mt-3'> 
                        <button type='button' className='btn btn-success' onClick={(e) => submit(e)}>Save</button>  
                    </div>
                </form>
            </div>
        </MainContainer>
        </>
    )
}