import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import Loader from '../../parts/Loader';
import initTreeView from '../../../helpers/initTreeView';
 
export default function PageSettings (){
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        initTreeView()
    }, []);


    return (
        <>
        <MainContainer page_title="Settings" menu="settings" breadcrumbs={true}>
            <div className="container" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <div className="row d-flex">
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-maroon card-outline w-100">
                            <div className="card-header">
                                <strong>Item</strong>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <Link to="categories" className="list-group-item list-group-item-action">Category</Link>
                                    <Link to="variant-types" className="list-group-item list-group-item-action">Variant Types</Link>
                                    <Link to="brands" className="list-group-item list-group-item-action">Brands</Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-success card-outline w-100">
                            <div className="card-header">
                                <strong>Transaction</strong>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <Link to="tax-rates" className="list-group-item list-group-item-action">Tax Rates</Link>
                                    <Link to="couriers" className="list-group-item list-group-item-action">Couriers</Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-success card-outline w-100">
                            <div className="card-header">
                                <strong>Sales Order</strong>
                            </div>
                            <div className="card-body">
                                <Link to="sales" className="list-group-item list-group-item-action">All Sales Order List</Link>
                                <Link to="sales?filter=customer" className="list-group-item list-group-item-action">Sales Order By Customer</Link>
                                <Link to="sales?filter=dates" className="list-group-item list-group-item-action">Sales Order By Dates</Link>
                                <Link to="sales?filter=invoice_status" className="list-group-item list-group-item-action">Sales Order by Invoice Status</Link>
                                <Link to="sales?filter=payment_term" className="list-group-item list-group-item-action">Sales Order by Payment Terms</Link>
                                <Link to="sales?filter=status" className="list-group-item list-group-item-action">Sales Order By Status</Link>
                                <Link to="sales?filter=sales_channel" className="list-group-item list-group-item-action">Sales Order By Sales Channel</Link>
                                <Link to="sales/top-items" className="list-group-item list-group-item-action">Top Sold Items</Link>
                                <Link to="sales/pending-deliveries" className="list-group-item list-group-item-action">Pending Delivery</Link>
                                <Link to="sales-return" className="list-group-item list-group-item-action">Sales Return</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-purple card-outline w-100">
                            <div className="card-header">
                                <strong>Invoice</strong>
                            </div>
                            <div className="card-body">
                                <Link to="invoices" className="list-group-item list-group-item-action">All Invoices List</Link>
                                <Link to="invoices?filter=customer" className="list-group-item list-group-item-action">Invoices By Customer</Link>
                                <Link to="invoices?filter=dates" className="list-group-item list-group-item-action">Invoices By Dates</Link>
                                <Link to="invoices?filter=status" className="list-group-item list-group-item-action">Invoices by Status</Link>
                                <Link to="invoices?filter=payment_term" className="list-group-item list-group-item-action">Invoices by Payment Terms</Link>
                                <Link to="invoices?filter=sales_channel" className="list-group-item list-group-item-action">Invoices By Sales Channel</Link>
                                <Link to="invoices?filter=overdue" className="list-group-item list-group-item-action">Overdue Invoices</Link>
                                <Link to="payments/received" className="list-group-item list-group-item-action">Payment Received</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-primary card-outline w-100">
                            <div className="card-header">
                                <strong>Purchases Order</strong>
                            </div>
                            <div className="card-body">
                                <Link to="purchases" className="list-group-item list-group-item-action">All Purchase Order List</Link>
                                <Link to="purchases?filter=vendor" className="list-group-item list-group-item-action">Purchase Order By Vendor</Link>
                                <Link to="purchases?filter=dates" className="list-group-item list-group-item-action">Purchase Order By Dates</Link>
                                <Link to="purchases?filter=payment_status" className="list-group-item list-group-item-action">Purchase Order by Payment Status</Link>
                                <Link to="purchases?filter=payment_term" className="list-group-item list-group-item-action">Purchase Order by Payment Terms</Link>
                                <Link to="purchases?filter=status" className="list-group-item list-group-item-action">Purchase Order By Status</Link>
                                <Link to="purchases/top-items" className="list-group-item list-group-item-action">Top Purchased Items</Link>
                                <Link to="purchases/receivables" className="list-group-item list-group-item-action">Receivable Items</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-orange card-outline w-100">
                            <div className="card-header">
                                <strong>Bills</strong>
                            </div>
                            <div className="card-body">
                                <Link to="bills" className="list-group-item list-group-item-action">All Bills List</Link>
                                <Link to="bills?filter=vendor" className="list-group-item list-group-item-action">Bills By Vendor</Link>
                                <Link to="bills?filter=dates" className="list-group-item list-group-item-action">Bills By Dates</Link>
                                <Link to="bills?filter=status" className="list-group-item list-group-item-action">Bills by Status</Link>
                                <Link to="bills?filter=payment_term" className="list-group-item list-group-item-action">Bills by Payment Terms</Link>
                                <Link to="bills?filter=overdue" className="list-group-item list-group-item-action">Overdue Bills</Link>
                                <Link to="payments/made" className="list-group-item list-group-item-action">Payment Made</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex">
                        <div className="card card-navy card-outline w-100">
                            <div className="card-header">
                                <strong>Miscellaneous</strong>
                            </div>
                            <div className="card-body">
                                <Link to="contacts" className="list-group-item list-group-item-action">Contact List</Link>
                                <Link to="vendors" className="list-group-item list-group-item-action">Vendor List</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </MainContainer>
        </>
    )
}