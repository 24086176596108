const recaptchakey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const base_root = window.location.host

let apiUrl = '';
if (base_root === "app.asinso.com" || base_root === "www.asinso.com" || base_root === "app.asinso.net" || base_root === "asinso.net" || base_root === "www.asinso.net" || base_root === "app.topventory.com") {
      apiUrl = process.env.REACT_APP_API_PROD_URL;
}
else if (base_root === "beta.asinso.com" || base_root === "beta-app.asinso.com" || base_root === "dev.asinso.com" || base_root === "beta.asinso.net" || base_root === "beta-app.asinso.net" || base_root === "dev.asinso.net" || base_root === "beta.topventory.com") {
      apiUrl = process.env.REACT_APP_API_BETA_URL;
}
else if (base_root === "demo.asinso.com" || base_root === "demo-app.asinso.com" || base_root === "demo.asinso.net" || base_root === "demo-app.asinso.net" || base_root === "demo.topventory.com") {
      apiUrl = process.env.REACT_APP_API_DEMO_URL;
}
else{
      apiUrl = process.env.REACT_APP_API_DEV_URL;
}

const appConfig = {
      apiUrl,
      recaptchakey
}

export default appConfig;