import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Loader from '../../parts/Loader';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import initTreeView from '../../../helpers/initTreeView';
import DropDownSelection from '../../elements/DropDownSelection';

import ContactListModal from '../../modals/ContactListModal';
import { Accordion, AccordionTab } from 'primereact/accordion';
 
export default function ReportPaymentReceived (){
    const [data_loaded, setDataLoaded] = useState(0);
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [page_title, setPageTitle] = useState('Payment Received Report');
    const [customer_modal, setContactModal] = useState(0);
    const [enums, setEnums] = useState([]);
    const [filter_by, setFilterBy] = useState('');
    const [filters, setFilters] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        customer_name: '',
        customer: '',
        payment_status: '',
        payment_status: '',
        payment_method: '',
        status: '',
        sales_channel: '',
        filter: '',
        dates: []
    });

    useEffect(() => {
        initTreeView()
        getEnums()
        getDataList()

        let p_title = page_title;
        let filter = helpers.getURLParams('filter') ?? ''
        if(filter) {
            if(filter === 'top-items'){
                p_title = 'Top Sold Items Report'
            }
            else if(filter === 'pending-delivery'){
                p_title = 'Pending Deliveries Report'
            }
            setPageTitle(p_title)

            let value = ''
            if(filter === 'top-items' || filter === 'pending-delivery'){
                value = filter
                filter = 'filter'
            }
            else if(filter === 'dates'){
                var date = new Date();
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                value = [firstDay, lastDay]
            }
            

            setFilterBy(filter)
            let new_state = { [filter] : value }
            setFilters({ ...filters, ...new_state });
        }
    }, []);

    useEffect(() => {
        if(filters.customer || filters.payment_status || filters.invoice_status || filters.payment_method || filters.status || filters.sales_channel || filters.filter || filters.dates){
            getDataList()
        }
    }, [
        filters.customer,
        filters.payment_status,
        filters.invoice_status,
        filters.payment_method,
        filters.status,
        filters.sales_channel,
        filters.filter,
        filters.dates
    ]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [filters.sortOrder]);

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": '',
        "product_type": ''
    }){
        const page = condition.page ?? filters.page
        const per_page = condition.per_page ?? filters.rows
        const search = condition.search ?? search_key

        let filter = '?store_id='+helpers.getStoreID()+'&'

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(search){
            filter += 'search='+search+'&'
        }

        if(filters.customer){
            filter += 'customer='+filters.customer+'&'
        }
        if(filters.payment_status){
            filter += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.invoice_status){
            filter += 'invoice_status='+filters.invoice_status+'&'
        }
        if(filters.payment_method){
            filter += 'payment_method='+filters.payment_method+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            filter += 'sales_channel='+filters.sales_channel+'&'
        }
        if(filters.filter){
            filter += 'filter='+filters.filter+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                filter += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                filter += 'order_to='+order_to+'&'
            }

        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/reports/payment-received'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setRecordData(res)
                setLoading(0)
                displayDataTable(res)
                setDataLoaded(1)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function getEnums(){
        setLoading(1)
        const url = helpers.getUrl('V1/enums/orders?store_id='+helpers.getStoreID())
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setEnums(res)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function exportReport(){
        const page = filters.page ?? ''
        const per_page = filters.rows ?? ''
        const search = search_key ?? ''
        const product_type = filters.product_type

        let filter = '?store_id='+helpers.getStoreID()+'&export=excel&'

        // if(page){
        //     filter += 'page='+page+'&'
        // }
        // if(per_page){
        //     filter += 'per_page='+per_page+'&'
        // }
        if(search){
            filter += 'search='+search+'&'
        }

        if(filters.customer){
            filter += 'customer='+filters.customer+'&'
        }
        if(filters.payment_status){
            filter += 'payment_status='+filters.payment_status+'&'
        }
        if(filters.invoice_status){
            filter += 'invoice_status='+filters.invoice_status+'&'
        }
        if(filters.payment_method){
            filter += 'payment_method='+filters.payment_method+'&'
        }
        if(filters.status){
            filter += 'status='+filters.status+'&'
        }
        if(filters.sales_channel){
            filter += 'sales_channel='+filters.sales_channel+'&'
        }
        if(filters.filter){
            filter += 'filter='+filters.filter+'&'
        }
        if(typeof filters.dates[0] !== 'undefined' || typeof filters.dates[1] !== 'undefined'){
            const order_from = helpers.formatDate(filters.dates[0])
            if(order_from){
                filter += 'order_from='+order_from+'&'
            }

            const order_to = filters.dates[1] ? helpers.formatDate(filters.dates[1]) : ''
            if(order_to){
                filter += 'order_to='+order_to+'&'
            }

        }

        filter = filter.slice(0, -1)

        setLoading(1)
        const today = new Date();
        const month = today.getMonth() + 1;
        const date = today.getFullYear() + '-' + month + '-' + today.getDate() + '_' +  today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
        const file_name = 'payment-received-export-reports-' + date + '.xlsx';


        const url = helpers.getUrl('V1/reports/payment-received'+filter)
        
        fetch(url, {
            method: 'GET',
            headers: httpAuthHeaders(),
        })
        .then((res) => {
          if(res.ok) {
            return res.blob();
          }
          
          const json = res.json();
          const status_code = res.status;
  
          if (!res.ok) {
              let errorCode = json.error_code ? json.error_code : 'unknown';
              let message = json.message ? json.message : (res.statusText ? res.statusText : 'Request failed');
              let data = json.error ? json.error: '';
              let status = json.status ? json.status: '';
              let error = { data: data, message: message, error_code: errorCode, status_code: status_code, status: status };
  
              if (status_code === 422) error = { ...error, ...json };
  
              return Promise.reject(error);
          }
        })
        .then(blob => {
            
            Swal.fire("Export Completed", "Export successfully completed. Please check your downloads.", "success");

            var url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.setAttribute("href", url);
            a.download = file_name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.parentNode.removeChild(a);
            setLoading(0);
        })
        .catch(err => {
          Swal.fire("Error", "Export failed", "error");
          setLoading(0);
        });
    }

    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function inputDateChange(e){
        let { value, name } = e.target;
        let new_state = { 'dates': value }

        if(!value){
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            new_state = { 'dates': [firstDay, lastDay] }
        }
        setFilters({ ...filters, ...new_state });
    }

    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": filters.page + 1,
            "search": value
        })
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                dataKey={data.product_variant_id}
                value={data.data} 
                size="small"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={filters.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                scrollHeight="700px"
                scrollable
                stripedRows
                resizableColumns
                emptyMessage="No data to diplay"
            >
                <Column 
                    header="ID"
                    field="id" 
                    style={{ width: '100px' }}  
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Payment Date" 
                    field="payment_date" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Payment #" 
                    field="payment_no" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="invoice_no" 
                    header="Invoice #"
                    headerStyle={{ background: '#f9fafb' }} 
                    body={({invoice_no})=> invoice_no ? invoice_no : ''}
                 />
                 <Column 
                     field="reference_no" 
                     header="Reference #"
                     headerStyle={{ background: '#f9fafb' }} 
                     body={({reference_no})=> reference_no ? reference_no : ''}
                  />
                <Column 
                    field="customer_name" 
                    header="Customer" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Payment" 
                    field="payment" 
                    body={({payment})=> payment ? getPrice(payment) : ''}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="payment_method" 
                    header="Method" 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    field="notes" 
                    header="Notes" 
                    body={({notes})=> notes ? notes : ''}
                    headerStyle={{ background: '#f9fafb' }}
                />
            </DataTable>
        )

    }

    const onPage = (e) => {
        setFilters(e)
        //setSelectedItems([])
    };

    function getPrice(data){
        return <>&#8369; {helpers.formatAmount(data)}</>
    }

    function handleCustomer(e){
        let new_state = { 
            'customer' : e.id,
            'customer_name': e.contact_name
        }
        setFilters({ ...filters, ...new_state });
    }

    return (
        <>
        <ContactListModal is_show={customer_modal} setShow={setContactModal} handleSelect={handleCustomer} />
        <MainContainer page_title={page_title} menu="reports" parent_link='/reports' parent_text="Reports">
            <div className="container-fluid">
                <div className='mb-4'>
                    <Link to="/reports" className="btn btn-outline-dark border"><i className="fas fa-arrow-left mr-2" /> Reports</Link>
                    <button type="button" className='btn bg-purple text-right float-right' onClick={exportReport} disabled={!data_loaded}><i className="fas fa-file-excel mr-2" />Export</button>
                </div>
            </div>
            
            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>

                <Accordion>
                    <AccordionTab header="Filters" className="text-dark" unstyled={true}>
                        <div className="row">
                            <div className="col-md-4 col-lg-3 mb-2">
                                <label>Customer</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Select Customer" onClick={(e) => setContactModal(1)} value={filters.customer_name} readOnly style={{backgroundColor: '#fff'}} />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button" onClick={(e) => setContactModal(1)}><i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 mb-2">
                                <DropDownSelection
                                    tagClass="form-control"
                                    id="payment_method"
                                    name='payment_method' 
                                    placeholder='Select Payment Method'
                                    value={filters.payment_method}  
                                    onChange={inputChange}
                                    onFocus=""
                                    optionList={enums.payment_methods}
                                    optionProps ={{value:'id', label:'method_name'}}
                                    label={<>Payment Method</>}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3 mb-2">
                                <label>Search</label>
                                <input type="search" className="form-control" placeholder="Search" onChange={search} />
                            </div>
                            <div className="col-md-4 col-lg-3 mb-2">
                                <label>Order Dates</label><br/>
                                <Calendar 
                                    value={filters.dates} 
                                    onChange={inputDateChange} 
                                    dateFormat="yy-mm-dd"
                                    selectionMode="range" 
                                    readOnlyInput 
                                    hideOnRangeSelection 
                                    className='w-100'
                                    showButtonBar
                                    placeholder='Payment Date Range'
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <div className="card card-success card-outline mt-3">
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
        </>
    )
}