import React, { useEffect, useState } from 'react';
import MainContainer from '../../parts/MainContainer';
import { httpRequest } from '../../../helpers/httpService';
import helpers from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Loader from '../../parts/Loader';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropDownSelection from '../../elements/DropDownSelection';
import Dropdown from 'react-bootstrap/Dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import initTreeView from '../../../helpers/initTreeView';
 
export default function InventoryAdjustmentList ({viewer = 'admin', menu = 'manage-inventory-adjustment', page_title = "Manage Inventory Adjustment"}){
    const [record_data, setRecordData] = useState([]);
    const [loading, setLoading] = useState(1);
    const [search_key, setSearchKey] = useState('');
    const [prompt_modal, setPromptModal] = useState(0);
    const [prompt_title, setPromptTitle] = useState('');
    const [prompt_caption, setPromptCaption] = useState('');
    const [prompt_error, setPromptError] = useState('');
    const [reasons_list, setResons] = useState();
    const [selected_reason, setSelectedReason] = useState('');
    const [data_table, setDataTable] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [selected_all, setSelectAll] = useState(false);
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });
    const [filters, setFilters] = useState({
        type_id: 0,
        search: ""
    });

    useEffect(() => {
        initTreeView()
        getEnums()
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        selected_all
    ]);

    useEffect(() => {
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            //"search": search_key
        })
    }, [datatable_state, filters]);
    
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setFilters({ ...filters, ...new_state });
    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl('V1/enums/orders?only=cancellation_reasons')
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setResons(res.cancellation_reasons)
            //setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function getDataList(condition = {
        "page": '',
        //"search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        const search = condition.search ?? search_key

        let filter = '?store_id='+helpers.getStoreID()+'&'

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(filters.search){
            filter += 'search='+filters.search+'&'
        }
        if(filters.type){
            filter += 'type='+filters.type+'&'
        }

        filter = filter.slice(0, -1)

        setLoading(1)

        const url = helpers.getUrl('V1/manage/item/adjustment/list'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                //setAdjustmentList(res.data)
                setRecordData(res)
                setLoading(0)
                //table_items(res.data)
                displayDataTable(res)
            })
            .catch((err) => {
                setLoading(0)

                Swal.fire({
                    title: "Error!",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }
    

    // function search(e){
    //     let { value } = e.target;
    //     setSearchKey(value)
    //     getDataList({
    //         "page": datatable_state.page + 1,
    //         "search": value
    //     })
    // }

    function triggerAction(data = '', action = '', status = ''){
        let ids = [];
        if(action === 'single-delete'){
            ids.push(data.id)
        }
        else{
            ids = data.map(o => o['id']);
        }

        let title = 'Delete Item'
        if(ids.length > 1){
            title = 'Delete Items'
        }

        Swal.fire({
            title: title,
            text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
            showCancelButton: true,
            showConfirmButton: false,
            closeOnCancel: true,
            showDenyButton: true,
            denyButtonText: 'Yes'
        })
        .then((result) => {
            if (result.isDenied) {
                deleteItem(ids)
            }
        })
    }

    function closeModal(){
        setPromptModal(0)
        setPromptTitle('')
        setPromptCaption('')
        setSelectedReason('')
        setPromptError('')
    }

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/item/adjustment/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    "search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])
                
                if(err.data){
                    //setErrors( err.data );
                }else{
                    //setErrors([])
                }

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                dataKey={data.id}
                value={data.data} 
                tableStyle={{ minWidth: '50rem' }}
                size="normal"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollHeight="700px"
                scrollable
                emptyMessage="No data to diplay"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="adjustment_date" header="DATE" ></Column>
                <Column field="type_id" header="TYPE" body={({type_id})=> type_id == 1 ? 'Stock Adjustment' : 'Price Adjustment'}></Column>
                <Column field="reasons" header="REASONS"></Column>
                <Column field="description" header="DESCRIPTION" body={({description})=> description ? description : '---'}></Column>
                <Column field="reference_no" header="REFERENCE&nbsp;#" body={({reference_no})=> reference_no ? reference_no : '---'}></Column>
                <Column body={displayActionMenu} ></Column> 
            </DataTable>
        )

    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function displayActionMenu(data){
        return (<Dropdown>
                        <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item key="edit" as={Link} to={`/inventory-adjustment/${data.id}/edit`}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>)
    }

    return (
        <MainContainer page_title={page_title} menu={menu}>
            
            <Modal show={prompt_modal}>
                <Modal.Header>
                    <h5>{prompt_title}</h5>
                    <Button variant="danger" size="sm" onClick={closeModal}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 form-group">
                            <DropDownSelection
                                tagClass="form-control"
                                id="selected_reason"
                                name='selected_reason' 
                                placeholder='Select Cancellation Reason'
                                value={selected_reason}  
                                onChange={(e) => setSelectedReason(e.target.value)}
                                onFocus=""
                                optionList={reasons_list}
                                optionProps ={{value:'id', label:'reason'}}
                                label={<><span className='text-danger'>*</span> Cancellation Reason</>}
                            />
                            <div className='text-danger' style={{fontSize: '.9rem'}}>{prompt_error}</div>
                            <p className='mt-3 mb-0'>{prompt_caption}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={deleteItem}>
                        Proceed
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container-fluid" style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                <div className="card">
                    <div className="card-header border-bottom-0">
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className="row">
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                        <DropDownSelection
                                            tagClass="form-control"
                                            id="type"
                                            name='type' 
                                            placeholder='Select Type'
                                            value={filters.type}  
                                            onChange={inputChange}
                                            onFocus=""
                                            optionList={[ 
                                                {"label": "All Types", "id": 0}, 
                                                {"label": "Stock", "id": 1}, 
                                                {"label": "Price", "id": 2}, 
                                             ]}
                                            optionProps ={{value:'id', label:'label'}}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" name="search" className="form-control" placeholder="Search" onChange={inputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-right mt-3 mt-md-0">
                                <Link className='btn btn-primary float-right' to="/inventory-adjustment/form"><i className="fas fa-plus-circle mr-2" />New</Link>
                                
                                {selected_items.length ?
                                <Dropdown className='float-right mr-3'>
                                    <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items)}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { data_table }
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}