import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

import InputText from '../../elements/InputText';
import InputNumber from '../../elements/InputNumber';
import InputTextArea from '../../elements/InputTextArea';
import InputCheckbox from '../../elements/InputCheckbox';
import ButtonGroupSelection from '../../elements/ButtonGroupSelection';
import RenderErrors from '../../elements/RenderErrors';
import DropDownSelection from '../../elements/DropDownSelection';
import Dropdown from 'react-bootstrap/Dropdown'

import VendorModal from '../../modals/VendorModal';
import AddressBookModal from '../../modals/AddressBookModal';
import ProductFinderModal from '../../modals/ProductFinderModal';
import orderHelper from '../../../helpers/orderHelper';
import initTreeView from '../../../helpers/initTreeView';
 
export default function PurchaseOrderFormV1 (){
    const [loading, setLoading] = useState(1);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    
    const [page_title, setPageTitle] = useState('New Purchase Order');
    const [order_id, setOrderID] = useState(0);
    const [order_data, setOrder] = useState(store.purchases_order_data);
    const [order_items, setOrderItems] = useState([]);
    const [table_items, setTableItems] = useState([]);

    const [vendor_data, setVendor] = useState(store.so_vendor_data);
    const [shipping_address, setAddress] = useState(store.so_vendor_data);
    const [enums, setEnums] = useState(store.order_enums);
    const [vendor_modal, setVendorModal] = useState(0);

    /** Modals */
    const [address_modal, setAddressModal] = useState(0);
    const [address_header_title, setAddressTitle] = useState('Search Address');
    const [address_src, setAddressSrc] = useState(0);

    const [address_target, setAddressTarget] = useState('');

    const [product_modal, setProductModal] = useState(0);

    const [selected_vendor_record, setVendorRecord] = useState('new');
    const [same_address, setSameAddress] = useState(0);
    
   
    useEffect(() => {
        initTreeView()
        const id = helpers.getURLParams('OrderID') ?? ''

        if(id){
            setPageTitle('Purchase Order Details')
            setOrderID(id)
            getData(id)
        }
        else{
            getEnums()
        }

        setTableItems(
            <tr>
            <td colSpan={5} className='text-center'>No item found.</td>
            </tr>
        )
    }, []);

    useEffect(() => {
        displayTableItems()
        computeTotals();
    }, [order_items]);

    useEffect(() => {
        const shipping_status_id = order_data.shipping_status_id

        Object.entries(order_items).map(([key, data]) => {

        })
    }, [order_data.shipping_status_id]);

    function getData(order_id){
        if(!order_id){
            return
        }
        const url = helpers.getUrl('V1/manage/order/purchase/'+order_id+'?store_id='+helpers.getStoreID())

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setOrder(res)
            setOrderItems(res.items)
            setVendorRecord('existing')
            getEnums()
        })
        .catch((err) => {
            setLoading(0)
            getEnums()

            Swal.fire({
                text: err.message,
                //text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //return navigate("/item/list");
                    window.location.replace("/purchases/list");
                }
            })
        }); 
    }

    function computeTotals(type){
                
        let items = 0;
        let total_amount = 0
        Object.entries(order_items).map(([key, data]) => {
            items = items + Number(data.quantity)
            total_amount = total_amount + parseInt(data.quantity) * parseFloat(data.unit_price)
        });

        total_amount = total_amount + parseFloat(order_data.shipping_fee)

        if(type == 'quantity'){
            return items;
        }

        if(type == 'total'){
            return helpers.formatAmount(total_amount);
        }

    }

    function displayTableItems(){
        let items = []
        
        if (order_items.length) {
            items = Object.entries(order_items).map(([key, data]) => {
                let total = parseInt(data.quantity) * parseFloat(data.unit_price)
                total = <>&#8369;{helpers.formatAmount(total)}</>
                return(
                <tr>
                    <td>{data.product_variant_id}</td>
                    <td>{data.product_name} ({data.product_variant_name})</td>
                    {order_id ? <td>{data.received_quantity ? data.received_quantity : '0'}</td> : ''}
                    <td>
                        <InputNumber
                            id="quantity" 
                            name="quantity" 
                            value={data.quantity}
                            tagClass="form-control mb-0"
                            onChange={(e) => inputItemChange(e, key)}
                            placeholder="Qty"
                            style={{maxWidth: '80px'}}
                            readOnly={order_data.order_status_id == 3 || order_data.order_status_id == 4}
                        />
                    </td>
                    <td>
                        <InputText
                            id="unit_price" 
                            name="unit_price" 
                            value={data.unit_price}
                            tagClass="form-control mb-0"
                            onChange={(e) => inputItemChange(e, key)}
                            placeholder="Vendor Price"
                            readOnly={order_data.order_status_id == 3 || order_data.order_status_id == 4}
                        />
                    </td>
                    <td>{total}</td>
                    <td>{order_data.order_status_id === 1 ? <button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button>: ''}</td>
                </tr>)

            })
        }

        setTableItems(items)
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...order_items]
        let target = {...items[key]}

        const available_stock = target['available_stock']

        // if(parseInt(value) > parseInt(available_stock)){
        //     return;
        // }
        target[name] = value
        items[key] = target
        setOrderItems(items)
    }

    function removeItem(key){
        let items = [...order_items]
        items.splice(key, 1); 

        setOrderItems(items)
    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl('V1/enums/orders')
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }
    
    function inputChange(e,) {
        let { value, name } = e.target;

        if(name == 'same_address'){
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
            let data = {...order_data}
            data.shipping_address_id = ''
            data.shipping_address = ''
            data.shipping_contact_name = ''
            data.shipping_phone = ''
                
            setOrder(data)

            setSameAddress(value)
            return;
        }

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        if(same_address && name == 'vendor_name'){
            new_state.shipping_contact_name = value
        }

        setOrder({ ...order_data, ...new_state });
    }

    function handleVendor(e){
        let data = {...order_data}

        data.vendor_id = e.id ?? ''
        data.vendor_name = e.vendor_name ?? ''
        data.vendor_contact_name = e.contact_name ?? ''
        data.vendor_address = e.vendor_address ?? ''
        data.vendor_phone = e.phone_no ?? ''

        setVendor(e)
        setOrder(data)
    }

    function handleVendorType(e){
        let { value, name } = e.target;
        setVendorRecord(value)

        if(value == 'existing'){
            setVendorModal(1)
            return;
        }

        let data = {...order_data}
        data.vendor_id = ''
        data.vendor_name = ''
        data.vendor_address = ''
        data.vendor_contact_name = ''
        data.vendor_email = ''
        data.vendor_phone = ''

        setVendor([])
        setOrder(data)
        setVendorRecord(value)
    }

    function viewAddressBook(type = ''){
        setAddressTitle('Search Delivery Address')
        setAddressSrc('delivery')
        //setAddressTarget(type)
        setAddressModal(1)
    }

    function handleAddress(e){
        let data = {...order_data}

        data.shipping_address_id = e.id
        data.shipping_address = e.address
        data.shipping_contact_name = e.contact_name
        data.shipping_phone = e.contact_phone
        
        setOrder(data)
        setAddress(e)
    }

    function handleProduct(e){
        let items = [...order_items]

        let new_item = {
            "product_id": e.id,
            "product_code": e.product_code,
            "unit_price": e.product_cost,
            "product_name": e.product_name,
            "product_variant_id": e.product_variant_id,
            "product_variant_name": e.product_variant_name,
            "quantity": 1,
            "available_stock": e.available_stock,
            "shipping_status_id": order_data.shipping_status_id
        }

        items.push(new_item)

        setOrderItems(items)
    }

    function submit(){
        setLoading(1)

        let url = helpers.getUrl('V1/manage/order/purchase/create')
        if(order_id){
            url = helpers.getUrl('V1/manage/order/purchase/' + order_id)
        }

        let form_data = {...order_data}
        form_data['items'] = order_items;
        form_data['vendor_record'] = selected_vendor_record;
        // form_data['product_status'] = product_status;

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = order_id;
                if(!order_id){
                    id = res.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/purchases/form?OrderID="+ id);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function triggerAction(action = '', id = '', order_no = ''){
        if(!action) return;

        if(action === 'open'){
            updateStatusItem(id, 1)
        }
        else if(action === 'confirm'){
            Swal.fire({
                title: 'Order Confirmation',
                text: `You confirm that the vendor has already received this purchase order no: ${order_no}.`,
                showConfirmButton: true,
                showCancelButton: true,
                showDenyButton: false,
                closeOnCancel: true,
                confirmButtonText: 'Yes'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    updateStatusItem(id, 2) //2 = Confirm
                }
            })
        }
    }

    function updateStatusItem(id, status_id){
        const url = helpers.getUrl('V1/manage/order/purchase/status')
        httpRequest(url, 'POST', {
            postdata: {
                "order_id": id,
                "store_id": helpers.getStoreID(),
                "status_id": status_id
            },
        }, true)
            .then((res) => {
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                // setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <>
        <VendorModal is_show={vendor_modal} setShow={setVendorModal} handleSelect={handleVendor} />

        <AddressBookModal 
            vendor_id={order_data.vendor_id} 
            is_show={address_modal} 
            setShow={setAddressModal}  
            handleSelect={handleAddress} 
            header_title={address_header_title}
            src={address_src}
            store_id = {helpers.getStoreID()}
        />

        <ProductFinderModal 
            vendor_id={order_data.vendor_id} 
            is_show={product_modal} 
            setShow={setProductModal}  
            handleSelect={handleProduct} 
            exclude={order_items} 
            restrict_stock={false}
            show_price = {false}
            show_cost = {true}
        />

        <MainContainer page_title={page_title} menu="manage-purchases" breadcrumbs={true} parent_link='/purchases/list' parent_text="Manage Purchases Order">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <form>
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div id="so-customer-section" className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='float-right mb-0 d-flex align-items-center'>
                                                {order_id && order_data.order_status_id === 1 ? 
                                                    <button type='button' className='btn btn-outline-success mr-3' onClick={(e) => triggerAction('confirm', order_data.id, order_data.order_no)}><i className="fas fa-check-circle mr-2" />Confirm Order</button>
                                                : ''}

                                                
                                                <h5 className='mb-0'>{helpers.displayOrderStatusBadge(order_data.order_status_id, order_data.order_status)}</h5>
                                            </div>

                                            <h5 className='mb-0'>
                                                Details
                                            </h5>
                                        </div>
                                        <div className="card-body">

                                            {order_data.order_no ?
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>PO #</label>
                                                </div>
                                                <div className="col-md-3 col-lg-2 form-group">
                                                <input type="text" className="form-control" placeholder="Sales Order No" value={order_data.order_no} readOnly />
                                                </div>
                                            </div> : ''}

                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Vendor <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-4 form-group">
                                                    <div className="input-group">
                                                        {
                                                            order_id ? <input type="text" className="form-control" placeholder="Select Vendor" value={order_data.vendor_name} disabled /> :
                                                            <input type="text" className="form-control" placeholder="Select Vendor" onClick={(e) => setVendorModal(1)} value={order_data.vendor_name} />
                                                        }

                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={(e) => setVendorModal(1)} disabled={order_id}><i className="fas fa-search" /></button>
                                                        </div>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="vendor_name" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">

                                                </div>
                                                <div className="col-md-5 col-lg-4 form-group">
                                                    <ButtonGroupSelection
                                                        tagClass="form-control"
                                                        id="shipping_type_id"
                                                        name='shipping_type_id' 
                                                        value={order_data.shipping_type_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={enums.shipping_types}
                                                        optionProps ={{value:'id', label:'shipping_type'}}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="shipping_type_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-3 col-lg-2"></div>
                                                <div className="col-md-4 col-lg-4 mb-3 mb-md-0">
                                                    <div className='border p-2 bg-light'>
                                                        <div>
                                                            <p className='mb-0'>{order_data.vendor_contact_name ? <b>{order_data.vendor_contact_name}</b> :''}</p>
                                                            <p className='mb-0'>{order_data.vendor_address ? order_data.vendor_address : '---'}</p>
                                                            <p className='mb-0'>{order_data.vendor_phone ? order_data.vendor_phone : ''}</p>
                                                        </div>
                                                        {/* <button type="button" className="btn btn-success btn-xs mt-2" onClick={(e) => viewAddressBook(2)}>Billing Address</button> */}
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-4 col-lg-4">
                                                    <div className='border p-2 bg-light'>
                                                        <div>
                                                            <p className='mb-0'>{order_data.shipping_contact_name ? <b>{order_data.shipping_contact_name}</b>:''}</p>
                                                            <p className='mb-0'>{order_data.shipping_address ? order_data.shipping_address : '---'}</p>
                                                            <p className='mb-0'>{order_data.shipping_phone ? order_data.shipping_phone : ''}</p>
                                                        </div>
                                                        <button type="button" className="btn btn-success btn-sm mt-2" onClick={(e) => viewAddressBook('shipping')}>Delivery Address</button>
                                                    </div>
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="shipping_address" 
                                                        className="text-danger mb-2"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Order Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="order_date" 
                                                        name="order_date" 
                                                        value={order_data.order_date}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        placeholder="Order Date"
                                                        type="date"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="order_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Expected Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="expected_date" 
                                                        name="expected_date" 
                                                        value={order_data.expected_date}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        placeholder="Expected Delivery Date"
                                                        type="date"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="expected_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Payment Terms <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="payment_term_id"
                                                        name='payment_term_id' 
                                                        placeholder='Terms'
                                                        value={order_data.payment_term_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={enums.payment_terms}
                                                        optionProps ={{value:'id', label:'payment_term'}}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="payment_term_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                                <div className="col-md-0 col-lg-1"></div>
                                                <div className="col-md-3 col-lg-2 text-right text-lg-left">
                                                    <label>Payment Status <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <DropDownSelection
                                                        tagClass="form-control"
                                                        id="payment_status_id"
                                                        name='payment_status_id' 
                                                        placeholder='Status'
                                                        value={order_data.payment_status_id}  
                                                        onChange={inputChange}
                                                        onFocus=""
                                                        optionList={enums.payment_status}
                                                        optionProps ={{value:'id', label:'payment_status'}}
                                                    />

                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="payment_status_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Shipping Fee <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputText
                                                        id="shipping_fee" 
                                                        name="shipping_fee" 
                                                        value={order_data.shipping_fee}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        placeholder="PHP"
                                                        type="text"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="shipping_fee" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Remarks</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextArea
                                                        id="remarks" 
                                                        name="remarks" 
                                                        value={order_data.remarks}  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder="Optional"
                                                        rows={3}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="remarks" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            { order_data.order_status_id == 4 ?
                                            <div className="row">
                                                <div className="col-12"><hr/></div>
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Cancellation Reason</label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextArea
                                                        value={order_data.reason}  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder="Optional"
                                                        rows={2}
                                                        disabled={true}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="remarks" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>: ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        {/* <div className="card-header">
                                            <span className='float-right'>
                                                <button type="button" className="btn btn-sm btn-primary" onClick={(e) => setProductModal(1)}>Browse Products</button>
                                            </span>
                                            <h5 className='mb-0'>Order Items</h5>
                                        </div> */}
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th width={70}>ITEM&nbsp;#</th>
                                                        <th width={'50%'}>ITEMS</th>
                                                        {order_id ? <th>CURRENT&nbsp;RECEIVED</th> : ''}
                                                        <th>{order_id ? 'ORDERED' : 'QUANTITY'}</th>
                                                        <th>ITEM&nbsp;PRICE&nbsp;(PHP)</th>
                                                        <th>TOTAL</th>
                                                        <th width={20}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    
                                                    {order_data.order_status_id === 1 ?
                                                    <tr className='bg-light'>
                                                        <td colSpan={order_id ? 7 : 6} className='text-center'>
                                                            <RenderErrors 
                                                                errors={errors} 
                                                                name="items" 
                                                                className="text-danger mb-2"
                                                                style={{fontSize: '.9rem'}}
                                                            />
                                                            <button type="button" className="btn btn-outline-primary" onClick={(e) => setProductModal(1)}>Add Items</button>
                                                        </td>
                                                    </tr> : ''}
                                                </tbody>
                                                <tbody className='border-top-0'>
                                                    <tr>
                                                        <td colSpan={2}>        
                                                        </td>
                                                        <td colSpan={order_id ? 3 : 2} className='text-right border-right border-bottom'><b>TOTAL ITEM(S)</b></td>
                                                        <td colSpan={2} className='border-bottom'><b>{computeTotals('quantity')}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>        
                                                        </td>
                                                        <td colSpan={order_id ? 3 : 2} className='text-right border-right border-bottom'><b>SHIPPING FEE</b></td>
                                                        <td colSpan={2} className='border-bottom'><b>&#8369;{helpers.formatAmount(order_data.shipping_fee)}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={order_id ? 5 : 4} className='text-right border-right'><h5 className='font-weight-bold'>TOTAL AMOUNT</h5></td>
                                                        <td colSpan={2}><h5 className='font-weight-bold'>&#8369;{computeTotals('total')}</h5></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>
                                <Link className='btn btn-secondary' to="/purchases/list"><i className="fas fa-arrow-left mr-2" />Cancel</Link>       
                                {
                                    order_data.order_status_id != 3 && order_data.order_status_id != 4 ? <button type='button' className='btn btn-success ml-3 ' onClick={(e) => submit(1)}><i className="fas fa-check mr-2" />Done</button> : ''
                                }                
                                
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}