import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loader from '../parts/Loader';
//import InputText from '../elements/InputText';
import RenderErrors from '../elements/RenderErrors';
import InputTextArea from '../elements/InputTextArea';
import ButtonGroupSelection from '../elements/ButtonGroupSelection';
import store from '../../helpers/store';
import helpers from '../../helpers/helpers';
import Swal from 'sweetalert2';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
         
 
export default function AddressFormModal ({
        reference_id = '', 
        is_show = false, 
        setShow, 
        handleSave, 
        header_title="Address",
        src="customer",
        store_id="",
        index="",
        address_data={},
        direct_save
    }){
    let default_data = {
        "id": "",
        "reference_id": "",
        "address_type_id": 1,
        "contact_name": "",
        "phone_no": "",
        "email": "",
        "address": "",
        "flag":"new"
    }
    const [loading, setLoading] = useState(0);
    const [notice, setNotice] = useState('');
    const [record_data, setRecordData] = useState(default_data);
    const [record_loaded, setRecordLoaded] = useState(0);
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        setErrors([])
        
        if(src=="store" && is_show) {
            getData()
        }
        else if(is_show && address_data) {
            setRecordData(address_data)
        }
        else{
            setRecordData(default_data)
        }
    }, [is_show, address_data]);

    function getData(){
        if(!reference_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/store/address/${reference_id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    
                }
            })
        }); 
    }
    
    function inputChange(e,) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(src !== 'store'){
            if(!record_data.address_type_id){
                new_errors['address_type_id'] = ['The address type field is required.']
            }
            else{
                delete new_errors['address_type_id'];
            }
        }

        if(!record_data.address){
            new_errors['address'] = ['The address field is required.']
        }
        else{
            delete new_errors['address'];
        }

        if(!record_data.contact_name){
            new_errors['contact_name'] = ['The contact name field is required.']
        }
        else{
            delete new_errors['contact_name'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function handleSubmit(e) {
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        if(src !== 'store'){
            setShow(0);
            let form_data = {...record_data}
            form_data.customer_id = reference_id
            //form_data.phone_no = helpers.removeString(record_data.phone_no)
            if(record_data.phone_no){
                form_data.phone_no = helpers.removeString(record_data.phone_no)
            }
    
            if(reference_id){
                form_data.flag = 'edit'
            }
            handleSave(form_data, index)
        }
        else{
            setLoading(1)
    
            let url = helpers.getUrl('V1/manage/store/address')

            if(reference_id && record_loaded){
                url = helpers.getUrl(`V1/manage/store/address/${reference_id}`)
            }

            let form_data = {...record_data}
            form_data.store_id = helpers.getStoreID()
            if(record_data.phone_no){
                form_data.phone_no = helpers.removeString(record_data.phone_no)
            }
            
            delete form_data.flag
            delete form_data.reference_id
            delete form_data.address_type_id
    
            httpRequest(url, 'POST', {
                postdata: form_data,
            }, true)
                .then((res) => {
                    form_data.id = res.id
                    setLoading(0)
    
                    Swal.fire({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        confirmButtonText: "OK",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            //close_modal(0);
                            setShow(0);
                            handleSave(form_data);
                            return;
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(0)
                    
                    if(err.data){
                        setErrors( err.data );
                    }else{
                        setErrors([])
                    }
    
                    Swal.fire({
                        //title: err.message,
                        text: err.message,
                        icon: "error",
                        confirmButtonText: "OK",
                    })
                }); 
        }
    }

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function reset(){
        setRecordData([])
        setNotice('')
    }

    return (
        <>
            <Modal show={is_show}>
                <Modal.Header className='pt-3 pb-3'>
                    <h5 className='mb-0'>{header_title}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}} className='px-4'>
                    <Loader isLoading={loading}/>

                    {
                    src !== 'store' ?
                    <div className="row">
                        <div className="col-md-4">
                            <label>Type</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <ButtonGroupSelection
                                tagClass="form-control"
                                id="address_type_id"
                                name='address_type_id' 
                                value={record_data.address_type_id}  
                                onChange={inputChange}
                                onFocus=""
                                optionList={store.contact_address_type}
                                optionProps ={{value:'id', label:'label'}}
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="address_type_id" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div> : ''
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <label>Contact Name <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <InputText
                                id="contact_name" 
                                name="contact_name" 
                                value={record_data.contact_name}
                                tagClass="form-control mb-0"
                                placeholder="Full Name"
                                onChange={inputChange}
                                className='w-100'
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="contact_name" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Address <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <InputTextArea
                                id="address" 
                                name="address" 
                                value={record_data.address}  
                                tagClass="form-control"
                                onChange={inputChange}
                                placeholder='Complete Address'
                                rows={2}
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="address" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Email</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <InputText
                                id="email" 
                                name="email" 
                                value={record_data.email}
                                tagClass="form-control mb-0"
                                placeholder="Email"
                                onChange={inputChange}
                                type="email"
                                keyfilter="email"
                                className='w-100'
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="email" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Phone No</label>
                        </div>
                        <div className="col-md-8">
                            <InputMask 
                                id="phone_no" 
                                name="phone_no" 
                                mask="9999-999-9999" 
                                value={record_data.phone_no}
                                placeholder="0920-321-5468"
                                onChange={inputChange}
                                className='w-100'
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="phone_no" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => handleSubmit(1)}><i className="fas fa-check mr-2" />Save</button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}