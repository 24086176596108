import React, { useEffect, useState } from 'react';
import { httpRequest } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loader from '../parts/Loader';
import RenderErrors from '../elements/RenderErrors';
import InputTextArea from '../elements/InputTextArea';
import helpers from '../../helpers/helpers';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import InputCheckbox from '../elements/InputCheckbox';
         
 
export default function WarehouseFormModal ({
        reference_id = '', 
        is_show = false, 
        setShow, 
        handleSave, 
        header_title="Warehouse"
    }){
    let default_data = {
        "id": "",
        "status_id": 1,
        "is_primary": 0,
        "warehouse_name": "",
        "warehouse_address": ""
    }
    const [loading, setLoading] = useState(0);
    const [record_data, setRecordData] = useState(default_data);
    const [record_loaded, setRecordLoaded] = useState(0);
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        getData()
        setErrors([])
        setRecordData(default_data)
    }, [is_show]);

    function getData(){
        if(!reference_id){
            return
        }
        const url = helpers.getUrl(`V1/manage/store/warehouse/${reference_id}?store_id=${helpers.getStoreID()}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setRecordData(res)
            setLoading(0)
            setRecordLoaded(1)
        })
        .catch((err) => {
            setLoading(0)
            setRecordLoaded(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    
                }
            })
        }); 
    }
    
    function inputChange(e,) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            value = 0;
            
            if (e.target.checked) value = 1;
        }

        let new_state = { [name]: value }

        setRecordData({ ...record_data, ...new_state });
    }

    function validateForm(){
        let new_errors = {...errors}
        let variant_errors = 0;

        if(!record_data.warehouse_address){
            new_errors['warehouse_address'] = ['The warehouse address field is required.']
        }
        else{
            delete new_errors['warehouse_address'];
        }

        if(!record_data.warehouse_name){
            new_errors['warehouse_name'] = ['The warehouse name field is required.']
        }
        else{
            delete new_errors['warehouse_name'];
        }

        setErrors(new_errors);

        return new_errors;
    }

    function handleSubmit(e) {
        const validate = validateForm();
        if(Object.keys(validate).length) return;

        setLoading(1)

        let url = helpers.getUrl('V1/manage/store/warehouse')

        if(reference_id && record_loaded){
            url = helpers.getUrl(`V1/manage/store/warehouse/${reference_id}`)
        }

        let form_data = {...record_data}
        form_data.store_id = helpers.getStoreID()
        if(record_data.phone_no){
            form_data.phone_no = helpers.removeString(record_data.phone_no)
        }
        
        delete form_data.flag
        delete form_data.reference_id
        delete form_data.address_type_id

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                form_data.id = res.id
                setLoading(0)

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        //close_modal(0);
                        setShow(0);
                        handleSave(form_data);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function reset(){
        setRecordData([])
    }

    return (
        <>
            <Modal show={is_show}>
                <Modal.Header className='pt-3 pb-3'>
                    <h5 className='mb-0'>{header_title}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}} className='px-4'>
                    <Loader isLoading={loading}/>

                    <div className="row">
                        <div className="col-md-4">
                            <label>Name <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <InputText
                                id="warehouse_name" 
                                name="warehouse_name" 
                                value={record_data.warehouse_name}
                                tagClass="form-control mb-0"
                                placeholder="Warehouse Name"
                                onChange={inputChange}
                                className='w-100'
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="warehouse_name" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Address <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <InputTextArea
                                id="warehouse_address" 
                                name="warehouse_address" 
                                value={record_data.warehouse_address}  
                                tagClass="form-control"
                                onChange={inputChange}
                                placeholder='Complete Address'
                                rows={2}
                            />
                            <RenderErrors 
                                errors={errors} 
                                name="warehouse_address" 
                                className="text-danger"
                                style={{fontSize: '.9rem'}}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-4" />
                        <div className="col-md-8">
                            <InputCheckbox
                                id="is_primary"
                                name="is_primary"
                                value="1"
                                onChange={inputChange}
                                checked = {record_data.is_primary}
                                tagClass='me-2'
                                label="Primary Warehouse"
                                labelClass="ms-2 font-weight-normal mb-0"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => handleSubmit(1)}><i className="fas fa-check mr-2" />Save</button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}