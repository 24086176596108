import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
import DataTable from 'react-data-table-component';
import { da } from 'date-fns/locale';
 
export default function OrderItemFinderModal ({
        reference_id='',
        //reference_id='', 
        is_show = false, 
        setShow, 
        handleSelect, 
        exclude = [], 
        restrict_stock = true, 
        show_price = true, 
        show_cost = false,
        source='',
        order_id='',
        title=''
    }){
    const [loading, setLoading] = useState(0);
    const [name, setName] = useState('');
    const [notice, setNotice] = useState(<div className="alert alert-primary px-3 py-2 mb-0 text-center mt-3" role="alert">Search Item to Begin.</div>);
    const [result_list, setProductResult] = useState([]);
    const [data_table, setDataTable] = useState([]);
    const [data_table_header, setDataTableHeader] = useState([]);
    
   
    // useEffect(() => {

    // }, [reference_id, reference_id, order_id]);
   
    useEffect(() => {
        reset()

        if(source && order_id && reference_id){
            getData({})
        }
    }, [is_show]);
   
    useEffect(() => {
        let tables = []

        // if(result_list.hasOwnProperty('data')){
        //     data = result_list.data
        // }
        
        if (Object.keys(result_list).length > 0) {
            tables = Object.entries(result_list).map(([key, data]) => {
                const ordered_quantity = parseInt(data.quantity)
                const shipping_status_id = data.shipping_status_id
                let fulfilled_quantity = 0
                if(source === 'purchase'){
                    fulfilled_quantity = parseInt(data.received_quantity ?? 0)
                }
                else { // Sales-Order
                    fulfilled_quantity = parseInt(data.fulfilled_quantity ?? 0)
                }

                // if(fulfilled_quantity >= ordered_quantity || (shipping_status_id == 4 || shipping_status_id == 5)){
                //     return;
                // }

                if(fulfilled_quantity >= ordered_quantity){
                    return;
                }

                const result = exclude.find((item) => {
                    if(item.product_variant_id == data.product_variant_id) {
                        return item.product_variant_id;
                    }
                })
                if(result) return;

                return (<tr key={data.product_variant_id}>
                            <td>{data.product_variant_id}</td>
                            <td>
                                <b>{data.product_name} ({data.product_variant_name})</b><br/>
                                <span>Product Code: {data.product_code}</span>
                            </td>
                            <td>{data.quantity}</td>
                            {
                                source === 'sales' ? 
                                <td>{data.fulfilled_quantity ? data.fulfilled_quantity : '0'}</td> :
                                <td>{data.received_quantity ? data.received_quantity : '0'}</td>
                            }
                            <td width={100} className='text-center'>
                                <button className='btn btn-sm btn-primary' source="button" onClick={(e) => onSelect(data)}>Select</button>
                            </td>
                        </tr>)
            })
        }

        if(tables.length){
        setDataTable(
            <>
                <table className='table table-hover table-sm mb-0 item-table-header'>
                    <thead>
                        <tr>
                            <th className='bg-light'>#</th>
                            <th className='bg-light'>ITEMS</th>
                            <th className='bg-light'>ORDERED</th>
                            <th className='bg-light'>{source === 'sales' ? 'FULFILLED' : 'RECEIVED'}</th>
                            <th className='bg-light'></th>
                        </tr>
                    </thead>
                    <tbody>{tables}</tbody>
                </table>
            </>
        )
        }
    }, [result_list]);

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function onSelect(e){
        reset();
        setShow(0);
        handleSelect(e)
    }

    function inputChange(e) {
        let { value, name } = e.target;
        
        if(!value) {
            setNotice('Customer field is required') 
        }

        if(!value) {
            reset()
            setNotice(<div className="alert alert-info mt-3 px-3 py-2 mb-0" role="alert">Search Item to Begin.</div>)
            return;
        }

        getData({
            'value': value
        })
    }

    function getData({value = ''}){
        setLoading(0)

        let filter = `?store_id=${helpers.getStoreID()}&`

        if(value){
            filter += 'search='+value+'&'
        }

        if(order_id){
            filter += 'order_id='+order_id+'&' // Sales order ID or Purchase Order ID
        }

        if(reference_id){
            filter += 'reference_id='+reference_id+'&' // Sales order ID or Purchase Order ID
        }
        else if(reference_id){
            filter += 'reference_id='+reference_id+'&' // Sales order ID or Purchase Order ID
        }
        
        filter = filter.slice(0, -1)

        let url = '';
        if(source === 'sales'){
            url = helpers.getUrl(`V1/manage/order/sales/items`+filter)
        }
        else if(source === 'purchase'){
            url = helpers.getUrl(`V1/manage/order/purchase/items`+filter)
        }


        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setProductResult(res)
                setLoading(0)
            })
            .catch(() => {
                reset()
                setProductResult([])
                setLoading(0)
                setNotice(<div className="alert alert-warning mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 

    }

    function reset(){
        setName('')
        setProductResult([])
        setDataTable([])
        setNotice('')
    }

    return (
        <>
            <Modal show={is_show} size="lg">
                <Modal.Header>
                    <h5>{title}</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body  style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>

                    <div className="row"  style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        <div className="col-12">
                            <div className="input-group">
                                <input source="text" className="form-control" placeholder="Search Item" aria-label="Search a item" aria-describedby="basic-addon2" onChange={inputChange} autoFocus />
                            </div>
                            <span className='text-danger'>{notice}</span>
                        </div>
                    </div>
                    
                    <div className='mt-3' style={{maxHeight: '500px', overflow: 'auto', scrollBehavior: 'smooth'}}>
                        {data_table}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}