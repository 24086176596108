/**
 * Input Text Reusable Components
 */
import React from "react";

const InputTextArea = ({
        id,
        name, 
        value, 
        label='', 
        tagClass='',
        labelClass='',
        placeholder = '', 
        onChange,
        onFocus,
        maxLength = 500,
        readOnly = false,
        rows=1,
        disabled = false
    }) => {

    return (
        <>
            {label && <label htmlFor={id} className={labelClass}>{label}</label>}
            <textarea
                id={id}
                value={value|| ''}
                rows={rows}
                onKeyUp={onFocus}
                onChange={(e) => onChange(e)}
                className={tagClass}
                type="text"
                name={name}
                placeholder={placeholder}
                maxLength={maxLength}
                readOnly={readOnly}
                disabled={disabled}
            />
        </>
    );
};

export default InputTextArea;
