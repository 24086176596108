import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import store from '../../../helpers/store';

import InputText from '../../elements/InputText';
import InputTextArea from '../../elements/InputTextArea';
import RenderErrors from '../../elements/RenderErrors';

import VendorModal from '../../modals/VendorModal';
import ProductFinderModal from '../../modals/ProductFinderModal';
import OrderListModal from '../../modals/OrderListModal';
import OrderItemFinderModal from '../../modals/OrderItemFinderModal';
import initTreeView from '../../../helpers/initTreeView';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
 
export default function PurchaseReceiveForm (){
    const { receive_id } = useParams();
    const [loading, setLoading] = useState(0);
    const [errors, setErrors] = useState([]);
    const [page_title, setPageTitle] = useState('New Purchase Receive');
    const [record_loaded, setRecordLoaded] = useState(0);
    const [record_data, setRecordData] = useState(store.purchase_receive_data);
    const [record_items, setRecordItems] = useState([]);
    const [table_items, setTableItems] = useState([]);

    const [vendor_data, setVendor] = useState(store.so_vendor_data);
    const [shipping_address, setAddress] = useState(store.so_vendor_data);
    const [enums, setEnums] = useState(store.product_enums);
    const [vendor_modal, setVendorModal] = useState(0);
    const [po_list_modal, setPOModal] = useState(0);

    /** Modals */
    const [product_modal, setProductModal] = useState(0);
    const [same_address, setSameAddress] = useState(0);
    
   
    useEffect(() => {
        initTreeView()

        if(receive_id){
            setPageTitle('Purchase Received Details')
            getData(receive_id)
        }
        else{
            getEnums()
        }
    }, []);

    useEffect(() => {
        displayTableItems()
        //computeTotals();
    }, [record_items]);

    useEffect(() => {
        // const shipping_status_id = record_data.shipping_status_id

        // Object.entries(record_items).map(([key, data]) => {

        // })
    }, [record_data.shipping_status_id]);

    function getData(receive_id){
        if(!receive_id){
            return
        }
        
        const url = helpers.getUrl('V1/manage/order/purchase-receive/'+receive_id+'?store_id='+helpers.getStoreID())

        httpRequest(url, "GET", {}, true)
        .then((res) => {
            let items = res;
            items.received_date = new Date(res.received_date)

            setLoading(0)
            setRecordData(res)
            setRecordItems(res.items)
            setRecordLoaded(1)
            getEnums()
        })
        .catch((err) => {
            setLoading(0)
            getEnums()

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.replace("/purchase-receives/list");
                }
            })
        }); 
    }

    function getEnums(){
        setLoading(1)

        const url = helpers.getUrl(`V1/enums/item?store_id=${helpers.getStoreID()}&only=warehouses`)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setEnums(res)
            setLoading(0)
        })
        .catch((err) => {
            setLoading(0)
        }); 
    }

    function displayTableItems(){
        let items = []
        
        if (record_items.length) {
            items = Object.entries(record_items).map(([key, data]) => {

                return(
                <tr>
                    <td>{data.product_name} ({data.product_variant_name})</td>
                    <td>{data.quantity}</td>
                    {/* {
                    record_loaded ?
                    <td>{data.received_items}</td> : ''
                    } */}
                    <td>
                        <InputNumber
                            id="quantity_to_receive" 
                            name="quantity_to_receive" 
                            value={data.quantity_to_receive}
                            inputClassName="form-control rounded-0"
                            min={0}
                            max={data.received_items}
                            onValueChange={(e) => inputItemChange(e, key)}
                            placeholder="Quantity"
                            decrementButtonClassName="p-input-button-left" 
                            incrementButtonClassName="p-input-button-right" 
                            incrementButtonIcon="fas fa-plus" 
                            decrementButtonIcon="fas fa-minus"
                            buttonLayout="horizontal"
                            showButtons
                        />
                    </td>
                    
                    <td><button className='btn btn-sm text-muted' type="button" onClick={(e) => removeItem(key)}><i className="fas fa-times text-danger"></i></button></td>
                </tr>)

            })
        }
        else{
            items = <tr>
                        <td colSpan={5} className='text-center'>&nbsp;</td>
                    </tr>
        }


        setTableItems(items)
    }
    
    function inputItemChange(e, key) {
        let { value, name } = e.target;
        let items = [...record_items]
        let target = {...items[key]}

        target[name] = value
        items[key] = target

        setRecordItems(items)
    }

    function removeItem(key){
        let items = [...record_items]
        items.splice(key, 1); 

        setRecordItems(items)
    }
    
    function inputChange(e,) {
        let { value, name } = e.target;

        if(name == 'same_address'){
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
            let data = {...record_data}
            data.shipping_address_id = ''
            data.shipping_address = ''
            data.shipping_contact_name = ''
            data.shipping_phone = ''
                
            setRecordData(data)

            setSameAddress(value)
            return;
        }

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        if(same_address && name == 'vendor_name'){
            new_state.shipping_contact_name = value
        }

        setRecordData({ ...record_data, ...new_state });
    }

    function handleVendor(e){
        let data = {...record_data}

        data.vendor_id = e.id ?? ''
        data.vendor_name = e.vendor_name ?? ''
        //data.vendor_contact_name = e.contact_person ?? ''
        //data.vendor_address = e.vendor_address ?? ''

        setVendor(e)
        setRecordData(data)
    }

    function handleProduct(e){
        let items = [...record_items]

        const received_quantity = parseInt(e.received_quantity) ?? 0;
        const remaining_quantity = parseInt(e.quantity) - received_quantity

        let new_item = {
            "product_id": e.id,
            "product_code": e.product_code,
            "product_name": e.product_name,
            "product_variant_id": e.product_variant_id,
            "product_variant_name": e.product_variant_name,
            "quantity": e.quantity,
            "quantity_to_receive": "",
            "received_items": remaining_quantity,
            "received_quantity":e.received_quantity,
            "available_stock": e.available_stock,
            "shipping_status_id": record_data.shipping_status_id,
            "po_item_id": e.po_item_id
        }

        items.push(new_item)

        setRecordItems(items)
    }
    
    function handleOrder(e){
        let data = {...record_data}

        data.po_id = e.id
        data.purchase_order = e.order_no
        
        setRecordItems([])
        setRecordData(data)
    }

    function submit(){
        setLoading(1)

        let url = helpers.getUrl(`V1/manage/order/purchase-receive/create`)
        if(receive_id){
            url = helpers.getUrl(`V1/manage/order/purchase-receive/${receive_id}`)
        }

        let form_data = {...record_data}
        form_data['items'] = record_items;
        form_data['purchase_order'] = record_data.po_id

        httpRequest(url, 'POST', {
            postdata: form_data,
        }, true)
            .then((res) => {
                setLoading(0)

                let id = receive_id;
                if(!id){
                    id = res.data.id
                }

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace(`/purchase-receives/${id}/edit`);
                        return;
                    }
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                
                if(err.data){
                    setErrors( err.data );
                }else{
                    setErrors([])
                }

                Swal.fire({
                    //title: err.message,
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }


    return (
        <>
        <VendorModal is_show={vendor_modal} setShow={setVendorModal} handleSelect={handleVendor} />

        <OrderListModal id={record_data.vendor_id} is_show={po_list_modal} setShow={setPOModal} handleSelect={handleOrder} type='purchase' />

        <OrderItemFinderModal 
            reference_id={record_data.vendor_id} 
            is_show={product_modal} 
            setShow={setProductModal}  
            handleSelect={handleProduct} 
            exclude={record_items} 
            restrict_stock={false}
            show_price = {false}
            show_cost = {true}
            source='purchase'
            order_id={record_data.po_id}
            title="Purchased Items"
        />

        <MainContainer page_title={page_title} menu="manage-purchase-receives" breadcrumbs={true} parent_link='/purchases/list' parent_text="Manage Purchases Order">
                <div className="content">
                    <div className="container-fluid pb-5" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        {record_data.receive_no ?
                        <div className='border rounded p-3 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                            <h5 className='mb-3 mb-md-0 d-flex'>Receive #: {record_data.receive_no}</h5>
                            <div className='d-flex flex-column flex-sm-row'>
                            </div>

                            <div className='text-right'>     
                                <Link className='btn btn-outline-dark ml-3' to="/purchase-receives/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>
                            </div>
                        </div> : ''}

                        <form id="pr-form">
                            <div className="row mb-2 d-flex align-items-stretch">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body pt-md-5">
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Vendor Name <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                        {
                                                            receive_id ?
                                                            <>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Select Vendor" value={record_data.vendor_name} disabled={true} />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" onClick={(e) => setVendorModal(1)} disabled={receive_id}><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            </> :
                                                            <>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Select Vendor" onClick={(e) => setVendorModal(1)} value={record_data.vendor_name} readOnly={true} />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" onClick={(e) => setVendorModal(1)} disabled={receive_id}><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>PO # <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3">
                                                        {
                                                            receive_id ?
                                                            <>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Select PO" value={record_data.purchase_order} disabled={true} /> 
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" disabled><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            </>
                                                            :
                                                            <>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Select PO" onClick={(e) => setPOModal(1)} onChange={(e) => setPOModal(1)} value={record_data.purchase_order} disabled={!record_data.vendor_id} />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" onClick={(e) => setPOModal(1)} disabled={!record_data.vendor_id}><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="purchase_order" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12"><hr/></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Received Date <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    {/* <InputText
                                                        id="received_date" 
                                                        name="received_date" 
                                                        value={record_data.received_date}
                                                        tagClass="form-control mb-0"
                                                        onChange={inputChange}
                                                        placeholder="Order Date"
                                                        type="date"
                                                    /> */}
                                                    <Calendar 
                                                        id="received_date" 
                                                        name="received_date"  
                                                        value={record_data.received_date} 
                                                        onChange={inputChange} 
                                                        className='w-100' 
                                                        dateFormat="yy-mm-dd" 
                                                        icon={() => <i className="far fa-calendar"></i>} 
                                                        showIcon  
                                                        disabled={!record_data.purchase_order}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="received_date" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label>Warehouse <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    { !receive_id ?
                                                    <Dropdown  
                                                        name='warehouse_id'
                                                        value={parseInt(record_data.warehouse_id)} 
                                                        onChange={inputChange} 
                                                        options={enums.warehouses} 
                                                        optionLabel="warehouse_name" 
                                                        optionValue="id" 
                                                        placeholder="Select Target Warehouse" 
                                                        className="w-100" 
                                                        checkmark={true} 
                                                        highlightOnSelect={false}
                                                        showClear
                                                        filter 
                                                        disabled={!record_data.purchase_order}
                                                    /> :

                                                    <input type='text' className='form-control' value={record_data.warehouse_name} disabled />

                                                    }
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="warehouse_id" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-lg-2 text-left text-md-right">
                                                    <label className='mb-0'>Note</label><br/>
                                                    <small>(Optional only)</small>
                                                </div>
                                                <div className="col-md-5 col-lg-3 form-group">
                                                    <InputTextArea
                                                        id="notes" 
                                                        name="notes" 
                                                        value={record_data.notes}  
                                                        tagClass="form-control"
                                                        onChange={inputChange}
                                                        placeholder=""
                                                        rows={2}
                                                        disabled={!record_data.purchase_order}
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="notes" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="so-item-container mt-5" className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0 table-responsive">
                                            <table className='table mb-0'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th>ITEMS</th>
                                                        <th width={150}>ORDERED</th>
                                                        {/* {
                                                        record_loaded ?
                                                        <th width={150}>RECEIVED</th> : ''
                                                        } */}
                                                        <th width={200}>RECIEPT&nbsp;QUANTITY</th>
                                                        <th width={20}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_items}
                                                    <tr className='bg-light'>
                                                        <td colSpan={record_loaded ? 5 : 4} className='text-center'>
                                                            <button type="button" className="btn btn-outline-primary" onClick={(e) => setProductModal(1)} disabled={!record_data.po_id}>Add Items</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <center>
                                            <RenderErrors 
                                                errors={errors} 
                                                name="items" 
                                                className="text-danger mb-2"
                                                style={{fontSize: '.9rem'}}
                                            />
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right mt-3'>
                                {/* <Link className='btn btn-secondary' to="/purchase-receives/list"><i className="fas fa-arrow-left mr-2" />Cancel</Link>       
                                {
                                    record_data.order_status_id != 3 && record_data.order_status_id != 4 ? 
                                    <button type='button' className='btn btn-success ml-3 ' onClick={(e) => submit(1)} disabled={!record_data.po_id}><i className="fas fa-check mr-2" />Received</button> : 
                                    ''
                                }     */}
                                <button type='button' className='btn btn-success mr-3 ' onClick={(e) => submit(1)} disabled={!record_data.purchase_order} style={{padding: '0.5rem 0.75rem'}}>Save</button>
                                <Link className='btn btn-outline-dark' to="/purchase-receives/list" style={{padding: '0.5rem 0.75rem'}}>Cancel</Link>                    
                                
                            </div>
                        </form>
                    </div>
                </div>
        </MainContainer>
        </>
    )
}