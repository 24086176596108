import React, { useEffect, useState } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import helpers from '../../helpers/helpers';
import Loader from '../parts/Loader';
import AddressFormModal from './AddressFormModal';
 
export default function ContactListModal ({is_show = false, setShow, handleSelect}){
    const [loading, setLoading] = useState(0);
    const [name, setName] = useState('');
    const [notice, setNotice] = useState('');
    const [customer_list, setCustomers] = useState([]);
    const [table_data, setTableData] = useState([]);

    
    const [show_address_modal, setShowAddressModal] = useState(0);
   
    useEffect(() => {

    }, []);
   
    useEffect(() => {
        if(is_show) {
            getData()
        }
    }, [is_show]);
   
    useEffect(() => {
        let table_items_lg = []
        let table_items_sm = []

        
        if (Object.keys(customer_list).length > 0) {
            table_items_lg = Object.entries(customer_list).map(([key, data]) => {

                return (<tr key={data.id} style={{fontSize: '15px'}}>
                            <td>{data.contact_name}</td>
                            <td>{data.gender ? data.gender : '---'}</td>
                            <td>{data.email ? data.email : '---'}</td>
                            <td>---</td>
                            <td width={50}><button className='btn btn-sm btn-outline-primary' type="button" onClick={(e) => onSelect(data)}>Select</button></td>
                        </tr>)
            })
            table_items_sm = Object.entries(customer_list).map(([key, data]) => {
                return (<tr key={data.id} style={{fontSize: '15px'}}>
                            <td>
                                Name: {data.contact_name}<br/>
                                Gender: {data.gender ? data.gender : '---'}<br/>
                                Email {data.email ? data.email : '---'}<br/>
                                Phone {data.phone_no ? data.phone_no : '---'}
                            </td>
                            <td width={50}><button className='btn btn-sm btn-outline-primary' type="button" onClick={(e) => onSelect(data)}>Select</button></td>
                        </tr>)
            })
        }

        setTableData(
            <>
            <div className='d-none d-md-block'>
                <table className='table table-hover table-sm mb-0 mt-3'><tbody>{table_items_lg}</tbody></table>
            </div>
            <div className='d-block d-md-none'>
                <table className='table table-hover table-sm mb-0 mt-3'><tbody>{table_items_sm}</tbody></table>
            </div>
            </>
        )
    }, [customer_list]);

    function handleClose(e) {
        reset();
        setShow(0);
    }

    function onSelect(e){
        reset();
        setShow(0);
        handleSelect(e)
    }

    function inputChange(e) {
        let { value, name } = e.target;
        
        if(!value) {
            setNotice('Customer field is required') 
        }

        if(value) setNotice('');

        //setName(value);
        getData(value)
    }

    function getData(name) {
        setLoading(1)

        let filter = `?store_id=${helpers.getStoreID()}`

        if(name){
            filter += '&search='+name+'&'
            filter = filter.slice(0, -1)
        }

        const url = helpers.getUrl('V1/manage/contact/list'+filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setNotice('')
                setCustomers(res)
                setLoading(0)
            })
            .catch((err) => {
                reset()
                setLoading(0)
                setNotice(<div className="alert alert-danger mt-3 px-3 py-2 mb-0" role="alert">Unable to fetch data.</div>)
            }); 
    }

    function reset(){
        setName('')
        setCustomers([])
        setTableData([])
        setNotice('')
    }

    return (
        <>
            <Modal show={is_show} size="lg">
                <Modal.Header>
                    <h5>Customer</h5>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-times"></i></Button>
                </Modal.Header>
                <Modal.Body style={{position: 'relative'}}>
                    <Loader isLoading={loading}/>
                    <div className="row">
                        <div className="col-9 col-md-10">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search Customer" aria-label="Customer Name" aria-describedby="basic-addon2" onChange={inputChange} autoFocus />
                                {/* <div className="input-group-append">
                                    <button className="btn btn-outline-primary" type="button" onClick={getData}>Search</button>
                                </div> */}
                            </div>
                        </div>
                        
                        <div className="col-3 col-md-2">
                            <button className="btn btn-primary w-100" type="button" onClick={(e) => setShowAddressModal(1)}><i className="fas fa-plus-circle mr-2" />New</button>
                        </div>
                    </div>
                    
                    <span className='text-danger'>{notice}</span>

                    {table_data}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}