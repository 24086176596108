import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../helpers/images';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import helpers from '../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../parts/Loader';
import initTreeView from '../../helpers/initTreeView';
 
export default function StarterTemplate (){
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
   
    useEffect(() => {
        initTreeView()
        //Mounted
        //getDataList()
    }, []);


    return (
        <>
        <MainContainer page_title="Starter Page" menu="home" breadcrumbs={true}>
            <div className="container-fluid" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>
                <h3>Starter Page Template</h3>
            </div>
        </MainContainer>
        </>
    )
}