import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../helpers/images';
import ContentHeader from '../parts/ContentHeader';
import MainContainer from '../parts/MainContainer';
import helpers from '../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../parts/Loader';
import initTreeView from '../../helpers/initTreeView';
import appSession from '../../helpers/session';
// import DataTable from 'react-data-table-component';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
 
function HomePage (){
    const [loading, setLoading] = useState(0);
    const [statistic, setStatistic] = useState([]);
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);

    
    const [widget_data, setWidgetData] = useState({
        "total_low_stock": '---',
        "total_items": '---',
        "totaL_value": '---',
        "month_sales": '---',
        "total_sales_order": '---',
        "pending_deliveries": '---',
        "total_purchase_order": '---',
        "total_receivable_items": '---',
        "top_items": [],
        "low_stock_items": [],
        "sales_orders": [],
        "purchases_orders": []
    });
    const [top_items, setTopItems] = useState([]);
    const [low_stock_items, setLowStockItems] = useState([]);
    const [sales_orders, setSalesOrders] = useState([]);
    const [purchases_orders, setPurchasesOrders] = useState([]);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

   
    useEffect(() => {
        initTreeView()
        //Mounted
        getWidgetData()
    }, []);
   
    useEffect(() => {
        displayTopItems()
        displayLowStockItems()
        displaySalesOrderTable()
        displayPurchasesOrderTable()
    }, [widget_data]);

    useEffect(() => {
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartOptions(options);
    }, []);

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }

    function getWidgetData(){
        setLoading(1)
        let filter = '?store_id='+helpers.getStoreID()+'&'
        filter = filter.slice(0, -1)

        const url = helpers.getUrl('V1/widgets' + filter)

        httpRequest(url, "GET", {}, true)
            .then((res) => {
                setWidgetData(res.data)
                setLoading(0)

                const widget_data = res.data.monthly_sales

                const data = {
                    labels: widget_data.month,
                    datasets: [
                        {
                            type: 'line',
                            label: 'Trend',
                            data: widget_data.sales,
                            borderColor: '#007bff',
                            borderWidth: 2,
                            fill: false,
                            tension: 0.4,
                        },
                        {
                            type: 'bar',
                            label: 'Monthly Sales (PHP)',
                            data: widget_data.sales,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                              borderColor: 'rgb(75, 192, 192)',
                              borderWidth: 1
                        },
                    ]
                };
                const options = {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                };
        
                setChartData(data);
            })
            .catch((err) => {
                setLoading(0)

                if(err.message === 'Unauthenticated.'){
                    Swal.fire({
                        title: "Invalid Access Token!",
                        text: err.message,
                        icon: "error",
                        confirmButtonText: "OK",
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            appSession.deleteSession()
                        }
                    })
                }
                else{
                    Swal.fire({
                        title: "Error!",
                        text: err.message,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                }
            }); 
    }

    function linkTo(target = ''){
        // let page = 'service-request'

        // if(!helpers.checkPermissions(["service-request-dashboard"])){
        //     page = 'technician-service-request'
        // }

        // navigate(page + params);
        navigate(target);
    }

    function displayTopItems(){
        setTopItems(
            <DataTable
                value={widget_data.top_items} 
                size="small"
                lazy
                scrollHeight="300px"
                scrollable
                stripedRows
                emptyMessage="No data to diplay"
            >
                <Column 
                    field="product_name" 
                    header="Name" 
                    body={({product_name, product_variant_name})=> `${product_name} (${product_variant_name})`}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Total Sales" 
                    body={({total_sales})=> helpers.formatCurrencyAmount(total_sales)} 
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column 
                    header="Sold" 
                    body={({sold_quantity, unit_name})=> <>{sold_quantity} <small>{unit_name}</small></>}
                    headerStyle={{ background: '#f9fafb' }}
                />
                {/* <Column 
                    field="product_type_id" 
                    body={({product_type_id})=> product_type_id === 1 ? 'Goods' : 'Services'} 
                    header="Type" 
                    headerStyle={{ background: '#f9fafb' }}
                /> */}
            </DataTable>
        )
    }

    function displayLowStockItems(){
        setLowStockItems(
            <DataTable
                value={widget_data.low_stock_items} 
                size="small"
                lazy
                scrollHeight="300px"
                scrollable
                stripedRows
                //resizableColumns
                emptyMessage="No data to diplay"
            >
                <Column 
                    field="product_name" 
                    header="Name" 
                    body={({product_name, product_variant_name})=> `${product_name} (${product_variant_name})`}
                    headerStyle={{ background: '#f9fafb' }}
                />
                <Column field="available_stock" header="Stock" headerStyle={{ background: '#f9fafb' }}/>
                <Column header="Level" body={({available_stock, reorder_point})=> helpers.displayStockLevel(available_stock, reorder_point)} headerStyle={{ background: '#f9fafb' }}/>
            </DataTable>
        )
    }

    function displaySalesOrderTable(){
        setSalesOrders(
            <DataTable
                value={widget_data.sales_orders} 
                size="small"
                lazy
                scrollHeight="300px"
                scrollable
                stripedRows
                //resizableColumns
                emptyMessage="No data to diplay"
            >
                <Column field="order_no" header="Order No" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="order_date" header="Date" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="customer_name" header="Customer" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="total_items" header="Orders" headerStyle={{ background: '#f9fafb' }}/>
                <Column header="Total Amount" body={({total_amount})=> helpers.formatCurrencyAmount(total_amount)}></Column>
            </DataTable>
        )

    }

    function displayPurchasesOrderTable(){
        setPurchasesOrders(
            <DataTable
                value={widget_data.purchases_orders} 
                size="small"
                lazy
                scrollHeight="300px"
                scrollable
                stripedRows
                //resizableColumns
                emptyMessage="No data to diplay"
            >
                <Column field="order_no" header="Order No" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="order_date" header="Date" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="vendor_name" header="Vendor" headerStyle={{ background: '#f9fafb' }}/>
                <Column field="total_items" header="Orders" headerStyle={{ background: '#f9fafb' }}/>
                <Column header="Total Amount" body={({total_amount})=> helpers.formatCurrencyAmount(total_amount)}></Column>
            </DataTable>
        )
    }

    return (
        <>
        {/* <MainContainer page_title={"Hello, " + helpers.getUserName()} menu="home" breadcrumbs={false}> */}
        <MainContainer page_title="Insights" menu="home" breadcrumbs={false}>
                <div className="content">
                    <div className="container-fluid"  style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>

                        {/* <h5>Insights</h5> */}
                        <div className="row">
                            {/* <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-primary"><i className="fas fa-tags"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">ITEMS</span>
                                        <h5 className="info-box-number">{widget_data.total_items}</h5>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-orange"><i className="fas fa-coins"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">ITEM VALUE</span>
                                        <h5 className="info-box-number">&#8369;{helpers.formatAmount(widget_data.totaL_value)}</h5>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-danger"><i className="fas fa-coins"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">LOW STOCK</span>
                                        <h5 className="info-box-number">{widget_data.total_low_stock}</h5>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-success"><i className="fas fa-shopping-cart"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text mb-0">MONTHLY SALES</span>
                                        <h5 className="info-box-number mb-0">&#8369;{helpers.formatAmount(widget_data.month_sales)}</h5>
                                        <small className='text-muted'><i>+ direct invoices</i></small>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    {/* <span className="info-box-icon bg-purple"><i className="fas fa-shopping-cart"></i></span> */}
                                    <span className="info-box-icon bg-blue"><i className="fas fa-shopping-cart"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">SALES ORDER</span>
                                        <h5 className="info-box-number mb-0">{widget_data.total_sales_order}</h5>
                                        <small className='text-muted'>Open</small>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-info"><i className="fas fa-shipping-fast"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">ORDER DELIVERIES</span>
                                        <h5 className="info-box-number">{widget_data.pending_deliveries}</h5>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    {/* <span className="info-box-icon bg-olive"><i className="fas fa-shopping-bag"></i></span> */}
                                    <span className="info-box-icon bg-orange"><i className="fas fa-shopping-bag"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">PURCHASE ORDER</span>
                                        <h5 className="info-box-number mb-0">{widget_data.total_purchase_order}</h5>
                                        <small className='text-muted'>Open</small>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-maroon"><i className="fas fa-dolly-flatbed"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">ITEM RECEIVABLES</span>
                                        <h5 className="info-box-number">{widget_data.total_receivable_items}</h5>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-sm-6 col-md-4 col-lg-3 d-flex">
                                <div className='info-box pointer'>
                                    <span className="info-box-icon bg-danger"><i className="fas fa-coins"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">LOW STOCK</span>
                                        <h5 className="info-box-number">{widget_data.total_low_stock}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row d-flex'>
                            <div className="col-lg-6 d-flex">
                                <div className="card w-100">
                                    <div className="card-header border-0">
                                        <h3 className="card-title mr-2">Gross Sales</h3>
                                        {/* <div className="card-tools">
                                            <a href="#" className="btn btn-tool btn-sm">
                                                <i className="fas fa-sync-alt"></i>
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        <Chart type="line" data={chartData} options={chartOptions}   />
                                    </div>
                                    <div className="card-footer">
                                        <small>Included direct invoices</small>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 d-flex">
                                <div className="card card-oliveS card-outline w-100">
                                    <div className="card-header border-0">
                                        <h3 className="card-title">Top Selling Items</h3>
                                        <small className='ml-1'>(Monthly)</small>
                                        {/* <div className="card-tools">
                                            <a href="#" className="btn btn-tool btn-sm">
                                                <i className="fas fa-sync-alt"></i>
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="card-body p-0 pt-2 pb-1">
                                        {
                                            top_items
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-6 d-flex">
                                <div className="card card-danger card-outline w-100">
                                    <div className="card-header border-0">
                                        <h3 className="card-title">LOW STOCK ITEMS</h3>
                                        <div className="card-tools">
                                            <a href="#" className="btn btn-tool btn-sm">
                                                <i className="fas fa-sync-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body p-0 pt-2 pb-1">
                                        {
                                            low_stock_items
                                        }
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className='row d-flex'>
                            <div className="col-lg-6 d-flex">
                                <div className="card w-100">
                                    <div className="card-header car border-0">
                                        <h3 className="card-title">SALES ORDERS</h3>
                                        <div className="card-tools">
                                            <a href="#" className="btn btn-tool btn-sm">
                                                <i className="fas fa-sync-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body p-0 pt-2 pb-1">
                                        {
                                            sales_orders
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex">
                                <div className="card w-100">
                                    <div className="card-header car border-0">
                                        <h3 className="card-title">PURCHASES ORDERS</h3>
                                        <div className="card-tools">
                                            <a href="#" className="btn btn-tool btn-sm">
                                                <i className="fas fa-sync-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body p-0 pt-2 pb-1">
                                        {
                                            purchases_orders
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </div>{/* /.container-fluid */}
                </div>

        </MainContainer>
        </>
    )
}
 
export default HomePage;