import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import images from '../../../helpers/images';
import ContentHeader from '../../parts/ContentHeader';
import MainContainer from '../../parts/MainContainer';
import helpers from '../../../helpers/helpers';
import { httpRequest, httpAuthHeaders } from '../../../helpers/httpService';
import Swal from 'sweetalert2';
import Loader from '../../parts/Loader';
import initTreeView from '../../../helpers/initTreeView';
import { TabView, TabPanel } from 'primereact/tabview';
import store from '../../../helpers/store';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Dropdown from 'react-bootstrap/Dropdown'
import WarehouseFormModal from '../../modals/WarehouseFormModal';
         
 
export default function StoreWarehousesPage (){
    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const [record_data, setRecordData] = useState({});
    const [selected_items, setSelectedItems] = useState([]);
    const [data_table, setDataTable] = useState([]);
    const [search_key, setSearchKey] = useState('');
    const [selected_address_index, setAddressIndex] = useState('');
    const [warehouse_id, setWarehouseID] = useState('');
    const [datatable_state, setDataTableState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null
    });
    const [show_modal, setShowModal] = useState(0);
   
    useEffect(() => {
        initTreeView()

        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows
        })
    }, []);

    useEffect(() => {
        displayDataTable(record_data)
    }, [datatable_state.sortOrder]);

    useEffect(() => {
        displayDataTable(record_data)
    }, [
        selected_items, 
        record_data
    ]);

    useEffect(() => {
        getDataList({
            "page": datatable_state.page + 1,
            "per_page": datatable_state.rows,
            "search": search_key
        })
    }, [datatable_state]);

    function getDataList(condition = {
        "page": '',
        "search": '',
        "per_page": ''
    }){
        const page = condition.page ?? datatable_state.page
        const per_page = condition.per_page ?? datatable_state.rows
        const search = condition.search ?? search_key
        let filter = `?`

        if(page){
            filter += 'page='+page+'&'
        }
        if(per_page){
            filter += 'per_page='+per_page+'&'
        }
        if(search){
            filter += 'search='+search+'&'
        }

        filter = filter.slice(0, -1)

        const url = helpers.getUrl(`V1/manage/store/${helpers.getStoreID()}/warehouse${filter}`)

        setLoading(1)
        httpRequest(url, "GET", {}, true)
        .then((res) => {
            setLoading(0)
            setRecordData(res)
            displayDataTable(res)
        })
        .catch((err) => {
            setLoading(0)

            Swal.fire({
                //title: err.message,
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //window.location.replace("/contacts/list");
                }
            })
        }); 
    }

    function displayDataTable(data){
        setDataTable(
            <DataTable 
                className='border-top'
                dataKey={data.id}
                value={data.data} 
                size="normal"
                paginator 
                rows={data.per_page} 
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                totalRecords={data.total}
                onPage={onPage}
                first={datatable_state.first}
                lazy
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                selection={selected_items} 
                onSelectionChange={onSelectItem}
                scrollHeight="500px"
                scrollable
                responsive={true}
                emptyMessage="No data to diplay"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="warehouse_name" header="NAME" ></Column>
                <Column field="warehouse_address" header="ADDRESS"></Column>
                <Column field="" header="PRIMARY" body={({is_primary})=> is_primary ? <i className='fa fa-check-circle text-green' /> : '' }></Column>
                <Column className="text-right" body={displayActionMenu}></Column> 
            </DataTable>
        )

    }

    function displayActionMenu(data){
        return (<Dropdown>
                    <Dropdown.Toggle className='btn-light border' size="sm" id={"dropdown-actions-"+data.id}>Action</Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item onClick={(e) => editForm(data)}><i className="fas fa-pencil-alt mr-2" />Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(data, 'single-delete')}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>)
    }
    
    function triggerAction(data = '', action = '', status = ''){
        let ids = [];
        if(action === 'single-delete'){
            ids.push(data.id)
        }
        else{
            ids = data.map(o => o['id']);
        }

        let title = 'Delete Warehouse'
        if(ids.length > 1){
            title = 'Delete Warehouses'
        }

        Swal.fire({
            title: title,
            text: 'This action cannot be reversed. All associated records will also be deleted. Do you want to continue?',
            showCancelButton: true,
            showConfirmButton: false,
            closeOnCancel: true,
            showDenyButton: true,
            denyButtonText: 'Yes'
        })
        .then((result) => {
            if (result.isDenied) {
                deleteItem(ids)
            }
        })
    }

    const onPage = (e) => {
        setDataTableState(e)
        setSelectedItems([])
    };

    const onSelectItem = (e) => {
        const value = e.value;
        setSelectedItems(value);
    };

    function deleteItem(id){
        const url = helpers.getUrl('V1/manage/store/warehouse/delete')
        httpRequest(url, 'POST', {
            postdata: {
                "warehouse_ids": id,
                "store_id": helpers.getStoreID()
            },
        }, true)
            .then((res) => {
                setLoading(0)
                getDataList({
                    "page": datatable_state.page + 1,
                    "per_page": datatable_state.rows,
                    "search": search_key
                })
                setSelectedItems([])

                Swal.fire({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    confirmButtonText: "OK",
                })
            })
            .catch((err) => {
                console.log(err)
                setLoading(0)
                setSelectedItems([])

                Swal.fire({
                    text: err.message,
                    //text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }); 
    }
    
    function addForm(e){
        setShowModal(1)
        setWarehouseID('')
    }

    function editForm(data){
        setWarehouseID(data.id)
        setShowModal(1)
    }

    function handleAddress(e, index){
        getDataList()
    }
    
    function search(e){
        let { value } = e.target;
        setSearchKey(value)
        getDataList({
            "page": datatable_state.page + 1,
            "search": value
        })
    }


    return (
        <>
        <WarehouseFormModal 
            reference_id={warehouse_id}
            is_show={show_modal} 
            setShow={setShowModal} 
            handleSave={handleAddress}
        />

        <MainContainer page_title="Warehouses" breadcrumbs={true}>
            <div className="container-fluid text-center" style={{position: 'relative'}}>
                <Loader isLoading={loading}/>


                <div className="card">
                    
                    <TabView onTabChange={(e) => navigate((store.store_target[e.index]))} activeIndex={1}  className='p-tabview-custom'>
                        <TabPanel header="Profile" />
                        <TabPanel header="Warehouse">
                            <div className="row">
                                    <div className="col-lg-4 py-3 px-4">
                                        <input type="text" className="form-control" placeholder="Search" onChange={search} />
                                    </div>
                                    <div className="col-lg-8 text-right mt-3 mt-md-0 py-3 px-4">
                                        <button type='button' className='btn btn-primary float-right' onClick={addForm}><i className="fas fa-plus-circle mr-2" />New</button>
                                
                                        {selected_items.length ?
                                        <Dropdown className='float-right mr-3'>
                                            <Dropdown.Toggle className='btn-light border' id={"dropdown-actions-"}>Bulk Action</Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item key="delete" className='text-danger' onClick={(e) => triggerAction(selected_items)}><i className="fas fa-trash mr-2" />Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> : ''}
                                    </div>
                                </div>

                            {data_table}
                        </TabPanel>
                        <TabPanel header="Delivery Address" />
                    </TabView>
                </div>

            </div>
        </MainContainer>
        </>
    )
}