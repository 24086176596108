import React from 'react';

export default function StoreInfo({
    store = {},
    className=null
}){
    function displayStoreInfo(){
        if (Object.entries(store).length){
            return (<div className={className}>
                        {/* <img src={images.topventory_logo} alt="Icon"  className="brand-image mb-2" style={{maxWidth: '150px'}} /> */}
                        <h5 className='mb-0'>{store.business_name}</h5>
                        <address className='mb-0'>
                            {store.address}
                        </address>
                        <div>{store.email}</div>
                        <div>{store.phone_no}</div>
                    </div>)
        }
    }

    return displayStoreInfo()
}
