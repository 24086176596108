import React, { useEffect, useState } from 'react';
import { Outlet, Link, useParams } from "react-router-dom";
import images from '../../../../helpers/images';
import appSession from '../../../../helpers/session';
import helpers from '../../../../helpers/helpers';
import SaversSideMenu from './SaversSideMenu';

export default function SaversHeader({menu}){
    const { group_id } = useParams();

    useEffect(() => {
        
    }, []);

    function setMenuOpen(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-open'
        }

        return classes;
    }


    function setCurrentMainMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'pointer nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'

            // if(parent_menu){
            //     classes += 'bg-navy text-light'
            // }
            // else{
            //     classes += 'bg-secondary'
            // }
        }

        return classes;
    }

    function setCurrentSubMenu(menu_id = [], current_page = '', parent_menu=false){
        let classes = 'nav-link nav-link-main'

        if(menu_id.includes(current_page)){
            classes += ' active'
        }

        return classes;
    }

    function AccessPermission({ allowedPermissions=[], children }){
        const authorized = helpers.checkPermissions(allowedPermissions);

        if(allowedPermissions.length && !authorized){
            //return <Navigate replace to="/" />
            return ''
        }
    
        return children;
    }

    function setMenuOpen(menu_id = [], current_page = ''){
        let classes = 'nav-item'

        if(menu_id.includes(current_page)){
            classes += ' menu-is-opening menu-open'
        }
        return classes;
    }

    function getStoreName(){
        let store_data = helpers.getDefaultStore()

        if(store_data.hasOwnProperty('name')){
            return store_data.name;
        }
    }

    return (
        <>

            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-dark">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    
                    {/* <li className="nav-item dropdown">
                        <Link className="nav-link" to="/store/profile">
                            <i className="fas fa-users" />
                            <span className='d-none d-md-inline-block ml-2'>{getStoreName()}</span>
                        </Link>
                    </li> */}

                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fas fa-plus-square" />
                            {/* <span className='d-none d-md-inline-block ml-2'>New</span> */}
                        </a>
                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right" style={{left: 'inherit', right: 0}}>
                            <a className="dropdown-item mb-1" href={`/app/savers/${group_id}/group-shares`}>
                                <i className="fas fa-tag mr-1" style={{width: '20px'}} /> Group Share
                            </a>
                            <a className="dropdown-item mb-1" href="/inventory-adjustment/form">
                                <i className="fas fa-percentage mr-1" style={{width: '20px'}} /> Inventory Adjustment
                            </a>
                            
                            <div className="dropdown-divider" />
                            <a className="dropdown-item mb-1" href="/sales/form">
                                <i className="fas fa-shopping-cart mr-1" style={{width: '20px'}} /> Sales Order
                            </a>
                            <a className="dropdown-item mb-1" href="/invoices/form">
                                <i className="fas fa-file mr-1" style={{width: '20px'}} /> Invoice
                            </a>
                            <a className="dropdown-item mb-1" href="/contacts/form">
                                <i className="fas fa-user-tag mr-1" style={{width: '20px'}} /> Contacts
                            </a>
                            <a className="dropdown-item" href="/payment-receives/form">
                                <i className="fas fa-receipt mr-1" style={{width: '20px'}} /> Receive Payment
                            </a>
                            <a className="dropdown-item mb-1" href="/shipments/form">
                                <i className="fas fa-shipping-fast mr-2" /> Ship Order
                            </a>
                            
                            <div className="dropdown-divider" />
                            <a className="dropdown-item mb-1" href="/purchases/form">
                                <i className="fas fa-shopping-bag mr-1" style={{width: '20px'}} /> Purchase Order
                            </a>
                            <a className="dropdown-item mb-1" href="/bills/form">
                                <i className="fas fa-file-alt mr-1" style={{width: '20px'}} /> Bill
                            </a>
                            <a className="dropdown-item" href="/payment-made/form">
                                <i className="fas fa-money-check mr-1" style={{width: '20px'}} /> Pay Bill
                            </a>
                            <a className="dropdown-item" href="/vendors/form">
                                <i className="fas fa-store mr-1" style={{width: '20px'}} /> Vendor
                            </a>
                            <a className="dropdown-item mb-1" href="/purchase-receives/form">
                                <i className="fas fa-dolly-flatbed mr-1" style={{width: '20px'}} /> Receive Purchase
                            </a>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
                            {/* <i className="fas fa-portrait" /> */}
                            <i className="far fa-user-circle" />
                            {/* <span className='d-none d-md-inline-block ml-2'>{helpers.getUserName()}</span> */}
                        </a>
                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right" style={{left: 'inherit', right: 0}}>
                            <Link className="dropdown-item" to="/account/profile">
                                <i className="fas fa-user-edit mr-2" /> Profile
                            </Link>

                            <Link className="dropdown-item mt-1" to="/settings">
                                <i className="fas fa-cog mr-2" /> Settings
                            </Link>

                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" data-widget="control-sidebar" data-slide="true" onClick={() => appSession.logout()}><i className="fas fa-sign-out-alt"></i> Logout</Link>
                        </div>
                    </li>

                    <li className="nav-item">

                        <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                            <i className="fas fa-th"></i>
                        </a>
                    </li>
                </ul>
            </nav>

            <SaversSideMenu menu={menu} />

            <Outlet />

            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-light">
                <div className='p-3'>
                    <h5>Apps</h5>
                    <hr/>
                    <a href='https://linkmo.co/' target='_blank' className='text-dark'>
                        <div className="border rounded p-3 mb-2 text-center">
                        <div><img src={images.linkmo_logo} alt="Linkmo.co Logo" width={130}/></div>
                            <small>URL Shortener with QR Code</small>
                        </div>
                    </a>

                    <a href='https://topventory.com/' target='_blank' className='text-dark'>
                        <div className="border rounded p-3 mb-2 text-center">
                            <div><img src={images.topventory_logo} alt="Topventory Logo" width={170}/></div>
                            <small>Sales & Inventory Software</small>
                        </div>
                    </a>

                    <a href='https://saver.asinso.net' className='text-dark' onClick={(e) => e.preventDefault()}>
                        <div className="border rounded p-3 mb-2 text-center">
                            <div><img src={images.asinso_logo} alt="Asinso Logo" width={120}/></div>
                            <small>Savings & Credit Software</small>    
                            <span className='badge badge-primary'>Coming Soon</span>         
                        </div>
                    </a>

                    <a href='https://qrmakes.com' className='text-dark' onClick={(e) => e.preventDefault()}>
                        <div className="border rounded p-3 mb-2 text-center">
                            <div><b>QR Makes</b></div>    
                            <small>QR Code Generator</small>    
                            <span className='badge badge-primary'>Coming Soon</span>         
                        </div>
                    </a>
                </div>
            </aside>

        </>
    )
}