import React, { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
 
export default function SaversContentHeader ({ 
        title = '', 
        breadcrumbs = true, 
        parent_link = '#', 
        parent_text = '',
        containerClass="container-fluid"
    }){
    
    useEffect(() => {
        //Mounted
    }, []);
    
    return (
        <>
            <section className="content-header">
                <div className={containerClass}>
                    <div className="row mb-2">
                        <div className="col-sm-12">

                            {
                            breadcrumbs ? 
                            <ol className="breadcrumb mb-3 float-md-right">
                                <li className="breadcrumb-item"><Link to="/" className='text-secondary'>Home</Link></li>

                                {
                                    parent_text ? <li className="breadcrumb-item"><Link to={parent_link} className='text-secondary'>{parent_text}</Link></li> : ''
                                }

                                <li className="breadcrumb-item active"><b>{title}</b></li>
                            </ol> 
                            : ""
                            }

                            
                            <h4 className="mb-0">{title}</h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}